import { Resource } from './types';

export const snapshotResourceCompare = (a: Resource, b: Resource) => {
  return a?.snapshotRelationCreated?.localeCompare(b?.snapshotRelationCreated);
};

export const zoneResourceCompare = (a: Resource, b: Resource) => {
  if (a?.name === b?.name) {
    return a?.id - b?.id;
  }
  return a?.name.localeCompare(b?.name);
};
