import * as category from '../category';

export const PROJECT_SETTING = `${category.PROJECT}Setting`;
export const PROJECT_VOLUME_UNIT_ADD_BTN = `${category.PROJECT}VolumeUnitAddBtn`;
export const PROJECT_VOLUME_UNIT_DEL_BTN = `${category.PROJECT}VolumeUnitDelBtn`;
export const PROJECT_MEMBER_SETTING = `${category.PROJECT}MemberSetting`;
export const PROJECT_SEARCH = `${category.PROJECT}Search`;
export const PROJECT_SNAPSHOT_SORT = `${category.PROJECT}SnapshotSort`;
export const PROJECT_SNAPSHOT_TAB = `${category.PROJECT}SnapshotTab`;
export const PROJECT_SORT = `${category.PROJECT}Sort`;
export const RETURN_TO_PROJECT_LIST_BTN = `ReturnToProjectListBtn`;
export const PROJECT_FILE_ARCHIVE_MENU = `${category.PROJECT}FileArchiveMenu`;

/**
 *  근노자 위치
 */
export const PROJECT_WORKER_HISTORY_MENU = `${category.PROJECT}WorkerHistoryMenu`;
export const PROJECT_WORKER_HISTORY_DAY_SORT = `${category.PROJECT}WorkerHistoryDaySort`;
export const PROJECT_WORKER_HISTORY_WEEK_SORT = `${category.PROJECT}WorkerHistoryWeekSort`;
export const PROJECT_WORKER_HISTORY_MONTH_SORT = `${category.PROJECT}WorkerHistoryMonthSort`;
export const PROJECT_WORKER_HISTORY_DATE_SORT = `${category.PROJECT}WorkerHistoryDateSort`;
export const PROJECT_WORKER_HISTORY_VULNERABLE_ALL_SEL = `${category.PROJECT}WorkerHistoryVulnerableAllSel`;
export const PROJECT_WORKER_HISTORY_VULNERABLE_SEL = `${category.PROJECT}WorkerHistoryVulnerableSel`;
export const PROJECT_WORKER_HISTORY_NOT_VULNERABLE_SEL = `${category.PROJECT}WorkerHistoryNotVulnerableSel`;
export const PROJECT_WORKER_HISTORY_STATUS_ALL_SEL = `${category.PROJECT}WorkerHistoryStatusAllSel`;
export const PROJECT_WORKER_HISTORY_STATUS_CHECK_IN_SEL = `${category.PROJECT}WorkerHistoryStatusCheckinSel`;
export const PROJECT_WORKER_HISTORY_STATUS_STOP_LOCA_SEL = `${category.PROJECT}WorkerHistoryStatusStopLocaSel`;
export const PROJECT_WORKER_HISTORY_STATUS_START_LOCA_SELECT = `${category.PROJECT}WorkerHistoryStatusStartLocaSel`;
export const PROJECT_WORKER_HISTORY_STATUS_LOGOUT_SEL = `${category.PROJECT}WorkerHistoryStatusLogoutSel`;
export const PROJECT_WORKER_HISTORY_STATUS_TIME_LOGOUT_SEL = `${category.PROJECT}WorkerHistoryStatusTimeLogoutSel`;
export const PROJECT_WORKER_HISTORY_STATUS_LOCA_LOGOUT_SEL = `${category.PROJECT}WorkerHistoryStatusLocaLogoutSel`;
export const PROJECT_WORKER_HISTORY_CALENDAR_BTN = `${category.PROJECT}WorkerHistoryCalendarBtn`;

/**
 *  차량계 위치
 */
export const PROJECT_VEHICLE_HISTORY_MENU = `${category.PROJECT}VehicleHistoryMenu`;
export const PROJECT_VEHICLE_HISTORY_DAY_SORT = `${category.PROJECT}VehicleHistoryDaySort`;
export const PROJECT_VEHICLE_HISTORY_WEEK_SORT = `${category.PROJECT}VehicleHistoryWeekSort`;
export const PROJECT_VEHICLE_HISTORY_MONTH_SORT = `${category.PROJECT}VehicleHistoryMonthSort`;
export const PROJECT_VEHICLE_HISTORY_DATE_SORT = `${category.PROJECT}VehicleHistoryDateSort`;
export const PROJECT_VEHICLE_HISTORY_VULNERABLE_ALL_SEL = `${category.PROJECT}VehicleHistoryVulnerableAllSel`;
export const PROJECT_VEHICLE_HISTORY_VULNERABLE_SEL = `${category.PROJECT}VehicleHistoryVulnerableSel`;
export const PROJECT_VEHICLE_HISTORY_NOT_VULNERABLE_SEL = `${category.PROJECT}VehicleHistoryNotVulnerableSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_ALL_SEL = `${category.PROJECT}VehicleHistoryTypeAllSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_DUMP_TRUCK_SEL = `${category.PROJECT}VehicleHistoryTypeDumpTruckSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_EXCAVATOR_SEL = `${category.PROJECT}VehicleHistoryTypeExcavatorSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_CRANE_SEL = `${category.PROJECT}VehicleHistoryTypeCraneSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_DOZER_SEL = `${category.PROJECT}VehicleHistoryTypeDozerSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_LOADER_SEL = `${category.PROJECT}VehicleHistoryTypeLoaderSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_GRADER_SEL = `${category.PROJECT}VehicleHistoryTypeGraderSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_SCRAPER_SEL = `${category.PROJECT}VehicleHistoryTypeScraperSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_ROLLER_SEL = `${category.PROJECT}VehicleHistoryTypeRollerSel`;
export const PROJECT_VEHICLE_HISTORY_TYPE_OTHER_SEL = `${category.PROJECT}VehicleHistoryTypeOtherSel`;
export const PROJECT_VEHICLE_HISTORY_CALENDAR_BTN = `${category.PROJECT}VehicleHistoryCalendarBtn`;
/**
 * 차량계 위치 경로
 */
export const PROJECT_VEHICLE_HISTORY_ROUTE_BTN = `${category.PROJECT}VehicleHistoryRouteBtn`;
/**
 * 현장 경계 확인
 */
export const PROJECT_SETTING_WORKER_BOUNDARY_BTN = `${category.PROJECT}SettingWorkerBoundaryBtn`;
