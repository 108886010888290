const MAIN = '#FF9800';
const DARK = '#C77700';
const LIGHT = '#FFB547';
const CONTRAST_TEXT = '#1D2125';

export const warning = {
  MAIN,
  DARK,
  LIGHT,
  CONTRAST_TEXT,
};
