export const keys = {
  all: () => ['project'],
  list: (searchKeyword?: string, order?: string, page?: number) => [
    'project',
    'list',
    searchKeyword,
    order,
    page,
  ],
  update: () => ['project', 'list'],
  detail: (projectId: number) => ['project', 'detail', projectId],
  unitList: (projectId: number) => ['project', 'unit', projectId],
};
