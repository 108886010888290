import { AnnotationType } from 'infrastructure/query/annotation/types';
import { annotationKeys, resourceKeys } from 'infrastructure/query/queryKeys';
import { useMutation, useQueryClient } from 'react-query';
import api from './api';
import { RelationResource } from './types';

export function useRelationMutation() {
  const queryClient = useQueryClient();
  const createRelation = useMutation(
    ({ snapshotId, resource }: { snapshotId: number; resource: RelationResource }) =>
      api.create({ snapshotId, resourceId: resource?.id }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(resourceKeys.listByType(v?.resource?.type));
        queryClient.invalidateQueries(resourceKeys.detail(v?.resource?.id));
      },
    },
  );
  const deleteRelation = useMutation(
    ({ resource: r }: { resource: RelationResource }) =>
      api.delete({ resourceId: r?.id, snapshotRelationId: r?.snapshotRelationId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(resourceKeys.listByType(v?.resource?.type));
      },
    },
  );
  return { deleteRelation, createRelation };
}

export function useAnnotationRelationMutation() {
  const queryClient = useQueryClient();
  const createAnnotationRelation = useMutation(
    ({
      snapshotId,
      annotationId,
    }: {
      annotationType: AnnotationType;
      zoneId: number;
      annotationId: number;
      snapshotId: number;
    }) => api.annotation.create({ snapshotId, annotationId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(
          annotationKeys.listByZoneId(v.annotationType, v.zoneId, v.snapshotId),
        );
        queryClient.invalidateQueries(
          annotationKeys.listBySnapshotId(v.annotationType, v.snapshotId),
        );
        queryClient.invalidateQueries(annotationKeys.detail(v.annotationId));
      },
    },
  );
  const deleteAnnotationRelation = useMutation(
    ({
      annotationId,
      snapshotRelationId,
    }: {
      zoneId: number;
      snapshotId: number;
      annotationId: number;
      snapshotRelationId: number;
      annotationType: AnnotationType;
    }) => api.annotation.delete({ annotationId, snapshotRelationId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(
          annotationKeys.listByZoneId(v.annotationType, v.zoneId, v.snapshotId),
        );
        queryClient.invalidateQueries(
          annotationKeys.listBySnapshotId(v.annotationType, v.snapshotId),
        );
      },
    },
  );
  return { deleteAnnotationRelation, createAnnotationRelation };
}

export function useChainRelationMutation() {
  const queryClient = useQueryClient();
  const createChainRelation = useMutation(
    ({ snapshotId, resourceId }: { snapshotId: number; resourceId: number }) =>
      api.chain.create({ snapshotId, resourceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(annotationKeys.chain.listAll());
      },
    },
  );
  const deleteChainRelation = useMutation(
    ({ resourceId, snapshotRelationId }: { resourceId: number; snapshotRelationId: number }) =>
      api.chain.delete({ resourceId, snapshotRelationId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(annotationKeys.chain.listAll());
      },
    },
  );
  return { deleteChainRelation, createChainRelation };
}
