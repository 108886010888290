import { QueryKey } from 'react-query';

export const keys: {
  [key: string]: (...args: any[]) => QueryKey;
} = {
  all: () => ['indoorVideo'],
  folderAll: () => ['indoorVideo', 'folder'],
  folderList: (buildingId: number) => ['indoorVideo', 'folder', buildingId],
  list: (folderId: number) => ['indoorVideo', 'list', folderId],
  detail: (id: number) => ['indoorVideo', 'detail', id],
  walkthroughAll: (id: number) => ['indoorVideo', id, 'walkthrough'],
  metaDataJson: (id: number) => ['indoorVideo', id, 'metaDataJson'],
  miniMap: (id: number) => ['indoorVideo', id, 'minimap'],
};
