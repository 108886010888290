import { apiAxios } from 'common/api/common';
import { Snapshot } from 'stores/data/types';

export default {
  async create({ zoneId, data }: { zoneId: number; data: Snapshot }) {
    const response = await apiAxios.post(`/api/v3/zones/${zoneId}/snapshots`, data);
    return response.data;
  },
  async read({
    zoneId,
    snapshotId,
    token,
  }: {
    zoneId: number;
    snapshotId: number;
    token?: string;
  }) {
    const response = await apiAxios.get<Snapshot>(
      `/api/v3/zones/${zoneId}/snapshots/${snapshotId}`,
      {
        params: { token },
      },
    );
    return response.data;
  },
  async update({
    zoneId,
    snapshotId,
    data,
  }: {
    zoneId: number;
    snapshotId: number;
    data: Snapshot;
  }) {
    const response = await apiAxios.patch(`/api/v3/zones/${zoneId}/snapshots/${snapshotId}`, data);
    return response.data;
  },
  async delete({ zoneId, snapshotId }: { zoneId: number; snapshotId: number }) {
    const response = await apiAxios.delete(`/api/v3/zones/${zoneId}/snapshots/${snapshotId}`);
    return response.data;
  },
  async list({ zoneId, onlyDsm }: { zoneId: number; onlyDsm?: boolean }) {
    const params = onlyDsm ? { processing_status: 'DONE', has_dsm: 'true' } : {};
    const response = await apiAxios.get<{ results: Snapshot[] }>(
      `/api/v3/zones/${zoneId}/snapshots`,
      { params, timeout: 60_000 },
    );
    return response.data?.results;
  },
  report: {
    async create({
      snapshotId,
      inputData,
      annotationIds,
    }: {
      snapshotId: number;
      inputData: string[];
      annotationIds: number[];
    }) {
      const response = await apiAxios.post<{ resourceId: number }>(
        `/api/v3/snapshots/${snapshotId}/report`,
        { inputData, annotationIds },
      );

      return response?.data;
    },
  },
};
