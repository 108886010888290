import { css } from 'styled-components';

/**
 * Material-UI 4.13에는 추가되었으나 @material-ui 라이브러리가 현재 4.11까지 지원하므로 임시로 공통화함
 * https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%96%A5-Platform-Styles-%F0%9F%96%A5?node-id=1016%3A735
 */
export const tooltipTextStyle = css`
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.6;
`;
