import { css } from 'styled-components';

export const defaultButtonStyle = css`
  border-radius: 0.25rem;
  box-shadow: none;
  padding: 0.375rem 1rem;
  word-break: keep-all;

  &:focus {
    outline: none;
  }

  .MuiButton-startIcon {
    display: inline-block;
    margin-left: -0.25rem;
    margin-right: 0.25rem;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1.25rem;
    }
  }

  .MuiButton-endIcon {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: -0.25rem;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1.25rem;
    }
  }

  &.MuiButton-sizeLarge {
    padding: 0.5rem 1.375rem;

    .MuiButton-iconSizeLarge > *:first-child {
      font-size: 1.375rem;
    }
  }

  &.MuiButton-sizeSmall {
    padding: 0.25rem 0.625rem;
    font-size: 0.8125rem;
    line-height: 1.3rem;

    .MuiButton-iconSizeSmall {
      &.MuiButton-startIcon {
        margin-left: -0.125rem;
      }
      &.MuiButton-endIcon {
        margin-right: -0.125rem;
      }
      > *:first-child {
        font-size: 1.125rem;
      }
    }
  }
`;
