import GA from 'infrastructure/ga';
import * as category from '../category';

export const ANALYSIS_START_START_BTN = `${category.ANALYSIS_START}StartBtn`;
export const ANALYSIS_START_CON_GCP_BTN = `${category.ANALYSIS_START}ConGCPBtn`;

/** 분석 시작 */
export const sendAnalysisStartStartBtn = () => {
  GA.event(category.ANALYSIS_START, ANALYSIS_START_START_BTN);
};

/** GCP 마킹 페이지로 이동 */
export const sendAnalysisStartConGcpBtn = () => {
  GA.event(category.ANALYSIS_START, ANALYSIS_START_CON_GCP_BTN);
};
