import GA from 'infrastructure/ga';
import { TWO_D_EARTHWORK_ZONE } from '../category';

const TWO_D_EARTHWORK_ZONE_EDIT_BTN = `${TWO_D_EARTHWORK_ZONE}EditBtn`;
const TWO_D_EARTHWORK_ZONE_EDITOR_ZONE_ADD_BTN = `${TWO_D_EARTHWORK_ZONE}EditorZoneAddBtn`;
const TWO_D_EARTHWORK_ZONE_EDITOR_ZONE_DEL_BTN = `${TWO_D_EARTHWORK_ZONE}EditorZoneDelBtn`;
const TWO_D_EARTHWORK_ZONE_EDITOR_SAVE_BTN = `${TWO_D_EARTHWORK_ZONE}EditorSaveBtn`;
const TWO_D_EARTHWORK_ZONE_VER_SET_BTN = `${TWO_D_EARTHWORK_ZONE}VerSetBtn`;
const TWO_D_EARTHWORK_ZONE_VER_SET_APPLY_DATE = `${TWO_D_EARTHWORK_ZONE}VerSetApplyDate`;
const TWO_D_EARTHWORK_ZONE_VER_SET_MORE_EDIT_INFO_MENU = `${TWO_D_EARTHWORK_ZONE}VerSetMoreEditIInfoMenu`;
const TWO_D_EARTHWORK_ZONE_VER_SET_MORE_DEL_MENU = `${TWO_D_EARTHWORK_ZONE}VerSetMoreDelMenu`;

export const sendTwoDEarthworkZoneEditBtn = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_EDIT_BTN);
export const sendTwoDEarthworkZoneEditorZoneAddBtn = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_EDITOR_ZONE_ADD_BTN);
export const sendTwoDEarthworkZoneEditorZoneDelBtn = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_EDITOR_ZONE_DEL_BTN);
export const sendTwoDEarthworkZoneEditorSaveBtn = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_EDITOR_SAVE_BTN);
export const sendTwoDEarthworkZoneVerSetBtn = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_VER_SET_BTN);
export const sendTwoDEarthworkZoneVerSetApplyDate = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_VER_SET_APPLY_DATE);
export const sendTwoDEarthworkZoneVerSetMoreEditInfoMenu = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_VER_SET_MORE_EDIT_INFO_MENU);
export const sendTwoDEarthworkZoneVerSetMoreDelMenu = () =>
  GA.event(TWO_D_EARTHWORK_ZONE, TWO_D_EARTHWORK_ZONE_VER_SET_MORE_DEL_MENU);
