import { FileArchiveProps } from './types';

export const keys = {
  list: (
    projectId: FileArchiveProps['projectId'],
    page: FileArchiveProps['page'],
    size: FileArchiveProps['size'],
    search: FileArchiveProps['search'],
  ) => ['archive', projectId, { page, size, search }],
};
