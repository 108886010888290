import { Atom, PrimitiveAtom, WritableAtom, atom } from 'jotai';

interface NDVIAtom {
  expanded: PrimitiveAtom<boolean>;
  visible: { map: Atom<boolean>; menu: Atom<boolean> };
  checked: {
    items: PrimitiveAtom<{ [x in NDVIControlsAtom['id']]: boolean }>;
    all: {
      write: WritableAtom<boolean, [update: boolean], void>;
      read: Atom<boolean>;
      indeterminate: Atom<boolean>;
    };
  };
  listControls: PrimitiveAtom<NDVIControlsAtom[]>;
}
export const ndvi: NDVIAtom = {
  expanded: atom(false),
  visible: {
    map: atom((get) => {
      const items = get(ndvi.checked.items);
      return Object.values(items).some((v) => v);
    }),
    menu: atom((get) => {
      const mapVisible = get(ndvi.visible.map);
      return mapVisible || get(ndvi.expanded);
    }),
  },
  checked: {
    items: atom({} as { [x in NDVIControlsAtom['id']]: boolean }),
    all: {
      write: atom(false, (get, set, update: boolean) => {
        const items = get(ndvi.checked.items);
        const idList = Object.keys(items);
        if (idList?.length === 0) {
          set(ndvi.checked.all.write, update);
          return;
        }

        const indeterminate = get(ndvi.checked.all.indeterminate);
        const newItems = idList.reduce(
          (acc, key) => ({ ...acc, [key]: indeterminate ? false : update }),
          {},
        );
        set(ndvi.checked.items, newItems);
      }),
      read: atom((get) => {
        const items = get(ndvi.checked.items);
        const current = get(ndvi.checked.all.write);
        const values = Object.values(items);
        return values?.length > 0 ? values?.every((v) => v) : current;
      }),
      indeterminate: atom((get) => {
        const items = get(ndvi.checked.items);
        const values = Object.values(items);
        return values?.length > 0 ? values?.some((v) => v) && values?.some((v) => !v) : false;
      }),
    },
  },
  listControls: atom<NDVIControlsAtom[]>([]),
};

export interface NDVIControlsAtom {
  histogram: { [index: number]: { count: number; color: [number, number, number] } };
  range: [number, number];
  opacity?: number;
  id: number | 'whole' | 'vari' | 'variGreen';
}
