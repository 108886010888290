import { IssueSideMenuMode } from 'components/snapshot/detailsPage/types';
import { Atom, PrimitiveAtom, atom } from 'jotai';

export interface IssueAtom {
  expanded: { root: PrimitiveAtom<boolean>; done: PrimitiveAtom<boolean> };
  checked: {
    running: PrimitiveAtom<boolean>;
    done: PrimitiveAtom<boolean>;
  };
  visible: { map: Atom<boolean>; menu: Atom<boolean> };
  mode;
}

const issueSideMenuModeAtom = atom('show');

export const issueAtom: IssueAtom = {
  expanded: { root: atom(false), done: atom(false) },
  checked: {
    running: atom(false),
    done: atom(false),
  },
  visible: {
    map: atom((get) => {
      const running = get(issueAtom.checked.running);
      const done = get(issueAtom.checked.done);
      return running || done;
    }),
    menu: atom((get) => {
      const visibleMap = get(issueAtom.visible.map);
      const expanded = get(issueAtom.expanded.root);
      return visibleMap || expanded;
    }),
  },
  mode: issueSideMenuModeAtom as Atom<IssueSideMenuMode>,
};
