import { PhotoBoxMediaEnum } from 'components/photoBox/constants/common.const';

/* 통신에 사용되는 변수 */
export enum FileResourceEnum {
  IMAGE = 'PHOTOBOX_IMAGE',
  VIDEO = 'PHOTOBOX_VIDEO',
}
/* v4 api response 포맷 정의 -> 논의 후 common 으로 넘길 예정 */
export interface V4Response<T1, T2> {
  result: T1;
  meta: T2;
}
export interface MediaListMeta {
  totalCount: number;
  hasNext: boolean;
}
/* 미디어 파일 관리 */
export interface PhotoBoxMediaListResult {
  mediaList: PhotoBoxMediaResponseEntity[];
}
export interface PhotoBoxFileResourceThumbnailEntity {
  file?: string;
}
export interface PhotoBoxMediaMetaEntity {
  videoDuration: number;
}
export interface CreatedByUserEntity {
  id: number;
  name: string;
  companyName?: string;
  job?: string;
}
export interface PhotoBoxMediaEntity {
  projectId: number;
  zoneId: number;
  resource: PhotoBoxFileResourceEntity;
  type: PhotoBoxMediaEnum;
  latitude?: number;
  longitude?: number;
  coordinate?: string;
  memo?: string;
  shootingDate?: string;
  mediaMeta?: PhotoBoxMediaMetaEntity;
  file?: File;
}
export interface PhotoBoxMediaResponseEntity extends PhotoBoxMediaEntity {
  id: number;
  resourceThumbnail: PhotoBoxFileResourceThumbnailEntity;
  created: string;
  createdByUser: CreatedByUserEntity;
}
export interface PhotoBoxFileResourceEntity {
  file: string;
  fileName: string;
  type: FileResourceEnum;
  size: number;
}
/* 미디어 관리 통신 관련 */
export interface MediaListParams {
  lastMediaId?: number;
  limit: number;
  projectId: number;
  type?: PhotoBoxMediaEnum;
  zoneId?: number;
}
export interface UploadMediaFileParams {
  mediaList: PhotoBoxMediaEntity[];
}
export interface UpdateMediaEntity {
  id: number;
  projectId: number;
  zoneId: number;
  latitude?: number;
  longitude?: number;
  coordinate?: string;
  memo?: string;
  shootingDate?: string;
  mediaMeta?: PhotoBoxMediaMetaEntity;
}
export interface UpdateMediaFileParams {
  mediaList: UpdateMediaEntity[];
}
/* 앨범 관리 */
export interface PhotoBoxAlbumListResult {
  albumList: PhotoBoxAlbumResponseEntity[];
}
export interface PhotoBoxAlbumEntity {
  projectId: number;
  latitude?: number;
  longitude?: number;
  coordinate?: string;
  name: string;
  createdByUser: CreatedByUserEntity;
  created: string;
  mediaCount: number;
  albumCoverMedia: PhotoBoxMediaResponseEntity;
}
export interface PhotoBoxAlbumResponseEntity extends PhotoBoxAlbumEntity {
  id: number;
}
export interface CreatePhotoBoxAlbumParams {
  projectId: number;
  latitude?: number;
  longitude?: number;
  coordinate?: string;
  name: string;
}
export interface CreatePhotoBoxAlbumResult {
  album: PhotoBoxAlbumResponseEntity;
}
export interface UpdatePhotoBoxAlbumParams {
  albumId: number;
  latitude?: number;
  longitude?: number;
  coordinate?: string;
  name: string;
}
export interface PhotoBoxAlbumDetailListParams {
  albumId: number;
  lastMediaId?: number;
  limit: number;
}
export interface PhotoBoxAlbumDetailListResult {
  albumId: number;
  mediaList: PhotoBoxMediaResponseEntity[];
}
export interface AddPhotoBoxMediaFileToAlbumParams {
  albumId: number;
  mediaIdList: number[];
}
export interface DeletePhotoBoxMediaFileFromAlbumParams {
  albumId: number;
  mediaIdList: number[];
}
