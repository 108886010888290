import { atom } from 'jotai';

export interface ClassState {
  key: string;
  name: string;
  visible: boolean;
  colorHex: string;
  colorRgb: [number, number, number];
  Icon: React.FunctionComponent;
  area: number;
}

const segmentationEnabledAtom = atom(false);
const segmentationAccordionExpandedAtom = atom(false);
const segmentationOpacityAtom = atom(0.5);
const segmentationClassStatesAtom = atom<ClassState[] | false>(false);

export const segmentationAtom = {
  enabled: segmentationEnabledAtom,
  expanded: segmentationAccordionExpandedAtom,
  opacity: segmentationOpacityAtom,
  classStates: segmentationClassStatesAtom,
};
