import { css } from 'styled-components';
import { primary } from 'components/styles/colors/primary';
import { text } from 'components/styles/colors/text';

export const DEFAULT_FONT_FAMILY =
  'Pretendard, PretendardJP, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, sans-serif';

export const textSecondary = css`
  font-size: 1rem;
  color: ${text.SECONDARY};
`;

export const textPrimary = css`
  font-size: 1rem;
  line-height: 1.75rem;
  color: ${text.PRIMARY};
`;

export const buttonText = css`
  font-size: 1.125rem; // 18px
  font-weight: 700;
`;

export const buttonTextPrimary = css`
  ${buttonText};
  color: ${primary.CONTRAST_TEXT};
`;

export const buttonTextDefault = css`
  ${buttonText};
  color: ${text.PRIMARY};
`;

export const body1 = css`
  color: ${text.PRIMARY};
  font-size: 1rem;
`;
