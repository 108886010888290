import { AxiosError } from 'axios';
import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { QueryStatus, useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { ExportArchive, ExportArchiveType, ExportUrls, FilePathType } from './types';

export function useExportUrlQuery(snapshotId: number): {
  data: ExportUrls | undefined;
  status: QueryStatus;
  errorCode: string | undefined;
} {
  const { data, status, error } = useQuery<ExportUrls, AxiosError<Error>, ExportUrls>(
    keys.detail(snapshotId),
    () => api.read(snapshotId),
    {
      enabled: !!snapshotId,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
  return { data, status, errorCode: error?.response?.data?.errorCode };
}

export function useCheckExistFileQuery(
  snapshotId: number,
  fileTypes: string[],
): {
  data: FilePathType;
  status: QueryStatus;
  errorCode: string | undefined;
} {
  const { data, status, error } = useQuery<FilePathType, AxiosError<Error>, FilePathType>(
    keys.checkExist(snapshotId),
    () => api.checkExist(snapshotId, fileTypes),
    {
      enabled: Boolean(snapshotId && fileTypes.length),
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
  return { data, status, errorCode: error?.response?.data?.errorCode };
}

export function useExportArchiveQuery(
  snapshotId: number,
  type: ExportArchiveType,
  resourceId: number,
): {
  data: ExportArchive;
  status: QueryStatus;
  errorCode: string | undefined;
} {
  const { data, status, error } = useQuery<ExportArchive, AxiosError<Error>, ExportArchive>(
    keys.archiveDetail(snapshotId, type),
    () => api.archive.read(snapshotId, resourceId),
    {
      enabled: Boolean(snapshotId && resourceId),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      refetchInterval: (d) => (d?.status === 'ARCHIVING' ? DEFAULT_POLLING_INTERVAL : false),
    },
  );
  return { data, status, errorCode: error?.response?.data?.errorCode };
}

export function useExportArchiveMutation(): { [key: string]: ReturnType<typeof useMutation> } {
  const queryClient = useQueryClient();
  const createExportArchive = useMutation(
    ({ snapshotId, fileType }: { snapshotId: number; fileType: ExportArchiveType }) =>
      api.archive.create(snapshotId, fileType),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          keys.archiveDetail(variables.snapshotId, variables.fileType),
          data,
        );
      },
    },
  );
  return { createExportArchive };
}
