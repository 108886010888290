import * as category from '../category';

export const SNAPSHOT_ADD_BTN = `${category.SNAPSHOT}AddBtn`;
export const SNAPSHOT_CARD = `${category.SNAPSHOT}Card`;
export const SNAPSHOT_DATE = `${category.SNAPSHOT}Date`;
export const SNAPSHOT_DEL_MENU = `${category.SNAPSHOT}DelMenu`;
export const SNAPSHOT_DOWN_BTN = `${category.SNAPSHOT}DownBtn`;
export const SNAPSHOT_EDIT_MENU = `${category.SNAPSHOT}EditMenu`;
export const SNAPSHOT_MORE_BTN = `${category.SNAPSHOT}MoreBtn`;
export const SNAPSHOT_NEXT_BTN = `${category.SNAPSHOT}NextBtn`;
export const SNAPSHOT_PREVIOUS_BTN = `${category.SNAPSHOT}PreviousBtn`;
export const SNAPSHOT_PRINT_BTN = `${category.SNAPSHOT}PrintBtn`;
export const SNAPSHOT_MORE_SHARE_MENU = `${category.SNAPSHOT}MoreShareMenu`;
export const SNAPSHOT_MORE_EDIT_MENU = `${category.SNAPSHOT}MoreEditMenu`;
