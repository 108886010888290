/* eslint-disable no-template-curly-in-string */
export default {
  /* commom */
  add: 'Add',
  cancel: 'Cancel',
  close: 'Close',
  delete: 'Delete',
  modified: 'Edit',
  done: 'Done',
  confirm: 'Confirm',
  home: 'Home',
  project: 'Project',
  myInfo: 'My Profile',
  manual: 'Manual',
  notice: 'Notice',
  logout: 'Log out',
  save: 'Save',
  edited: 'edited',
  refresh: 'Refresh',
  addNew: 'Add New',
  reset: 'Reset',
  downloadButton: 'Download',
  logoutTitle: 'Confirm Log out',
  logoutMessage: 'Will you log out of your current account?',
  warningBrowser:
    "Some operations may not work smoothly if it's not a recommended browser. Please use the recommended browser.",
  dontShowAgain: "Don't show this again",
  total: 'All',
  select: 'Select',

  twoDMapButton: '2D',
  threeDMapButton: '3D',
  back: 'Back',
  copy: 'Copy',
  change: 'Change',
  stopButton: 'Stop',
  continuedButton: 'Continue',
  nextButton: 'Next',
  prevButton: 'Back',
  submitButton: 'Submit',
  skip: 'Skip',
  retry: 'Retry',
  next: 'Next',
  noPermissionMsgContent: 'You do not have permission to use this feature.',
  titleExitWithoutSaving: 'Verify changes',
  exitWithoutSaving: 'Would you like to leave this page without saving your changes?',

  textFeedbackErrorMessage: 'An error has occurred. Please try again.',
  textFeedbackSuccessMessage: 'Your changes have been saved.',
  textFeedbackLoadingMessage: 'Please wait.',
  textFeedbackWarningMessage: 'Warning',

  noticeTitle: 'Notice',
  noticeNewTitle: 'New',
  noticeErrorTitle: 'Warning',
  noticeDontShowAgainButton: "Don't show this message again",
  noticeDontShowAgainMessage:
    'If you want to see this message again, click the notice icon on the left!',
  noticeDisableMessage: "Don't show again today",
  moreInformation: 'View More',
  downloadChromeBrowser: 'Download Chrome',

  BIM: 'BIM',
  currentDsm: 'Ground Level',

  /* components/loginPage/LoginPage */
  loginTitle: 'Log In',
  password: 'Password',
  inputEmail: 'Enter your email',
  inputPw: 'Enter your password',
  checkPwLength: 'Enter at least 8 characters mixed with letters and numbers.',
  findPassword: 'Forgot your password?',
  email: 'Email',
  oneStopService: 'Drone OneStop',

  /**components/userPage/content/FindPw */
  goBack: 'Back',
  generateTempPwButton: 'Send Temporary Password',
  generateTempPwDescription:
    'Enter your email address.\nWe will verify your account and send you a temporary password.',
  sentEmail: 'A temporary password has been sent.\nPlease check your inbox.',

  /* components/myPage/MyPage */
  myPageTitle: 'My Profile',
  myPageEditTitle: 'Edit Profile',
  phPassword: 'Enter your password',
  name: 'Name',
  emailAddress: 'Email',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  confirmNewPassword: 'Confirm new password',
  company: 'Company',
  jobTitle: 'Job Title',
  phName: 'Enter your name',
  phCompany: 'Enter your company',
  phJobTitle: 'Enter your job title',
  phCurrentPassword: 'Enter your current password',
  phNewPassword: 'Enter your new password',
  phConfirmPassword: 'Confirm your password',
  phConfirmNewPassword: 'Confirm your new password',
  requiredName: 'Enter your name',
  requiredCompany: 'Enter your company',
  requiredPassword: 'Enter your password',
  requiredConfirmPassword: 'Confirm your password',
  requiredConfirmNewPassword: 'Confirm your new password',
  notEqualPassword: 'Your passwords do not match',
  notEqualNewPassword: 'Your passwords do not match',
  minLength: 'Enter at least ${n} characters',
  completedMyInfoEditing: 'Your profile has been saved',
  confirmSecurityTitle: 'Security check',
  confirmSecurityMessage: 'Your changes will be saved.\nEnter your password for confirmation.',

  /* components/projectPage/content/ProjectEdit */
  latlng: 'EPSG:4326 (World Geodetic System 1984)',
  eastDatum: 'EPSG:5187',
  eastSeaDatum: 'EPSG:5188',
  centralDatum: 'EPSG:5186',
  westDatum: 'EPSG:5185',
  eastDatumBessel: 'EPSG:5176',
  eastSeaDatumBessel: 'EPSG:5177',
  centralDatumBessel: 'EPSG:5174',
  westDatumBessel: 'EPSG:5173',
  epsg6669: 'EPSG:6669',
  epsg6670: 'EPSG:6670',
  epsg6671: 'EPSG:6671',
  epsg6672: 'EPSG:6672',
  epsg6673: 'EPSG:6673',
  epsg6674: 'EPSG:6674',
  epsg6675: 'EPSG:6675',
  epsg6676: 'EPSG:6676',
  epsg6677: 'EPSG:6677',
  epsg6678: 'EPSG:6678',
  epsg6679: 'EPSG:6679',
  epsg6680: 'EPSG:6680',
  epsg6681: 'EPSG:6681',
  epsg6682: 'EPSG:6682',
  epsg6683: 'EPSG:6683',
  epsg6684: 'EPSG:6684',
  epsg6685: 'EPSG:6685',
  epsg6686: 'EPSG:6686',
  epsg6687: 'EPSG:6687',
  selectEtc: 'Input CRS',
  phToSelectCoordSystem: 'Select CRS',
  phToCoordSystem: 'Enter CRS',
  phToValidCoordinate: 'Invalid CRS',

  /* components/projectPage/content/projectMember */
  grade: 'Permissions',
  status: 'Status',
  ownerTitle: 'Owner',
  ownerDesc: 'Assign managers and use all features.',
  managerTitle: 'Manager',
  managerDesc: 'Assign users and use profile related features.',
  userTitle: 'User',
  userDesc: 'Assign guests and use mapping tools',
  guestTitle: 'Guest',
  guestDesc: 'View project user and profiles',
  pauseTitle: 'Ban project user',
  pauseDesc: 'Ban all permissions from project user',
  joinStatus: 'Joined',
  deleteUserTitle: 'Remove project user',
  cancelDeleteMode: 'Cancel',
  deleteUser: 'Would like to remove ${n} users from this project?',
  failedToDeleteUser: 'An error has occurred.\nPlease try again.',
  changeGradeTitle: 'Permission change verification',
  changeGrade: "Would you like to change the account's permissions to\n${n}?",
  failedToChangeGrade: 'Permissions cannot be changed.\nPlease try again.',
  invalidEmailFormatAlert: 'Enter a valid email address.',

  inviteParticipant: 'Invite project user',
  invite: 'Invite',
  invited: 'Invited',
  invitationTimestamp: 'Invited ${n}',
  joinTimestamp: 'Joined ${n}',
  participantGradeTooltipHeader: 'Project User Permissions',
  noMemberSelected: 'No accounts selected.',
  noInviteesMessage: "Press 'Invite' to invite new project users.",
  sendInvitation: 'Send invitation',
  sendInvitationLoadingMessage: 'Please wait.',
  sendInvitationSuccessMessage: 'Invitations have been sent.',
  sendInvitationErrorMessage: 'Failed to send invitations.',

  resendInvitation: 'Resend invitation',
  resendInvitationSuccess: 'Invitation email has been resent.',
  resendInvitationErrorTitle: 'Invitation resend error',
  resendInvitationErrorMessage: 'An error has occurred.\nTry again?',
  resendInvitationBlocked: 'Cannot resend within 1 minute.\nPlease try again later.',

  emailEmptyError: 'Enter email.',
  emailFormatError: 'Enter a valid email address.',
  emailAlreadyInvited: 'The user has already been invited.',
  emailAlreadyUser: 'The user has already joined your project.',
  gradeEmptyError: 'Select a permission level.',

  /** ProjectCard */
  projectCardSnapshotNumber: 'Number of Profile:',
  projectCardLatestSnapshot: 'Recent capture:',

  /* sidemenu */
  mapSideMenuAccordionGroupTitle: 'Map',
  measurementSideMenuAccordionGroupTitle: 'Measurement',
  layerSideMenuAccordionGroupTitle: 'Layer',
  mediaSideMenuAccordionGroupTitle: 'Media',
  issueSideMenuAccordionGroupTitle: 'Issue',
  safetySideMenuAccordionGroupTitle: 'Safety',

  gcpAccordionGoToEditorButtonTitle: 'Check GCP marking',

  measurementSideMenuAccordionGroupTooltipMessage:
    'Add tools to your map to see images of your surroundings\nor to calculate distances and elevations.',
  layerSideMenuAccordionGroupTooltipMessage:
    'You can adjust transparency or change land compensation status with data that can be viewed on a map.',

  /* File Archive */
  fileArchiveEmptyMessage: 'There is no file on site.\nTry adding a file on site.',
  fileArchiveSearchEmptyMessage: 'No results were found. \nPlease try another word.',
  fileArchiveSearchPlaceholder: 'Search by name',
  fileArchiveTableHeaderZone: 'Zone',
  fileArchiveTableHeaderFileName: 'Name',
  fileArchiveTableHeaderType: 'Type',
  fileArchiveTableHeaderDate: 'Created Date',
  fileArchiveTableHeaderDownload: 'Download',
  fileArchiveTableHeaderDelete: 'Delete',
  fileArchiveTableHeaderSelect: 'Select',
  fileArchiveTypeChain: 'Earthwork crossing line',
  fileArchiveTypePoint: 'Point',
  fileArchiveTypePolyline: 'Polyline',
  fileArchiveTypePolygon: 'Polygon',
  fileArchiveTypePlanLevel: 'Design level',
  fileArchiveTypeBim: 'BIM',
  fileArchiveTypeStratum: 'Stratum',
  fileArchiveTypeDrawingRaster: 'Auto Vector Drawing',
  fileArchiveTypeDrawingVector: 'Auto Raster Drawing',
  fileArchiveTypeDrawingPositioning: 'Manual Drawing',
  fileArchiveTypePolygonVector: 'Drawing Polygon',

  /* FileArchive - Drawing Download */
  fileArchiveDrawingDownloadTitle: 'Download drawings',
  fileArchiveDrawingDownloadDesc: 'Please compress the file first.',
  fileArchiveDrawingDownloadDescLoading: 'Compressing file. Please wait.',
  fileArchiveDrawingDownloadDescSuccess: 'Download is ready.',
  fileArchiveDrawingDownloadButton: 'Compress files',
  fileArchiveDrawingDownloadButtonSuccess: 'Download',
  fileArchiveDrawingDownloadCancelTitle: 'Cancel Compressing',
  fileArchiveDrawingDownloadCancelDesc:
    'The file has not been compressed yet.\nAre you sure you want to exit without compressing?',
  fileArchiveDrawingDownloadCancelButton: 'Continue',
  fileArchiveDrawingDownloadCancelConfirm: 'Exit',

  /* projectDetailPage - common nls */
  exportTitle: 'Download',
  idleTitle: 'Not Processed',
  runningTitle: 'Processing',
  errorTitle: 'Error',
  doneTitle: 'Completed',

  /* components/projectDetailPage/drawingConfig/DrawingConfig */
  drawingConfigTitle: 'Drawing Settings',
  drawingFirstStep:
    'Move the markers to an identifiable place by zooming in/out the drawing. (1/2)',
  drawingSecondStep: 'Drag the markers to place the drawing on the map. (2/2)',
  drawingConfigTitleWithoutSaving: 'Cancel Drawing Settings',
  drawingConfigMessageWithoutSaving: 'Your settings will not be saved.\nWould you like to exit?',
  drawingConfigNextButton: 'Next',
  drawingConfigPrevButton: 'Back',
  drawingConfigClear: 'Reset',
  drawingConfigClose: 'Exit',
  drawingConfigSubmit: 'Complete',

  /* components/projectDetailPage/mainContent/map/handleAnnotation */
  areaExitTooltip: 'Right-click or press ESC to complete.',
  areaExitTooltipTouchDevice: "Press the 'Done' button to create.",

  /* components/projectDetailPage/mainContent/map/print/PrintView */
  sizeOriginal: '1배율',
  sizeDouble: '2배율',
  sizeTriple: '3배율',
  sizeFourTimes: '4배율',
  sizeFiveTimes: '5배율',

  /* components/projectDetailPage/sideMenu/annotation/Annotation */
  meterValue: '${n}m',
  squareMeterValue: '${n}m²',
  noInformationAnnotationCreator: 'No user info',
  deletedAnnotationCreator: 'Deleted user',
  emptyAnnotationCreator: 'The data before April 20, 2023\ndoes not have user information.',

  /** resourceToAnnotation */
  resourceToAnnotationProgressRunningChip: 'Running',
  resourceToAnnotationDeleteMessage:
    '${n} If you delete the drawing, it will be permanently deleted from the project. Are you sure you want to delete?',
  resourceToAnnotationErrorMessage: 'An error occurred, please try again.',
  polygonVectorEmptyMessage: 'There is no polygon in this drawing.',
  polygonVectorUploadTitle: 'Add polygon with drawing',
  polygonVectorUploadDescription: 'Only polygon objects can be created.',
  addPolygonVectorButton: 'Add with Drawing',
  runningResourceToAnnotationTooltipMessage: 'You can link file after it is analyzed.',

  /* components/projectDetailPage/sideMenu/annotation/AnnotationTool */
  axisDataTitle: 'Horizontal Length (${n})',
  axisElevationTitle: 'Elevation (${n})',
  cutVolumeSubTitle: 'Cut',
  fillVolumeSubTitle: 'Fill',
  volumeSoil: 'Soil Volume',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDiv */
  referenceDateType: 'Reference Date',
  compareDateType: 'Comparison Date',
  planElevationType: 'Design Level',
  lengthTooltip: 'Length',
  elevationTooltip: 'Compare Elevation',
  generateElevationDownload: 'Creating DXF file now.\nPlease wait.',
  failedCreateElevationDownload: 'Failed to create elevation graph file.\nPlease try again.',
  elevationDivEmpty: 'Failed to generate graph because the segment does not overlap with the DSM.',
  elevationDivWarning: 'Areas without a DSM will be generated based on nearby data.',
  elevationDivError: 'A temporary error has occurred.',
  cancelCreateElevationDownloadTitle: 'Cancel',
  cancelCreateElevationDownload: 'Creating DXF file now.\nAre you sure you want to stop?',
  stratumElevationNth: 'Stratum${n}',
  showElevationGraph: 'View Graph',
  elevationChartDownloadTooltip: 'Download Elevation Graph',
  elevationChartCloseTooltip: 'Close',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDateDiv */
  graphDownloadTitle: 'Download Graph',
  phToEmptyElevation: '${n} not available',

  /* components/projectDetailPage/sideMenu/annotation/ElevationDownloadModal */
  graphSelectionDesc: 'Select the type of graph to download.',
  createGraphButton: 'Download',

  /* components/projectDetailPage/sideMenu/common/ErrorMsgDiv */
  zPositionWarningMsg: 'Z coordinates cannot be measured in areas outside the DSM.',
  zPositionErrorMsg: 'An error has occurred.',

  snapshotHeaderCompare: 'Site comparison',
  snapshotHeaderEditButton: 'Edit profile',
  basicShare: 'Share',
  snapshotHeaderPrintButton: 'Print',
  snapshotHeaderDownloadButton: 'Download',
  snapshotHeaderMoreButton: 'More',

  /* components/projectDetailPage/sideMenu/snapshotDetail/ExportDiv */
  etcTitle: 'Other Files',
  planLevelTitle: 'Design Level',

  downloadPopupTitle: 'Download profile',
  downloadTabImageTitle: 'Original image',
  downloadTabOrthophotoTitle: 'Orthomosaic',
  downloadTabDsmTitle: 'DSM',
  downloadTabPointCloudTitle: '3D Point Cloud',
  downloadTabMeshTitle: '3D Mesh',
  downloadTabGcpTitle: 'GCP',
  downloadTabReportTitle: 'Report',
  downloadImageName: 'images.zip',
  downloadOrthophotoName: 'Original tif',
  downloadOrthophotoPngName: 'High resolution png',
  downloadOrthophotoSampledName: 'Low resolution png',
  downloadDsmName: 'Original tif',
  downloadDsmDesc: 'Data including height value (Z)',
  downloadDsmPngName: 'High resolution png',
  downloadDsmPngDesc: 'Visualize elevation with RGB Color',
  downloadPointCloudName: 'Original las',
  downloadPointCloudPlyName: 'Original ply',
  downloadPointCloudSampledName: 'Low resolution ply',
  downloadPointCloudSampledDesc: 'Data with a reduced number of points',
  downloadMeshFbxName: 'Original fbx',
  downloadMeshObjName: 'Original obj',
  downloadMeshObjDesc: 'Includes obj, mtl and jpg file extentions',
  downloadGcpName: 'GCP',
  downloadPopupItemButton: 'Download',
  downloadArchivePopupItemButtton: 'Download ZIP file',
  downloadArchivePopupItemLoading: 'Creating ZIP file',
  downloadArchivePopupItemSuccess: 'ZIP file creating is completed. Please dowonload the file.',
  downloadArchivePopupItemError: 'An error occurred, please try again',

  downloadPopupImageView: 'Individual images (${n})',
  beta: 'Beta',
  downloadReportButton: 'Download Report',
  downloadPix4DReportLabel: 'Pix4D analysis report',
  createReportButton: 'Create report',
  creatingReportButton: 'Creating report',
  reportAnnotationInclusionModeLabel: 'Annotation options',
  reportAnnotationExtensionLabel: 'Report type',
  reportCreationLoadingMessage: 'Creating file. Please wait.',
  reportCreationCompletedMessage: 'Creating report is completed',
  downloadEmptyViewMessage: 'No Files',
  downloadEmptyViewAddButton: 'Add file',
  downloadCreateZipFileButton: 'Create ZIP file',
  downloadLoadingZipFileButton: 'Creating ZIP file',
  downloadSuccessZipFile: 'Creating ZIP file is completed',
  downloadCompensationReportComment:
    'The reports includes orthomosaic and the status of cadastral compensation.',
  downloadAnnotationReportLabel: 'Mapping tools',
  downloadCompensationReportLabel: 'Status of cadastral compensation',
  downloadSelectedAnnotationReportLabel: 'Only selected Annotation',
  downloadAllAnnotationReportLabel: 'All Annotations',
  downloadSelectedAnnotationReportComment:
    'The orthophoto and annotations that are\npresented on the current map will be included.',
  downloadAllAnnotationReportComment:
    'The orthophoto and all annotations\nfrom the archive will be included. ',

  sharePopoverInfo:
    'The link allows you to check data without logging in. Share the link with anyone who needs it.',
  sharePopoverCopyButton: 'Copy',
  sharePopoverCopyCompleteButton: 'Copied',

  /* components/projectDetailPage/sideMenu/snapshotDetail/MainDiv */
  deleteHistoryContent:
    'Deleting profile will delete all site status data as well. Are you sure you want to delete it?',

  /* components/projectDetailPage/sideMenu/snapshotDetail/PlanVolumeSlider */
  planVolumeOpacity: 'Opacity',

  /* components/projectDetailPage/sideMenu/snapshotDetail/snapshotDetail */
  deleteMsg: 'Deleted.',

  /* components/projectDetailPage/sideMenu/snapshotDetail/3D/BIM */
  BimDataLoading: 'Preparing data. Please wait.',
  BimDataError: 'An unexpected error occurred while loading. Please delete and re-upload the file.',
  bimOnPointCloudNoLocation:
    'This file has no coordinate information. It is randomly placed in the center.',
  bimOnPointCloudOutOfPointCloudBound:
    'This file is outside the map area. It is randomly placed in the center.',

  alertGcpWarning: 'GCP location is outside the site area.',

  /* components/projectDetailPage/sideMenu/snapshotNew/SnapshotNew */
  deletingSnapshot: 'Delete Profile',
  aerialImage: 'Image',
  uploadButton: 'Upload',
  uploadingButton: 'Uploading',

  stratumAliasIsEmpty: 'Enter stratum name',
  stratumFileIsEmpty: 'Select files',

  /* components/projectDetailPage/sideMenu/snapshotNew/gcp/GcpEditor */
  gcpEditorTitle: 'Mark GCP',
  gcpEditorDesc: 'At least 3 GCPs with 3 marked images are required for analysis.',
  resetGcpImageLocations: 'Reset all',
  /* gcp/GcpTable */
  gcpFileSubTitle: 'GCP List',
  gcpName: 'Name',
  positionX: 'X (Easting)',
  positionY: 'Y (Northing)',
  positionZ: 'Z (Altitude)',
  horizontalAccuracy: 'Horizontal Accuracy (${n})',
  verticalAccuracy: 'Vertical Accuracy (${n})',
  imageCount: 'Marked images',
  gcpImageCountDesc: 'Mark at least 4 images per GCP for the accuracy of the 3D model.',

  /** gcp/GcpSetting */
  settingGcpTitle: 'GCP Marker Settings',
  descriptionGcpSetting: 'Right-click to add a marker.',
  /** gcp/GcpImageList */
  gcpImageListTitle: '${n} Image List',
  gcpImageListDesc: 'Images are ordered by nearest to the selected GCP.',
  resetGcpImagesAlertTitle: 'Reset all GCP markers',
  resetGcpImagesAlert:
    'Would you like to reset all markers in ${n}? \nAuto recommended markers are also reset.',
  gcpMarkingDoneButton: 'Done marking',
  addGcpImageButton: 'Attach image',
  gcpRecommendationButton: 'GCP Recommendation',
  gcpImageManagePopupTitle: 'Manage Attached image',
  gcpImageTableCellTitle: 'Attached image',

  gcpRecommendationInProgressMessage:
    'Looking for adjacent GCPs based on marked GCPs. \nThis may take up to 2 minutes. Please wait a moment.',
  gcpRecommendationErrorTitle: 'GCP Recommendation',
  gcpRecommendationInfoMessage:
    'The recommended GCP marker may not be precised. \nYou must reposition before you can use it for GCP analysis.',
  resetGcpMarkerLocationTooltip: 'All recommended / hand-marked markers will be deleted.',
  resetGcpMarkerButtonText: 'Reset marker',
  gcpMarkerColorInPerson: 'GCP with marking completed',
  gcpMarkerColorRecommended: 'GCP with marking required',
  gcpRecommendationStopConfirmTitle: 'Cancel the GCP recommendation',
  gcpRecommendationStopConfirmMessage:
    'GCP recommendation is in progress.\n Are you sure you want to stop the job?',
  gcpRecommendationStopConfirmOk: 'Stop',
  gcpRecommendationStopConfirmCancel: 'Continue',
  gcpRecommendationTooltipHow:
    'Mark at least one image per GCP. After marking, click the Auto Recommend button to recommend multiple GCP at once.',
  gcpRecommendationTooltipWhat:
    'Once marked, the remaining images automatically recommend the GCP location.',
  gcpRecommendationTooltipLink:
    'https://www.notion.so/meissa/GCP-0dd74c481e284c0481de96af19ea176f?pvs=4#b1cb2a922d574b11bac62014a5869456',

  joinCheckBoxLabelText1: 'Accept our ',
  joinCheckBoxPrivacyPolicy: 'Privacy Policy',
  joinCheckBoxLabelText2: ' and ',
  joinCheckBoxTermsOfUse: 'Terms and Conditions',
  joinCheckBoxLabelText3: '.',
  joinCheckBoxErrorMessage: 'Accept the terms and conditions',
  joinTitle: 'Create New Account',
  joinButton: 'Create',
  joinSuccessAlertMsg: 'Your account has been created.\nLogin to use the Meissa platform.',
  termsOfUseUrl: 'https://meissa.notion.site/Terms-and-Conditions-42aeadfc19334ad38ca18d52a7aff588',
  privacyPolicyUrl: 'https://meissa.notion.site/Privacy-Policy-ff072616551a459b9ad764746757de20',

  projectFieldName: 'Name',
  projectFieldDescription: 'Description',
  projectFieldCoord: 'CRS',
  projectFieldPeriod: 'Duration',
  projectFieldStatus: 'Status',
  projectStatusInProgress: 'Ongoing',
  projectStatusCompleted: 'Completed',
  projectMapProvider: 'Map',
  projectMapProviderVworld: 'Vworld map',
  projectMapProviderBing: 'Bing map',
  projectMapProviderGoogle: 'Google map',

  projectFormHeadingCreating: 'New Project',
  projectFormHeadingEditing: 'Edit Project',
  projectFormSubheadingInfo: 'Basic Info',
  projectFormSubheadingInfoDetail: 'Advanced Info',
  projectFormSubheadingLaborerLogoutDistance: 'Worker Distance Checkout',
  projectFormTitleHint: 'Enter the name of your project',
  projectFormTitleEmptyError: 'Enter your project name',
  projectFormDescriptionHint: 'Enter a description of your project',
  projectFormCoordSelectHint: 'Select CRS',
  projectFormCoordTextHint: 'Only digits are allowed',
  projectFormCoordTextNoMatches: 'Unsupported CRS',
  projectFormCoordinateSystemTextEmptyError: 'Enter CRS',
  projectFormCoordinateSystemOptionEmptyError: 'Select CRS',
  projectFormUnit: 'Unit',
  projectFormUnitAdd: 'Add unit',
  projectFormUnitHint: 'Enter the unit to add',
  projectFormUnitValue: 'Enter coefficients',
  projectFormSuccessConfirmTitle: 'Project Created',
  projectFormSuccessConfirmMessage: '${n} Project was successfully created.',
  projectFormSuccessConfirmDefaultButton: 'Add Profile',
  projectFormSuccessConfirmShowProjectListButton: 'View Project List',
  projectFormDistance: 'Distance',

  projectSettingButton: 'Settings',
  projectInfo: 'Project Settings',
  participantsInfo: 'Project User Settings',
  fileArchive: 'File Archive',
  laborerHistory: 'Worker record',

  projectFormUpdateSuccess: 'Your changes have been saved.',
  projectFormUpdateError: 'An error has occurred. Please try again.',

  projectListSearch: 'Search',
  projectListInProgressTitle: 'Ongoing Projects',
  projectListCompletedTitle: 'Completed Projects',
  projectListExpiredTitle: 'Expired Projects',
  projectListCreateButton: 'Add new project',
  projectListEmptyText: 'No projects.',
  projectListFetchError: 'An error has occurred. Please try again.',

  sortOrderNameAsc: 'Ascending',
  sortOrderNameDesc: 'Descending',
  sortOrderConstructionDateAsc: 'Oldest',
  sortOrderConstructionDateDesc: 'Latest',

  projectInfoHeading: 'Project Settings',
  projectInfoSnapshotList: 'Project Profile List',

  projectDeleteConfirmTitle: 'Delete Project',
  projectDeleteConfirmMessage:
    'Deleting project will delete all site status data as well. Are you sure you want to delete it?',

  projectQRCodeDownload: 'Download QR',
  projectQRCodeDownloadTooltipMessage:
    'Download the QR code as an image that workers can check-in on the Meissa Guard App.',
  projectFieldBoundaryDialogButton: 'Check site boundaries',
  projectQRCodeImageDownload: 'Download jpg file',
  projectQRCodeForMeiday: 'QR code for Meissa Guard Check-in',
  projectQRCodeDescription:
    "On-site workers can check-in to their project by scanning this QR code on the Meissa Guard app.\n You can check their current location on 'Worker Location'.",
  projectFieldBoundaryInstructionMessage:
    'Workers are automatically logged out if they go beyond the designated distance based on the site boundary.',

  zoneProjectList: 'Project List',
  zoneList: 'Zone List',
  zoneEditName: 'Edit zone name',
  zoneSaveName: 'Save',
  emptyZoneNameError: 'Enter the zone name.',

  zoneModeToMap: 'Map View',
  zoneModeToCard: 'List View',
  zoneEmptyData: 'No Profile',

  zoneSnapshotList: 'Profile List',
  zoneImageTab: 'Image',
  zoneVideoTab: 'Video',
  zonePanoramaTab: '360° Panorama',
  zoneBirdsEyeViewTab: "Bird's eye view",
  indoorDataTab: 'Indoor Data',

  zoneSortOrderDateDesc: 'Newest',
  zoneSortOrderDateAsc: 'Oldest',
  buildingSortOrderNameAsc: 'Name Ascending',
  buildingSortOrderNameDesc: 'Name Descending',

  zoneSnapshotEmpty: 'There is no profile.',
  zoneSnapshotError: 'An error has occurred. Please try again.',
  zoneSnapshotRetryButton: 'Try Again',
  zoneSnapshotAddButton: 'Add Profile',
  zoneSnapshotEdit: 'Edit',
  zoneSnapshotDelete: 'Delete',
  zoneBirdsEyeViewAddButton: "Add Bird's eye view",
  buildingListEmpty: "Add indoor data using the 'MeiScan' application.",

  createZone: 'Create A New Zone',
  createZoneDefaultName: 'New zone',
  zoneNameEditHelperText: 'Enter a name to edit.',
  zoneDeleteMessage:
    "Deleting ${n}' will delete all site status data contained in this zone together. Are you sure you want to delete it?",
  zoneName: 'Zone Name',
  editName: 'Edit Name',
  zoneDelete: 'Delete Zone',
  zoneNameRestriction: 'Zone name can be up to ${n} characters.',

  videoSnapshotCreatingTitle: 'Add Video Profile',
  videoSnapshotEditingTitle: 'Edit Video Profile',
  videoSnapshotFileLabel: 'Video File',
  videoSnapshotFilePlaceholder: 'Select video file.',
  videoSnapshotFileSizeError: 'The file exceeds the maximum allowed size. (1.5GB)',
  videoSnapshotFileFormatError: 'Unsupported file format. Please upload a mp4 or mov file.',
  videoSnapshotFileInfo: 'Max size: 1.5GB, Format: mp4, mov',

  panoramaSnapshotCreatingTitle: 'Add Panorama Image',
  panoramaSnapshotEditingTitle: 'Edit Panorama Image',
  panoramaSnapshotFilePlaceholder: 'Select a 360° Panorama file.',
  panoramaSnapshotFileSizeError: 'The file exceeds the maximum allowed size. (60MB)',
  panoramaSnapshotFileFormatError:
    'Unsupported file format. Please upload a jpg, jpeg or png file.',
  panoramaSnapshotFileInfo: 'Max size: 60MB, Format: jpg, jpeg, png',
  panoramaConverting: 'Your image is being converted.\nThis process may take up to 1 minute.',
  panoramaDeleteTitle: 'Delete 360° Panorama',
  panoramaDeleteMessage: 'Are you sure you want to delete it?',

  birdsEyeViewCreatingTitle: "Add Bird's eye view",
  birdsEyeViewEditingTitle: "Edit Bird's eye view",
  birdsEyeViewFileLabel: "Bird's eye view",
  birdsEyeViewFilePlaceholder: 'Select Image file.',
  birdsEyeViewFileInfo: 'Max size: 50MB, Format: jpg, png',
  birdsEyeViewFileSizeError: 'The file exceeds the maximum allowed size. (50MB)',
  birdsEyeViewFileFormatError: 'Unsupported file format. Please upload a jpg or png file.',

  snapshotZone: 'Zone',
  snapshotTakeDateLabel: 'Date of filming',
  snapshotDescriptionLabel: 'Description',
  snapshotDescriptionPlaceholder: 'Enter a description of the profile',

  fileSnapshotCreatingButton: 'Add',
  fileSnapshotEditingButton: 'Save',
  fileSnapshotCreatingSuccess: '${n} profile has been created.',
  fileSnapshotEditingSuccess: '${n} profile has been edited.',

  locationMarkerTooltip: '[${n1}] ${n2}(${n3}) - ${n4}',

  cadastralMarkerPopup:
    'Address: ${n1}<br>Appraised Land Value: ${n2}KRW<br>Reference Date: ${n3}-${n4}<br>${n5}',
  cadastralMarkerDetails: 'Details',
  cadastralEmptyPrice: '-',

  customizingTitleSngis: 'Chung Cheong Inc.',
  customizingTitleKolon: 'Kolon Global',
  customizingTitleKcc: 'KCC E&C Platform',
  customizingTitleHdc: 'HDC',
  customizingTitleLotte: 'Lotte E&C',
  customizingTitleNami: 'NAMI Global',
  customizingTitleDongbu: 'Dongbu Corporation',

  compensationStatusLabel: 'Compensation Status',
  compensationRequired: 'Compensation Required',
  compensationPending: 'Compensation Pending',
  compensationDone: 'Compensation Completed',
  compensationUndefined: 'Undefined',

  count: '${n}',

  laborerMarkerPopupName: 'Name : ${n}',
  laborerMarkerPopupPhone: 'Phone Number : ${n}',
  laborerMarkerPopupLocation: 'Last Location : ${n}',
  laborerMarkerPopupLocationHistory: 'Time : ${n}',

  monthsAgo: '${n} months ago',
  weeksAgo: '${n} weeks ago',
  hoursAgo: '${n} hours ago',
  minutesAgo: '${n} minutes ago',
  secondsAgo: '${n} seconds ago',

  compareSnapshotSliderTitle: 'Comparison Slider',
  compareSnapshotCompareTwoTitle: '2part Comparison',
  compareSnapshotCompareFourTitle: '4part Comparison',
  compareSnapshotMemoViewTitle: 'Description',
  compareSnapshotStandardDate: 'Reference Date',
  compareSnapshotCompareDate: 'Comparison Date',

  snapshotDetailAddFileButton: 'Add file',
  snapshotDetailAddingFileButton: 'Adding files',
  snapshotDetailAddButton: 'Add ${n} ',
  snapshotDetailAnnotationEmpty: 'No ${n} Tools',
  snapshotDetailHazardAreaEmpty: 'There are currently no hazard areas in the site.',
  snapshotDetailFileEmpty: 'No Files',
  snapshotDetailFile: 'Files',
  snapshotDetailAddFileMessage: 'The file does not exist. Add it.',
  snapshotDetailViewButton: '${n} Details',

  layerViewAll: 'View all',
  layerOpacity: 'Opacity',
  layerOrthophotoTitle: 'Orthomosaic',
  layerDrawingTitle: 'Drawings',
  layerAnnotationTitle: 'Mapping Tools',
  layerDSMTitle: 'DSM/DTM',
  layerPointCloudTitle: '3D Point Cloud',
  layerMeshTitle: '3D Mesh',
  layerMarkerTitle: 'Point',
  layerLineTitle: 'Polyline',
  layerAreaTitle: 'Polygon',
  layerHeightTitle: 'Height',
  layerChainTitle: 'Earthwork crossing line',
  layerPlanLevelTitle: 'Design Level',
  layerBimTitle: 'BIM',
  layerStratumTitle: 'Stratum',
  layerEtcTitle: 'Other Files',
  layerAerialImageTitle: 'Original image',
  layerIssueTitle: 'Issue',
  layerPolygonVector: 'Drawing Polygon',
  editingPolygonVectorMessage:
    'When the drawing polygon is modified, the previously created earthwork quantity table is deleted.',
  layerCctvTitle: 'Security Camera',
  layerPanoramaTitle: '360° Panorama',
  layerNdviTitle: 'NDVI',
  layerNdmiTitle: 'NDMI',
  layerThermalTitle: 'Thermal',
  layerGreenTitle: 'Green',
  openSecondaryMenu: 'In detail',

  tooltipTitleWhat: 'What',
  tooltipTitleHow: 'How',
  tooltipMoreButton: 'Read More',
  layerChainTooltipWhat:
    'Multiple polyline can be added at once to easily check the progress of the earthwork and download them in batches to a cad file (.dxf).',
  layerChainTooltipHow:
    'Upload the csv file to the form. You can view the cross-sectional graph of the generated line segments.',
  layerPlanLevelTooltipWhat:
    'If you upload the tif file of the design model, You can check the altitude the same as the Digital Surface Model(DSM).',
  layerPlanLevelTooltipHow:
    '[Comparison with current height] Toggle allows you to compare the ground height and the design height on that date and calculate the required cut/fill volume according to the design model.',
  layerStratumTooltipWhat: 'You can check the altitude of each stratums.',
  layerStratumTooltipHow:
    'If you upload the stratum height and create a segmentation tool, you can check the height values of each stratum individually or overlapped.',
  layerCadastralTooltipWhat:
    'You can access the cadastral map provided by the Korea Ministry of Land, Infrastructure and Transport.',
  layerCadastralTooltipHow:
    'If you click on the partitioned space on the map, you can check the progress of land compensation during construction along with the official land price.',
  layerHazardAreaTooltipWhat:
    'The ability to specify the content, time, and location of the expected risky operation.',
  layerHazardAreaTooltipHow:
    'When used with the Meissa Guard app, you can check the entry and exit of actual workers or give a hazard alert directly to your mobile phone.',
  layerLaborerTooltipWhat:
    'The Meissa Guard app allows you to record and view the location of workers who have checked in for a month.',
  layerLaborerTooltipHow:
    "After installing the Meissa Guard app on the worker's mobile phone, please provide the QR code from the [Project Information] menu for check-in.",
  layerIssueTooltipWhat:
    'It is a function to mark problems discovered during construction on the map and to communicate and tracking them until the problem is solved.',
  layerIssueTooltipHow:
    'Create issue by entering a name and description. You can communicate with your team members through comments within the created issue.',

  accordionGroupAnnotationTitle: 'Mapping Tools',
  accordionGroupComparedDataTitle: 'Comparison data',
  comparedDataTooltipMessage: 'It can be compared to the current date.',
  layerCadastralTitle: 'Cadastral Map',
  layerGCPTitle: 'GCP',
  snapshotDetailGcpEditorButton: 'View',
  comparedCurrentDsm: 'Compare to the ground level',
  comparedCurrentDsmTooltipMessage:
    'You can check the Cut&Fill volume by comparing the current date and elevation',
  accordionGroupCurrentDataTitle: 'Current state',
  currentDataTooltipMessage:
    'You can view detailed information or \nreal-time information about the current date.',
  layerHazardAreaTitle: 'Hazard Area',
  layerLaborerTitle: 'Worker Location',
  drawingTooltip:
    'When setting up an automatic drawing, mark it on the map based on the coordinate information inside the drawing. When setting up a manual drawing, specify the location of the drawing directly on the map.',
  snapshotDetailDownload: 'Download',
  snapshotDetailDelete: 'Delete',
  accordionGroupEtcFilesTitle: 'Others',
  etcFilesTooltipMessage: 'You can upload and download \n other files that belong to this project.',
  resourceAddButton: 'Add',
  resourceAddFolder: 'New Folder',
  resourceAddFile: 'Upload File',
  resourceListLoadingMessage: 'Loading list',
  snapshotItemUndefinedPosition: 'unlocated',
  snapshotCctvFullScreen: 'Full screen',
  snapshotCctvIsEmpty: 'There is no registered security camera.',
  snapshotCctvAngleLabel: 'Angle',
  snapshotCctvPreviewButton: 'Preview',
  snapshotCctvPreviewTitle: 'Security camera list preview',

  folderNameEnter: 'Enter folder name',
  folderNameDuplicate: 'The folder name already exists.',
  folderCreateButton: 'Add',
  folderEmptyMessage: 'The folder is empty. Please either add new file or linked file.',
  folderAddFile: 'Add file to this folder',
  folderAddAnnotation: 'Add ${n} to this folder',
  folderMove: 'Move to folder',
  folderMoveToAnother: 'Move to another folder',
  folderMoveTitle: 'Move to',
  folderMoveButton: 'Move',
  folderMoveButtonError: 'There is no folder to move to.\nPlease add a folder and try again.',
  folderMoveErrorMessage: 'This file already exists in the folder.',
  folderCurrentLocation: 'Current location',

  rename: 'Rename',

  /** cctv */
  cctvCreationPopupTitle: 'Add Security Camera',
  cctvCreationPopupLabelName: 'Security Camera Name',
  cctvCreationPopupLabelRtspUrl: 'RTSP URL',
  cctvCreationPopupLabelRtspUrlExample: 'Example) rtsp://0.0.0.0:554/live/cctv001.stream',
  cctvCreationPopupLabelRtspId: 'RTSP ID',
  cctvCreationPopupLabelRtspPw: 'RTSP Password',
  cctvCreationPopupCopyFieldPlaceholder:
    'It is automatically combined with the information you entered.',
  cctvCreationPopupGuideButtonTitle: 'Guide',

  cctvDetailSideAreaLabelLocation: 'Location',
  cctvDetailSideAreaButtonAddLocation: 'Add Location',
  cctvDetailSideAreaLabelRtspUrl: 'RTSP URL',
  cctvDetailSideAreaLabelRtspId: 'RTSP ID',
  cctvDetailSideAreaLabelRtspPw: 'RTSP Password',
  cctvDeleteConfirmTitle: 'Delete Security Camera',
  cctvDeleteConfirmMessage:
    'The Security Camerea will be permanently deleted. Are you sure you want to delete it?',

  cctvPlayerSelectPlaceholder: 'Select security camera',
  cctvPlayerButtonSplit4: '4part comparison',
  cctvPlayerButtonSplit9: '9part comparison',

  resetPositionLabel: 'Reset location',
  resetPositionAlertTitle: 'Reset location',
  resetPositionAlertMessage:
    'The location of the ${n} will be deleted. \nAre you sure you want to reset the location?',
  resetPositionAlertButton: 'Reset location',

  drawingManual: 'Manual',
  drawingAutomatic: 'Automatic',
  drawingPositioning: 'Positioning',
  drawingPositioningEmptyMessage: 'It is not positioned and cannot be viewed',
  drawingConvertingErrorMessage: 'The file cannot be converted',
  drawingConvertingCancelMessage: 'Drawing conversion canceled. please try again',
  QCAD1000: 'Unable to convert. Please check the file again.',

  markerAnnotationImageSubtitle: 'Original image',
  annotationNameHint: '${n} Name',
  positionAreaCoordinateSystem: 'Coordinate',
  positionAreaTotal: 'Total',

  measureVolume: 'Measure',
  measureVolumeTooltip: 'Calculate the Cut&Fill Volume \nbased on the selected area',
  totalVolume: 'Total area',
  measureTypeTriangular: 'Triangulation',
  measureTypeTriangularTooltip:
    'Calculate by making a triangle based on the Z value of the selected area.',
  measureTypeTriangularTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_triangulation_en.png',
  measureTypeTriangularTooltipLink:
    'https://meissasaas.zendesk.com/hc/en-us/articles/8109699829135-3-2-5-Automatic-earthwork-volume-calculation-function#:~:text=%E2%9C%85%20Volume%20calculation%20method%20guide',
  measureTypeDate: 'Comparison Date',
  measureTypeDateTooltip: 'Calculate the Cut&Fill volume compared to the site of another date',
  measureTypeDateTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_comparison-date_en.png',
  measureTypeDateTooltipLink:
    'https://meissasaas.zendesk.com/hc/en-us/articles/8109699829135-3-2-5-Automatic-earthwork-volume-calculation-function#:~:text=%E2%9C%85%20Volume%20calculation%20method%20guide',
  measureTypeHeight: 'Enter height',
  measureTypeHeightTooltip: 'Calculate the Cut&Fill Volume \nbased on the entered height',
  measureTypeHeightTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_height_en.png',
  measureTypeHeightTooltipLink:
    'https://meissasaas.zendesk.com/hc/en-us/articles/8109699829135-3-2-5-Automatic-earthwork-volume-calculation-function#:~:text=%E2%9C%85%20Volume%20calculation%20method%20guide',
  measureTypePlan: 'Design Level',
  measureTypePlanTooltip:
    'Since it is counted as the uploaded Stratum File,\n it is more accurate to upload all Stratum Files.',
  measureTypePlanTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_design-level_en.png',
  measureTypePlanTooltipLink:
    'https://meissasaas.zendesk.com/hc/en-us/articles/8109699829135-3-2-5-Automatic-earthwork-volume-calculation-function#:~:text=%E2%9C%85%20Volume%20calculation%20method%20guide',

  measure: 'Calculation',
  measuring: 'Calculating',
  insertReferenceHeight: 'Enter reference height',
  insertReferenceHeightEmpty: 'Enter the height',
  uploadDsm: 'You must upload your DSM for calculations',
  volumeErrorMessage: 'An error has occurred.',
  volumeCutSumLabel: 'Net Volume',
  volumeCutSumDescription: '(Cut-Fill)',
  volumeFillSumLabel: 'Net Volume',
  volumeFillSumDescription: '(Fill-Cut)',
  calculatingVolumetricComparedToDesignLevel:
    'Volumetric calculation is on going. It may take more than 20 minutes.',
  volumeComparedToSnapshotInfoMessage: 'This is cut and fill volume of ${n2} compared to ${n1}',

  annotationDescriptionEmpty: 'Enter the description',
  hazardAreaDescriptionEmpty: 'Enter up to ${n} characters',
  markerImageEmpty: 'There is no image.',
  markerImageCanAdd: 'There is no image. Add a new one.',

  hazardAreaDateSubtitle: 'Date',
  hazardAreaStartDate: 'Start date',
  hazardAreaEndDate: 'End date',
  hazardAreaEndDateRequired: 'Enter the end date',
  hazardAreaEndDateBeforeStartDate: 'End date must be later than start date',

  hazardAreaTooltip:
    'Expired hazard areas are automatically deleted. \nCheck the expired hazard areas at the worker location',
  laborerLocationTooltip:
    'Check the current location of checked-in workers in the Meissa Guard app.',
  memberLocationTooltip:
    'Check the current location of checked-in managers in the Meissa Lite app.',

  hazardAreaTooltipStartDate: 'Start date : ${n}',
  hazardAreaTooltipEndDate: 'End date : ${n}',
  hazardAreaTooltipDescription: 'Description : ${n}',

  hazardEventHistoryButton: 'Hazard Area History',
  hazardEventHistoryTypeAllHistory: 'All',
  hazardEventHistoryTypeHazardIn: 'Hazard',
  hazardEventHistoryTypeAlarmOff: 'Safety',
  hazardEventHistoryTypeStart: 'Create',
  hazardEventHistoryTypeEnd: 'Run out',
  hazardEventHistoryAreaListOptionAll: 'All Hazard Areas',

  hazardEventHistoryTitleAlarmOff: 'Worker safety',
  hazardEventHistoryTitleHazardIn: 'Worker hazard',
  hazardEventHistoryTitleStart: 'Create Hazard Areas',
  hazardEventHistoryTitleEnd: 'Expiry Hazard Areas',

  hazardEventHistoryDescriptionHazardIn: '${n1} entered ${n2}.',
  hazardEventHistoryDescriptionAlarmOff: '${n1} has moved from ${n2} to a safe area.',
  hazardEventHistoryDescriptionStart: '${n1} created ${n2}.',
  hazardEventHistoryDescriptionEnd: '${n} has expired.',
  hazardEventHistoryListEmptyMessage: 'There is no recorded hazard area history.',

  calculateElevationOfChainListWithDsm: 'DSM Calculation',
  calculateElevationOfChainListWithPlanLevel: 'Design Level Calculation',
  calculationStatusForChainList: 'Pending:${n1} In progress:${n2} Error:${n3} Completed:${n4}',
  calculationIsProgressingMessage:
    'The calculation is ongoing. It will take a few minutes. \n(The calculation continues even if you leave the page.)',
  calculationIsErrorMessage: 'The calculation has failed. Please try again.',
  calculatingButton: 'Calculating',
  retryCalculationButton: 'Retry calculations',
  calculationButton: 'Make a calculation',
  downloadChainListFileButton: 'Elevation profile download',
  downloadChainListFileDescription: 'Only calculated chain file can be downloaded in DXF.',
  selectPlanLevelPlaceholder: 'Please select the design level',
  selectEmptyPlanLevelPlaceholder: 'File does not exist',
  selectNonePlanLevel: 'Not selected',
  downloadStatusForChainList: 'Error:${n1}, Completed:${n2}',
  downloadChainListAlertMessage:
    '${n} calculated chain file will be downloaded. \n Calculated chain files can be deleted if you press cancel button.',
  downloadChainListAlertTitle: 'The elevation profile is being downloaded.',
  downloadChainListCancelModalTitle: 'Cancel the elevation profile download',
  downloadChainListCancelMessage:
    ' Chain files are being created in DXF. \n Do you want to stop the job?',
  downloadChainListKeepGoingButton: 'Continue',
  downloadChainListStopButton: 'Stop',
  downloadChainListWithStratum: 'Cross section of stratum is included.',

  polygonVectorLayerListTitle: 'Drawing Polygon Layers',
  earthworkTableEntryButton: 'Create earthwork volume table',

  earthworkTableNoPlanLevelOption: 'Need design level',
  earthworkTableCalculationButton: 'Calculate take-out volume',
  earthworkTableExportButton: 'Export as .xlsx',
  earthworkTableExportButtonCaption: 'The unit of volume is ${n}',
  earthworkTableExportingMessage: 'Processing file. Please wait',
  earthworkTableExportingErrorMessage: 'The file has not created. Please try again',
  earthworkTableExportingSuccessMessage: 'File has been successfully created',
  earthworkTableLabelCut: 'Cut',
  earthworkTableLabelFill: 'Fill',
  earthworkTableLabelCutSum: 'Total cut',
  earthworkTableLabelFillSum: 'Total fill',
  earthworkTableLabelSoil: 'Soil',
  earthworkTableLabelTotal: 'Total',
  earthworkTableHeadAnnotation: 'Area',
  earthworkTableHeadIsCutOrFill: 'Section',
  earthworkTableHeadStratumType: 'Stratum type',
  earthworkTableHeadTotalAmount: 'Total amount',
  earthworkTableHeadAmountComparedWithPastDate: 'Take out volume by date (m³)',
  earthworkTableHeadPlanedAmount: 'Planned',
  earthworkTableHeadAmountLeft: 'Remaining',
  earthworkTableHeadSumAmountDayByDay: 'Total',
  earthworkTableEmptyValueWarning: 'Planned volume not entered are calculated as 0.',
  earthworkTableNotTmsError:
    'It is not possible to calculate the take-out volume which has DSM, design level and stratum analyzed before June 1, 2023. To calculate, please contact customer service or help@meissa.ai.',
  earthworkTableNoPlanLevelError: 'Need design level to calculate earthwork volume',
  earthworkTableVolumeListError: 'Cannot load cut and fill table',
  earthworkTableExcludeSnapshotHasNoDsmWarning: 'The profile without DSM are not calculated',
  earthworkTableCalculating: 'Leaving this page will not interrupt the process',
  earthworkTableCalculatingError: 'An error has occurred. please try again.',
  earthworkTableCalculatingSuccess: 'Calculation completed',
  earthworkTableLoadingView: 'Loading table',
  earthworkTablePlanedVolumeErrorView:
    'Loading planned volume has been failed. Please refresh screen and try again.',
  earthworkTableCreatingErrorView:
    'Loading table has been failed. Please refresh screen and try again.',
  earthworkTableEmptyView: 'Please upload design level',
  earthworkTableResourceConversion:
    'Cannot calculate take-out volume while converting stratum or design level',
  earthworkTableFileLinkingAlert:
    'Drawing Polygon, Design level and Stratum need to be linked in all profiles. Unlinked files will be linked automatically to every profile.',
  earthworkTableCellPlaceholder: 'Enter',
  earthworkTableCellCalculating: 'Calculating...',
  earthworkTableCellCalculatingError: 'Error',
  earthworkTableCellCalculatingRequired: 'Calculate',
  earthworkTableDateFormat: 'MMM/d/yyyy',

  adjustingBounds: 'Specifying print area',
  resetBounds: 'Reset',
  print: 'Print',
  generatingPrintFile: 'Creating file. Please wait.',
  fileName: 'File name',
  printFormat: 'File format',
  printResolution: 'Resolution',
  printHighResolution: 'High resolution',
  printLowResolution: 'Low resolution',
  downloadPrintFile: 'Download file',
  generatePrintFile: 'Create print file',
  printFileError: 'The file was not created. Please try again.',
  printFileSuccess: 'The file is complete.',
  printHighResolutionInfo:
    'High resolution files can take more than 5 minutes depending on the site size.',

  controlPointSize: 'Dot sizing',
  togglePointToMesh: 'View on Mesh',
  helperControlPointSize:
    'The larger the point, the more likely it is to fill up with square tiles, \nand the easier it is to see the state curved surface.',
  helperTogglePointToMesh: 'View the 3D model as mesh data',
  tiffFileConverting: 'Converting files. It can take more than 20 minutes',
  tiffFileConversionError: 'File conversion failed. Please try again.',
  compareBim: 'Compare to BIM',
  compareBimTooltipMessage:
    'Check 3D point clouds and BIM at the same time. The BIM can be manually positioned \n and refer to the "BIM Manipulation Guide" in the upper right corner.',
  emptyBimFile: 'There is no BIM file.',
  bimOnPointCloudAlert: 'BIM files without coordinate information are centered.',
  bimControlAlert: 'Location of BIM will be saved automatically.',
  bimControlGuide: 'BIM Manipulation Guide',
  bimTranslateUnit: 'Move (${n}m)',
  bimUpDownUnit: 'Up/down (${n}m)',
  bimRotateUnit: 'Turn left/right (${n}°)',
  bimDetailTranslateUnit: 'Move to detail (${n}cm)',
  bimDetailUpDownUnit: 'Up/down to detail (${n}cm)',
  bimDetailRotateUnit: 'Turn left/right to detail (${n}°)',
  bimControlInfo: 'After selecting the BIM object, you can move it with the keyboard.',
  bimManualControl: 'Manual',
  meshOnPointcloud: 'View with 3D Mesh',
  meshOnPointcloudTooltip: 'Check the 3D point cloud and mesh at the same time.',
  threeDTileLoadErrorMessage: 'File load has been failed. Please refresh screen and try again.',

  elevationSubTitle: 'Elevation graphs',
  helperElevationGraph:
    'Compare elevation, design Level, and stratum by date in a graph at a glance.',
  selectFile: 'Select file',
  fileLinking: 'Connect ${n}',
  stratumMaxLinkingCount: '(maximum ${n})',
  fileStorage: 'File archive',
  fileLinkingManagement: 'Manage',
  fileLinkingPointManagement: 'Point management',
  fileLinkingPolylineManagement: 'Polyline management',
  fileLinkingPolygonManagement: 'Polygon management',
  fileLinkingDrawingManagement: 'Drawing management',
  hazardAreaHistory: 'Hazard area history',
  fileStorageEmptyDescription:
    'There are no files that can be connected.\n Add a new tool or file.',
  unlinkRelationButton: 'Disconnect',
  fileLinkingStratumMaxNumberErrorMessage: 'Stratum files can be linked up to 5.',

  laborer: 'Current location',
  laborerLocation: 'Worker Information',
  laborerLocationListEmpty:
    'No workers found.\nYou can see the location of checked-in workers on the Meissa Guard app.',
  laborerLocationHistoryListEmpty:
    "No workers' previous location found.\nYou can check another date.",
  laborerLocationHistoryListTimeEmpty:
    "No workers' previous location found.\nYou can check another time.",
  laborerLocationListEmptyForPrimary: 'No workers found.',
  laborerLocationHistoryListEmptyForPrimary: "No workers' previous location found.",
  laborerVulnerable: 'Vulnerable',
  laborerPaused: 'Stopped sharing a location',
  laborerTabLocationList: 'Current time',
  laborerTabLocationHistoryList: 'Location history',
  laborerViewHazardArea: 'View hazard area',
  laborerViewHazardAreaTooltip: 'View with Hazard Areas\nthat existed in the past.',
  laborerSliderAlert: "You can't select this time.",
  laborerDatePickerFormat: 'PP',
  laborerGpsErrorSnackbarMessage:
    'The location on the map and the actual location of the workers may differ.',

  getFileFromStorage: 'Connect to Archive',
  showFileListInStorage: 'Check in Archive',
  addUploadFileRow: 'Add File Row',
  fileStorageDeleteResourceTitle: 'Delete File',
  fileStorageDeleteResourceMessage:
    'Deleting ${n} files permanently deletes the files in the profile. Are you sure you want to delete them?',
  fileStorageDeleteFileAlertMessage:
    'Deleting permanently deletes the files in the profile. Are you sure you want to delete them?',
  fileStorageDeleteResourceConfirmButton: 'Delete',
  fileStorageDeleteResourceCancelButton: 'Cancel',
  fileStorageDeleteAnnotationTitle: 'Delete mapping tool',
  fileStorageDeleteAnnotationMessage:
    'Deleting a ${n} tool permanently deletes the mapping tool in the profile. Are you sure you want to delete it?',
  fileStorageDeleteAnnotationConfirmButton: 'Delete',
  fileStorageDeleteAnnotationCancelButton: 'Cancel',

  hazardAreaDeleteTitle: 'Delete Hazard Area',
  hazardAreaDeleteMessage:
    'Deleting a ${n} file permanently deletes the hazard area in the laborer location history.\nAre you sure you want to delete it?',

  uploadPopupStratumFiles: 'Add Stratum File',
  uploadPopupEtcFiles: 'Add Other File',
  uploadPopupBimFiles: 'Add BIM File',
  uploadPopupPlanLevelFiles: 'Add Design Level File',
  uploadPopupChainFiles: 'Add Cross section File',
  uploadPopupNdviSegmentationFiles: 'Add NDVI(segmented) file',

  uploadPopupBimDescription:
    'Files that do not have coordinate information or are outside the map area are randomly placed in the exact center.',
  uploadPopupSuperResolutionFiles: 'Add super resolution(SR) file',
  fileUploadExtensionPolicy: 'Format: ${n}',
  fileUploadSizePolicy: 'Max size: ${n}',
  fileUploadAllFileExtension: 'All file formats',
  fileUploadSizeErrorMessage: 'Only files under ${n} can be uploaded.',
  fileUploadCountErrorMessage: 'You can upload up to ${n} files at once.',
  fileUploadExtensionErrorMessage: 'Only files with extension ${n} can be uploaded.',
  fileSizeNoLimit: 'No Limits',
  fileUploadFailMessage: 'Failed uploading. Please try again.',

  uploadPopupStratumAliasPlaceholder: 'Stratum name',
  uploadPopupStratumFilePlaceholder: 'Please select the stratum file.',

  drawingUploadPopupTitle: 'Add drawing file',
  drawingTabTypeManual: 'Automatic',
  drawingTabTypeAutomatic: 'Manual',
  drawingSelectFileTitle: 'Select drawing',
  drawingAutoTypePolicy: 'Format: dxf, Max size: ${n} MB',
  drawingManualTypePolicy: 'Format: png, jpg, pdf, dxf, Max size: ${n} MB',
  drawingManualInfo:
    'Directly place an image without coordinate information.\nThere may be errors, but conversion and utilization are fast.',
  drawingAutoRasterInfo:
    'Display the drawings as images. Compared to [Vector], the initial conversion is slower, but it is light and fast when utilized.',
  drawingAutoVectorInfo:
    'Mark as if you are drawing a layer. Although the initial conversion is faster than [Raster], it may be slower on poor-performance devices. It is not displayed in the Meissa Flight App.',
  drawingSelectCRSTitle: 'CRS settings',
  drawingSelectDisplayTitle: 'Display method',
  drawingRasterRadioLabel: 'Auto Raster',
  drawingVectorRadioLabel: 'Auto Vector',
  drawingFileUploadButton: 'Upload',
  autoRasterDrawingConverting:
    'Converting drawing.\nWait and refresh this page. The process may take up to several minutes.\n(Leaving this page will not interrupt the process.)',
  autoRasterDrawingEnhancing:
    'Enhancing drawing resolution. Wait and refresh this page.\n(Leaving this page will not interrupt the process.)',
  autoVectorDrawingRendering: 'The drawing is being displayed on the map. ${n1}/${n2}',
  drawingConvertButton: 'Conversion',
  drawingAutoVectorChip: 'Auto Vector',
  drawingAutoRasterChip: 'Auto Raster',
  drawingManualChip: 'Manual',
  drawingsUploadSuccess: 'Upload is complete.',
  drawingsUploadError: 'An error has occurred. please try again.',
  drawingsUploadLoading: 'Uploading. Please wait.',
  projectCRS: 'Project CRS',
  drawingFileMaxSizeAlert: 'Only files less than ${n}MB can be uploaded.\n',
  manualDrawingFileFormateAlert: 'Manual placement uploads only dxf, png, jpg, and pdf files.',
  autoDrawingFileFormateAlert: 'Automatic uploads only dxf files.',

  snapshotPanoramaEmptyMessage: 'The file does not exist. \nAdd a new file.',
  snapshotPanoramaEmptyMessageForPrimary: 'The file does not exist. ',
  snapshotPanoramaErrorMessage: 'An error has occurred.\nPlease try again.',
  snapshotPanoramaViewerButton: 'Enlarge',

  imageSnapshotEditTitle: 'Edit the image profile',
  snapshotProcessingTypeLabel: 'Profile type',
  processingTypeLabel: 'Analysis on platform',
  preprocessedTypeLabel: 'Upload analyzed files',
  snapshotEngineLabel: 'Analysis engine',
  imageSnapshotEditChangeZoneConfirmTitle: 'Edit zone',
  imageSnapshotEditChangeZoneConfirmMessage:
    'The data linked in this site will be canceled and linked with the zone data to be changed.\nAre you sure you want to change the zone?',

  snapshotFormPageTitle: 'Add profile',
  snapshotFormPageEditTitle: 'Edit profile',
  snapshotFormPrevButton: 'Back',
  snapshotFormNextButton: 'Next',
  snapshotFormExitButton: 'Exit',
  snapshotFormRequiredCaption: '(Required)',
  snapshotFormRecommendedCaption: '(Recommended)',
  snapshotFormInfoTitle: 'Profile information',
  snapshotFormInfoCaption: 'Enter the required information (required)',
  snapshotFormFileUploadTitle: 'Upload analyzed files',
  snapshotFormFileUploadCaption: 'Upload engine analyzed file (required)',
  snapshotFormImageUploadTitle: 'Upload original images',
  snapshotFormImageUploadCaption: 'Select and upload images taken with drones. ${n}',
  snapshotFormGcpUploadTitle: 'Mark GCP',
  snapshotFormGcpUploadCaption:
    'Match with the original image to make the 3D model more accurate. ${n}',
  snapshotFormProcessTitle: 'Start analysis',
  snapshotFormProcessCaption: 'Start analysis with uploaded files',
  snapshotFormOrthophotoLabel: 'Orthomosaic',
  snapshotFormDsmLabel: 'DSM',
  snapshotFormPointCloudLabel: '3D Point Cloud',
  snapshotFormMeshLabel: '3D Mesh',
  snapshotFormFileFormatText: 'File Format: ${n}',
  snapshotFormMeshFileFormatText: 'Format: ${n1}, Max size: ${n2}MB',
  snapshotFormMeshMaxFileSizeError: 'Only files less than ${n}MB can be uploaded.',
  snapshotFormLoadingViewText: 'Loading. Please wait.',
  snapshotFormFileMinCountAlert: 'Minimum number of file uploads is ${n}.',
  snapshotFormFileMaxCountAlert: 'Maximum number of file uploads is ${n}.',
  snapshotFormImageUploadLabel: 'Upload the original image',
  snapshotFormImageMinCountText: 'You can upload at least 30 images.',
  snapshotFormImageWithoutGpsAlert: 'Only files with GPS data can be uploaded.',
  snapshotFormImageCompressError: 'An error occurred during compression. Please try again.',
  snapshotFormImageCompressSuccess:
    'Image compression is complete. Please press the download button.',
  snapshotFormImageCompressLoading: 'Compressing uploaded image. Please wait.',
  snapshotFormImageUploadSuccess: 'Upload completed.',
  snapshotFormImageUploadError: 'There are files that failed to upload. Please try again.',
  snapshotFormImageUploadMaxCountAlert:
    'The maximum number of uploads is ${n}. Please check the number of files.',
  snapshotFormMapTitle: 'Preview',
  snapshotFormMapCaptionForImage: 'Please check if the selected image is the correct file.',
  snapshotFormImageArchiveLoadingButton: 'Compressing',
  snapshotFormImageArchiveDownloadButton: 'Download a compressed file',
  snapshotFormGcpUploadLabel: 'Uploading a GCP file',
  snapshotFormGcpDownloadButton: 'Download',
  snapshotFormGcpFileGuideText: 'File format: csv txt\nGCP point: enter at least 3',
  snapshotFormFileFormatGuideButton: 'Upload Guide',
  snapshotFormGcpCoordinateLabel: 'Select CRS',
  snapshotFormGcpDisabledCoordinate:
    'If you want to change the CRS, please delete the GCP file and upload it again.',
  snapshotFormGcpLocationWarningAlert: '${n} GCP points are out of site range.',
  snapshotFormGcpCorrectionLabel: 'Mark GCP',
  snapshotFormGcpCorrectionCaption:
    'Match the GCP with the original image to make the 3D more accurate.',
  snapshotFormGcpCorrectionButton: 'Mark GCP',
  snapshotFormProcessButton: 'Start analysis',
  snapshotFormProcessingButton: 'Analyzing',
  snapshotFormProcessingText:
    'It takes up to 24 hours depending on the number of original images and the site area. \nYou will be notified by e-mail when the analysis is complete.',
  snapshotFormPauseButton: 'Cancel analysis',
  snapshotFormPauseConfirmTitle: 'Cancel analysis',
  snapshotFormPauseConfirmMessage:
    'Analysis processed so far will be deleted. \nAre you sure you want to stop the analysis?',
  snapshotFormPauseConfirmPrimaryButton: 'Cancel analysis',
  snapshotFormPauseConfirmSecondaryButton: 'Continue',
  snapshotFormPauseSuccessMessage: 'Analysis cancelled.',
  snapshotFormProcessDoneMessage:
    'This is the site situation where the analysis has been completed.',
  snapshotFormResultInfoMessage:
    'After the analysis is completed, four analysis results data are available.',
  snapshotFormImagesNeedToBeMatchedToGcpTitle: 'GCP marker analysis',
  snapshotFormImagesNeedToBeMatchedToGcp:
    'No GCP markers have been added.\nAre you sure you want to analyze this?',
  snapshotFormImagesNeedToBeMatchedToGcpPrimaryButton: 'Add a marker',
  snapshotFormImagesNeedToBeMatchedToGcpSecondaryButton: 'Analyze',
  snapshotFormGoGcpMarkPage: 'Mark GCP',
  snapshotFormMapToolLinkTitle: 'Connect Mapping tools',
  snapshotFormMapToolLinkContent:
    'Connect the Points, Polylines, Polygons from the latest ${n} profile.',
  snapshotFormMappedImageCountIsNotRecommendedModalTitle: 'Profile Processing',
  snapshotFormMappedImageCountIsNotRecommendedModalContent:
    'Analysis result will be effected by GCP image marking when less than 5.',
  snapshotFormMappedImageCountIsNotRecommendedModalSecondaryButton: 'Move to GCP marking',
  snapshotFormMappedImageCountIsNotRecommendedModalPrimaryButton: 'Start analysis',

  /** 현장 이슈 관리 */
  mediaViewerDetailWriter: 'Creator',
  mediaViewerDetailUploadDate: 'Upload',
  mediaViewerDetailFileCreationTime: 'Date of image taken',

  issueMediaFileExpand: 'Zoom in',

  doneIssueListTitle: 'Closed issue',
  issueEmptyMessage: 'No issue',
  issueAddButton: 'issue',

  issueFileUploadNumberIssue: 'Only ${n} files can be uploaded at a time',
  issueCommentPlaceholder: 'Please write the contents',
  doneIssueCommentPlaceholder:
    "Please change the status of the issue to an 'ongoing' in order to write a comments",

  deleteMainImageConfirmTitle: 'Delete the main image',
  deleteMainImageConfirmMessage:
    'The main image will be permanently deleted. Are you sure you want to delete it?',
  issueRunning: 'Ongoing',
  issueDone: 'Completed',
  issueNameHint: 'Enter the name (Required)',
  issueDescriptionHint: 'Enter the description (Required)',
  deleteCommentConfirmTitle: 'Delete the comment',
  deleteCommentConfirmMessage:
    'The comments will be permanently deleted. Are you sure you want to delete it?',

  localeDateWithoutYearFormat: 'do MMMM',
  localeDateWithoutCommaFormat: 'do MMMM yyyy',

  deleteIssueConfirmTitle: 'Delete the issue',
  deleteIssueConfirmMessage:
    "The issue you've created will be permanently deleted on entire sites. Are you sure you want to delete it?",

  issueMainImageUploadButton: 'Select the main image',

  issueUpdateCancelWarningTitle: 'Confirming changes',
  issueUpdateCancelWarningMessage:
    'Are you sure you want to leave the page without saving changes?',

  issueCreateSystemMessage: '${n} has created an issue',
  issueDoneSystemMessage: '${n} has completed the issue',
  issueReactivatedSystemMessage: '${n} has changed the status as ongoing',

  issuePolygonNdviAnalysis: 'Issue Index Analysis ',
  issuePolygonNdviAnalysisTooltip: 'When NDVI is present, it is calculated based on NDVI.',
  issuePolygonNdviCalculationBtn: 'Calculate Vegetation Index',
  issuePolygonNdviHealthyChip: 'Healthy',
  issuePolygonNdviStressedChip: 'Stressed',
  issuePolygonNdviCriticalChip: 'Critical',
  issuePolygonCalculationRequiredText: 'Calculation required',
  issuePolygonSizeLabel: 'Area',
  issuePolygonNdviCalculationWarningMessage: 'Only Vegetation Index area will be calculated.',
  issuePolygonNdviViewCheckboxLabel: 'View vegetation index',

  issuePolygonComparedButton: 'Comparison by date',
  issuePolygonComparedSelectPlaceholder: 'Date selection',
  issuePolygonComparedMessageTitle: 'Index',
  issuePolygonComparedEmptyFile: 'No file',
  issuePolygonComparedConverting: 'Converting',
  issuePolygonComparedCalculating: 'Calculating',
  issuePolygonComparedCalculationError: 'Calculation error',
  issuePolygonComparedCalculationRequired: 'Calculation required',

  viewPointButtonTitle: 'Quick Search',
  mapControlCompareButtonGroupTitle: 'Comparison',
  mapControlToolButtonGroupTitle: 'Add tool',

  mapRotationTooltipMessage:
    'If there are many linked mapping tools, map rotation may become slow.\nPlease unlink unused mapping tools from [Manage].',

  errorBoundaryMessage: 'An error has occurred. \nPlease refresh the page.',
  errorBoundaryRefreshButton: 'Refresh',

  /** Green */
  greenTooltipWhat: 'Add Greens on the map and monitor Green status',
  greenTooltipHow:
    "Quickly navigate to your registered green using 'Green Move.' You can compare greens by type and date.",
  greenAddButtonTitle: 'Add Green',
  greenCompareButtonTitle: 'Compare',
  greenCompareByGreenButtonTitle: 'By Greens',
  greenCompareByDateButtonTitle: 'By Dates',
  greenDeleteConfirmTitle: 'Delete Green',
  greenDeleteConfirmMessage:
    "Permanently delete the '${n}' green. Are you sure you want to delete it?",
  greenAnalysis: 'Analyze Green',
  greenAnalysisInvalidMessage:
    'There is no green area, or the location is outside the vegetation index range and cannot be analyzed. Please add a green area or adjust the location.',
  greenAnalysisProgressMessage:
    'Green analysis is in progress. Please wait a moment. (The analysis will continue even if you leave the page.)',
  greenAnalysisErrorMessage: 'An error has occurred. Please try again.',
  greenLabel: 'Green',

  /** AI Tools */
  toolsAIaccordionGroupTitle: 'AI tool',
  toolsAIDetectButtonTitle: 'Detect',
  toolsAIColorPaletteTitle: 'Color',

  toolsAIObjectDetectionAccordionTitle: 'Object Detection',
  toolsAIObjectDetectionSubtitleDetected: 'Detected',
  toolsAIObjectDetectionGvienClassPlane: 'Plane',
  toolsAIObjectDetectionGvienClassShip: 'Ship',
  toolsAIObjectDetectionGvienClassSmallVehicle: 'Small Vehicle',
  toolsAIObjectDetectionGvienClassLargeVehicle: 'Large Vehicle',
  toolsAIObjectDetectionGvienClassUncategorized: 'Uncategorized',
  toolsAIObjectDetectionUnProgressedMessage: 'There is no detected object.',
  toolsAIObjectDetectionHideObjectTooltip: 'Click to hide on the map',
  toolsAIObjectDetectionUnhideObjectTooltip: 'Click to display on the map',

  toolsAIAnalyzeError: 'An error has occurred. Please try again.',

  toolsAISegmentationAccordionTitle: 'Semantic Segmentation',
  toolsAISegmentationBefore: 'Before segmentation.',
  toolsAISegmentationDo: 'Segmentation',
  toolsAISegmentationDone: 'Segmented',
  toolsAISegmentationBuilding: 'Building',
  toolsAISegmentationRoad: 'Road',
  toolsAISegmentationWater: 'Water',
  toolsAISegmentationVegetation: 'Vegetation',
  toolsAISegmentationBarren: 'Barren',
  toolsAISegmentationOpacity: 'Opacity',
  toolsAISegmentationErrorMessage: 'An error has occurred. Please try again.',

  toolsAIChangeDetectionAccordionTitle: 'Change Detection',
  toolsAIChangeDetectionProgressingInformation:
    'Change detection can take 10 to 30 minutes. Even if you go to another screen, the detection continues.',
  toolsAIChangeDetectionSameSnapshotInvalidWarning: 'The same snapshots cannot detect changes.',

  toolsAINdmiAccordionTitle: 'NDMI',
  toolsAINdmiAccordionUploadFileButtonTtile: 'Add file',
  toolsAINdmiAccordionNoContents: 'No Files',
  toolsAINdmiFileUploadPopupTitle: 'Add NDMI File',
  toolsAINdmiFileUploadPopupDescription: 'Format: tif,tiff, Size: No limit',
  toolsAINdmiFileUploadErrorMessage: 'File conversion failed. Please try again.',

  /** landDisplacement */
  landDisplacement: 'Land Displacement',
  landDisplacementAddPoint: 'Add Point',
  landDisplacementPointTitle: 'point ${n}',
  landDisplacementPointListEmpty: 'Add a point to check the land displacement in a graph.',
  landDisplacementControlPixelSize: 'Pixel size (m)',
  landDisplacementGraphPositionInvalid: 'The selected point cannot display a graph.',
  landDisplacementVelSliderTitle: 'Displacement Velocity (mm/y)',
  landDisplacementGraphTitle: 'point ${n} Land Displacement',
  landDisplacementGraphXTick: 'yyyy',
  landDisplacementGraphYTitle: 'Displacement (mm)',
  landDisplacementTooltipCompareToDate: "'Compare to 'PPP",

  /** superResolution */
  superResolutionAccordionTitle: 'SR(Super Resolution)',
  superResolutionCompareButtonTitle: 'Compare with Original',
  superResolutionComparedOrthoPhotoInfoHeader: 'Orthophoto',
  superResolutionCompareErrorDetail: 'There is no super resolution (SR) on this date.',

  /** buildingFormModal */
  buildingCreateButton: 'Add Building',
  buildingFormModalCreateTitle: 'Add Building',
  buildingFormModalTitle: 'Edit Building',
  buildingFormModalEditConfirmTitle: 'Confirm your changes',
  buildingFormModalEditConfirmMessage:
    'Are you sure you want to leave the page without saving your changes?',
  buildingFormModalNameLabel: 'Building name',
  buildingFormModalDescriptionLabel: 'Description',
  buildingFormModalEditTitlePlaceholder: 'Please enter a title for your building',
  buildingFormModalEditDescriptionPlaceholder: 'Please enter a description of the building',
  /** building/sideMenu */
  indoorDataAccordionGroupSubtitle: 'Indoor data',
  indoorDataVideoAccordionTitle: 'Video',
  indoorDataVideoManagementButton: 'Manage',
  indoorDataItemStatusLoading: 'Creating indoor data. Please wait a moment.',
  indoorDataItemStatusError: 'An error occurred while creating indoor data, please try again.',
  indoorDataItemRetryButton: 'Retry',
  indoorVideoFolderMoveTitle: 'Video',
  indoorVideoFileUploadButton: '360 Video File',
  indoorVideoFileUploadTitle: 'Select 360 video file',
  indoorVideoFileUploadSelectFolder: 'Select folder',
  indoorVideoFileUploadPolicyMessage: 'Max size: no limits, Format: mp4',
  indoorVideoFileUploadDisableTooltip: 'There is no folder. Please upload after adding the folder.',
  indoorVideoFileUploadDescription:
    'Supports video upload in equirectangular & mp4 format.\nFor videos in other formats, please refer to the [Video Conversion Guide].',
  indoorVideoFileUploadGuideButton: 'Video Conversion Guide',
  /** building/secondarySideMenu */
  indoorDataVideoSecondaryEditButton: 'Edit',
  indoorDataVideoSecondaryMenuTitle: 'Video Management',
  indoorDataVideoFileDeleteConfirmTitle: 'Delete file',
  indoorDataVideoFileDeleteConfirmMessage:
    '${n} files will be permanently deleted. Are you sure you want to delete it?',
  indoorDataVideoFileDeleteConfirmCancel: 'Cancel',
  indoorDataVideoFileDeleteConfirmDelete: 'Delete',
  indoorDataVideoPlayButton: 'Watch the original video',
  panoramaVideoHelpText: 'Drag the mouse \nto view the video in 360°',
  /** building/indoorVideo/main */
  indoorVideoViewTypeToggleWalkthrough: 'Walkthrough',
  indoorVideoViewTypeToggleVideo: '360 Video',
  indoorVideoEmptyMessage:
    'There is no 360 video.\nYou can add a new folder and upload it, \nor use the Meissa Scan app.',
  /** building/comparePage */
  walkthroughComparePageTitle: 'Site comparison',
  walkthroughCompareButton: 'Site comparison',
  walkthroughCompareButtonTooltip: 'By creating a minimap, site comparison is possible.',
  walkthroughCompareSelectLabelBase: 'Reference video',
  walkthroughCompareSelectLabelCompared: 'Comparison video',
  walkthroughCompareGuidePopupTitle: 'Indoor Site Comparison Guide',
  walkthroughCompareArrowButtonSnackbarMessage:
    'Videos can be operated individually. Use the minimap for common actions.',
  walkthroughCompareMiniMapMarkerSnackbarMessage:
    'You can operate the video together. Use the arrows for individual actions.',

  /** floorPlan */
  floorPlanAccordionTitle: 'Floor plan (Minimap)',
  floorPlanFileUploadButton: 'Upload',
  floorPlanFileEmptyMessage: 'No file found',
  floorPlanFileUploadTitle: 'Add floor plan file',
  floorPlanFileUploadPolicyMessage: 'File types: png, jpg, pdf, dxf, maximum file size: 50MB',
  floorPlanFileUploadDescription:
    'You can place floor plans and walkthroughs directly to create a minimap.\nOnly one file can be uploaded, we recommend uploading a full floor plan',
  floorPlanFileDeleteTitle: 'Delete file',
  floorPlanFileDeleteMessage:
    'If you delete a floor plan, all video minimaps will be permanently deleted. Are you sure you want to delete?',
  floorPlanFileConverting: 'The file is being converted. Please wait a moment.',
  /** MiniMap */
  miniMapTitle: 'Minimap',
  createMiniMapButton: 'Create minimap',
  miniMapIsNotConnectedText: 'No minimap created.',
  miniMapSettingTitle: 'Create minimap',
  miniMapSettingConfirmWithoutSavingTitle: 'Abort minimap creation',
  miniMapSettingConfirmWithoutSavingMessage:
    'If you exit now, the minimap will not be saved.\nDo you want to abort the operation?',
  miniMapSettingStepOneHelpMessage: '1. Move the marker to a suitable place to place it',
  miniMapSettingStepTwoHelpMessage: '2. Drag the marker to position it to fit the floor plan',
  miniMapResetConfirmTitle: 'Reset minimap',
  miniMapResetConfirmMessage:
    'The walkthrough and floor plan layout are deleted. Are you sure you want to reset the minimap?',
  miniMapResetConfirmCancel: 'Cancel',
  miniMapResetConfirmRefresh: 'Reset',
  miniMapResetButtonTooltip: 'Reset minimap',
  miniMapShowSwitchLabel: 'View minimap',
  /** LaborerHistory **/
  laborerHistoryTableCellDate: 'Date',
  laborerHistoryTableCellTime: 'Time',
  laborerHistoryTableCellName: 'Name',
  laborerHistoryTableCellWeak: 'Vulnerable',
  laborerHistoryTableCellStatus: 'Status',
  laborerHistorySearchEmptyMessage: 'There is no data on that date.\nPlease select another date.',
  laborerHistoryEmptyMessage: 'No results were found for your search',
  laborerHistoryFilterToggleButtonDate: 'Day',
  laborerHistoryFilterToggleButtonWeek: 'Week',
  laborerHistoryFilterToggleButtonMonth: 'Month',
  laborerHistoryFilterToggleButtonDuration: 'Period',
  laborerHistoryFilterAll: 'All',
  laborerHistoryFilterWeakOrNot: 'Vulnerable',
  laborerHistoryFilterWeak: 'Vulnerable',
  laborerHistoryFilterNotWeak: 'Not vulnerable',
  laborerHistoryFilterStatus: 'Worker status',
  laborerHistoryFilterStatusCheckIn: 'Check-in',
  laborerHistoryFilterStatusActive: 'Start sharing location',
  laborerHistoryFilterStatusPause: 'Stop sharing location',
  laborerHistoryFilterStatusLogout: 'Checkout',
  laborerHistoryFilterStatusTimeLogout: 'Time checkout',
  laborerHistoryFilterStatusDistanceLogout: 'Distance checkout',
  laborerHistoryFilterTextSearchPlaceHolder: 'Name search',

  /** HTTP Problem */
  CLIENT_ERROR: 'An error has occurred.',
  SERVER_ERROR: 'Request cannot be processed.',
  TIMEOUT_ERROR: 'Request timeout',
  CONNECTION_ERROR: 'Cannot connect to server',
  NETWORK_ERROR: 'Check your internet connection',

  /** Server Error Message Code  */
  ER1000: 'You do not have permission to access this feature.',
  ER1001: 'You have been logged out for security purposes.\nPlease login again.',
  ER1002: 'Invalid request',
  ER1003: 'Check your email or password.',
  ER1004:
    'The invitation validity period has expired.\nPlease ask the project manager for another invitation.',
  ER1005: 'The page has expired.\nYou will be redirected to the login page.',
  ER1006: 'The account already exists.\nYou will be redirected to the login page.',
  ER1007: 'Your email is not registered.\nPlease check again.',
  ER1008: 'Your password is incorrect.',
  ER1010: 'Password error exceeded 5 times.\nPlease click the ‘Forgot your password?’.',
  ER2000: 'An error has occurred.\nPlease try again.',
  ER3000: 'Not Found.',
  ER3001: 'Forbidden.',
  ER3002: 'The account has already been invited.',
  ER3003: 'Processing cannot be canceled.\nCheck if your profile is currently being processed.',
  ER3004: 'Processing using Pix4D\ncannot be canceled.',
  ER3005: 'Already processing.',
  ER3006: 'The profile has already been processed.',
  ER3007: 'Uploaded GCPs are not located within the site area.',
  ER3008: 'Invalid data. Verify your data and try again.',
  ER3009: 'No images available for processing.\nUpload your images and try again.',
  ER3010: 'Profile processing is incomplete.\nTry again after processing completes.',
  ER3012: 'No images available for compression. Verify your data and try again.',
  ER3013: 'File format not supported.\nUpload a new file.',
  ER3014: 'Resending is not allowed within 1 minute.\n.Please wait and try again.',
  ER3015: 'Upload at least 30 images.',
  ER3016: 'Maximum number of stratum data files exceeded. Refresh this page and try again.',
  ER3017: 'Drawing conversion status changed.\nRefresh this page and try again.',
  ER3018: 'Drawing conversion status changed.\nRefresh this page and try again.',
  ER3019: 'Invalid data. Verify format and try again.',
  ER3020: 'Please upload a file with at least 3 GCP points entered.',
  ER3021: 'GCPs in the site range is less than 4.',
  ER3022: 'Unable to convert. Please check the file again.',
  ER3023: 'Please upload orthomosaic.',
  ER3024:
    'In order to be processed, there is certain condition need to be satisfied. \n1. More than 3 GCP that are matched with 3 images.',
  ER3025: 'Only 10 files can be uploaded at a time',
  ER3026: 'Only files under 1GB can be uploaded.',
  ER3027:
    'You have exceeded the maximum area available for high-definition support. The image quality may look low in certain sections.',
  ER3028: 'Comments cannot be created or modified on the completed issue.',
  ER3029: 'Attachment exceeded the limit.',
  ER3030: 'Earthwork Cross Polyline relation information already exists.',
  ER3031: "The calculation can't proceed without DSM file.",
  ER3032: 'The header of this file is in blank',
  ER3033: 'Please rewrite the header with follow rules GCP NAME, X Y , X Y or X Y, X Y',
  ER3034:
    'There are blank space or no decimal numbers in the coordinates• There are blank space or no decimal numbers in the coordinates',
  ER3035: 'Analyzed NDVI file is needed to calculate.',
  ER3036: 'It can be calculated after converting the whole NDVI.',
  ER3037: 'The same name already exists.',
  ER3038: 'There is no DSM for this date',
  ER3041: 'You must have at least one image marked with GCP to use AutoGCP feature.',
  ER3042: 'This item is already linked.',
  ER3043: "You can't update an area with more than 300 vertices.",
  /** 공통 에러 */
  already_referenced_error: 'The value is in use and cannot be deleted.',
  not_found_error: 'An error has occurred. Please try again.',
  invalid_input_parameter_error:
    'The information you entered has not been verified. please try again.',
  value_already_exist_error: '동일한 이름의 앨범이 있습니다',

  annotationColorPickerTitle: 'Color',
  dtmAddButtonTitle: 'Add DTM',
  dtmAddPopupTitle: 'Add DTM',
  dtmCreateButtonTitle: 'Create Yourself',
  dtmFileUploadButtonDescriptionMessage:
    'You can upload multiple DTM and TIF files created on other platforms.',
  dtmCreateButtonDescriptionMessage:
    "You can create a DTM by removing the desired area based on Meissa's DSM.",
  demAnalyzedAccordionSubtitle: 'Analyzed file',
  demUploadAccordionSubtitle: 'upload file',
  demAccordionTitle: 'Digital Elevation Model',
  dsmAccordionItemTitle: 'Digital Surface Model',
  dtmAccordionItemTitle: 'Digital Terrain Model',
  basicWorking: 'Working',
  dtmCreateTopBarButtonTitle: 'Create DTM',
  dtmCreateConfirmTitle: 'Create DTM',
  dtmCreateConfirmMessage:
    'It may take up to 3 hours for the DTM file to be created. Do you want to create it like this?',
  dtmPolygonCreateTopBarMessage:
    "Click the 'Specify Area' button and draw the area you want to erase.",
  dtmPolygonCreateButtonTitle: 'Specify area',
  dtmPolygonDraftButtonTitle: 'Save temporarily',
  dtmEditorCloseConfirmTitle: 'Confirm DTM save',
  dtmEditorCloseConfirmMessage:
    'If you leave, your work will not be saved. Are you sure you want to leave the editor?',
  basicExit: 'Exit',
  dtmDraftSuccessSnackbarMessage: 'Temporarily saved.',
  dtmEditorCloseWithoutCreateConfirmTitle: 'Cancel DTM creation',
  dtmEditorCloseWithoutCreateConfirmMessage:
    'The DTM file has not been created yet. Are you sure you want to leave the editor?',
  basicMoreInfo: 'More information',
  basicDeleteFile: 'Delete File',
  dtmDeleteConfirmMessage:
    'When deleting a ${n} file, the file is permanently deleted from the entire site situation. Are you sure you want to delete?',
  demCompareRightSideMenuToggleTitle: 'Compare at once',
  demComplareRightSideMenuToggleTooltip: 'You can compare DSM/DTM histograms at once.',
  dtmPolygonSnackbarMessage:
    'Please make it larger than the area you want to erase.\nFlattens based on the vertices of the specified area.',
  dtmPolygonListTitle: 'Created area',
  dtmPolygonRefreshButtonTitle: 'reset',
  dtmPolygonRefreshConfirmTitle: 'Reset the area',
  dtmPolygonRefreshConfirmMessage: 'Do you want to reset all specified areas?',
  dtmEditButtonTitle: 'DTM correction',
  earthworkBaseSurfaceSubtitle: 'Base surface',
  earthworkBaseSurfaceToggleLabel: 'DTM',
  earthworkBaseSurfaceTooltipMessage:
    'For the date the DTM file exists, the volume is calculated based on the DTM.',
  earthworkPlanLevelSubtitle: 'Select Design level',
  dtmBulkPolygonName: 'Area',
  dtmCreateFailErrorMessage: 'This file cannot be converted. Please delete the file and try again.',
  dtmUploadSuccessSnackbarMessage:
    'Upload Complete! It will take some more time for the analysis to be completed.',
  dtmGuideCaptionBadSnackbar: 'The vertices of the object',
  dtmGuideCaptionGoodSnackbar: 'specified as larger than the object',
  dtmCopyObjectDetectionResultButtonTitle: 'Copy object detection',
  dtmCopyObjectDetectionResultSnackbarMessage:
    'Copy the object detection results to make it easier to\n specify the area you want to erase.',
  dtmNotSpecifiedAreaMessage:
    'There is no specified area yet.\nYou can specify a new area or detect and import objects.',
  dtmCopyObjectDetectionResultNotFoundMessage:
    'No object detection results.\nDo you want to detect new?',
  dtmCopyObjectDetectionResultDuplicateWarningMessage:
    'If you already have areas that you have copied, the area may be duplicated.',
  dtmCopyObjectDetectionResultConfirmButton: 'Copy',
  dtmObjectDetectionInProgressMessage:
    'Object detection is in progress. Detection will continue even if you leave this page.',
  dtmCopyObjectDetectionResultInProgressMessage: 'Copying object detection results.',
  dtmCopyObjectDetectionResultModalTitle: 'Copy object detection',
  dtmObjectDetectionModalTitle: 'Object Detection',
  dtmObjectDetectionRunConfirmButton: 'Detect',
  snapshotMapLoadingMessage: 'Creating a map.\nPlease wait.',
  zoneDrawingCompletionDateSelectButtonTitle: 'Completion date',
  zoneDrawingCompletionDateTooltipMessage:
    'When you select a completion date by area, calculations are excluded from the earthwork quantity table after that date.',
  basicReset: 'reset',
  basicApply: 'apply',
  basicCompleted: 'completed',
  zoneDrawingCompletionDateConfirmTitle: 'Check completion date',
  zoneDrawingCompletionDateConfirmMessage:
    'Calculations will be excluded from the earthwork quantity table after ${n}.',
  zoneDrawingCompletionDateResetConfirmTitle: 'Reset completion date',
  zoneDrawingCompletionDateResetConfirmMessage: 'Do you want to reset the applied completion date?',

  issueShareWithKakao: 'Share on KakaoTalk',
  issueShareWithClipboard: 'Copy link',
  issueShareDone: 'Link has been copied.',

  /* 차량계 위치 */
  vehicleLocation: 'construction vehicle locations',
  layerVehicleTitle: 'construction vehicle locations',
  vehicleTabLocationList: 'Current time',
  vehicleTabLocationHistoryList: 'Location history',
  vehicleLocationListEmpty:
    'here are no active vehicle logs.\nYou can view the location of vehicles checked in with Meissa Guard.',
  vehicleSliderAlert: "You can't select this time.",
  vehicleDatePickerFormat: 'PPP',
  vehicleGpsErrorSnackbarMessage:
    'The location on the map and the actual location of the vehicle position may differ',
  vehicleLocationHistoryListEmpty:
    'There is no previous location record for the vehicle log.\nPlease check another date.',
  vehicleLocationHistoryListTimeEmpty:
    'There is no previous location record for the vehicle log.\nPlease check another time.',
  vehicleHistory: 'Vehicle operation record',
  vehicleHistoryFilterStatus: 'Vehicle type',
  vehicleHistoryFilterAll: 'All',
  vehicleHistoryFilterStatusDumpTruck: 'Dump Truck',
  vehicleHistoryFilterStatusExcavator: 'Excavator',
  vehicleHistoryFilterStatusCrane: 'Crane',
  vehicleHistoryFilterStatusDozer: 'Dozer',
  vehicleHistoryFilterStatusLoader: 'Loader',
  vehicleHistoryFilterStatusGrader: 'Grader',
  vehicleHistoryFilterStatusScraper: 'Scraper',
  vehicleHistoryFilterStatusRoller: 'Roller',
  vehicleHistoryFilterStatusOther: 'Other',
  vehicleHistoryFilterTextSearchPlaceHolder:
    'Search by worker name and vehicle registration number',
  /* Vehicle History Table */
  vehicleHistoryTableCellStartDate: 'Work start time',
  vehicleHistoryTableCellEndDate: 'Work end time',
  vehicleHistoryTableCellTotalDriveTime: 'Total driving time',
  vehicleHistoryTableCellVehicleType: 'Vehicle type',
  vehicleHistoryTableCellVehicleNumber: 'Vehicle registration number',
  vehicleHistoryTableCellName: 'Name',
  vehicleHistoryTableCellRoute: 'route',
  vehicleHistorySearchEmptyMessage: 'There is no data on that date.\nPlease select another date.',
  vehicleHistoryEmptyMessage: 'No results were found for your search',
  layerVehicleTooltipHow:
    "Please install the Meissa Guard app on the vehicle driver's mobile phone, and then share the QR code or URL from the [Project Information] menu for check-in.",
  layerVehicleTooltipWhat:
    'The Meissa Guard app allows you to record and view the location of vehicle who have checked in for a month.',
  vehicleHistoryFilterToggleButtonDate: 'Day',
  vehicleHistoryFilterToggleButtonWeek: 'Week',
  vehicleHistoryFilterToggleButtonMonth: 'Month',
  vehicleHistoryFilterToggleButtonDuration: 'Period',
  vehicleHistoryFilterDateRangeAlertMessage: 'Please select a date after the start date.',
  vehicleMarkerPopupName: 'Name : ${n}',
  vehicleMarkerPopupPhone: 'Phone Number : ${n}',
  vehicleMarkerPopupLocation: 'Last Location : ${n}',
  vehicleMarkerPopupLocationHistory: 'Time : ${n}',
  vehicleHistoryTableCellViewRouteButton: 'View Route',
  vehicleDrivingPopupInformationStartDate: 'Start date of employment',
  vehicleDrivingPopupTitle: 'Vehicle location route',
  vehicleDrivingPopupDescription:
    'Click or hover over the route to display the time, click again to release. Capture after clicking on the route.',
  vehicleDrivingPopupMapCaptureButton: 'Capture Image',
  vehicleDrivingPopupMapCaptureSnackbarComment: 'Image Captured',
  deeplinkGuardServiceMessage: 'Real-time safety management to be more safe!',
  deeplinkMobileServiceMessage: 'Start communicating in the job site with mobile device',
  deeplinkRedirectButtonMessage: 'Open in app',
  laborerHistorySearchEndDateSelect: 'Select end date',

  annotationEditSnackbarMessage: 'Click the "Done" button to apply the changes.',

  dtmOffsetModificationBatchOffset: 'Adjust All',
  dtmOffsetModificationTriangulated: 'Auto Flatten',
  dtmOffsetModificationFixedOffset: 'Specify Custom Height',
  dtmOffsetModificationValuePlaceholder: 'Enter height to adjust',

  dtmCreationGuideButtonTitle: 'Creation Guide',
  dtmCreationModalTitle: 'DTM Creation Guide',
  dtmBulkPolygonHeaderTitle: 'Height (Z)',
  dtmOffsetModificationDescriptionMessage:
    'If the height is not entered, it is calculated as ‘automatic flattening’.',

  polygonMaxVertexErrorMessage: 'Up to 300 vertices can be created.',
  /* 그린 작업일지 */
  dailySchedule: 'Daily Schedule',
  dailyScheduleArchiveTabName: 'Archive',
  dailyScheduleSearchTabName: 'Search',
  dailyScheduleMaintenanceEmptyScheduleMessage: 'There is no daily schedule.',
  dailyScheduleMaintenanceCreateScheduleMessage: 'Try creating a new daily schedule.',
  dailyScheduleMaintenanceCreateScheduleButton: 'Create a new daily schedule',
  dailyScheduleMaintenanceCopyScheduleButton: 'Copy previous daily schedule',
  dailyScheduleMaintenanceCreateScheduleLoadMessage:
    'A new daily schedule is being created. please wait for a moment.',
  dailyScheduleMaintenanceCreateScheduleRetryButton:
    'Daily schedule has not been created. please try again.',
  dailyScheduleMaintenanceRetryButton: 'Retry',
  dailyScheduleMaintenancePositionFullCourseButton: 'Full course',
  dailyScheduleMaintenancePositionFullHallButton: 'Full hall',
  dailyScheduleMaintenanceCategorySelectMessage: 'Select or Create',
  dailyScheduleMaintenanceCreateButton: 'Create',
  dailyScheduleMaintenanceSaveButton: 'Save',
  dailyScheduleMaintenanceEditButton: 'Edit',
  dailyScheduleMaintenanceDeleteButton: 'Delete',
  dailyScheduleMaintenanceConfirmButton: 'Confirm',
  dailyScheduleMaintenanceCancelButton: 'Cancel',
  dailyScheduleMaintenanceAddRowButton: 'Add',
  dailyScheduleReagentFertilizerSelectButton: 'Select reagent category',
  dailySchedulePositionCategorySelectMessage: 'Select location',
  dailySchedulePositionCategoryEtcMessage: 'Others',
  dailyScheduleMaintenanceCreateErrorMessage: 'An error has occurred. please try again.',
  dailyScheduleCategoryDuplicateNameErrorMessage: 'The same name already exists.',
  dailyScheduleCategoryDeleteConfirmMessage:
    'The value will be permanently deleted. Are you sure you want to delete?',
  dailyScheduleCategoryDeleteErrorMessage: 'The value is in use and cannot be deleted.',
  dailyScheduleMaintenanceEditBreakAwayButton: 'Exit',
  dailyScheduleMaintenanceEditBreakAwayMessage:
    'If you leave, your work will not be saved. Are you sure you want to leave the page?',
  dailyScheduleMaintenanceDeleteConfirmMessage:
    'The daily schedule will be permanently deleted. Are you sure you want to delete?',
  dailyScheduleSearchDateColumnName: 'Daily schedule date',
  dailyScheduleSearchKeywordPlaceholderName: 'Search task, Reagent/Fertilizer',
  dailyScheduleSearchEmptyScheduleMessage: 'No results were found for your search',
  dailyScheduleMaintenanceLoadingMessage: 'Checking the daily schedule. Please wait for a moment.',
  dailyScheduleMaintenanceCommentLabel: 'Comment',
  dailyScheduleMaintenanceWeatherLabel: 'Weather',
  dailyScheduleMaintenanceWeatherLowestTemperatureLabel: 'Min temperature',
  dailyScheduleMaintenanceWeatherHighestTemperatureLabel: 'Max temperature',
  dailyScheduleMaintenanceWeatherHumidityLabel: 'Humidity',
  dailyScheduleMaintenanceWeatherPrecipitationLabel: 'Precipitation',
  dailyScheduleMaintenanceMowingHeightLabel: 'Mowing height',
  dailyScheduleMaintenanceMowingHeightGreenLabel: 'Green',
  dailyScheduleMaintenanceMowingHeightGreenCollarLabel: 'Green Collar',
  dailyScheduleMaintenanceMowingHeightTeeLabel: 'Tee',
  dailyScheduleMaintenanceMowingHeightFairwayLabel: 'F/W',
  dailyScheduleMaintenanceMowingHeightRoughLabel: 'Rough',
  dailyScheduleMaintenanceMowingHeightGreenSpeedLabel: 'Green Speed',
  dailyScheduleMaintenanceCategoryLabel: 'Category',
  dailyScheduleMaintenanceReagentFertilizerLabel: 'Reagent/Fertilizer',
  dailyScheduleMaintenanceReagentNameLabel: 'Reagent Name',
  dailyScheduleMaintenanceFertilizerNameLabel: 'Fertilizer Name',
  dailyScheduleMaintenanceReagentFertilizingUsageLabel: 'Usage',
  dailyScheduleMaintenanceLocationLabel: 'Location',
  dailyScheduleMaintenanceTaskAssigneeLabel: 'Task Assignee',
  dailyScheduleMaintenanceTaskLabel: 'Task',
  dailyScheduleMaintenanceTaskContentLabel: 'Task Content',
  dailyScheduleMaintenanceTaskNameLabel: 'Task Name',
  dailyScheduleMaintenanceDescriptionLabel: 'Description',
  dailyScheduleMaintenanceAttendanceLabel: 'Attendance',
  dailyScheduleMaintenanceAttendanceEmployeeLabel: 'Employee',
  dailyScheduleMaintenanceAttendanceEmployeeCountLabel: 'Employee count',
  dailyScheduleMaintenanceAttendanceMaleLabel: 'Male',
  dailyScheduleMaintenanceAttendanceMaleCountLabel: 'Male count',
  dailyScheduleMaintenanceAttendanceFemaleLabel: 'Female',
  dailyScheduleMaintenanceAttendanceFemaleCountLabel: 'Female count',
  dailyScheduleMaintenanceAttendanceOffDutyLabel: 'Off-duty',
  dailyScheduleMaintenanceAttendanceOffDutyCountLabel: 'Off-duty count',
  dailyScheduleMaintenanceAttendanceTotalPersonnelLabel: 'Total Personnel',
  dailyScheduleMaintenanceAttendanceTotalPersonnelCountLabel: 'Total Personnel count',
  dailyScheduleMaintenanceReagentTypeGermicideLabel: 'Germicide',
  dailyScheduleMaintenanceReagentTypeInsecticideLabel: 'Insecticide',
  dailyScheduleMaintenanceReagentTypeHerbicideSelectiveLabel: 'Herbicide(selective)',
  dailyScheduleMaintenanceReagentTypeHerbicideNoneSelectiveLabel: 'Herbicide(non-selective)',
  dailyScheduleMaintenanceReagentTypeGrowthRegulatorLabel: 'Growth regulator',
  dailyScheduleMaintenanceReagentTypeOtherLabel: 'Others',

  planLevelFileUploadDescriptionMessage:
    'If the application dates overlap, the most recently uploaded file will be applied.',
  planLevelFileUploadInputPlaceholderMessage: 'Please select the design level file.',
  planLevelSubgradeType: 'Design level type',
  basicType: 'Type',
  basicDateOfApplication: 'Date of application',
  planLevelDatePickerPlaceholderMessage: 'Please select the date you wish to apply.',
  planLevelSubgradeTypeProjectApproval: 'Business approval',
  planLevelSubgradeTypeDetailDesign: 'Detailed design',
  basicUploadInformation: 'Upload information',
  stratumSubgradeType: 'Stratum type',
  stratumSubgradeTypeRockClassification: 'Rock classification',
  stratumSubgradeTypeGeotechnicalInvestigation: 'Geotechnical investigation',
  basicAddNewItem: 'Add',
  basicDetailInfo: 'Detailed information',
  basicTitle: 'Title',

  earthwork: 'Earthwork',
  earthworkArea: 'Earthwork area',
  earthworkAreaPlan: 'Earthwork area plan',

  drawingVectorEditButtonTitle: 'Edit Area',
  drawingVectorVersionRightSideMenuTitle: 'Version Setting',
  drawingVectorVersionButtonTitle: 'Version Setting',

  drawingVectorEditorLeftSideTitle: 'Create area',
  addPolygonVectorButtonTitle: 'Add earthworks Area',
  basicDescription: 'Description',
  drawingVectorEditorDescriptionPlaceholderMessage: 'Take note of what has changed.',
  creatingInformation: 'Creating info',
  basicLatest: 'latest',

  drawingVectorVersionDifferenceInformationLabel: 'Edit information',
  drawingVectorVersionInformationEditButtonTitle: 'Edit version information',
  drawingVectorVersionDeleteButtonTitle: 'Delete version',
  drawingVectorVersionDeleteConfirmContentMessage:
    'When ${n} is deleted, it is also excluded from the calculation of the earthwork aggregate table. Are you sure you want to delete?',

  earthworkAreaPlanEditorHeaderMessage: 'Editing the earthwork area plan',
  earthworkAreaPlanVesionSaveDialogTitle: 'Save earthwork area plan version',
  earthworkAreaPlanVersionSelectDatePlaceholder: 'Select the date to apply',

  earthworkDetailTable: 'Earthwork details',
  earthworkDetailTableOptionDialogTitle: 'Earthwork details select date',
  earthworkDetailTableOptionDialogDescriptionMessage:
    'You cannot select dates for which there is no DSM, GCP, or design level, earthwork area plan.',
  earthworkDetailTableOptionSelectDate: 'Select date',
  earthworkDetailTableCompleteSnackbarMessage: 'Calculation completed',
  basicNoData: 'no data',

  earthworkSummaryTableDescriptionMessage:
    'Earthwork summary is current data extracted from the Earthwork details. Even if the details is changed later, it will not be reflected in the earthwork summary',
  earthworkSummaryTableSuccessToastMessage: 'Earthwork summary has been completed.',
  earthworkSummaryTableSuccessMessage: 'The quantity total and daily total match.',
  earthworkSummaryTableWarningMessage:
    'Please enter the exact quantity for each standard. The total quantity and daily total must match.',

  earthworkTableAccordionTitle: 'Earthwork quantity table',
  earthworkTableGuideDialogDescirption:
    'Earthwork quantity table is created only when both the earthwork are plan and design level are present',
  earthworkTableGuideTitle: 'Guide to creating earthwork quantity table',
  basicHasData: 'available',
  basicDate: 'date',
  earthworkSummaryTable: 'Earthwork summary',
  earthworkSummaryTableDeleteConfirmTitle: 'Delete earthwork summary',
  earthworkSummaryTableCellAddButtonTitle: 'add item',
  earthworkSummaryTableHeaderStratum: 'stratum',
  earthworkSummaryTableHeaderMaterial: 'standard',
  earthworkSummaryTableHeaderQuantity: 'quantity',
  earthworkDetailTableResourceStratumCell: '${n1} and ${n2}',
  earthworkSummaryTableCreationButtonTitle: 'Export to earthwork summary',
  earthworkSummaryTableCreationConfirmTitle: 'Export to earthwork summary',
  earthworkSummaryTableCreationConfirmPlaceholderMessage: 'Enter earthwork summary name',

  backToMapPageTooltipMessage: 'back to map',
  earthworkDetailTableInformationQuantityTitle: 'Planned',
  earthworkDetailTableInformationQuantityContent:
    'Any planned quantities not entered will be displayed as 0.',
  earthworkDetailTableInformationDataTitle: 'no data',
  earthworkDetailTableInformationDataContent:
    "If there is no 'stratum,' 'design level', or 'earthwork area' applied on that date or has been deleted, it is displayed as 'no data' in the table.",
  fileConversionErrorMessage:
    'There was a problem converting files, please contact us on Channel Talk.',
  dailyScheduleExcelExportTitle: 'Export as .xlsx',
  dailyScheduleExcelExportBeforeStartMonthErrorMessage:
    'Please select a date after the start date.',
  dailyScheduleExcelExportThirteenMonthDiffErrorMessage:
    'You can select in increments of up to one year.',
  dailyScheduleExcelExportBreakAwayTitle: 'Cancel export to Excel file',
  dailyScheduleExcelExportCalloutMessage:
    'If you have a housemate without a date, the dates you need to be able to export to a file will remain blank.',
  dailyScheduleExcelExportSelectDateLabel: 'Select period',
  dailyScheduleExcelExportMaxSelectDateMessage: 'Maximum selection period: 1 year',
  dailyScheduleExcelExportErrorMessage:
    'An error occurred and the information you entered could not be verified. please try again.',

  stratumSubgradeTypeIsEmpty: 'Select type',
  stratumEffectiveDateIsEmpty: 'Select application date',

  quantityVolumeOptionEmptyDsm: 'DSM 없음',
  earthworkAreaDetailSubtitle: 'Earthworks area details',
  earthworkSummaryTableEditConfirmTitle: 'Edit earthwork summary name',
  basicDownloadOriginalFile: 'Download original file',
  copyPreviousScheduleSelectDateMessage: 'Date selection',
  copyPreviousSchedulePreviewTitle: 'Preview',
  copyPreviousScheduleButtonLabel: 'Create by copying',
  copyPreviousScheduleEmptyScheduleMessage: 'No data exists for the selected date.',
  copyPreviousScheduleSuccessMessage: 'A daily schedule has been created.',
  basicDuplicateNameErrorMessage: 'This name is already in use.',
  earthworkResourceLegacyInfoTooltipMessage:
    'There is no upload information \nfor data before 2024-08-30.',

  earthworkAreaPlanFileUploadPopupTitle: 'Add earthwork area plan file',

  basicCalculatingStatusMessage: 'Calculating. please wait for a moment.',
  /* 포토박스 */
  photoBox: 'Photo Box',
  photoBoxPhoto: 'photos',
  photoBoxVideo: 'videos',
  photoBoxSnapshotMediaListMessage: 'Only the most recent ${n} photos/videos are displayed.',
  photoBoxSnapshotMediaListEmptyContentsBoxMessage:
    'There are no saved photos/videos.\nPlease upload photos/videos from Photo Box management.',
  photoBoxManagement: 'Photo Box Management',
  photoBoxAlbum: 'Album',
  photoBoxMediaEmptyContentsBoxMessage:
    'There are no saved photos/videos.\nDrag and drop photos and videos, or click the button to upload.',
  photoBoxAlbumDetailEmptyContentsBoxMessage: 'There are no photos/videos in the album.',
  photoBoxMediaFileLabel: 'Photo/Video',
  photoBoxMediaFileUploadLabel: 'Photo/Video Upload',
  photoBoxMemoLabel: 'Memo',
  photoBoxMemoPlaceholderMessage: 'Please enter a memo',
  photoBoxEditMediaSectionCalloutMessage:
    'When selecting multiple photos/videos, the memo will be applied in bulk.',
  photoBoxMediaFileUploadStopMessage:
    'The upload is not yet complete. Completed files will not be canceled. Do you really want to cancel the upload?',
  photoBoxMediaFileUploadRetryMessage: 'There are photos/videos that failed to upload.',
  photoBoxMediaFileUploadLimitCountMessage: 'You can upload up to ${n} photos/videos.',
  photoBoxAddToAlbumLabel: 'Add to Album',
  photoBoxUploadDateLabel: 'Upload Date',
  photoBoxDateOfShootingLabel: 'Shooting Date',
  photoBoxDeleteMediaCallbackMessage:
    'Do you want to delete the photos/videos?\nPhotos/videos saved in the album will also be deleted.',
  photoBoxAddToNewAlbumLabel: 'Add New Album',
  photoBoxAddToAlbumTitle: 'Add to This Album',
  photoBoxNewAlbumLabel: 'New Album ${n}',
  photoBoxAlbumEmptyContentBoxMessage: 'Add an album to manage photos/videos.',
  photoBoxCreateNewAlbumTitle: 'Create New Album',
  photoBoxAddNewAlbumMessage: 'After creating an album, please add photos/videos.',
  photoBoxDeleteAlbumCallbackTitle: 'Delete Album',
  photoBoxDeleteAlbumCallbackContentMessage:
    'Do you want to delete the selected album?\nPhotos within the album will not be removed.',
  photoBoxUpdateAlbumNameLabel: 'Rename Album',
  photoBoxDeleteFromAlbumCallbackTitle: 'Remove from Album',
  photoBoxDeleteFromAlbumCallbackContent:
    'Do you want to remove ${n} photos/videos from this album?',
  photoBoxDeleteMediaCallbackContent:
    'Do you want to delete ${n} photos/videos?\nPhotos/videos saved in the album will also be deleted',
  photoBoxDropzoneGuideSnackbarContentMessage: 'Drag and drop files here to start the upload.',
  photoBoxAddMediaToAlbumDuplicatedMessage:
    'The selected photos/videos are duplicated and cannot be imported.',
  photoBoxAddMediaToAlbumSuccessMessage:
    '${n2} photos/videos have been added to the ‘${n1}’ album.',

  earthworkFileSettingTitle: 'Earthwork file settings',
  pileFileSettingTitle: 'Pile file settings',
  basicPile: 'Pile',
  pileAIdetectionAccordionTitle: 'AI Pile Detection',
  basicInDev: 'In development',
  basicNewFolder: 'New folder',
  basicFileUpload: 'Upload file',
  earthworkFileSettingEmptyMessage:
    'Please upload supporting files related to cancer diagnosis or earthwork.',
  pileFileSettingEmptyMessage: 'Please upload supporting files related to Pile.',
  fileSettingEmptyMessage: 'There is no file.',
  fileSettingUploadNumberLimitMessage: 'You can upload up to 20 files at a time.',
  fileSettingUploadSizeLimitMessage: 'Only files less than 50MB can be uploaded.',
  earthworkFileSettingUploadPopupTitle: 'Add a earthwork file',
  basicEditFileInformation: 'Edit file information',
  basicMove: 'Move',
  basicDownload: 'Download',
  basicFileName: 'File name',
  fileSettingFolderMoveNoFolderErrorMessage:
    'There is no folder to move to. Please add a folder and then move it.',
  fileUploadToFolderButtonTitle: 'Add file to this folder',
  basicEditName: 'Edit name',
  fileSettingFolderNameDuplicateErrorMessage: 'The folder name already exists.',
  fileSettingDeleteFolderMessage:
    'When you delete ${n}, all files in the folder will be deleted. Are you sure you want to delete them?',
  fileArchiveTypeEarthwork: 'Earthwork file',
  fileArchiveTypePile: 'Pile file',
  fileSettingFolderMoveDialogTitle: 'Move to',
  basicCurrentLocation: 'Current location',
  fileSettingFolderMoveConfirmButton: 'Move',
  fileSettingDeleteFileMessage:
    'If you delete ${n}, the file will be permanently deleted from the entire scene. Are you sure you want to delete it?',
  basicMore: 'More',
  photoBoxMediaLoadMoreLabel: 'Load more',
  /* 파노라마 스티칭 */
  panoramaTypeSelectModalTitle: 'Add 360° panorama profile',
  panoramaImageUploadTitle: 'Upload panorama image',
  panoramaImageUploadDescription: 'Upload a panorama image to view it in 360° view.',
  panoramaStitchingTitle: 'Stitch into a panorama image',
  panoramaStitchingDescription:
    "If you upload images taken in the drone's panorama mode, it will be stitched into a panorama.",
  panoramaStitchingImageUploadPopupTitle: 'Add images to stitch',
  panoramaStitchingImageUploadDescription:
    'File format: jpg, jpeg, png (Total file size limit: ${n}MB)',
  panoramaStitchingImageUploadMinDescription: 'Uploading is possible for at least ${n} photos.',
  panoramaStitchingImageDownloadFileName: '[Stitched Panorama] ${n1}(${n2}).Extension',
  panoramaStitchingSelectImageTitle: 'Select Image (${n1}/${n2})',
  panoramaStitchingUploadLimitWarningMessage:
    'The maximum number of file uploads has been exceeded.',
  panoramaStitchingUploadTotalFileSizeLimitWarningMessage:
    'The maximum file upload size has been exceeded.(${n}MB)\nReduce the size and upload again.',
  panoramaStitchingUploadSingleFileSizeLimitWarningMessage:
    'Files with an image capacity of more than ${n}MB cannot be uploaded.',
  fileArchivePhotoBoxFileImageType: 'Photobox Photo',
  fileArchivePhotoBoxFileVideoType: 'Photobox Video',
  photoBoxFileSelectLimitAlertMessage: 'You can select up to ${n} items at once.',
  photoBoxSnapshotPositionDeleteAlertMessage:
    'The location of this photo/video will be deleted.\nAre you sure you want to reset the location?',

  panoramaImageDownloadButtonTitle: 'Download stitched image',
  panoramaOriginalImageDownloadButtonTitle: 'Download original image',

  basicSharingEmail: 'Share via email',
  recipeintUserBoxPlaceholder: 'Show selected users',
  projectUserSearchPlaceholder: 'Search name (shows only project participants)',
  sharingSuccessSnackbarMessage: 'Sharing has been completed.',
  basicDeletedFileErrorMessage: 'This file has been deleted. Please check again.',
};
