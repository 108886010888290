import { Atom, PrimitiveAtom, atom } from 'jotai';

export const isAllChecked = (data: { id: number }[], checkedObj: { [id: number]: boolean }) => {
  return data?.every((x) => checkedObj?.[x.id]);
};

export const getToggledAllCheckboxes = (data: { id: number }[], checked: boolean) => {
  return data?.reduce((acc, x) => ({ ...acc, [x?.id]: checked }), {});
};

export function getInitialItems(update: any[], initialValue: boolean) {
  return (prev) =>
    update?.reduce(
      (acc, cur) => ({ ...acc, [cur?.id]: prev[cur?.id] ?? initialValue }),
      {} as { [x: number]: boolean },
    ) || [];
}

export function initializeItems<T>(
  checked: {
    all: {
      read: Atom<boolean>;
      indeterminate: Atom<boolean>;
    };
    items: PrimitiveAtom<{ [x: number]: boolean }>;
  },
  maintainData?: boolean, // 기존 데이터 유지 여부
) {
  return atom([], (get, set, update: T[]) => {
    const currentValue = get(checked.all.read);
    const indeterminate = get(checked.all.indeterminate);

    // 초기화시 indeterminate 상태가 아닐 경우에만 데이터 정합성을 위해 상위값으로 초기화
    if (indeterminate) return;
    const getNewItems = getInitialItems(update, currentValue);
    set(checked.items, (prev) => {
      const newItems = getNewItems(prev) || {};
      return maintainData ? { ...prev, ...newItems } : newItems;
    });
  });
}
