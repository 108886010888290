import { isGreen } from 'common/customize';
import { useMatchParams } from 'components/common/hooks';
import { useSetAtom } from 'jotai';
import { usePermissionQuery, useResourceListQuery } from 'infrastructure/query';
import { Orthophoto } from 'infrastructure/query/resource/types';
import { ndvi } from 'stores/v3/snapshotDetail/twoD';
import { initializeItems } from 'stores/v3/snapshotDetail/twoD/visibilityUtils';

export default function useOrthophoto(options: { snapshotId?: number } = {}) {
  const { projectId, snapshotId } = useMatchParams();
  const { data: permission } = usePermissionQuery(projectId);
  const setNdvisChecked = useSetAtom(initializeItems(ndvi.checked, true));

  const orthophoto = useResourceListQuery<Orthophoto>(
    {
      snapshotId: options.snapshotId ?? snapshotId,
      permission,
      type: 'ORTHOPHOTO',
    },
    {
      onSuccess: () => {
        isGreen() && setNdvisChecked([{ id: 'vari' }]);
      },
    },
  )?.resources?.[0];

  return {
    orthophoto,
  };
}
