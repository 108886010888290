import { useMatchParams } from 'components/common/hooks';
import { useAtom } from 'jotai';
import { LatLng } from 'leaflet';
import { nls } from 'infrastructure/locale/language';
import { BulkPolygon, DrawingVector } from 'infrastructure/query/annotation/drawingVector/types';
import useDrawingVectorMutation from 'infrastructure/query/annotation/drawingVector/useDrawingVectorMutation';
import useDrawingVectorQuery from 'infrastructure/query/annotation/drawingVector/useDrawingVectorQuery';
import { useEffect } from 'react';
import {
  drawingVectorEditingAtom as atom,
  EditingBulkPolygon,
} from 'stores/v3/snapshotDetail/twoD/annotation/drawingVector';
import { ANNOTATION_COLOR_CHIP } from 'components/styles/colors/symbols';

export default function useDrawingVectorEdit() {
  const { resourceId } = useMatchParams();
  const { updateMutation } = useDrawingVectorMutation();

  const [createMode, setCreateMode] = useAtom(atom.createMode);
  const [bulkPolygons, setBulkPolygons] = useAtom(atom.bulkPolygons);
  const [selectedBulkPolygonTempId, setSelectedBulkPolygonTempId] = useAtom(
    atom.selectedBulkPolygonTempId,
  );
  const [dirty, setDirty] = useAtom(atom.dirty);

  const { drawingVector } = useDrawingVectorQuery({ enabled: true }, resourceId);

  useEffect(() => {
    if (Boolean(bulkPolygons.length) || !drawingVector?.annotationList?.length) return;

    setBulkPolygons(
      drawingVector?.annotationList?.map((x) => ({ ...x, tempId: x.annotationId })) || [],
    );
  }, [resourceId, drawingVector]);

  function createBulkPolygon(latlngs: LatLng[]) {
    const positionList = latlngs.map((latlng) => ({
      latitude: +latlng.lat.toFixed(16),
      longitude: +latlng.lng.toFixed(16),
    }));

    const colorList = Object.values(ANNOTATION_COLOR_CHIP);
    const tempId = Math.max(...bulkPolygons.map((x) => x.tempId).concat(0)) + 1;
    const name = `${nls.earthworkArea()} ${bulkPolygons.length + 1}`;
    const color = colorList[tempId % colorList.length];
    const newBulkPolygon = { tempId, name, color, positionList } as EditingBulkPolygon;
    setBulkPolygons((prev) => [...prev, newBulkPolygon]);
    setCreateMode(false);
    setDirty(true);
  }

  function updateBulkPolygon(tempId: number, latlngs: LatLng[]) {
    const positionList = latlngs.map((latlng) => ({
      latitude: +latlng.lat.toFixed(16),
      longitude: +latlng.lng.toFixed(16),
    }));
    setBulkPolygons((prev) => [
      ...prev.map((x) => (x.tempId === tempId ? { ...x, positionList } : x)),
    ]);
    setDirty(true);
  }

  function deleteBulkPolygon(tempId: number) {
    setBulkPolygons((prev) => [...prev.filter((x) => x.tempId !== tempId)]);
    setSelectedBulkPolygonTempId(false);
    setDirty(true);
  }

  async function save({
    title,
    description,
    effectiveDate,
  }: Pick<DrawingVector, 'title' | 'description' | 'effectiveDate'>) {
    const annotationList: BulkPolygon[] = bulkPolygons.map((x) => ({
      annotationId: x.annotationId || null,
      color: x.color,
      positionList: x.positionList.map((position, idx) => ({
        id: position.id || null,
        annotationId: x.annotationId || null,
        latitude: position.latitude,
        longitude: position.longitude,
        idx,
      })),
      name: x.name,
    }));
    await updateMutation.mutateAsync({
      drawingVectorId: resourceId,
      data: { title, description, effectiveDate, annotationList },
    });
    setDirty(false);
  }

  function reset() {
    setBulkPolygons([]);
    setSelectedBulkPolygonTempId(false);
    setCreateMode(false);
    setDirty(false);
  }

  return {
    bulkPolygons,
    selectedBulkPolygonTempId,
    setSelectedBulkPolygonTempId,
    reset,
    createBulkPolygon,
    updateBulkPolygon,
    deleteBulkPolygon,
    save,
    createMode,
    setCreateMode,
    dirty,
  };
}
