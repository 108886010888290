import GA from 'infrastructure/ga';
import { TWO_D_FLOATING } from '../category';

export const TWO_D_FLOATING_POINT_ADD_BTN = `${TWO_D_FLOATING}PointAddBtn`;
export const TWO_D_FLOATING_POLYGON_ADD_BTN = `${TWO_D_FLOATING}PolygonAddBtn`;
export const TWO_D_FLOATING_POLYLINE_ADD_BTN = `${TWO_D_FLOATING}PolylineAddBtn`;

export const TWO_D_FLOATING_ORIGIN_IMG_VIEW_CBOX = `${TWO_D_FLOATING}OriginImgViewCbox`;
export const TWO_D_FLOATING_ORIGIN_IMG_ADD_BTN = `${TWO_D_FLOATING}OriginImgAddBtn`;
export const TWO_D_FLOATING_GCP_VIEW_CBOX = `${TWO_D_FLOATING}GCPViewCbox`;
export const TWO_D_FLOATING_GCP_ADD_BTN = `${TWO_D_FLOATING}GCPAddBtn`;

export const TWO_D_FLOATING_COMPARE_SLIDER_BTN = `${TWO_D_FLOATING}CompareSliderBtn`;
export const TWO_D_FLOATING_COMPARE_TWO_SCREEN_BTN = `${TWO_D_FLOATING}CompareTwoScreenBtn`;
export const TWO_D_FLOATING_COMPARE_FOUR_SCREEN_BTN = `${TWO_D_FLOATING}CompareFourScreenBtn`;

export const TWO_D_FLOATING_HOLE_MOVE_BTN = `${TWO_D_FLOATING}HoleMoveBtn`;

export function send2DFloatingOriginImgViewCbox(checked: boolean) {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_ORIGIN_IMG_VIEW_CBOX, checked ? 'ON' : 'OFF');
}
export function send2DFloatingOriginImgAddBtn() {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_ORIGIN_IMG_ADD_BTN);
}
export function send2DFloatingGcpViewCbox(checked: boolean) {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_GCP_VIEW_CBOX, checked ? 'ON' : 'OFF');
}
export function send2DFloatingGcpAddBtn() {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_GCP_ADD_BTN);
}
export function send2DFloatingCompareSliderBtn() {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_COMPARE_SLIDER_BTN);
}
export function send2DFloatingCompareTwoScreenBtn() {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_COMPARE_TWO_SCREEN_BTN);
}
export function send2DFloatingCompareFourScreenBtn() {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_COMPARE_FOUR_SCREEN_BTN);
}
export function send2DFloatingHoleMoveBtn(label: string) {
  GA.event(TWO_D_FLOATING, TWO_D_FLOATING_HOLE_MOVE_BTN, label);
}
