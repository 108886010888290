import { atom } from 'jotai';

export type DemState = {
  id: number;
  visible: boolean;
  visibleOnMap?: boolean;
  opacity: number;
  histogram?: {
    [index: number]: { count: number; color: [number, number, number] };
  };
};

export type DemRange = [number, number];

const demEnabledAtom = atom<boolean>(false);
const demExpandedAtom = atom<boolean>(false);
const demStatesAtom = atom<DemState[]>([]);

/** Dem들(dsm, dtms)의 range를 공통으로 조정하기 위해서 사용. 추후 분리가 필요하다면 이 부분 수정 */
const demsRangeAtom = atom<DemRange>([0, 100]);

const selectedRightSideDemIdAtom = atom<number | false>(false);

export const demAtom = {
  enabled: demEnabledAtom, // dem enabled
  expanded: demExpandedAtom, // accordion expanded
  states: demStatesAtom, // dem states
  range: demsRangeAtom, // dem histogram range
  selectedRightSideDemId: selectedRightSideDemIdAtom, // right side dem id
};
