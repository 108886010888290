import enLocale from 'date-fns/locale/en-US';
import jaLocale from 'date-fns/locale/ja';
import koLocale from 'date-fns/locale/ko';

import en from './en';
import ja from './ja';
import ko from './ko';

const defaultLocale = ko;

export const getLanguage = () => {
  const lang = getBrowserLanguage();

  if (lang === 'ko' || lang === 'en' || lang === 'ja') {
    return lang;
  }

  return 'en';
};

export const getLocale = () => {
  switch (getLanguage()) {
    case 'ko':
      return koLocale;
    case 'ja':
      return jaLocale;
    case 'en':
    default:
      return enLocale;
  }
};

function getBrowserLanguage() {
  const params = queryParser(window.location.href);
  let lang = navigator.language;

  if (params && params.lang && params.lang !== '') {
    lang = params.lang;
  }

  return lang.split('-')[0];
}

function queryParser(query) {
  const parseQuery = query.split('?');
  const parseQueryList = (parseQuery.length > 1 && parseQuery[1].split('&')) || [];
  const len = parseQueryList.length;

  let result;

  if (len === 0) {
    result = null;
  } else {
    result = {};

    parseQueryList.forEach((value) => {
      const parseVal = value.split('=');

      result[parseVal[0]] = parseVal[1];
    });
  }

  return result;
}

function language() {
  (window as any).nls = getLanguageObject();
}

function getLanguageObject(): { [key in keyof typeof defaultLocale]: string } {
  switch (getLanguage()) {
    case 'ko':
      return ko;
    case 'ja':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return ja;
    case 'en':
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return en;
  }
}

type ValueParameter = number | string | (number | string)[];
type NlsFunction = (key: string, value?: ValueParameter) => string;
type NlsObject = {
  [key in keyof typeof defaultLocale]: (value?: ValueParameter) => string;
};

const nlsFunction: NlsFunction = (key, value?) => {
  if ((window as any).nls == null) {
    language();
  }

  const locale = (window as any).nls;
  let result = key in locale ? locale[key] : defaultLocale[key];

  if (value != null && result.indexOf(`\$\{n\}`) > -1) {
    result = result.replace(`\$\{n\}`, value);
    return result;
  }

  if (value != null && value[0] != null && result.indexOf(`\$\{n1\}`) > -1) {
    let i = 1;
    do {
      result = result.replace(`\$\{n${i}\}`, value[i - 1]);
      i += 1;
    } while (result.indexOf(`\$\{n${i}\}`) > -1);
  }
  return result;
};

// nls 함수에 로케일에 있는 키로 접근하면 값을 돌려주는 속성 정의
// nls('key') === nls.key()
// nls('key', value) === nls.key(value)
Object.keys(defaultLocale).forEach((key) => {
  Object.defineProperty(nlsFunction, key, {
    get() {
      return (val: ValueParameter) => nlsFunction(key, val);
    },
  });
});

const nls = nlsFunction as NlsFunction & NlsObject;

export { nls };
