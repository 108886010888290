export const keys = {
  all: () => ['drawing-vector'],
  summaries: (snapshotId: number) => ['drawing-vector', 'summaries', { snapshotId }],
  detail: (resourceId: number) => ['drawing-vector', 'detail', { resourceId }],
  resourceList: (zoneId: number) => ['drawing-vector', 'resource', { zoneId }],

  version: {
    all: () => ['drawing-vector', 'version'],
    list: (resourceId: number) => ['drawing-vector', 'version', { resourceId }],
  },

  comletion: {
    detail: (resourceId: number) => ['drawing-vector', 'completion', { resourceId }],
  },
};
