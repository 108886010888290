import { COMMON_MAP_OPTIONS } from 'common/policies/map';
import React from 'react';
import { TileLayer } from 'react-leaflet';

export default function VWorldSatellite({ mapKey, ...props }) {
  if (!mapKey) return null;
  return (
    <TileLayer
      url={`http://api.vworld.kr/req/wmts/1.0.0/${mapKey}/Satellite/{z}/{y}/{x}.jpeg`}
      {...COMMON_MAP_OPTIONS}
      {...props}
    />
  );
}
