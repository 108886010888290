import * as category from '../category';

/* 프로젝트 하위 */
export const PHOTO_BOX_BTN = `${category.PHOTO_BOX}BTN`;
export const PHOTO_BOX_CHECK_BOX = `${category.PHOTO_BOX}Cbox`;
export const PHOTO_BOX_OPEN_SECONDARY_BTN = `${category.PHOTO_BOX}OpenSecondaryBtn`;
export const PHOTO_BOX_EDIT_BTN = `${category.PHOTO_BOX}EditBtn`;
export const PHOTO_BOX_RESET_BTN = `${category.PHOTO_BOX}ResetBtn`;
export const PHOTO_BOX_DOWN_BTN = `${category.PHOTO_BOX}DownBtn`;
export const PHOTO_BOX_DELETE_BTN = `${category.PHOTO_BOX}DelBtn`;
export const PHOTO_BOX_DETAIL_BTN = `${category.PHOTO_BOX}DetailBtn`;
export const PHOTO_BOX_UPLOAD_BTN = `${category.PHOTO_BOX}UploadBtn`;
export const PHOTO_BOX_UPLOAD_SUCCESS = `${category.PHOTO_BOX}UploadSuccess`;
/* 포토박스 - 전체 메뉴 */
export const PHOTO_BOX_ALL_MENU = `${category.PHOTO_BOX}AllMenu`;
export const PHOTO_BOX_ALL_MENU_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AllMenuAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_ADD_POPUP_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AlbumAddPopUpAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_ADD_POPUP_ALBUM_EDIT_BTN = `${category.PHOTO_BOX}AlbumAddPopUpAlbumEditBtn`;
export const PHOTO_BOX_ALL_MENU_EDIT_BTN = `${category.PHOTO_BOX}AllMenuEditBtn`;
export const PHOTO_BOX_ALL_MENU_DOWN_BTN = `${category.PHOTO_BOX}AllMenuDownBtn`;
export const PHOTO_BOX_ALL_MENU_DELETE_BTN = `${category.PHOTO_BOX}AllMenuDelBtn`;
/* 포토박스 - 앨범 메뉴 */
export const PHOTO_BOX_ALBUM_MENU = `${category.PHOTO_BOX}AlbumMenu`;
export const PHOTO_BOX_ALBUM_MENU_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AlbumMenuAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_MENU_ALBUM_NAME_EDIT_BTN = `${category.PHOTO_BOX}AlbumMenuAlbumNameEditBtn`;
export const PHOTO_BOX_ALBUM_MENU_EDIT_BTN = `${category.PHOTO_BOX}AlbumMenuEditBtn`;
export const PHOTO_BOX_ALBUM_MENU_DELETE_FROM_ALBUM_BTN = `${category.PHOTO_BOX}AlbumMenuDelFromAlbumBtn`;
export const PHOTO_BOX_ALBUM_MENU_DOWN_BTN = `${category.PHOTO_BOX}AlbumMenuDownBtn`;
export const PHOTO_BOX_ALBUM_MENU_DELETE_BTN = `${category.PHOTO_BOX}AlbumMenuDelBtn`;
/* 미디어 상세 팝업 */
export const PHOTO_BOX_DETAIL_PHOTO_EDIT_BTN = `${category.PHOTO_BOX}DetailPhotoEditBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_EDIT_SUCCESS = `${category.PHOTO_BOX}DetailPhotoEditSuccess`;
export const PHOTO_BOX_DETAIL_PHOTO_ALBUM_ADD_BTN = `${category.PHOTO_BOX}DetailPhotoAlbumAddBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_DOWN_BTN = `${category.PHOTO_BOX}DetailPhotoDownBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_DELETE_BTN = `${category.PHOTO_BOX}DetailPhotoDelBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_EDIT_BTN = `${category.PHOTO_BOX}DetailVideoEditBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_EDIT_SUCCESS = `${category.PHOTO_BOX}DetailVideoEditSuccess`;
export const PHOTO_BOX_DETAIL_VIDEO_ALBUM_ADD_BTN = `${category.PHOTO_BOX}DetailVideoAlbumAddBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_DOWN_BTN = `${category.PHOTO_BOX}DetailVideoDownBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_DELETE_BTN = `${category.PHOTO_BOX}DetailVideoDelBtn`;
/* 기타 */
export const PHOTO_BOX_UPLOAD_DRAG_DROP = `${category.PHOTO_BOX}UploadDragDrop`;
