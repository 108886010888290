import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import {
  Project,
  ProjectPagination,
  ProjectPaginationParams,
  ProjectUnit,
} from 'infrastructure/query/project/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export function useProjectQuery(projectId: number) {
  return useQuery<Project>(keys.detail(projectId), () => api.read({ projectId }), {
    enabled: !!projectId,
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}

export function useProjectListQuery({ searchKeyword, order, page }: ProjectPaginationParams) {
  return useQuery<ProjectPagination>(
    keys.list(searchKeyword, order, page),
    () =>
      api.list({
        searchKeyword,
        order,
        page,
      }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      select: (data) => ({
        result: {
          projects: data.result.projects.filter((v) => v?.permissionInfo?.projectGet),
        },
        meta: data.meta,
      }),
    },
  );
}

export function useProjectMutation() {
  const queryClient = useQueryClient();
  const updateProject = useMutation((payload: any) => api.update(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.detail(v?.projectId)),
  });
  const createProject = useMutation((payload: any) => api.create(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.update()),
  });
  const deleteProject = useMutation((payload: any) => api.delete(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.update()),
  });

  return { updateProject, createProject, deleteProject };
}

export function useProjectUnitListQuery(projectId: number, options?: any) {
  return useQuery<ProjectUnit[]>(keys.unitList(projectId), () => api.units({ projectId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...options,
  });
}

export function useProjectUnitMutation(projectId: number) {
  const queryClient = useQueryClient();
  const createProjectUnit = useMutation((payload: any) => api.unitCreate(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.unitList(projectId)),
  });
  const deleteProjectUnit = useMutation((payload: any) => api.unitDelete(payload), {
    onSuccess: (_, v) => queryClient.invalidateQueries(keys.unitList(projectId)),
  });

  return { createProjectUnit, deleteProjectUnit };
}
