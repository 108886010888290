import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'array.prototype.fill';
import { isDev } from 'common/utils/env';
import GA from 'infrastructure/ga';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGa from 'react-ga4';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router';
import 'sanitize.css/sanitize.css';
import App from './App';

const history = createBrowserHistory();

if (process.env.SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.SENTRY_KEY,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.01, // performance 측정할 sampling data 전송비율
  });
}

const queryClient = new QueryClient();
const GAEnabled = GA.init();

const encryptedUserId = Cookies.get('userId');
ReactGa.set({ user_id: encryptedUserId });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      {GAEnabled && <GA />}
      <App />
    </Router>
    {isDev && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,
  document.getElementById('app'),
);
