import { LatLngBoundsLiteral } from 'leaflet';
import { Drawing } from 'infrastructure/query/drawing/types';
import { FileSettingType } from 'infrastructure/query/fileSetting/types';
import { Folder } from 'infrastructure/query/folder/types';
import { PlanLevel } from 'infrastructure/query/planLevel/types';
import { Stratum } from 'infrastructure/query/stratum/types';
import { LoadingStatus } from 'stores/data/types';

export interface TempFile<T = any> {
  tempId: string;
  name: string;
  size: number;
  file: File;
  status: LoadingStatus;
  type: ResourceType;
  additional?: T;
  id?: number;
  errorCode?: string;
}

// BIRDS_EYE_VIEW의 경우 Resource에 속하긴 하나 다른 타입들과 데이터 응답 형식이 너무 달라서 따로 분리함
export type ResourceType =
  | Resource['type']
  | 'AERIAL_IMAGE'
  | 'BIRDS_EYE_VIEW'
  | 'ISSUE_FILE'
  | 'GCP_IMAGE'
  | 'POLYGON_VECTOR'
  | 'ENGINE_THERMAL'
  | 'PHOTO_BOX'
  | 'PHOTOBOX_IMAGE'
  | 'PHOTOBOX_VIDEO'
  | FileSettingType
  | 'PANORAMA'
  | 'PANORAMA_STITCH_SOURCE_IMAGE';

export interface BaseResource<ResourceType, DataType> {
  id: number;
  name: string;
  size: number;
  description: string;
  fileUrl: string;
  type: ResourceType;
  conversionData: DataType;
  alias: string;
  metaUrl: string;
  binaryUrl: string;
  snapshotRelationCreated: string;
  folder: Folder;
  data?: any;
  createdUserName?: string;
  createdDate?: string;
}

export type Resource =
  | Bim
  | PointCloud
  | Mesh
  | Orthophoto
  | GcpResource
  | Drawing
  | Dem
  | ImageArchive
  | PlanLevel
  | Stratum
  | EngineMeshGlb
  | PrintOut
  | Etc
  | Report
  | Ndvi
  | Ndmi
  | AnnotationCsv
  | Thermal
  | SuperResolution
  | LandDisplacement
  | PanoramaStitchSourceImage;

export interface Bim extends BaseResource<'BIM', {}> {
  data: {
    bimInfo: {
      tileSetUrl?: string;
      ionAccessToken?: string;
      glbFilePath: string;
      isNewConverter: boolean;
      accessToken: string;
    };
    progressStatus: ProgressStatus;
    positionInfo?: {
      isManual: boolean;
      position: { x: number; y: number; z: number };
      rotation: { x: number; y: number; z: number; order?: string };
    };
    /** TilesPositionInfo 별도로 지정 */
    tilesPositionInfo?: {
      isManual: boolean;
      position: { x: number; y: number; z: number };
      rotation: { x: number; y: number; z: number; order?: string };
    };
  };
}
export type PointCloud = BaseResource<'POINT_CLOUD' | 'ENGINE_POINT_CLOUD', {}>;
export type Mesh = BaseResource<'MESH' | 'ENGINE_MESH', {}>;
export type EngineMeshGlb = BaseResource<'ENGINE_MESH_GLB', {}>;

export type Orthophoto = RasterResource<'ORTHOPHOTO' | 'ENGINE_ORTHOPHOTO'>;
export interface GcpResource extends BaseResource<'GCP', {}> {
  data: { coordinate: string };
}

export type DrawingType = 'DRAWING_RASTER' | 'DRAWING_VECTOR' | 'DRAWING_POSITIONING';

export type ProgressStatus =
  | 'CONVERSION'
  | 'ENHANCE_QUALITY'
  | 'DONE'
  | 'ERROR'
  | 'CANCEL'
  | 'WARNING'
  | '';
export type RasterResource<ResourceType> = BaseResource<ResourceType, RasterData>;

export type RasterData = {
  coordinate: string;
  rootUrl: string;
  meta: {
    tileMap: any;
    zoomLevel: {
      min: number;
      max: number;
    };
    boundingBox: {
      maxx: number;
      maxy: number;
      minx: number;
      miny: number;
    };
    rootTaskId: string;
    token: string; // TODO: check API
  };
  errorCode: string; //TODO: check API
  progressStatus: ProgressStatus;
  progressPercentage: number;
};

export interface UploadFilePolicy {
  uploadCount?: { min: number; max: number };
  acceptFormat?: string;
  maxSize?; // in megabytes
  aliasMaxLength?: number;
}

export interface BinaryData {
  tifBinaryFileInfo: {
    width: number;
    border: [number, number, number, number];
    height: number;
    coordinate: string;
    targetWidth: number;
    binFilePath: string;
    targetHeight: number;
  };
  tifRange: { min: number; max: number; mean: number; stdDev: number };
  progressStatus: ProgressStatus;
}
export interface TifPolygon {
  tifPolygonArea: number;
  tifPolygonFileInfo: { keyPath: string; storageName: string };
  tifPolygonVolume: {
    gsd: number;
    cutVolume: number;
    fillVolume: number;
    stratumCutVolumeData: { soil: number };
  };
}

/**
 * @description
 * Dem(DEM): 수치표고모델
 * Dsm(DSM): 수치표면모델
 * Dtm(DTM): 수치지형모델
 */
export type Dem = Dsm | Dtm;
export interface Dsm extends RasterResource<'DSM' | 'ENGINE_DSM'> {
  data: BinaryData & TifPolygon;
}
export interface Dtm extends RasterResource<'DTM'> {
  data: BinaryData & TifPolygon & { dtmSourceType: 'MANUAL' | 'FILE_UPLOAD' | 'MANUAL_TEMP' };
}

export interface Thermal extends RasterResource<'ENGINE_THERMAL'> {
  data: BinaryData & TifPolygon;
}
export interface Ndvi extends RasterResource<'NDVI' | 'ENGINE_NDVI'> {
  data: BinaryData & TifPolygon & { vegetationIndexAreaType: 'WHOLE' | 'SEGMENTATION' }; //TODO: check API
}
export interface Ndmi extends RasterResource<'NDMI'> {
  data: BinaryData & TifPolygon;
}
export type SuperResolution = RasterResource<'SUPER_RESOLUTION'>;

export type Etc = BaseResource<'ETC', {}>;

export interface ImageArchive extends BaseResource<'AERIALIMAGE_ARCHIVE', any> {
  data: { ids: number[] };
}

export interface PrintOut extends BaseResource<'PRINT_OUT', any> {
  data: {
    annotationIds: number[];
    drawingIds: number[];
    roi: [{ latitude: number; longitude: number }, { latitude: number; longitude: number }];
    format: 'PNG' | 'PDF' | 'DXF';
    resolution: 'HIGH' | 'LOW';
    rotationDegree: number;
    progressStatus?: ProgressStatus;
  };
}

export interface AnnotationCsv extends BaseResource<'ANNOTATION_CSV', {}> {
  data: { color?: string };
}

export type PrintOutRequestParam = Pick<PrintOut, 'type' | 'name' | 'data'>;
export interface Report extends BaseResource<'REPORT', {}> {
  data: { progressStatus: ProgressStatus };
}

export interface LandDisplacement extends BaseResource<'LAND_DISPLACEMENT', {}> {
  data: {};
}

export interface PanoramaStitchSourceImage
  extends BaseResource<'PANORAMA_STITCH_SOURCE_IMAGE', {}> {
  data: {};
}

export type BoundingBox = {
  maxx: number;
  maxy: number;
  minx: number;
  miny: number;
};

export type LatLng = {
  latitude: number;
  longitude: number;
};

export function isInBoundingBox(boundingBox: BoundingBox, latLng: LatLng): boolean {
  return (
    latLng.latitude >= boundingBox.miny &&
    latLng.latitude <= boundingBox.maxy &&
    latLng.longitude >= boundingBox.minx &&
    latLng.longitude <= boundingBox.maxx
  );
}

// 구역 목록에서도 해당 함수를 사용하기 때문에, RasterResource가 아닌 conversionData를 포함하는 객체로 지정
export function getBounds(raw: { conversionData: RasterData }) {
  return raw?.conversionData?.meta?.boundingBox
    ? ([
        [
          Number(raw?.conversionData?.meta?.boundingBox?.miny),
          Number(raw?.conversionData?.meta?.boundingBox?.minx),
        ],
        [
          Number(raw?.conversionData?.meta?.boundingBox?.maxy),
          Number(raw?.conversionData?.meta?.boundingBox?.maxx),
        ],
      ] as LatLngBoundsLiteral)
    : null;
}

// 구역 목록에서도 해당 함수를 사용하기 때문에, RasterResource가 아닌 conversionData를 포함하는 객체로 지정
export function getRasterTmsUrl(raw: { conversionData: RasterData }, postfix: '' | '@2x' = '') {
  // 이유를 알 수 없는 간헐적 CORS 오류가 URL에 쿼리 파라미터 추가해주면 사라져서 ? 추가
  const rootURL = raw?.conversionData?.rootUrl;
  return rootURL ? `${rootURL}{z}/{x}/{y}${postfix}.png?` : '';
}

// 구역 목록에서도 해당 함수를 사용하기 때문에, RasterResource가 아닌 conversionData를 포함하는 객체로 지정
export function getZoomLevel(raw: { conversionData: RasterData }) {
  return raw?.conversionData?.meta?.zoomLevel;
}
export function get2dStatus(raw: Resource) {
  return raw?.conversionData?.progressStatus;
}
export function get3dStatus(raw: Stratum | PlanLevel | Dsm) {
  return raw?.data?.progressStatus;
}
export function getVolume(raw: Stratum | PlanLevel) {
  return raw?.data?.tifPolygonVolume;
}
export function getTifRange(raw: Stratum | PlanLevel | Dsm | Ndvi | Ndmi | Dem): [number, number] {
  if (!raw?.data?.tifRange) return [0, 100];
  return [raw?.data?.tifRange?.min, raw?.data?.tifRange?.max];
}

export function getLowerCaseResourceType(type: ResourceType) {
  return {
    PLAN_LEVEL: 'planLevel',
    BIM: 'bim',
    STRATUM: 'stratums',
    DRAWING_RASTER: 'drawings',
    DRAWING_VECTOR: 'drawings',
    DRAWING_POSITIONING: 'drawings',
    ANNOTATION_CSV: 'chains',
    POLYGON_VECTOR: 'bulkPolygons',
    PHOTOBOX_IMAGE: 'photoBoxImage',
    PHOTOBOX_VIDEO: 'photoBoxVideo',
  }[type];
}

export interface FileUploadMutationOptions {
  onSuccess: Callback;
  onError: ErrorCallback;
  onCancel: Callback;
}

export type Callback = (tempFile: TempFile) => void;
export type ErrorCallback = (tempFile: TempFile, errorCode?: string) => void;
