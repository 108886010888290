import GA from 'infrastructure/ga';
import { LOCA } from 'infrastructure/ga/category';
import { LaborerTabValue, VehicleTabValue } from 'stores/data/types';
import * as category from 'infrastructure/ga/category';

/**
 *  근로자 위치
 */
export const LOCA_WORKER_LIST = `${LOCA}WorkerList`;
export const LOCA_WORKER_TAB = `${LOCA}WorkerTab`;
export const LOCA_WORKER_HAZARD_AREA_TGL = `${LOCA}WorkerHazardAreaTgl`;
export const LOCA_HISTORY_DATE_PICKER = `${LOCA}HistoryDatePicker`;
export const LOCA_HISTORY_SKIP_BACKWARD_BTN = `${LOCA}HistorySkipBackwardBtn`;
export const LOCA_HISTORY_PLAYBACK_BTN = `${LOCA}HistoryPlaybackBtn`;
export const LOCA_HISTORY_SKIP_FORWARD_BTN = `${LOCA}HistorySkipForwardBtn`;
export const LOCA_HISTORY_SLIDER = `${LOCA}HistorySlider`;

export const sendLocaWorkerTab = (tabValue: LaborerTabValue) => {
  GA.event(
    LOCA,
    LOCA_WORKER_TAB,
    tabValue === 'LABORER_LOCATION_LIST' ? 'Location' : 'Location History',
  );
};

/**
 *  차량계 위치
 */
export const LOCA_VEHICLE_LIST = `${LOCA}VehicleList`;
export const LOCA_VEHICLE_TAB = `${LOCA}VehicleTab`;
export const LOCA_VEHICLE_HISTORY_DATE_PICKER = `${LOCA}VehicleHistoryDatePicker`;
export const LOCA_VEHICLE_HISTORY_SKIP_BACKWARD_BTN = `${LOCA}VehicleHistorySkipBackwardBtn`;
export const LOCA_VEHICLE_HISTORY_PLAYBACK_BTN = `${LOCA}VehicleHistoryPlaybackBtn`;
export const LOCA_VEHICLE_HISTORY_SKIP_FORWARD_BTN = `${LOCA}VehicleHistorySkipForwardBtn`;
export const LOCA_VEHICLE_HISTORY_SLIDER = `${LOCA}VehicleHistorySlider`;

export const sendLocaVehicleTab = (tabValue: VehicleTabValue) => {
  GA.event(
    LOCA,
    LOCA_VEHICLE_TAB,
    tabValue === 'VEHICLE_LOCATION_LIST' ? 'Location' : 'Location History',
  );
};

/**
 * 차량계 위치 경로
 */
export const LOCA_VEHICLE_HISTORY_ROUTE_SLIDER = `${category.LOCA}VehicleHistoryRouteSlider`;
export const LOCA_VEHICLE_HISTORY_ROUTE_CAPTURE_BTN = `LocaVehicleHistoryRouteCaptureBtn`;
