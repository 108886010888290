import { AnnotationType, Elevation } from './types';

export const keys = {
  listByZoneId: (type: AnnotationType, zoneId: number, snapshotId: number) => [
    'annotations',
    'list',
    { zoneId, snapshotId, type },
  ],
  listBySnapshotId: (type: AnnotationType, snapshotId: number) => [
    'annotations',
    'list',
    { snapshotId, type },
  ],
  detail: (annotationId: number) => ['annotations', 'detail', annotationId],
  elevation: {
    detail: (elevationId: number) => ['elevation', 'detail', elevationId],
    download: (elevationId: number) => ['elevation', 'download', elevationId],
    dxf: (snapshotId: number, dxfFileKey: string) => ['elevation', 'dxf', snapshotId, dxfFileKey],
    graph: (url: Elevation['jsonFileUrl']) => ['elevation', 'graph', url],
    earthworkCrossingLines: (snapshotId: number, resourceId: number, planLevelId?: number) => [
      'elevation',
      'earthworkCrossingLines',
      snapshotId,
      { resourceId, planLevelId },
    ],
  },
  volume: {
    list: () => ['volumes', 'list'],
    listByPolygonId: (polygonId: number) => ['volumes', 'list', polygonId], // polygonId가 변경될 때 detail도 invalidate되어, 사라진 volume id로 조회하게 되는 것을 방지하고자 list와 detail을 분리
    detail: (polygonId: number, volumeId: number) => ['volumes', 'detail', polygonId, volumeId],
  },
  chain: {
    all: () => ['chains'],
    listAll: () => ['chains', 'list'],
    list: (snapshotId: number) => ['chains', 'list', { snapshotId }],
    listByZone: (zoneId: number, snapshotId: number) => ['chains', 'list', { zoneId, snapshotId }],
    group: (groupId: number) => ['chains', 'detail', groupId],
    groupAll: () => ['chains', 'detail'],
  },
};
