import { nls } from 'infrastructure/locale/language';

const TOOLTIP_CLASS_NAME = 'leaflet-control-rotate-toggle-tooltip';
export function useMapRotationTooltip() {
  const initialize = () => {
    const rotateControl = document.getElementsByClassName('leaflet-control-rotate');
    if (rotateControl.length < 1) {
      return;
    }

    // x에 hover했을 때 툴팁이 보이도록 div 생성
    const div = document.createElement('div');
    div.setAttribute('class', TOOLTIP_CLASS_NAME);
    div.innerHTML = nls.mapRotationTooltipMessage();
    rotateControl[0].appendChild(div);
  };

  const show = () => {
    const tooltip = document.getElementsByClassName(TOOLTIP_CLASS_NAME);
    if (tooltip.length < 1) {
      return;
    }
    tooltip[0].setAttribute('style', 'display: flex');
  };

  const hide = () => {
    const tooltip = document.getElementsByClassName(TOOLTIP_CLASS_NAME);
    if (tooltip.length < 1) {
      return;
    }
    tooltip[0].setAttribute('style', 'display: none');
  };

  return {
    initializeTooltip: initialize,
    showTooltip: show,
    hideTooltip: hide,
  };
}
