import { LaborerHistoryStatus } from 'infrastructure/query/laborer/types';

export const keys = {
  list: (projectId: number) => ['laborer', 'list', projectId],
  listHistory: () => ['laborer', 'list', 'history'],
  listHistoryV2: (
    projectId: number,
    page?: number,
    weak?: boolean,
    status?: LaborerHistoryStatus,
    search?: string,
    startAt?: string,
    endAt?: string,
  ) => ['laborer', 'list', 'history', projectId, { page, weak, status, search, startAt, endAt }],
  historyExistDate: (projectId: number, startAt: string, endAt: string) => [
    'laborer',
    'history',
    'exist-date',
    projectId,
    { startAt, endAt },
  ],
  locationExistDate: (projectId: number, date: string) => [
    'laborer',
    'location',
    'exist-date',
    projectId,
    date,
  ],
  listHistoryByBaseDate: (projectId: number, baseDate: string) => [
    'laborer',
    'list',
    'history',
    projectId,
    baseDate,
  ],
  listHistoryByBaseDateAndTime: (projectId: number, baseDate: string, baseTime: string) => [
    'laborer',
    'list',
    'history',
    projectId,
    baseDate,
    { baseTime },
  ],
  tab: (projectId: number) => ['laborer', 'tab', projectId],
  viewHazardArea: (projectId: number) => ['laborer', 'viewHazardArea', projectId],
};
