import { Atom, PrimitiveAtom, atom } from 'jotai';

/** @deprecated */
export type ChainAtom = {
  expanded: {
    root: PrimitiveAtom<boolean>;
  };
  checked: {
    items: PrimitiveAtom<{ [x: number]: boolean }>;
  };
  visible: { map: Atom<boolean>; menu: Atom<boolean> };
};

/** @deprecated */
export const chain: ChainAtom = {
  expanded: {
    root: atom(false),
  },
  checked: {
    items: atom<{ [x: number]: boolean }>({}),
  },
  visible: {
    map: atom((get) => {
      const items = get(chain.checked.items);
      const values = Object.values(items);
      return values?.some((v) => v);
    }),
    menu: atom((get) => {
      const mapVisible = get(chain.visible.map);
      const expanded = get(chain.expanded.root);
      return mapVisible || expanded;
    }),
  },
};

type ChainState = {
  id: number;
  checked?: boolean;
  /** checked가 true인 경우 하위 선분들은 default on
   *  개별로 hidden 처리하고 싶은 경우 hiddenItems 사용 */
  hiddenItems?: number[];
};

const chainEnabledAtom = atom<boolean>(false);
const chainExpandedAtom = atom<boolean>(false);
const chainStatesAtom = atom<ChainState[]>([]);

export const chainAtom = {
  enabled: chainEnabledAtom,
  expanded: chainExpandedAtom,
  states: chainStatesAtom,
};
