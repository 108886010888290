import { text } from './text';
import { hexToRgba } from './utils';

/** rgba(29, 33, 37, 0.05) */
const HOVER = hexToRgba(text.PRIMARY, 0.05);
/** rgba(29, 33, 37, 0.1) */
const SELECTED = hexToRgba(text.PRIMARY, 0.1);
/** rgba(29, 33, 37, 0.14) */
const DISABLED_BACKGROUND = hexToRgba(text.PRIMARY, 0.14);

export const action = {
  HOVER,
  SELECTED,
  DISABLED_BACKGROUND,
};
