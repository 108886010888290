import Typography from '@material-ui/core/Typography';
import { DEFAULT_TITLE } from 'common/policies/platform';
import { MOBILE_MAX_WIDTH } from 'common/policies/screen';
import { navigationBarWidth, topBarHeight } from 'common/policies/sizes';
import BackButton from 'components/common/button/BackButton';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';

interface Props {
  title: ReactNode;
  backButtonText?: string;
  onBackButtonClick?: () => void;
}
export default function TopBar({ title, backButtonText, onBackButtonClick }: Props) {
  return (
    <HeaderArea>
      <ButtonWrapper>
        <BackButton onClick={onBackButtonClick}>{backButtonText}</BackButton>
      </ButtonWrapper>
      <ProjectTitle>{title ?? DEFAULT_TITLE}</ProjectTitle>
    </HeaderArea>
  );
}

const HeaderArea = styled.div`
  height: ${topBarHeight};
  display: flex;
  align-items: center;
  justify-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: ${navigationBarWidth};
  bottom: ${topBarHeight};
  z-index: ${theme.zIndex.appBar};
  background-color: ${theme.palette.background.paper};
  border-bottom: 1px solid ${theme.palette.divider};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
  align-items: center;
`;

const ProjectTitle = styled(Typography).attrs({ variant: 'h5' })`
  margin: auto;
  display: flex;
  align-items: center;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 1rem;
  }
`;
