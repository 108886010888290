import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import api from './api';

export function useVworldMapKeyQuery(
  token?: string,
  options?: UseQueryOptions<string, AxiosError<Error>>,
) {
  return useQuery(['vworldkey'], () => api.readVworldKey({ token }), options);
}
