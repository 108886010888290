import * as category from '../category';

export const DOWNLOAD_DSM_DOWN_BTN = `${category.DOWNLOAD}DSMDownBtn`;
export const DOWNLOAD_DSM_HIGH_RES_DOWN_BTN = `${category.DOWNLOAD}DSMHighResDownBtn`;
export const DOWNLOAD_GCP_DOWN_BTN = `${category.DOWNLOAD}GCPDownBtn`;
export const DOWNLOAD_MESH_DOWN_BTN = `${category.DOWNLOAD}MeshDownBtn`;
export const DOWNLOAD_MESH_OBJ_DOWN_BTN = `${category.DOWNLOAD}MeshObjDownBtn`;
export const DOWNLOAD_ORIG_IMG_DOWN_BTN = `${category.DOWNLOAD}OrigImgDownBtn`;
export const DOWNLOAD_ORIG_IMG_DOWN_BTN_IN_VIEWER = `${category.DOWNLOAD}OrigImgDownBtnInViewer`;
export const DOWNLOAD_ORIG_IMG_THUMB = `${category.DOWNLOAD}OrigImgThumb`;
export const DOWNLOAD_ORTHOPHOTO_DOWN_BTN = `${category.DOWNLOAD}OrthophotoDownBtn`;
export const DOWNLOAD_ORTHOPHOTO_HIGH_RES_DOWN_BTN = `${category.DOWNLOAD}OrthophotoHighResDownBtn`;
export const DOWNLOAD_ORTHOPHOTO_LOW_RES_DOWN_BTN = `${category.DOWNLOAD}OrthophotoLowResDownBtn`;
export const DOWNLOAD_POINT_CLOUD_DOWN_BTN = `${category.DOWNLOAD}PointCloudDownBtn`;
export const DOWNLOAD_REPORT_CREATE_BTN = `${category.DOWNLOAD}ReportCreateBtn`;
export const DOWNLOAD_REPORT_DOWN_BTN = `${category.DOWNLOAD}ReportDownBtn`;
