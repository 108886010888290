import GA from '../index';
import { TWO_D } from '../category';

export const TWO_D_ORTHOPHOTO_VIEW_CBOX = `${TWO_D}OrthophotoViewCbox`;
export const TWO_D_DSM_ELEVATION_SLIDER = `${TWO_D}DSMElevationSlider`;
export const TWO_D_DSM_ELEVATION_Input = `${TWO_D}DSMElevationInput`;
export const TWO_D_DSM_OPACITY_SLIDER = `${TWO_D}DSMOpacitySlider`;
export const TWO_D_DSM_OPACITY_INPUT = `${TWO_D}DSMOpacityInput`;
export const TWO_D_DSM_VIEW_CBOX = `${TWO_D}DSMViewCbox`;
export const TWO_D_MAP_ROTATION_BTN = `${TWO_D}MapRotationBtn`;

export const sendEvent2D = (action: string, label?: string) => {
  GA.event(TWO_D, action, label);
};

export const sendTwodDsmOpacitySlider = () => {
  sendEvent2D(TWO_D_DSM_OPACITY_SLIDER);
};
export const sendTwodDsmElevationSlider = (range: [number, number]) => {
  sendEvent2D(TWO_D_DSM_ELEVATION_SLIDER, `${range[0]} - ${range[1]}`);
};
export const sendTwodDsmElevationInput = () => {
  sendEvent2D(TWO_D_DSM_ELEVATION_Input);
};
export const sendTwodDsmOpacityInput = () => {
  sendEvent2D(TWO_D_DSM_OPACITY_INPUT);
};

export const sendTwodMapRotationBtn = () => {
  sendEvent2D(TWO_D_MAP_ROTATION_BTN);
};
