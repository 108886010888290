import { AxiosError } from 'axios';
import { DEFAULT_POLLING_INTERVAL } from 'common/policies/request';
import { Error } from 'common/types';
import { getPositionFromExif } from 'common/utils';
import { AerialImage } from 'infrastructure/query/aerialImage/types';
import { ImageArchive } from 'infrastructure/query/resource/types';
import { UseQueryOptions, useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

interface Props {
  snapshotId: number;
  enabled?: boolean;
}
export function useAerialImageListQuery(
  snapshotId: number,
  options?: UseQueryOptions<AerialImage[]>,
) {
  const { enabled = true, ...restOptions } = options ?? {};
  return useQuery(keys.list(snapshotId), () => api.list(snapshotId), {
    enabled: enabled && Boolean(snapshotId),
    select: (data) => {
      /** AerialImage 데이터에 포함된 GPS 정보를 지도에 그릴 수 있는 Position 정보로 변경 필요 */
      return data?.map((x) => {
        const position = !x?.position && x?.gpsInfo && getPositionFromExif(x.gpsInfo);
        return {
          ...(position ? { ...x, position: getPositionFromExif(x.gpsInfo) } : x),
          status: 'SUCCESS' as const,
        } as AerialImage;
      });
    },
    ...restOptions,
  });
}

export function useImageArchiveQuery({ snapshotId, enabled }: Props) {
  return useQuery<ImageArchive, AxiosError<Error>, ImageArchive>(
    keys.archive(snapshotId),
    () => api.archive(snapshotId),
    {
      enabled: Boolean(snapshotId && enabled),
      refetchInterval: DEFAULT_POLLING_INTERVAL,
      retry: false,
      onError: (err) => {}, // useApiErrorHandler가 호출되지 않도록 하기 위함
    },
  );
}
