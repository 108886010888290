import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { nls } from 'infrastructure/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';

interface Props {
  open: boolean;
  onClose: (e: any, reason: any) => void;
}

export default function AlertSnackbar({ open, onClose }: Props) {
  return (
    <StyledSnackbar
      autoHideDuration={3000}
      open={open}
      onClose={onClose}
      message={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <StyledSnackbarMessage>
          <StyledSnackbarIcon />
          <StyledSnackbarText>{nls.noticeDontShowAgainMessage()}</StyledSnackbarText>
        </StyledSnackbarMessage>
      }
    />
  );
}

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    padding: 0;
    background-color: ${theme.palette.grey[800]};
    box-shadow: ${theme.shadows[6]};
  }
  .MuiSnackbarContent-message {
    padding: 0.375rem 1rem;
    width: 100%;
  }
`;

const StyledSnackbarMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.375rem;
`;

const StyledSnackbarIcon = styled(NotificationsIcon)`
  font-size: 1.125rem;
`;

const StyledSnackbarText = styled(Typography).attrs({ variant: 'body2' })`
  padding: 0.5rem 0;
  color: ${theme.palette.primary.contrastText};
`;
