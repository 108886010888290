import IssuePolygon from 'components/common/2D/layers/issue/IssuePolygon';
import { useMatchParams } from 'components/common/hooks';
import { useIssueQuery, usePermissionQuery, useSnapshotListQuery } from 'infrastructure/query';
import { Issue } from 'infrastructure/query/issue/types';
import React from 'react';
import { isDone } from 'stores/data/types';
import styled from 'styled-components';
import Header from './Header';
import MapItem from './MapItem';

export default function IssuePolygonComparedPage() {
  const { projectId, zoneId, issueId } = useMatchParams();
  const issue = useIssueQuery(zoneId, issueId)?.data;
  const snapshotList = useSnapshotListQuery({ zoneId })?.data?.filter(isDone);
  const permission = usePermissionQuery(projectId)?.data;

  return (
    <Page>
      <Header backButtonLabel={issue?.name} />
      <Content>
        {Array(9)
          .fill('')
          .map((_, index) => (
            <MapItem
              key={index}
              permission={permission}
              snapshotId={snapshotList?.[index]?.id}
              snapshotList={snapshotList}
            >
              {issue && <IssuePolygon issue={issue as Issue} />}
            </MapItem>
          ))}
      </Content>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Content = styled.div`
  margin: -0.125rem;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4rem;
`;
