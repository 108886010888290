import { CancelToken } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { requestUploadFile, s3DirectFileUpload } from 'infrastructure/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'infrastructure/query/fileUpload/types';
import { FileUploadMutationOptions, TempFile } from 'infrastructure/query/resource/types';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { CreateGcpResourceType, Gcp } from './types';

export function useGcpListQuery(snapshotId: number, options?: UseQueryOptions<Gcp[]>) {
  const { enabled = true, ...restOptions } = options ?? {};
  const { data, ...rest } = useQuery(keys.list(snapshotId), () => api.list({ snapshotId }), {
    enabled: Boolean(snapshotId && enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    refetchOnWindowFocus: false,
    ...restOptions,
  });
  return { gcpList: data, ...rest };
}

export function useGcpResourcesMutation() {
  const queryClient = useQueryClient();

  const createGcpResources = useMutation(
    async ({
      snapshotId,
      gcpId,
      type,
      tempFile,
      cancelToken,
    }: {
      snapshotId: number;
      gcpId: number;
      type: 'IMAGE';
      tempFile: TempFile;
      cancelToken: CancelToken;
    } & FileUploadMutationOptions) => {
      const { data: dataForUploads } = await requestUploadFile({
        name: tempFile.name,
        size: tempFile.size,
      });
      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, tempFile.file, cancelToken);
      const data: CreateGcpResourceType = {
        file: fields.key,
        fileName: tempFile.name,
        fileType: type,
      };
      return api.createGcpResource({ snapshotId, gcpId, data, cancelToken });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(keys.list(variables.snapshotId));
        variables?.onSuccess?.(variables.tempFile);
      },
      onError: (_, variables) => {
        variables?.onError?.(variables.tempFile);
      },
    },
  );
  const deleteGcpResources = useMutation(
    ({
      snapshotId,
      gcpId,
      resourceId,
    }: {
      snapshotId: number;
      gcpId: number;
      resourceId: number;
    }) => api.deleteGcpResource({ snapshotId, gcpId, resourceId }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(keys.list(variables.snapshotId));
      },
    },
  );
  return { createGcpResources, deleteGcpResources };
}
