import { IMAGE_FILE_EXTENSIONS, VIDEO_FILE_EXTENSIONS } from 'common/types';
import { PolygonAnnotation, Position } from 'infrastructure/query/annotation/types';
import { TempFile } from 'infrastructure/query/resource/types';

export type IssueType = Issue['type'];
type IssueUrlGenericType<T extends string> = T extends `ISSUE_${infer U}` ? Lowercase<U> : never;
export type IssueUrlType = IssueUrlGenericType<Issue['type']>;

export type Issue = PointIssue | PolygonIssue | QuickVoicePointIssue | QuickImagePointIssue;

export interface BaseIssue {
  id: number;
  name: string;
  description: string;
  status: 'RUNNING' | 'DONE';
  color?: string;
  resources?: IssueResource[];
  positions: Position[];
  created?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
  modified?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
  modifiedByName?: string;
}

export type PointIssue = BaseIssue & { type: 'ISSUE_POINT' };
export type QuickVoicePointIssue = BaseIssue & { type: 'QUICK_VOICE_ISSUE_POINT' };
export type QuickImagePointIssue = BaseIssue & { type: 'QUICK_IMAGE_ISSUE_POINT' };
export type PolygonIssue = BaseIssue & {
  type: 'ISSUE_POLYGON';
  calculateData: PolygonAnnotation['calculateData'];
};

export type EditingIssueType = Partial<Omit<Issue, 'id'>> & {
  tempFile?: TempFile;
  dirty?: boolean;
  positionsDirty?: boolean;
  id: number | 'new';
};

export type IssueStatus = Issue['status'];

export interface IssueValidation {
  name: boolean;
  description: boolean;
}

export interface Comment {
  id: number;
  issueId: number;
  type: 'CREATED' | 'NORMAL' | 'COMPLETED' | 'REACTIVATED';
  description?: string;
  createdBy?: number; // creator id
  modifiedBy?: number; // modifier id
  createdByName?: string; // creator name
  modifiedByName?: string; // modifier name
  resources?: IssueResource[];
  jobTitle?: string;
  companyName?: string;
  created?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
  deleted?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
  modified?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
  isModified?: boolean;
  isAuthor?: boolean;
}

export type IssueResource = {
  resource: {
    file: string;
    fileName: string;
    fileType: string;
    size?: number;
  };
  resourceThumbnail?: {
    file: string;
    datetimeOriginal: string;
  };
};

export type Vi = {
  id: number;
  calculationStatus: 'REQUIRED' | 'IN_PROGRESS' | 'DONE' | 'ERROR';
  ndviSummary: {
    healthy: number;
    stressed: number;
    critical: number;
  };
  resultCode: 'warning' | 'empty' | 'error' | 'normal' | null;
  errorCode?: string;
};

export type CreateCommentType = Comment & { tempFiles?: TempFile[] };

export function getIssueFileType(x: string) {
  if (IMAGE_FILE_EXTENSIONS.includes(x.toLowerCase())) {
    return 'IMAGE';
  }
  if (VIDEO_FILE_EXTENSIONS.includes(x.toLowerCase())) {
    return 'VIDEO';
  }
  return 'ETC';
}

/** TODO : 이슈 타입별 처리를 임시로 해둠. 리팩토링 필요 2023.11.24 */
export function getIssueUrlType(type: Issue['type']) {
  const issueType = type === 'ISSUE_POLYGON' ? 'ISSUE_POLYGON' : 'ISSUE_POINT';
  return issueType?.replace('ISSUE_', '').toLowerCase() as IssueUrlType;
}
