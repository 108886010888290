const host = process.env.API_HOST;
const port = process.env.API_PORT;
const apiPath = process.env.API_PATH;
const authPath = process.env.AUTH_API_PATH;
const staticPath = process.env.STATIC_API_PATH;

export const baseUrl = port ? `${host}:${port}` : host;
/** @deprecated */
export const apiUrl = baseUrl + (apiPath || '');
/** @deprecated */
export const authUrl = baseUrl + (authPath || '');
/** @deprecated */
export const staticUrl = baseUrl + (staticPath || '');

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const PROJECTS = '/projects';
/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const PROJECT = (projectId) => {
  return `${PROJECTS}/${projectId}`;
};

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const PROJECTS_V2 = `/v2${PROJECTS}`;
/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const PROJECT_V2 = (projectId) => `${PROJECTS_V2}/${projectId}`;

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const ZONES = (projectId) => `${PROJECT_V2(projectId)}/zones`;
/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const ZONE = (projectId, zoneId) => `${ZONES(projectId)}/${zoneId}`;

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const SNAPSHOTS = (projectId) => {
  return `${PROJECT(projectId)}/snapshots`;
};

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const SNAPSHOT = (projectId, snapshotId) => {
  return `${SNAPSHOTS(projectId)}/${snapshotId}`;
};

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const REPORT_SNAPSHOT = (projectId, snapshotId) => {
  return `${SNAPSHOT(projectId, snapshotId)}/report`;
};

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const USER_LOGIN = '/login';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const USER_LOGOUT = '/expiry';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const JWT_REFRESH = '/refresh';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const USER_SIGNUP = '/signup';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const NEW_PASSWORD = '/password/new';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const USER_INFO = '/info';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const COMPANY = '/company';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const GCP_TEMPLATE = '/gcp_template';

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const COORDINATES = (search = '', limit = '') =>
  `/coordinates?search=${search}&limit=${limit}`;

/**
 * @deprecated 스트링 리터럴을 대신 사용
 */
export const USED_COORDINATES = (search = '') => `/coordinates/used?search=${search}`;
