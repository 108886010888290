import React from 'react';
import styled from 'styled-components';

export default function MapArea({ children }: { children: React.ReactNode }) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;
