export const keys = {
  list: (projectId: number) => ['vehicle', 'list', projectId],
  listHistory: () => ['vehicle', 'list', 'history'],
  listHistoryV2: (
    projectId: number,
    page?: number,
    search?: string,
    startAt?: string,
    endAt?: string,
    vehicleType?: string,
  ) => ['vehicle', 'list', 'history', projectId, { page, search, startAt, endAt, vehicleType }],
  historyExistDate: (projectId: number, startAt: string, endAt: string) => [
    'vehicle',
    'history',
    'exist-date',
    projectId,
    { startAt, endAt },
  ],
  locationExistDate: (projectId: number, date: string) => [
    'vehicle',
    'location',
    'exist-date',
    projectId,
    date,
  ],
  listHistoryByBaseDate: (projectId: number, baseDate: string) => [
    'vehicle',
    'list',
    'history',
    projectId,
    baseDate,
  ],
  listHistoryByBaseDateAndTime: (projectId: number, baseDate: string, baseTime: string) => [
    'vehicle',
    'list',
    'history',
    projectId,
    baseDate,
    { baseTime },
  ],
  tab: (projectId: number) => ['vehicle', 'tab', projectId],
  historyDrivingRouteData: (projectId: number, historyId: string) => [
    'vehicle',
    'history',
    'route',
    projectId,
    historyId,
  ],
};
