import axios, { AxiosResponse, CancelToken } from 'axios';
import { TempFile } from 'infrastructure/query/resource/types';
import api from './api';
import { isLargeFile, isNormalFile, LargeFileType, S3FileUploadType } from './types';

export async function requestUploadFile(
  tempFile: Pick<TempFile, 'name' | 'size'>,
): Promise<{ data: S3FileUploadType }> {
  const response = await api.uploadFiles({
    query: '',
    filename: tempFile?.name,
    size: tempFile?.size,
  });
  return response;
}

export async function s3DirectFileUpload(
  resData: S3FileUploadType,
  file: File,
  cancelToken?: CancelToken,
) {
  if (isNormalFile(resData)) {
    const { url, fields } = resData;
    return api.directUploadToS3({ url, fields, originFile: file, cancelToken });
  }
  if (isLargeFile(resData)) {
    return uploadLargeFile(resData, file, cancelToken);
  }
  return Promise.reject(new Error('Invalid resData'));
}

const DEFAULT_CHUNK_SIZE = 4 * 1024 * 1024 * 1024; // 4GB

async function uploadLargeFile(resData: LargeFileType, file: File, cancelToken: CancelToken) {
  const responses = await Promise.all(uploadParts(resData, file, cancelToken));
  await api.completeMultipartUpload({
    url: resData?.complete_multipart_upload_url,
    data: getPartsList(responses),
  });
}

function getPartsList(responses: AxiosResponse<any, any>[]) {
  const eTags = responses.map(
    ({ headers }, idx) =>
      `<Part><PartNumber>${idx + 1}</PartNumber><ETag>${headers?.etag}</ETag></Part>`,
  );
  const results = `<CompleteMultipartUpload>${eTags.join('')}</CompleteMultipartUpload>`;
  return results;
}

function uploadParts(resData: LargeFileType, file: File, cancelToken: CancelToken) {
  const urlCount = resData?.urls?.length;
  const fileChunkSize = Math.ceil(file.size / urlCount) || DEFAULT_CHUNK_SIZE;
  const requests = resData?.urls?.map((url, i) => {
    const start = i * fileChunkSize;
    const end = (i + 1) * fileChunkSize;
    const blob = i < urlCount ? file.slice(start, end) : file.slice(start);
    return axios.put(url, blob, { cancelToken });
  });
  return requests;
}
