import GA from 'infrastructure/ga';
import { TWO_D_CURRENT_CCTV_FULL_SCREEN_BTN } from 'infrastructure/ga/actions';
import { TWO_D_CURRENT } from 'infrastructure/ga/category';
import { useAtom } from 'jotai';
import { LatLngLiteral } from 'leaflet';
import {
  useCctvListQuery,
  useCctvMutation,
  useFocusedCctvQuery,
  usePermissionQuery,
} from 'infrastructure/query';
import { Cctv } from 'infrastructure/query/cctv/types';
import { cctvKeys } from 'infrastructure/query/queryKeys';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import routes from 'routes';
import { cctvPositioningModeAtom } from 'stores/v3/snapshotDetail/twoD';
import { useMatchParams } from '../hooks';

export function useCctvHooks(enabled: boolean) {
  const { projectId, zoneId, snapshotId } = useMatchParams();
  const { data: permissions } = usePermissionQuery(projectId);
  const canEdit = permissions?.cctvPut;
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: cctvs, refetch: refetchCctv } = useCctvListQuery(zoneId, {
    enabled: Boolean(enabled),
  });
  const [cctvPositioningMode, setCctvPositioningMode] = useAtom(cctvPositioningModeAtom);

  const { updateCctv } = useCctvMutation();
  const { data: focusedCctv } = useFocusedCctvQuery(zoneId);

  const setCctvPosition = (position: LatLngLiteral) => {
    setCctvPositioningMode(false);

    updateCctv.mutate({
      zoneId,
      cctvId: focusedCctv?.id,
      data: { latitude: position.lat, longitude: position.lng },
    });
  };
  const updateCctvData = (cctv: Partial<Cctv>) => {
    updateCctv.mutate({ zoneId, cctvId: cctv.id, data: { ...cctv } });
  };

  const setCctvFocused = (selected: Cctv | false) => {
    if (!selected) {
      queryClient.setQueryData<Cctv>(cctvKeys.focused(zoneId), null);
      return;
    }
    queryClient.setQueryData<Cctv>(cctvKeys.focused(zoneId), selected);
  };

  const goCctvPlayer = (cctvId?: number) => {
    if (cctvId) {
      setCctvFocused(cctvs?.find((x) => x.id === cctvId));
    }
    history.push(routes.snapshot.cctv.of({ projectId, zoneId, snapshotId }));
    GA.event(TWO_D_CURRENT, TWO_D_CURRENT_CCTV_FULL_SCREEN_BTN);
  };

  return {
    cctvs,
    cctvAddMode: cctvPositioningMode,
    cancelCctvAddMode: () => setCctvPositioningMode(false),
    setCctvPosition,
    setCctvFocused,
    goCctvPlayer,
    focusedCctv,
    refetchCctv,
    updateCctvData,
    canEdit,
  };
}
