import { apiAxios } from 'common/api/common';
import { GcpLocation, GcpRecommendation } from './types';

interface Props {
  snapshotId: number;
  gcpId: number;
  locationId: number;
}
export default {
  async list({ snapshotId, gcpId }: Pick<Props, 'snapshotId' | 'gcpId'>) {
    const response = await apiAxios.get(`/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/locations`);
    return response?.data?.results;
  },
  async read({
    snapshotId,
    gcpId,
    locationId,
  }: Pick<Props, 'snapshotId' | 'gcpId' | 'locationId'>) {
    const response = await apiAxios.get<GcpLocation>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/locations/${locationId}`,
    );
    return response?.data;
  },
  async create({
    snapshotId,
    gcpId,
    data,
  }: Pick<Props, 'snapshotId' | 'gcpId'> & { data: Omit<GcpLocation, 'id' | 'gcpId'> }) {
    const response = await apiAxios.post<GcpLocation>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/locations`,
      data,
    );
    return response?.data;
  },
  async update({
    snapshotId,
    gcpId,
    locationId,
    data,
  }: Omit<Props, 'data'> & { data: Partial<GcpLocation> }) {
    const response = await apiAxios.patch<GcpLocation>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/locations/${locationId}`,
      data,
    );
    return response?.data;
  },
  async delete({
    snapshotId,
    gcpId,
    locationId,
  }: Pick<Props, 'snapshotId' | 'gcpId' | 'locationId'>) {
    const response = await apiAxios.delete<{ id: number }>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/locations/${locationId}`,
    );
    return response?.data;
  },
  async deleteList({ snapshotId, gcpId }: Pick<Props, 'snapshotId' | 'gcpId'>) {
    const response = await apiAxios.post<null>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/reset-locations`,
    );
    return response?.data;
  },
  async createGcpRecommendation({ snapshotId }: Pick<Props, 'snapshotId'>) {
    const response = await apiAxios.post(`/api/v3/snapshots/${snapshotId}/gcps/recommend`);
    return response?.data;
  },

  async getGcpRecommendation({ snapshotId }: Pick<Props, 'snapshotId'>) {
    const response = await apiAxios.get<GcpRecommendation>(
      `/api/v3/snapshots/${snapshotId}/gcps/recommend`,
    );
    return response?.data;
  },
  async abortGcpRecommendation({ snapshotId }: Pick<Props, 'snapshotId'>) {
    const response = await apiAxios.post(`/api/v3/snapshots/${snapshotId}/gcps/recommend/abort`);
    return response?.data;
  },
};
