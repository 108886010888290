import { AxiosError, AxiosResponse } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { useMutation, useQuery } from 'react-query';
import api from './api';

export function useWatchTokenMutation() {
  const createWatchToken = useMutation((snapshotId: number) => api.create(snapshotId));
  const deleteWatchToken = useMutation((token: string) => api.delete(token));
  return { createWatchToken, deleteWatchToken };
}

export function useWatchTokenQuery(token: string) {
  const { data, status, error } = useQuery<
    AxiosResponse<WatchTokenResponse>,
    AxiosError<Error>,
    WatchTokenResponse
  >(['watchToken', token], () => api.read(token), {
    select: (res) => res?.data,
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(token),
  });
  return { data: (data ?? {}) as WatchTokenResponse, status, error };
}

type WatchTokenResponse = {
  snapshotId: number;
  zoneId: number;
  projectId: number;
};
