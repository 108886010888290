import { AxiosError } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Video } from 'infrastructure/query/video/types';
import api from './api';
import { keys } from './queryKeys';

export function useVideoQuery(
  { zoneId, videoId }: Parameters<typeof api.read>[0],
  options?: UseQueryOptions<Video, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.detail(zoneId, videoId), () => api.read({ zoneId, videoId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && videoId && enabled),
    ...rest,
  });
}

export function useVideoListQuery(
  { zoneId }: Parameters<typeof api.list>[0],
  options?: UseQueryOptions<Video[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.list(zoneId), () => api.list({ zoneId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && enabled),
    ...rest,
  });
}

export function useVideoMutation({ zoneId }: { zoneId: number }) {
  const queryClient = useQueryClient();

  const createVideo = useMutation(
    ({ data }: { data: Video }) => {
      const formData = new FormData();
      formData.append('file', data?.file);
      formData.append('take_date', data?.takeDate);
      data?.description && formData.append('description', data?.description);
      return api.create({ zoneId, formData });
    },
    { onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)) },
  );

  const updateVideo = useMutation(
    ({ videoId, data }: Omit<Parameters<typeof api.update>[0], 'zoneId'>) =>
      api.update({ zoneId, videoId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.list(zoneId));
      },
    },
  );

  const deleteVideo = useMutation(
    ({ videoId }: Omit<Parameters<typeof api.delete>[0], 'zoneId'>) =>
      api.delete({ zoneId, videoId }),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)),
    },
  );

  return { createVideo, updateVideo, deleteVideo };
}
