import { nls } from 'infrastructure/locale/language';

export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png'];
export const VIDEO_FILE_EXTENSIONS = ['mp4', 'mov'];

export const enum UploadStatus {
  FAILURE = 0,
  READY = 1,
  SUCCESS = 2,
}

export const enum Extension {
  ORIGINAL_IMAGE = '.(jpg|jpeg)$',
  ORIGINAL_IMAGE_FOR_GOLF = '.(jpg|jpeg|tif|tiff)$',
  PANORAMA = '.(jpg|jpeg|png)$',
  BIRDS_EYE_VIEW = '.(jpg|jpeg|png)$',
  LAS = '.las$',
  TIF = '.tif$',
  GCP = '.(csv|txt)$',
  FBX = '.fbx$',
  ETC = '',
  VIDEO = '.(mp4|mov)$',
  BIM = '.(rvt|ifc)$',
  AUTO_DRAWING = '.dxf$',
  MANUAL_DRAWING = '.(png|jpg|pdf|dxf|jpeg)$',
}

export type DataThreeD = {
  pointSize?: number;
};

export type CoreEngine = 'MEISSA' | 'PIX4D' | 'METASHAPE' | 'MEISSA_V2';

export enum CoordinateSystem {
  /**위경도 */
  latlng = '4326',
  /** 동부원점 */
  eastDatum = '5187',
  /** 중부원점 */
  centralDatum = '5186',
  /** 동해원점 */
  eastSeaDatum = '5188',
  /** 서부원점 */
  westDatum = '5185',
  /** 동부원점 Bessel */
  eastDatumBessel = '5176',
  /** 중부원점 Bessel */
  centralDatumBessel = '5174',
  /** 동해원점 Bessel */
  eastSeaDatumBessel = '5177',
  /** 서부원점 Bessel */
  westDatumBessel = '5173',

  /* 1계 EPSG:6669(JGD2011) */
  epsg6669 = '6669',
  /* 2계 EPSG:6670(JGD2011) */
  epsg6670 = '6670',
  /* 3계 EPSG:6671(JGD2011) */
  epsg6671 = '6671',
  /* 4계 EPSG:6672(JGD2011) */
  epsg6672 = '6672',
  /* 5계 EPSG:6673(JGD2011) */
  epsg6673 = '6673',
  /* 6계 EPSG:6674(JGD2011) */
  epsg6674 = '6674',
  /* 7계 EPSG:6675(JGD2011) */
  epsg6675 = '6675',
  /* 8계 EPSG:6676(JGD2011) */
  epsg6676 = '6676',
  /* 9계 EPSG:6677(JGD2011) */
  epsg6677 = '6677',
  /* 10계 EPSG:6678(JGD2011) */
  epsg6678 = '6678',
  /* 11계 EPSG:6679(JGD2011) */
  epsg6679 = '6679',
  /* 12계 EPSG:6680(JGD2011) */
  epsg6680 = '6680',
  /* 13계 EPSG:6681(JGD2011) */
  epsg6681 = '6681',
  /* 14계 EPSG:6682(JGD2011) */
  epsg6682 = '6682',
  /* 15계 EPSG:6683(JGD2011) */
  epsg6683 = '6683',
  /* 16계 EPSG:6684(JGD2011) */
  epsg6684 = '6684',
  /* 17계 EPSG:6685(JGD2011) */
  epsg6685 = '6685',
  /* 18계 EPSG:6686(JGD2011) */
  epsg6686 = '6686',
  /* 19계 EPSG:6687(JGD2011) */
  epsg6687 = '6687',

  /** 기타 선택 */
  etc = 'etc',
}

/** 좌표계 */
export const datumList = [
  { desc: nls.latlng(), value: CoordinateSystem.latlng },
  { desc: nls.westDatum(), value: CoordinateSystem.westDatum },
  { desc: nls.centralDatum(), value: CoordinateSystem.centralDatum },
  { desc: nls.eastDatum(), value: CoordinateSystem.eastDatum },
  { desc: nls.eastSeaDatum(), value: CoordinateSystem.eastSeaDatum },
  { desc: nls.westDatumBessel(), value: CoordinateSystem.westDatumBessel },
  { desc: nls.centralDatumBessel(), value: CoordinateSystem.centralDatumBessel },
  { desc: nls.eastDatumBessel(), value: CoordinateSystem.eastDatumBessel },
  { desc: nls.eastSeaDatumBessel(), value: CoordinateSystem.eastSeaDatumBessel },
  { desc: nls.selectEtc(), value: CoordinateSystem.etc },
];

export const datumListJp = [
  { desc: nls.epsg6669(), value: CoordinateSystem.epsg6669 },
  { desc: nls.epsg6670(), value: CoordinateSystem.epsg6670 },
  { desc: nls.epsg6671(), value: CoordinateSystem.epsg6671 },
  { desc: nls.epsg6672(), value: CoordinateSystem.epsg6672 },
  { desc: nls.epsg6673(), value: CoordinateSystem.epsg6673 },
  { desc: nls.epsg6674(), value: CoordinateSystem.epsg6674 },
  { desc: nls.epsg6675(), value: CoordinateSystem.epsg6675 },
  { desc: nls.epsg6676(), value: CoordinateSystem.epsg6676 },
  { desc: nls.epsg6677(), value: CoordinateSystem.epsg6677 },
  { desc: nls.epsg6678(), value: CoordinateSystem.epsg6678 },
  { desc: nls.epsg6679(), value: CoordinateSystem.epsg6679 },
  { desc: nls.epsg6680(), value: CoordinateSystem.epsg6680 },
  { desc: nls.epsg6681(), value: CoordinateSystem.epsg6681 },
  { desc: nls.epsg6682(), value: CoordinateSystem.epsg6682 },
  { desc: nls.epsg6683(), value: CoordinateSystem.epsg6683 },
  { desc: nls.epsg6684(), value: CoordinateSystem.epsg6684 },
  { desc: nls.epsg6685(), value: CoordinateSystem.epsg6685 },
  { desc: nls.epsg6686(), value: CoordinateSystem.epsg6686 },
  { desc: nls.epsg6687(), value: CoordinateSystem.epsg6687 },
  { desc: nls.selectEtc(), value: CoordinateSystem.etc },
];

export enum PermissionType {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  USER = 'USER',
  GUEST = 'GUEST',
  PAUSE = 'PAUSE',
  DEACTIVATE = 'DEACTIVATE',
}

// TODO 이름, 설명 값 변경, '삭제' 제외 및 만료 상태 추가
export const memberPermission = {
  [PermissionType.OWNER]: {
    title: nls.ownerTitle(),
    desc: nls.ownerDesc(),
    value: PermissionType.OWNER,
  },
  [PermissionType.MANAGER]: {
    title: nls.managerTitle(),
    desc: nls.managerDesc(),
    value: PermissionType.MANAGER,
  },
  [PermissionType.USER]: {
    title: nls.userTitle(),
    desc: nls.userDesc(),
    value: PermissionType.USER,
  },
  [PermissionType.GUEST]: {
    title: nls.guestTitle(),
    desc: nls.guestDesc(),
    value: PermissionType.GUEST,
  },
};

export enum MemberStatusType {
  JOIN = 'JOIN',
  INVITED = 'INVITED',
  PAUSE = 'PAUSE',
}

export const memberStatus = {
  [MemberStatusType.JOIN]: {
    title: nls.joinStatus(),
    value: MemberStatusType.JOIN,
  },
  [MemberStatusType.PAUSE]: {
    title: nls.pauseTitle(),
    value: MemberStatusType.PAUSE,
  },
  [MemberStatusType.INVITED]: {
    title: nls.invited(),
    value: MemberStatusType.INVITED,
  },
};

export const FAIL_VALUE = -9999;
export const INVALID_ADDR = 'about:blank';
export const CONVERT_FAIL = 'about:blank';

export const DefaultTifFileInfo = {
  width: 335,
  height: 347,
  targetWidth: 1000,
  targetHeight: 1035,
};

export const MetaInfo = {
  minZoom: 13,
  maxZoom: 23,
  maxNativeZoom: 18,
  photoOpacity: 1.0,
  suffix: '{z}/{x}/{y}.png',
  zoom: 18,
  zoomControl: false,
  center: null,
  doubleClickZoom: false,
  interactive: false,
  updateWhenIdle: true,
  updateWhenZooming: false,
};

export interface MarkerPosition {
  id?: number;
  name?: string;
  key?: number;
  latitude: number;
  longitude: number;
}

/**
 * @link https://www.notion.so/5bef89fe57a0432f966d8630c58b80bf?v=8749f21177a146cfb69ecb740bd4aadf
 */
export enum ErrorMessageCode {
  AUTH_PERMISSION_DENIED = 'ER1000',
  AUTH_JWT_TOKEN = 'ER1001',
  AUTH_INVALID_INVITE_CODE = 'ER1004',
  AUTH_INVALID_READONLY_CODE = 'ER1005',
  AUTH_REGISTERED_EMAIL = 'ER1006',
  AUTH_PASSWORD_FAIL = 'ER1008',
  SERVER_FAIL_CODE = 'ER2000',
  NOT_FOUND_CONTENT = 'ER3000',
  UNPROCESSABLE_INVALID_VALUE = 'ER3008',
  UNPROCESSABLE_FILE_EXTENSION_FAILURE = 'ER3013',
}

export enum Alert {
  warning,
  error,
}

export enum ImgArchiveStatus {
  Ready = 'ready',
  InProgress = 'in-progress',
  Done = 'done',
  Error = 'error',
}

export interface Error {
  errorCode: string;
  message: string;
}

/**
 * Common utility types for convenience
 */
export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;
