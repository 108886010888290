import * as category from '../category';

export const PANORAMA_ADD_BTN = `${category.PANORAMA}AddBtn`;
export const PANORAMA_CARD = `${category.PANORAMA}Card`;
export const PANORAMA_DEL_MENU = `${category.PANORAMA}DelMenu`;
export const PANORAMA_DOWN_BTN_IN_PLAYER = `${category.PANORAMA}DownBtnInPlayer`;
export const PANORAMA_DOWN_MENU = `${category.PANORAMA}DownMenu`;
export const PANORAMA_EDIT_MENU = `${category.PANORAMA}EditMenu`;
export const PANORAMA_MOVE_BTN_IN_PLAYER = `${category.PANORAMA}MoveBtnInPlayer`;
export const PANORAMA_POPUP_UPLOAD_SELECT_BTN = `${category.PANORAMA}PopupUploadSelectBtn`;
export const PANORAMA_POPUP_STITCH_SELECT_BTN = `${category.PANORAMA}PopupStitchSelectBtn`;
