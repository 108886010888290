export const keys = {
  planedVolumesAll: () => ['planedVolumes', 'read'],
  planLevelVolumesAll: () => ['planLevelVolumes', 'read'],
  planedVolumes: (zoneId: number, drawingId: number, planLevelId: number) => [
    'planedVolumes',
    'read',
    zoneId,
    drawingId,
    planLevelId,
  ],
  planLevelVolumes: (drawingId: number, planLevelId: number) => [
    'planLevelVolumes',
    'read',
    drawingId,
    planLevelId,
  ],
  export: (resourceId: number) => ['export-earthwork-table', resourceId],
};
