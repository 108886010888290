import { TWO_D_PANORAMA } from 'infrastructure/ga/category';
import GA from 'infrastructure/ga';

export const getAction = (name: string) => `${TWO_D_PANORAMA}${name}`;
export const sendTwoDPanoramaEvent = (name: string) => GA.event(TWO_D_PANORAMA, getAction(name));

const TWO_D_PANORAMA_PANORAMA_LIST_ITEM = 'PanoramaListItem';
const TWO_D_PANORAMA_PANORAMA_DOWN_MENU = 'PanoramaDownMenu';
const TWO_D_PANORAMA_PANORAMA_DEL_MENU = 'PanoramaDelMenu';
const TWO_D_PANORAMA_PANORAMA_EDIT_MENU = 'PanoramaEditMenu';
const TWO_D_PANORAMA_PANORAMA_ADD_BTN = 'PanoramaAddBtn';

export const sendTwoDPanoramaPanoramaListItem = () => {
  sendTwoDPanoramaEvent(TWO_D_PANORAMA_PANORAMA_LIST_ITEM);
};
export const sendTwoDPanoramaPanoramaDownMenu = () => {
  sendTwoDPanoramaEvent(TWO_D_PANORAMA_PANORAMA_DOWN_MENU);
};
export const sendTwoDPanoramaPanoramaDelMenu = () => {
  sendTwoDPanoramaEvent(TWO_D_PANORAMA_PANORAMA_DEL_MENU);
};
export const sendTwoDPanoramaPanoramaEditMenu = () => {
  sendTwoDPanoramaEvent(TWO_D_PANORAMA_PANORAMA_EDIT_MENU);
};
export const sendTwoDPanoramaPanoramaAddBtn = () => {
  sendTwoDPanoramaEvent(TWO_D_PANORAMA_PANORAMA_ADD_BTN);
};
