import { atom } from 'jotai';

type PlanLevelState = {
  id: number;
  visible?: boolean;
  opacity?: number;
  range?: [number, number];
  histogram?: { [index: number]: { count: number; color: [number, number, number] } };
};

export const planLevelAtom = {
  enabled: atom<boolean>(false),
  selectedIdForDetails: atom<number | false>(false),
  expanded: atom(false),
  states: atom<PlanLevelState[]>([]),
};
