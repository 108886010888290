import { AxiosError } from 'axios';
import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { Panorama } from 'infrastructure/query/panorama/types';
import { useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export function usePanoramaQuery(
  { zoneId, panoramaId }: Parameters<typeof api.read>[0],
  options?: UseQueryOptions<Panorama, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.detail(zoneId, panoramaId), () => api.read({ zoneId, panoramaId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && panoramaId && enabled),
    ...rest,
  });
}

export function usePanoramaListQuery(
  { zoneId }: Parameters<typeof api.list>[0],
  options?: UseQueryOptions<Panorama[], AxiosError<Error>>,
) {
  const queryClient = useQueryClient();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { enabled = true, onSuccess, ...rest } = options || {};
  return useQuery(keys.list(zoneId), () => api.list({ zoneId }), {
    refetchInterval,
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && enabled),
    onSuccess: (data) => {
      const needRefetch = data.find((x) => x.progressStatus === 'CONVERSION');
      setRefetchInterval(needRefetch ? DEFAULT_POLLING_INTERVAL : false);
      onSuccess?.(data);

      selectFirstItem(queryClient, zoneId, data);
    },
    ...rest,
  });
}

function selectFirstItem(queryClient: QueryClient, zoneId: number, data: Panorama[]) {
  // 이전에 선택된 파노라마가 없다면 첫번째 파노라마를 선택한다.
  queryClient.setQueryData(keys.selected(zoneId), (prevSelected: Panorama) => {
    if (prevSelected) return prevSelected;
    if (!data || data?.length < 0) return null;

    return data?.[0];
  });
}

export function usePanoramaMutation({ zoneId }: { zoneId: number }) {
  const queryClient = useQueryClient();

  const createPanorama = useMutation(
    ({ data }: { data: Panorama }) => {
      const formData = new FormData();
      formData.append('file', data?.file);
      formData.append('take_date', data?.takeDate);
      data?.description && formData.append('description', data?.description);
      return api.create({ zoneId, formData });
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(keys.list(zoneId));
        queryClient.invalidateQueries(keys.list(zoneId));
      },
    },
  );

  const updatePanorama = useMutation(
    ({ panoramaId, data }: Omit<Parameters<typeof api.update>[0], 'zoneId'>) =>
      api.update({ zoneId, panoramaId, data }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(keys.list(zoneId));
        queryClient.setQueryData(keys.selected(zoneId), (prevSelected: Panorama) => {
          if (!prevSelected) return null;
          if (prevSelected.id !== data.id) return prevSelected;
          return { ...prevSelected, ...data };
        });
      },
    },
  );

  const deletePanorama = useMutation(
    ({ panoramaId }: Omit<Parameters<typeof api.delete>[0], 'zoneId'>) =>
      api.delete({ zoneId, panoramaId }),
    {
      onSuccess: () => {
        queryClient.removeQueries(keys.list(zoneId));
        queryClient.invalidateQueries(keys.list(zoneId));
      },
    },
  );

  return { createPanorama, updatePanorama, deletePanorama };
}

export function useSelectedPanoramaQuery(zoneId: number) {
  return useQuery<Panorama>(keys.selected(zoneId), {
    initialData: null,
    enabled: !!zoneId,
    staleTime: Infinity,
  });
}
export function usePanoramaFullViewQuery(snapshotId: number) {
  return useQuery<Panorama>(keys.fullView(snapshotId), {
    initialData: null,
    enabled: !!snapshotId,
    staleTime: Infinity,
  });
}

export function usePanoramaFromImageSetQuery(zoneId: number) {
  const queryClient = useQueryClient();

  const createPanoramaFromImageSet = useMutation(
    ({ stitchResourceIds }: { stitchResourceIds: number[] }) => {
      return api.make({ zoneId, stitchResourceIds });
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(keys.list(zoneId));
        queryClient.invalidateQueries(keys.list(zoneId));
      },
    },
  );

  return { createPanoramaFromImageSet };
}
