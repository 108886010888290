import GA from 'infrastructure/ga';
import { THREE_D_COMPARE } from 'infrastructure/ga/category';

export const THREE_D_COMPARE_BIM_ADD_BTN = `${THREE_D_COMPARE}BIMAddBtn`;
export const THREE_D_COMPARE_BIM_LINK_BTN = `${THREE_D_COMPARE}BIMLinkBtn`;
export const THREE_D_COMPARE_BIM_VIEW_RADIO_BTN = `${THREE_D_COMPARE}BIMViewRadioBtn`;
export const THREE_D_COMPARE_PLAN_LEVEL_ADD_BTN = `${THREE_D_COMPARE}PlanLevelAddBtn`;
export const THREE_D_COMPARE_PLAN_LEVEL_LINK_BTN = `${THREE_D_COMPARE}PlanLevelLinkBtn`;
export const THREE_D_COMPARE_PLAN_LEVEL_VIEW_RADIO_BTN = `${THREE_D_COMPARE}PlanLevelViewRadioBtn`;

export const sendThreeDComparePlanLevelLinkBtn = () =>
  GA.event(THREE_D_COMPARE, THREE_D_COMPARE_PLAN_LEVEL_LINK_BTN);

export const sendThreeDCompareBimLinkBtn = () =>
  GA.event(THREE_D_COMPARE, THREE_D_COMPARE_BIM_LINK_BTN);
