import BigNumber from 'bignumber.js';
import { nls } from 'infrastructure/locale/language';
import times from 'lodash/times';
import { ElevationType, MAX_ELEVATION_POINT } from 'infrastructure/query/annotation/types';
import * as COLOR from 'components/styles/colors/symbols';
import { hexToRgba } from 'components/styles/colors/utils';

export function samplingElevationData(graphData, type: string, linLen: number) {
  const lastDistRatio = new BigNumber(graphData[graphData.length - 1]?.data || 1);
  let index = 0;
  let prevX = null;
  let prevElevation = null;
  let x = new BigNumber(graphData[index].data).dividedBy(lastDistRatio);
  let bElevation = new BigNumber(graphData[index].elevation);

  // 점 개수 1500개로 고정 샘플링 진행
  // 리스트의 마지막 값의 길이가 선분의 길이와 일치하지 않는 문제로 수동으로 마지막 값을 추가하기 때문에 -1 처리함.
  const samplingData = times(MAX_ELEVATION_POINT - 1).map((_: any, idx: number) => {
    const i = new BigNumber(idx).dividedBy(new BigNumber(MAX_ELEVATION_POINT - 1));

    while (i.isGreaterThanOrEqualTo(x)) {
      index += 1;
      prevX = new BigNumber(x);
      prevElevation = new BigNumber(bElevation);
      x = new BigNumber(graphData[index].data).dividedBy(lastDistRatio);
      bElevation = new BigNumber(graphData[index].elevation);
    }
    /* (elevation * (i - prevX)) / (x - prevX) + (prevElevation * (x - i)) / (x - prevX) */
    const currentElevationA = bElevation.multipliedBy(i.minus(prevX)).dividedBy(x.minus(prevX));
    const currentElevationB = prevElevation.multipliedBy(x.minus(i)).dividedBy(x.minus(prevX));
    const currentElevation = currentElevationA.plus(currentElevationB);
    const length = i.multipliedBy(linLen).toNumber();

    return {
      distRatio: i.toNumber(),
      type,
      elevations: currentElevation.toNumber(),
      length,
    };
  });

  return samplingData.concat({
    distRatio: 1,
    type,
    elevations: graphData[graphData.length - 1].elevation,
    length: linLen,
  });
}

export function getColor(type: ElevationType) {
  return ElevationProps[type]?.color;
}

export function isExistingElevationByType(elevations, type: ElevationType) {
  return !!elevations.find((item) => item.type === type);
}

export function getName(type: ElevationType) {
  return ElevationProps[type]?.name;
}

export const ElevationProps = {
  STANDARD: { color: COLOR.CURRENT_LEVEL, name: nls.referenceDateType() },
  REFER_SNAPSHOT: { color: COLOR.COMPARISON_DATE, name: nls.compareDateType() },
  REFER_PLAN_LEVEL: { color: COLOR.PLAN_LEVEL, name: nls.planElevationType() },
  REFER_STRATUM1: { color: COLOR.STRATUM1, name: nls.stratumElevationNth(1) },
  REFER_STRATUM2: { color: COLOR.STRATUM2, name: nls.stratumElevationNth(2) },
  REFER_STRATUM3: { color: COLOR.STRATUM3, name: nls.stratumElevationNth(3) },
  REFER_STRATUM4: { color: COLOR.STRATUM4, name: nls.stratumElevationNth(4) },
  REFER_STRATUM5: { color: COLOR.STRATUM5, name: nls.stratumElevationNth(5) },
  REFER_STRATUM6: { color: COLOR.STRATUM6, name: nls.stratumElevationNth(6) },
  REFER_STRATUM7: { color: COLOR.STRATUM7, name: nls.stratumElevationNth(7) },
  REFER_STRATUM8: { color: COLOR.STRATUM8, name: nls.stratumElevationNth(8) },
  REFER_STRATUM9: { color: COLOR.STRATUM9, name: nls.stratumElevationNth(9) },
  REFER_STRATUM10: { color: COLOR.STRATUM10, name: nls.stratumElevationNth(10) },
  REFER_STRATUM11: { color: COLOR.STRATUM11, name: nls.stratumElevationNth(11) },
  REFER_STRATUM12: { color: COLOR.STRATUM12, name: nls.stratumElevationNth(12) },
  REFER_STRATUM13: { color: COLOR.STRATUM13, name: nls.stratumElevationNth(13) },
  REFER_STRATUM14: { color: COLOR.STRATUM14, name: nls.stratumElevationNth(14) },
  REFER_STRATUM15: { color: COLOR.STRATUM15, name: nls.stratumElevationNth(15) },
  REFER_STRATUM16: { color: COLOR.STRATUM16, name: nls.stratumElevationNth(16) },
  REFER_STRATUM17: { color: COLOR.STRATUM17, name: nls.stratumElevationNth(17) },
  REFER_STRATUM18: { color: COLOR.STRATUM18, name: nls.stratumElevationNth(18) },
  REFER_STRATUM19: { color: COLOR.STRATUM19, name: nls.stratumElevationNth(19) },
  REFER_STRATUM20: { color: COLOR.STRATUM20, name: nls.stratumElevationNth(20) },
  REFER_STRATUM21: { color: COLOR.STRATUM21, name: nls.stratumElevationNth(21) },
  REFER_STRATUM22: { color: COLOR.STRATUM22, name: nls.stratumElevationNth(22) },
  REFER_STRATUM23: { color: COLOR.STRATUM23, name: nls.stratumElevationNth(23) },
  REFER_STRATUM24: { color: COLOR.STRATUM24, name: nls.stratumElevationNth(24) },
  REFER_STRATUM25: { color: COLOR.STRATUM25, name: nls.stratumElevationNth(25) },
};

export function getGradient(type) {
  const hexCode = getColor(type);
  return `l(270) 0:${hexToRgba(hexCode, 0.1)} 0.5:${hexToRgba(hexCode, 0.5)} 1:${hexToRgba(
    hexCode,
    0.8,
  )}`;
}

export function getAnnotationInfoFromJsonLink(json: string) {
  const infos = json
    .split('_')[0]
    .substring(json.indexOf('elevation/') + 10)
    .split('/');
  return { snapshotId: +infos[0], annotationId: +infos[1] };
}
