import createPalette from '@material-ui/core/styles/createPalette';
import { action } from '../colors/action';
import { background } from '../colors/background';
import { error } from '../colors/error';
import { grey } from '../colors/grey';
import { info } from '../colors/info';
import { others } from '../colors/others';
import { primary } from '../colors/primary';
import { secondary } from '../colors/secondary';
import { success } from '../colors/success';
import { text } from '../colors/text';
import { warning } from '../colors/warning';

const palette = createPalette({
  primary: {
    main: primary.MAIN,
    dark: primary.DARK,
    light: primary.LIGHT,
    contrastText: primary.CONTRAST_TEXT,
  },
  info: {
    main: info.MAIN,
    dark: info.DARK,
    light: info.LIGHT,
    contrastText: info.CONTRAST_TEXT,
  },
  secondary: {
    main: secondary.MAIN,
    dark: secondary.DARK,
    light: secondary.LIGHT,
    contrastText: secondary.CONTRAST_TEXT,
  },
  error: {
    main: error.MAIN,
    dark: error.DARK,
    light: error.LIGHT,
    contrastText: error.CONTRAST_TEXT,
  },
  warning: {
    main: warning.MAIN,
    dark: warning.DARK,
    light: warning.LIGHT,
    contrastText: warning.CONTRAST_TEXT,
  },
  action: {
    hover: action.HOVER,
    selected: action.SELECTED,
    disabledBackground: action.DISABLED_BACKGROUND,
  },
  text: {
    primary: text.PRIMARY,
    secondary: text.SECONDARY,
    disabled: text.DISABLED,
  },
  grey: {
    50: grey.GREY_50,
    100: grey.GREY_100,
    200: grey.GREY_200,
    300: grey.GREY_300,
    400: grey.GREY_400,
    500: grey.GREY_500,
    600: grey.GREY_600,
    700: grey.GREY_700,
    800: grey.GREY_800,
    900: grey.GREY_900,
    A100: grey.GREY_A100,
    A200: grey.GREY_A200,
    A400: grey.GREY_A400,
    A700: grey.GREY_A700,
  },
  divider: others.DIVIDER,
  background: {
    paper: background.PAPER,
    default: background.DEFAULT,
  },
  success: {
    main: success.MAIN,
    dark: success.DARK,
    light: success.LIGHT,
    contrastText: success.CONTRAST_TEXT,
  },
});

export default palette;
