import { apiAxios } from 'common/api/common';
import { Cctv } from 'infrastructure/query/cctv/types';

export default {
  async read({ zoneId, cctvId }: { zoneId: number; cctvId: number }) {
    const response = await apiAxios.get<Cctv>(`/api/v2/zones/${zoneId}/cctvs/${cctvId}`);
    return response.data;
  },
  async list({ zoneId }: { zoneId: number }) {
    const response = await apiAxios.get<{ results: Cctv[] }>(`/api/v2/zones/${zoneId}/cctvs`);
    return response.data?.results;
  },
  async create({ zoneId, data }: { zoneId: number; data: Partial<Cctv> }) {
    const response = await apiAxios.post<Cctv>(`/api/v2/zones/${zoneId}/cctvs`, data);
    return response.data;
  },
  async update({ zoneId, cctvId, data }: { zoneId: number; cctvId: number; data: Partial<Cctv> }) {
    const response = await apiAxios.patch<Cctv>(`/api/v2/zones/${zoneId}/cctvs/${cctvId}`, data);
    return response.data;
  },
  async delete({ zoneId, cctvId }: { zoneId: number; cctvId: number }) {
    return await apiAxios.delete(`/api/v2/zones/${zoneId}/cctvs/${cctvId}`);
  },
  async url({
    protocol,
    subUrl,
    authId,
    authPw,
  }: {
    protocol: string;
    subUrl: string;
    authId: string;
    authPw: string;
  }) {
    const response = await apiAxios.get<{ integratedUrl: string }>(`/api/v3/util/url`, {
      params: {
        protocol,
        sub_url: subUrl,
        auth_id: authId,
        auth_pw: authPw,
      },
    });
    return response.data?.integratedUrl;
  },
};
