import Typography from '@material-ui/core/Typography';
import { Vi } from 'infrastructure/query/issue/types';
import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from 'components/styles/colors/utils';
import theme from 'components/styles/mui/theme';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { nls } from 'infrastructure/locale/language';

export default function AlertMessage({ vi }: { vi: Vi }) {
  const isRequired = !vi?.errorCode && vi?.calculationStatus === 'REQUIRED';
  const isInProgress = !vi?.errorCode && vi?.calculationStatus === 'IN_PROGRESS';
  const isError = !vi?.errorCode && vi?.calculationStatus === 'ERROR';

  return (
    <Message>
      <Text>{nls.issuePolygonComparedMessageTitle()}</Text>
      {vi?.errorCode === 'ER3035' && (
        <ErrorMessage>{nls.issuePolygonComparedEmptyFile()}</ErrorMessage>
      )}
      {vi?.errorCode === 'ER3036' && (
        <ErrorMessage>{nls.issuePolygonComparedConverting()}</ErrorMessage>
      )}
      {isRequired && (
        <WarningMessage>{nls.issuePolygonComparedCalculationRequired()}</WarningMessage>
      )}
      {isInProgress && <WarningMessage>{nls.issuePolygonComparedCalculating()}</WarningMessage>}
      {isError && <ErrorMessage>{nls.issuePolygonComparedCalculationError()}</ErrorMessage>}
    </Message>
  );
}

const Message = styled.div`
  background-color: ${theme.palette.text.primary};
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const Text = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.57;
  font-weight: 600;
  color: ${hexToRgba(theme.palette.primary.contrastText, 0.6)};
  svg + & {
    margin-left: 0.25rem;
  }
`;

const ErrorMessage = ({ children }) => {
  return (
    <Box>
      <ErrorIcon fontSize="small" htmlColor={theme.palette.error.main} />
      <Text>{children}</Text>
    </Box>
  );
};

const WarningMessage = ({ children }) => {
  return (
    <Box>
      <WarningIcon fontSize="small" htmlColor={theme.palette.warning.main} />
      <Text>{children}</Text>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  align-items: center;
`;
