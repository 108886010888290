import { keys as birdsEyeViewKeys } from './birdsEyeView/queryKeys';
import { ResourceType } from './types';

export const keys = {
  all: () => ['resources'],
  list: () => ['resources', 'list'],
  listByZoneId: (zoneId: number, snapshotId?: number, type?: ResourceType) => [
    'resources',
    'list',
    { zoneId, snapshotId, type },
  ],
  listBySnapshotId: (snapshotId: number, type?: ResourceType) => [
    'resources',
    'list',
    { snapshotId, type },
  ],
  listByProjectId: (projectId: number, type?: ResourceType) => [
    'resources',
    'list',
    { projectId, type },
  ],
  listByType: (type: ResourceType) => ['resources', 'list', { type }],
  detail: (resourceId: number) => ['resources', 'detail', resourceId],
  birdsEyeView: birdsEyeViewKeys,
};
