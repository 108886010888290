import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ConfirmWithoutSaving from '../ConfirmWithoutSaving';
import BackButton from '../button/BackButton';
import PUTitle from './PUTitle';

interface Props {
  title: string;
  divider?: boolean;
  children?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  shouldConfirm?: boolean;
  hasBackButton?: boolean;
}
export default function PUHeader({
  title,
  children,
  divider = true,
  buttonText,
  onButtonClick,
  shouldConfirm,
  hasBackButton = false,
}: Props) {
  return (
    <Header $hasBackButton={hasBackButton}>
      {hasBackButton && (
        <BackButton onClick={onButtonClick} edge="start">
          {buttonText}
        </BackButton>
      )}
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <PUTitle>{title}</PUTitle>
          {children}
        </Box>
      </Box>
      {divider && <StyledDivider />}
      {shouldConfirm && <ConfirmWithoutSaving shouldConfirm={shouldConfirm} />}
    </Header>
  );
}
const Header = styled.div<{ $hasBackButton: boolean }>`
  margin-top: ${({ $hasBackButton }) => ($hasBackButton ? '3rem' : '0')};
`;

const StyledDivider = styled(Divider)`
  margin-top: 1.5rem;
`;
