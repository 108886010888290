import { QueryKey } from 'react-query';
import { ExportArchiveType } from './types';

export const keys: {
  [key: string]: (...args: any[]) => QueryKey;
} = {
  all: () => ['exportUrls'],
  detail: (snapshotId: number) => ['exportUrls', snapshotId],
  checkExist: (snapshotId: number) => ['exportUrls', 'checkExist', snapshotId],
  archiveDetail: (snapshotId: number, type: ExportArchiveType): QueryKey => [
    'exportUrls',
    'archive',
    snapshotId,
    type,
  ],
};
