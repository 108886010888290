import proj4 from 'proj4';
import { useListProj4DefinitionQuery } from 'infrastructure/query';
import { useEffect, useState } from 'react';

export default function useProj4() {
  const [isReady, setIsReady] = useState(false);
  const { data } = useListProj4DefinitionQuery();

  useEffect(() => {
    if (data?.length > 0) {
      const defs = data?.map(({ code, proj4 }) => [code, proj4]);
      proj4.defs(defs);
      setIsReady(true);
    }
  }, [data]);
  return { proj4: isReady ? proj4 : undefined };
}
