export type Cctv = {
  id: number;
  zoneId: number;
  name: string;
  thumbnailImg: string;
  latitude: number;
  longitude: number;
  data: { degree: number };
  authId: string;
  authPw: string;
  subUrl: string;
  protocol: string;
  flashphonerWcsHost: string;
  streamUrl: string;
  clientStreamUrl: string;
  hasPosition: boolean;
};

export const getCctvUrl = (clientStreamUrl: string) => {
  return `${clientStreamUrl}&mediaProviders=&skin="light"&autoplay="true"`;
};
