import { FileResourceEnum, PhotoBoxMediaResponseEntity } from 'infrastructure/query/photoBox/types';

export enum PhotoBoxMediaEnum {
  MEDIA = 'MEDIA',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export enum PhotoBoxAlbumEnum {
  ALBUM = 'album',
  DETAIL = 'detail',
}
export const PHOTO_BOX_SNACKBAR_DURATION = 5000;
export const uploadFileLimitCount = 1000;
export const uploadMemoLimitCount = 40;
export const albumNameLimitCount = 20;
export const mediaFileLimit = 100;
export const selectedItemLimit = 30;
export const initialDetailMediaFile: PhotoBoxMediaResponseEntity = {
  id: 0,
  projectId: 0,
  zoneId: 0,
  resource: {
    file: '',
    fileName: '',
    type: FileResourceEnum.IMAGE,
    size: 0,
  },
  type: PhotoBoxMediaEnum.IMAGE,
  latitude: 0,
  longitude: 0,
  coordinate: '',
  memo: '',
  mediaMeta: {
    videoDuration: 0,
  },
  file: null,
  resourceThumbnail: {
    file: '',
  },
  created: '',
  createdByUser: {
    id: 0,
    name: '',
    companyName: '',
    job: '',
  },
};

/* 포토박스 - 현장 상황 */
export const photoBoxSecondarySideMenuListLimitCount = 100;
