import MuiIconButton from '@material-ui/core/IconButton';
import styled, { css } from 'styled-components';
import theme from 'components/styles/mui/theme';

export const IconButton = styled(MuiIconButton)<{ $active?: boolean; $fill?: boolean }>`
  padding: ${({ size }) => (size === 'small' ? '0.1875rem' : '0.75rem')};
  &.MuiIconButton-root.Mui-disabled svg {
    color: ${theme.palette.action.disabledBackground};
  }

  &:focus {
    outline: none;
  }
  ${({ size, $fill }) =>
    size === 'small' &&
    !$fill &&
    css`
      border-radius: 0.25rem;
    `}
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${theme.palette.action.selected};
    `}
  ${({ $fill }) =>
    $fill &&
    css`
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
      &:disabled {
        background-color: ${theme.palette.action.disabledBackground};
      }
    `}
`;

export const IconButtonFillWhite = styled(IconButton)`
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[6]};
  border-radius: 50%;
  &:hover {
    background-color: ${theme.palette.grey[100]};
  }
`;
