import { atom } from 'jotai';

type FolderState = {
  id: number;
  expanded?: boolean;
};

type PointState = {
  id: number;
  folderId?: number;
  /* visible이 필요한 모든 곳에서 사용 */
  visible?: boolean;
  /* map에서만 보여줘야하는 경우 사용 */
  visibleOnMap?: boolean;
};

const pointEnabledAtom = atom<boolean>(false);
const pointAccordionExpandedAtom = atom<boolean>(false);
const pointStatesAtom = atom<PointState[]>([]);
const pointFolderStatesAtom = atom<FolderState[]>([]);

export const pointAtom = {
  enabled: pointEnabledAtom,
  expanded: pointAccordionExpandedAtom,
  states: pointStatesAtom,
  folderStates: pointFolderStatesAtom,
};
