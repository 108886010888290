import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from 'components/styles/colors/utils';
import theme from 'components/styles/mui/theme';

export default function LeftSideBar({ children }) {
  return <LeftSideBarContainer>{children}</LeftSideBarContainer>;
}

const LeftSideBarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 4rem;
  width: 17.125rem;
  height: calc(100% - 4rem);
  background-color: ${hexToRgba(theme.palette.grey[900], 0.9)};
  color: ${theme.palette.primary.contrastText};
  z-index: ${theme.zIndex.appBar};
`;
