import { Z_INDEX } from 'common/policies/2d';
import {
  DEFAULT_CENTER,
  MAX_ZOOM,
  MIN_ZOOM,
  UPDATE_WHEN_IDLE,
  UPDATE_WHEN_ZOOMING,
} from 'common/policies/map';
import { useMatchParams } from 'components/common/hooks';
import { useAtomValue } from 'jotai';
import useOrthophoto from 'infrastructure/query/orthophoto/useOrthophoto';
import { getBounds, getRasterTmsUrl, getZoomLevel } from 'infrastructure/query/resource/types';
import React, { useEffect, useMemo } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import { orthophotoAtom } from 'stores/v3/snapshotDetail/twoD';
import { mapViewAtom } from 'stores/v3/snapshotDetail/twoD/view';

interface Props {
  snapshotId?: number;
  moveCenter?: boolean;
}

export default function OrthophotoLayer({ snapshotId = undefined, moveCenter = true }: Props) {
  const map = useMap();
  const { zoneId } = useMatchParams();
  const checked = useAtomValue(orthophotoAtom.checked);
  const view = useAtomValue(mapViewAtom);
  const { orthophoto } = useOrthophoto({ snapshotId });

  const [bounds, center] = useMemo(() => {
    const bounds = getBounds(orthophoto);
    const center = !bounds
      ? DEFAULT_CENTER
      : {
          lat: (bounds[0][0] + bounds[1][0]) / 2,
          lng: (bounds[0][1] + bounds[1][1]) / 2,
        };
    return [bounds, center];
  }, [orthophoto]);

  useEffect(() => {
    if (!moveCenter) return;
    const c = view[zoneId]?.center || center;
    const z = view[zoneId]?.zoom || (bounds ? map.getBoundsZoom(bounds) : map.getZoom());

    map.setView(c, z, { animate: false });
  }, [bounds, center]);

  if (!orthophoto || !bounds || !checked) return null;
  return (
    <TileLayer
      key={orthophoto.id}
      tms
      url={getRasterTmsUrl(orthophoto)}
      bounds={getBounds(orthophoto)}
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      maxNativeZoom={getZoomLevel(orthophoto)?.max}
      minNativeZoom={getZoomLevel(orthophoto)?.min}
      updateWhenZooming={UPDATE_WHEN_ZOOMING}
      updateWhenIdle={UPDATE_WHEN_IDLE}
      zIndex={Z_INDEX.ORTHOPHOTO}
    />
  );
}
