import { apiAxios } from 'common/api/common';
import { BirdsEyeView, CreationBirdsEyeView } from './types';

export default {
  async list({ zoneId }: { zoneId: number }) {
    const response = await apiAxios.get<{ results: BirdsEyeView[] }>(
      `/api/v3/zones/${zoneId}/birds-eye-view`,
    );
    return response.data?.results;
  },
  async create({ zoneId, data }: { zoneId: number; data: CreationBirdsEyeView }) {
    const response = await apiAxios.post(`/api/v3/zones/${zoneId}/birds-eye-view`, data);
    return response.data;
  },
  async read({ zoneId, id }: { zoneId: number; id: number }) {
    const response = await apiAxios.get<BirdsEyeView>(
      `/api/v3/zones/${zoneId}/birds-eye-view/${id}`,
    );
    return response.data;
  },
  async update({ zoneId, id, data }: { zoneId: number; id: number; data: Partial<BirdsEyeView> }) {
    const response = await apiAxios.patch(`/api/v3/zones/${zoneId}/birds-eye-view/${id}`, data);
    return response.data;
  },
  async delete({ zoneId, id }: { zoneId: number; id: number }) {
    const response = await apiAxios.delete(`/api/v3/zones/${zoneId}/birds-eye-view/${id}`);
    return response.data;
  },
};
