import axios, { CancelToken } from 'axios';
import { apiAxios } from 'common/api/common';

interface Props {
  snapshotId: number;
  resourceId: number;
  data: any;
}
export default {
  uploadFiles({ query, filename, size }: { query: string; filename: string; size: number }) {
    return apiAxios.post(
      `/api/upload_files${query}`,
      { filename, size },
      { transformResponse: [(data) => JSON.parse(data)] },
    );
  },
  directUploadToS3({
    url,
    fields,
    originFile,
    cancelToken,
  }: {
    url: string;
    fields: { [key: string]: string };
    originFile: File;
    cancelToken: CancelToken;
  }) {
    // 응답으로 받은 모든 fields를 formData로 만들어 전송해야함.
    // 순서 유지 필요: fields의 key값이 file 데이터 보다 위에 위치해야 함.
    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
    formData.append('file', originFile);

    return axios.post(url, formData, {
      cancelToken,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  completeMultipartUpload({ url, data }: { url: string; data: string }) {
    return axios.post(url, data, {
      headers: { 'Content-Type': 'text/xml' },
      timeout: 3600_000,
    });
  },
};
