import {
  DEFAULT_CIRCLE_MARKER_RADIUS,
  DEFAULT_EDITING_PATH_DASH_ARRAY,
  DEFAULT_EDITING_PATH_DASH_OFFSET,
  DEFAULT_PATH_DASH_ARRAY,
  DEFAULT_PATH_DASH_OFFSET,
} from 'common/policies/2d';
import { isTouchDevice } from 'common/utils';
import L, { LatLng, LeafletMouseEvent } from 'leaflet';
import React, { useRef, useState } from 'react';
import { Marker, MarkerProps, useMap, useMapEvents } from 'react-leaflet';
import { COLOR_CHIP } from 'components/styles/colors/symbols';
import EditControlSnackbar from '../../common/EditControlSnackbar';
import { getAnnotationNumberMarker, getMarkerAddIcon } from './utils';

interface Props {
  color: string;
  originalPositions: LatLng[];
  positions: LatLng[];
  setPositions: (positions: LatLng[]) => void;
  getObjectLatLngs: () => LatLng[];
  setObjectLatLngs: (latLngs: LatLng[]) => void;
  setObjectStyle?: (styles) => void;
  snapPositions?: LatLng[];
  snackbarMessage: { default: string; dirty: string };
  showSnackbar: boolean;
  onCancel: () => void;
  onSave: (onError?) => void;
  disabledAddPoint?: boolean;
}

const DELETE_DISTANCE = DEFAULT_CIRCLE_MARKER_RADIUS;
const SNAP_DISTANCE = DEFAULT_CIRCLE_MARKER_RADIUS;

export default function AnnotationEditingTools({
  originalPositions,
  positions,
  setPositions,
  color,
  getObjectLatLngs,
  setObjectLatLngs,
  setObjectStyle,
  snapPositions,
  snackbarMessage,
  showSnackbar,
  onCancel,
  onSave,
  disabledAddPoint,
}: Props) {
  if (!positions?.length) return null;

  const map = useMap();
  const [dirty, setDirty] = useState(false);

  const numberMarkersRef = useRef<L.Marker[]>(new Array(positions.length));
  const addMarkersRef = useRef<L.Marker[]>(new Array(positions.length));
  const draggingStartPositionRef = useRef<{ idx: number; lat: number; lng: number } | null>(null);
  const addingObjectRef = useRef(null);

  /** drag event에서는 e.target.getLatLng()이, mousemove event에서는 e.latlng을 사용해야해서 함수 하나로 통일 */
  function getLatLng(e) {
    return e.latlng || e.target.getLatLng();
  }

  const numberMarkerHandlers = {
    dragstart: (e: LeafletMouseEvent, idx: number) => {
      draggingStartPositionRef.current = {
        idx,
        lat: e.target.getLatLng().lat,
        lng: e.target.getLatLng().lng,
      };
    },
    drag: (e: LeafletMouseEvent, idx: number) => {
      const latlng = shouldSnapPosition(e) || getLatLng(e);
      /** move current target number marker */
      const target = numberMarkersRef.current[idx];
      target.setLatLng(latlng);

      /** set polygon positions */
      const old = getObjectLatLngs();
      setObjectLatLngs([...old.slice(0, idx), latlng, ...old.slice(idx + 1)]);

      /** move prev add marker: center of current and prev number marker */
      const prevAddMarkerIndex = (idx || positions.length) - 1;
      const prevNumberMarker = numberMarkersRef.current[prevAddMarkerIndex];
      addMarkersRef.current[prevAddMarkerIndex]?.setLatLng({
        lat: (latlng.lat + prevNumberMarker.getLatLng().lat) / 2,
        lng: (latlng.lng + prevNumberMarker.getLatLng().lng) / 2,
      });

      /** move next add marker: center of current and next number marker */
      const nextAddMarkerIndex = idx;
      const nextNumberMarker =
        numberMarkersRef.current[(nextAddMarkerIndex + 1) % positions.length];
      addMarkersRef.current[nextAddMarkerIndex]?.setLatLng({
        lat: (latlng.lat + nextNumberMarker.getLatLng().lat) / 2,
        lng: (latlng.lng + nextNumberMarker.getLatLng().lng) / 2,
      });

      /** set style */
      if (shouldRevertPosition(e, idx)) {
        setObjectStyle({
          color: COLOR_CHIP.GREY,
          dashArray: DEFAULT_EDITING_PATH_DASH_ARRAY,
          dashOffset: DEFAULT_EDITING_PATH_DASH_OFFSET,
        });
      } else if (shouldDeletePosition(e, idx)) {
        setObjectStyle({
          color: COLOR_CHIP.RED,
          dashArray: DEFAULT_EDITING_PATH_DASH_ARRAY,
          dashOffset: DEFAULT_EDITING_PATH_DASH_OFFSET,
        });
      } else if (shouldSnapPosition(e)) {
        setObjectStyle({
          color,
          dashArray: DEFAULT_EDITING_PATH_DASH_ARRAY,
          dashOffset: DEFAULT_EDITING_PATH_DASH_OFFSET,
        });
      } else {
        setObjectStyle({
          color,
          dashArray: DEFAULT_PATH_DASH_ARRAY,
          dashOffset: DEFAULT_PATH_DASH_OFFSET,
        });
      }
    },
    dragend: (e: LeafletMouseEvent, idx: number) => {
      setObjectStyle({
        color,
        dashArray: DEFAULT_PATH_DASH_ARRAY,
        dashOffset: DEFAULT_PATH_DASH_OFFSET,
      });

      if (shouldRevertPosition(e, idx)) {
        const { lat, lng } = draggingStartPositionRef.current;
        numberMarkersRef.current[idx].setLatLng(draggingStartPositionRef.current);
        setObjectLatLngs(positions);

        /** move prev add marker: center of current and prev number marker */
        const prevAddMarkerIndex = (idx || numberMarkersRef.current.length) - 1;
        const prevNumberMarker = numberMarkersRef.current[prevAddMarkerIndex];
        addMarkersRef.current[prevAddMarkerIndex]?.setLatLng({
          lat: (lat + prevNumberMarker.getLatLng().lat) / 2,
          lng: (lng + prevNumberMarker.getLatLng().lng) / 2,
        });

        /** move next add marker: center of current and next number marker */
        const nextAddMarkerIndex = idx;
        const nextNumberMarker = numberMarkersRef.current[nextAddMarkerIndex + 1];
        addMarkersRef.current[nextAddMarkerIndex]?.setLatLng({
          lat: (lat + nextNumberMarker.getLatLng().lat) / 2,
          lng: (lng + nextNumberMarker.getLatLng().lng) / 2,
        });
        return;
      }
      if (shouldDeletePosition(e, idx)) {
        deletePosition(idx);
      }

      setPositions(getObjectLatLngs());
      setDirty(true);
    },
  };

  const addMarkerHandlers = {
    dragstart: (e: LeafletMouseEvent, idx: number) => {
      const latlng = getLatLng(e);

      const oldPos = getObjectLatLngs();
      setObjectLatLngs([...oldPos.slice(0, idx + 1), latlng, ...oldPos.slice(idx + 1)]);
      addingObjectRef.current = getObjectLatLngs();
    },
    drag: (e: LeafletMouseEvent, idx: number) => {
      const latlng = getLatLng(e);

      const target = addMarkersRef.current[idx];
      target.setLatLng(latlng);

      setObjectLatLngs([
        ...addingObjectRef.current.slice(0, idx + 1),
        latlng,
        ...addingObjectRef.current.slice(idx + 2),
      ]);
    },
    dragend: () => {
      setPositions(getObjectLatLngs());
      setDirty(true);
    },
  };

  function deletePosition(idx: number) {
    const old = getObjectLatLngs();
    setObjectLatLngs([...old.slice(0, idx), ...old.slice(idx + 1)]);
  }

  function shouldSnapPosition(e: LeafletMouseEvent) {
    if (!snapPositions?.length) return null;

    const latlng = getLatLng(e);

    const closest = snapPositions.reduce(
      (acc, p) => {
        const distance = map.latLngToLayerPoint(latlng).distanceTo(map.latLngToLayerPoint(p));
        return distance < acc.distance ? { distance, p } : acc;
      },
      { distance: Number.MAX_SAFE_INTEGER, p: null },
    );

    return closest.distance <= SNAP_DISTANCE ? closest.p : null;
  }

  function shouldDeletePosition(e: LeafletMouseEvent, idx: number) {
    const latlng = getLatLng(e);

    const prevDefaultItem = positions[(idx || positions.length) - 1];
    const nextDefaultItem = positions[(idx + 1) % positions.length];

    const prevDistance = map
      .latLngToLayerPoint([prevDefaultItem.lat, prevDefaultItem.lng])
      .distanceTo(map.latLngToLayerPoint(latlng));
    const nextDistance = map
      .latLngToLayerPoint([nextDefaultItem.lat, nextDefaultItem.lng])
      .distanceTo(map.latLngToLayerPoint(latlng));
    return prevDistance < DELETE_DISTANCE || nextDistance < DELETE_DISTANCE;
  }

  function shouldRevertPosition(e: LeafletMouseEvent, idx: number) {
    if (shouldDeletePosition(e, idx) && positions.length <= 3) return true;

    const latlng = getLatLng(e);
    const latLngs = positions.filter((_, i) => i !== idx).map((p) => ({ lat: p.lat, lng: p.lng }));

    const distances = latLngs.map((x) =>
      map.latLngToLayerPoint(latlng).distanceTo(map.latLngToLayerPoint(x)),
    );

    return Math.min.apply(null, distances) <= DELETE_DISTANCE && !shouldDeletePosition(e, idx);
  }

  function onClickCancel() {
    setDirty(false);
    setObjectLatLngs(originalPositions);
    onCancel();
  }

  function onClickSave() {
    onSave(onClickCancel);
    setDirty(false);
  }

  const initialAddMarkerPositions = positions?.map((x, i) => ({
    lat: (x.lat + positions[(i + 1) % positions.length].lat) / 2,
    lng: (x.lng + positions[(i + 1) % positions.length].lng) / 2,
  }));

  return (
    <>
      {!disabledAddPoint &&
        initialAddMarkerPositions?.map((position: LatLng, i) => {
          return (
            <EditingMarker
              key={i}
              icon={getMarkerAddIcon()}
              position={position}
              eventHandlers={{
                add: (e) => {
                  addMarkersRef.current[i] = e.target;
                },
                dragstart: (e: LeafletMouseEvent) => {
                  addMarkerHandlers.dragstart(e, i);
                },
                drag: (e: LeafletMouseEvent) => {
                  addMarkerHandlers.drag(e, i);
                },
                dragend: () => {
                  addMarkerHandlers.dragend();
                },
              }}
            />
          );
        })}
      {positions?.map((position: LatLng, i) => {
        return (
          <EditingMarker
            key={i}
            icon={getAnnotationNumberMarker(color, i + 1)}
            position={position}
            eventHandlers={{
              add: (e) => {
                numberMarkersRef.current[i] = e.target;
              },
              dragstart: (e: LeafletMouseEvent) => {
                numberMarkerHandlers.dragstart(e, i);
              },
              drag: (e: LeafletMouseEvent) => {
                numberMarkerHandlers.drag(e, i);
              },
              dragend: (e: LeafletMouseEvent) => {
                numberMarkerHandlers.dragend(e, i);
              },
            }}
          />
        );
      })}
      <EditControlSnackbar
        message={dirty ? snackbarMessage.dirty : snackbarMessage.default}
        open={showSnackbar || dirty}
        showAction={dirty}
        onCancel={onClickCancel}
        onSave={onClickSave}
      />
    </>
  );
}

/** 다른 컴포넌트에서 사용이 필요한 경우 파일 분리해서 EditingMarker.tsx를 생성하고 사용하는 것을 권장 */
/** touch device의 경우 mousedown/up 이 동작하지 않고, touchstart도 정상 동작하지 않아서 drag를 사용 */
function EditingMarker({ icon, eventHandlers, position }: MarkerProps) {
  const grab = useRef(false);
  const { add, dragstart, drag, dragend } = eventHandlers;

  useMapEvents({
    mousemove: (e: LeafletMouseEvent) => {
      if (isTouchDevice() || !grab?.current) return;
      drag?.(e);
    },
    mouseup: (e: LeafletMouseEvent) => {
      if (isTouchDevice() || !grab?.current) return;
      grab.current = false;
      dragend?.(e);
    },
  });

  return (
    <Marker
      icon={icon}
      position={position}
      draggable={isTouchDevice()}
      eventHandlers={{
        add,
        mousedown: (e) => {
          if (isTouchDevice() || grab.current) return;
          grab.current = true;
          dragstart?.(e);
        },

        /** touch device */
        dragstart: (e) => {
          if (!isTouchDevice()) return;
          dragstart?.(e);
        },
        drag: (e) => {
          if (!isTouchDevice()) return;
          drag?.(e);
        },
        dragend: (e) => {
          if (!isTouchDevice()) return;
          dragend?.(e);
        },
      }}
    />
  );
}
