import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import SvgIcon from '@material-ui/core/SvgIcon';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ReactComponent as RadioDisabledIcon } from 'assets/images/icon_radio_disabled.svg';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme from 'components/styles/mui/theme';

interface LabelRadioProps extends RadioProps {
  label?: ReactNode;
  loading?: boolean;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  fullWidth?: boolean;
}

export const LabelRadio = (props: LabelRadioProps) => {
  const {
    label,
    loading = false,
    checked = false,
    disabled = false,
    labelPlacement = 'end',
    onClick,
    color = 'primary',
    fullWidth = true,
    ...rest
  } = props;

  const progressSize = props?.size === 'small' ? '1.25rem' : '1.5rem';
  const icon = (loading && <CircularProgress size={progressSize} />) ||
    (disabled && checked && <DisabledActiveIcon className="radio-icon" />) ||
    (disabled && (
      <SvgIcon component={RadioDisabledIcon} className="radio-icon custom disabled" />
    )) ||
    (checked && <RadioButtonCheckedIcon className="radio-icon" />) || (
      <UncheckedIcon className="radio-icon" />
    );
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  const radio = (
    <StyledRadio
      checked={checked}
      disabled={disabled}
      icon={icon}
      checkedIcon={icon}
      onClick={handleClick}
      color={color}
      {...rest}
    />
  );

  const title = typeof label === 'string' ? label : '';
  return (
    <StyledFormControl
      $fullWidth={fullWidth}
      control={radio}
      label={label}
      title={title}
      labelPlacement={labelPlacement}
      onClick={(event) => event.stopPropagation()}
    />
  );
};

const StyledFormControl = styled(FormControlLabel)<{ $fullWidth: boolean }>`
  margin: 0;
  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          margin-right: 1rem;
          width: 100%;
        `
      : css`
          & + & {
            margin-left: 2.5rem;
          }
        `};

  &.MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    width: initial;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};

    &.Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
  }

  svg.custom.disabled {
    color: ${theme.palette.text.primary};
  }
`;

const StyledRadio = styled(Radio)`
  padding: 0.5625rem;
  .radio-icon {
    ${({ size }) =>
      size === 'small'
        ? css`
            width: 1.25rem;
            height: 1.25rem;
          `
        : css`
            width: 1.5rem;
            height: 1.5rem;
          `}
  }
`;

const DisabledActiveIcon = styled(RadioButtonCheckedIcon)`
  color: ${theme.palette.action.disabledBackground};
`;
const UncheckedIcon = styled(RadioButtonUncheckedIcon)`
  color: ${theme.palette.text.secondary};
`;
