import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { keys } from './queryKeys';
import api from './api';

export function useZoneListQuery({ projectId }: { projectId: number }) {
  return useQuery(keys.list(projectId), () => api.list({ projectId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(projectId),
  });
}

export function useZoneQuery({ projectId, zoneId }: { projectId: number; zoneId: number }) {
  return useQuery(keys.detail(projectId, zoneId), () => api.read({ projectId, zoneId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(projectId && zoneId),
  });
}

export function useZoneMutation({ projectId }: { projectId: number }) {
  const queryClient = useQueryClient();
  const createZone = useMutation(
    ({ data }: Omit<Parameters<typeof api.create>[0], 'projectId'>) =>
      api.create({ projectId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.all());
      },
    },
  );

  const updateZone = useMutation(
    ({ zoneId, data }: Omit<Parameters<typeof api.update>[0], 'projectId'>) =>
      api.update({ projectId, zoneId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.all());
      },
    },
  );

  const deleteZone = useMutation(
    ({ zoneId }: Omit<Parameters<typeof api.delete>[0], 'projectId'>) =>
      api.delete({ projectId, zoneId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.list(projectId));
      },
    },
  );
  return { createZone, updateZone, deleteZone };
}
