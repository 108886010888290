import GA from 'infrastructure/ga';
import { TWO_D_TOOL } from 'infrastructure/ga/category';
import { getCheckboxLabel } from 'infrastructure/ga/label';
import { AnnotationType } from 'infrastructure/query/annotation/types';

export const TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_ALL_CBOX = `${TWO_D_TOOL}EarthworkXingLineViewAllCbox`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_BY_GRP_CBOX = `${TWO_D_TOOL}EarthworkXingLineViewbyGrpCbox`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_CBOX = `${TWO_D_TOOL}EarthworkXingLineViewCbox`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_CREATE_BTN = `${TWO_D_TOOL}EarthworkXingLineCreateBtn`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_ITEM = `s${TWO_D_TOOL}EarthworkXingLineItem`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_FILE = `${TWO_D_TOOL}EarthworkXingLineFile`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DSM_CAL_BTN = `${TWO_D_TOOL}EarthworkXingLineGrpDSMCalBtn`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DSM_RECAL_BTN = `${TWO_D_TOOL}EarthworkXingLineGrpDSMRecalBtn`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DESIGN_CAL_BTN = `${TWO_D_TOOL}EarthworkXingLineGrpDesignCalBtn`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DESIGN_RECAL_BTN = `${TWO_D_TOOL}EarthworkXingLineGrpDesignRecalBtn`;
export const TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DXF_DOWN_BTN = `${TWO_D_TOOL}EarthworkXingLineGrpDXFDownBtn`;

export const TWO_D_TOOL_ELEVATION_GRAPH_COMPARE_DATE = `${TWO_D_TOOL}ElevationGraphCompareDate`;
export const TWO_D_TOOL_ELEVATION_GRAPH_COMPARE_DATE_VIEW_TGL = `${TWO_D_TOOL}ElevationGraphCompareDateViewTgl`;
export const TWO_D_TOOL_ELEVATION_GRAPH_DOWN_BTN = `${TWO_D_TOOL}ElevationGraphDownBtn`;
export const TWO_D_TOOL_ELEVATION_GRAPH_PLAN_LEVEL = `${TWO_D_TOOL}ElevationGraphPlanLevel`;
export const TWO_D_TOOL_ELEVATION_GRAPH_PLAN_LEVEL_VIEW_TGL = `${TWO_D_TOOL}ElevationGraphPlanLevelViewTgl`;
export const TWO_D_TOOL_ELEVATION_GRAPH_REF_DATE = `${TWO_D_TOOL}ElevationGraphRefDate`;
export const TWO_D_TOOL_ELEVATION_GRAPH_REF_DATE_VIEW_TGL = `${TWO_D_TOOL}ElevationGraphRefDateViewTgl`;
export const TWO_D_TOOL_ELEVATION_GRAPH_STRATUM = `${TWO_D_TOOL}ElevationGraphStratum`;
export const TWO_D_TOOL_ELEVATION_GRAPH_STRATUM_VIEW_TGL = `${TWO_D_TOOL}ElevationGraphStratumViewTgl`;

export const TWO_D_TOOL_POINT_ADD_BTN = `${TWO_D_TOOL}PointAddBtn`;
export const TWO_D_TOOL_POINT_FILE_ADD_FOLDER_BTN = `${TWO_D_TOOL}PointFileAddFolderBtn`;
const TWO_D_TOOL_POINT_LINK_BTN = `${TWO_D_TOOL}PointLinkBtn`;
export const TWO_D_TOOL_POINT_DEL_MENU = `${TWO_D_TOOL}PointDelMenu`;
export const TWO_D_TOOL_POINT_LIST = `${TWO_D_TOOL}PointList`;
export const TWO_D_TOOL_POINT_PANEL_DEL_BTN = `${TWO_D_TOOL}PointPanelDelBtn`;
export const TWO_D_TOOL_POINT_DEL_MODAL_BTN = `${TWO_D_TOOL}PointDelModalBtn`;
export const TWO_D_TOOL_POINT_PANEL_DESC_FLD = `${TWO_D_TOOL}PointPanelDescFld`;
export const TWO_D_TOOL_POINT_PANEL_ORIG_IMG_THUMB = `${TWO_D_TOOL}PointPanelOrigImgThumb`;
export const TWO_D_TOOL_POINT_PANEL_VIEW_CBOX = `${TWO_D_TOOL}PointPanelViewCbox`;
export const TWO_D_TOOL_POINT_VIEW_ALL_CBOX = `${TWO_D_TOOL}PointViewAllCbox`;
export const TWO_D_TOOL_POINT_VIEW_CBOX = `${TWO_D_TOOL}PointViewCbox`;
export const TWO_D_TOOL_POINT_FOLDER_ADD_BTN = `${TWO_D_TOOL}PointFolderAddBtn`;
export const TWO_D_TOOL_POINT_NAME_EDIT = `${TWO_D_TOOL}PointNameEdit`;
export const TWO_D_TOOL_POINT_DRAG = `${TWO_D_TOOL}PointDrag`;
export const TWO_D_TOOL_POINT_MOVE_BTN = `${TWO_D_TOOL}PointMoveBtn`;
export const TWO_D_TOOL_POINT_SEL_BTN = `${TWO_D_TOOL}PointSelBtn`;

export const TWO_D_TOOL_POLYGON_ADD_BTN = `${TWO_D_TOOL}PolygonAddBtn`;
export const TWO_D_TOOL_POLYGON_FILE_ADD_FOLDER_BTN = `${TWO_D_TOOL}PolygonFileAddFolderBtn`;
const TWO_D_TOOL_POLYGON_LINK_BTN = `${TWO_D_TOOL}PolygonLinkBtn`;
export const TWO_D_TOOL_POLYGON_DEL_MENU = `${TWO_D_TOOL}PolygonDelMenu`;
export const TWO_D_TOOL_POLYGON_LIST = `${TWO_D_TOOL}PolygonList`;
export const TWO_D_TOOL_POLYGON_PANEL_DEL_BTN = `${TWO_D_TOOL}PolygonPanelDelBtn`;
export const TWO_D_TOOL_POLYGON_DEL_MODAL_BTN = `${TWO_D_TOOL}PolygonDelModalBtn`;
export const TWO_D_TOOL_POLYGON_PANEL_DESC_FLD = `${TWO_D_TOOL}PolygonPanelDescFld`;
export const TWO_D_TOOL_POLYGON_PANEL_MEASURE_BTN = `${TWO_D_TOOL}PolygonPanelMeasureBtn`;
export const TWO_D_TOOL_POLYGON_PANEL_VIEW_CBOX = `${TWO_D_TOOL}PolygonPanelViewCbox`;
export const TWO_D_TOOL_POLYGON_VIEW_ALL_CBOX = `${TWO_D_TOOL}PolygonViewAllCbox`;
export const TWO_D_TOOL_POLYGON_VIEW_CBOX = `${TWO_D_TOOL}PolygonViewCbox`;
export const TWO_D_TOOL_POLYGON_FOLDER_ADD_BTN = `${TWO_D_TOOL}PolygonFolderAddBtn`;
export const TWO_D_TOOL_POLYGON_NAME_EDIT = `${TWO_D_TOOL}PolygonNameEdit`;
export const TWO_D_TOOL_POLYGON_DRAG = `${TWO_D_TOOL}PolygonDrag`;
export const TWO_D_TOOL_POLYGON_MOVE_BTN = `${TWO_D_TOOL}PolygonMoveBtn`;
export const TWO_D_TOOL_POLYGON_SEL_BTN = `${TWO_D_TOOL}PolygonSelBtn`;
const TWO_D_TOOL_DRAWING_POLYGON_ADD_BTN = `${TWO_D_TOOL}DrawingPolygonAddBtn`;
const TWO_D_TOOL_DRAWING_POLYGON_FILE = `${TWO_D_TOOL}DrawingPolygonFile`;
const TWO_D_TOOL_DRAWING_POLYGON_ITEM = `${TWO_D_TOOL}DrawingPolygonItem`;
const TWO_D_TOOL_DRAWING_POLYGON_CREATE_TABLE_BTN = `${TWO_D_TOOL}DrawingPolygonCreateTableBtn`;

export const TWO_D_TOOL_POLYLINE_ADD_BTN = `${TWO_D_TOOL}PolylineAddBtn`;
export const TWO_D_TOOL_POLYLINE_FILE_ADD_FOLDER_BTN = `${TWO_D_TOOL}PolylineFileAddFolderBtn`;
const TWO_D_TOOL_POLYLINE_LINK_BTN = `${TWO_D_TOOL}PolylineLinkBtn`;
export const TWO_D_TOOL_POLYLINE_DEL_MENU = `${TWO_D_TOOL}PolylineDelMenu`;
export const TWO_D_TOOL_POLYLINE_LIST = `${TWO_D_TOOL}PolylineList`;
export const TWO_D_TOOL_POLYLINE_PANEL_DEL_BTN = `${TWO_D_TOOL}PolylinePanelDelBtn`;
export const TWO_D_TOOL_POLYLINE_DEL_MODAL_BTN = `${TWO_D_TOOL}PolylineDelModalBtn`;
export const TWO_D_TOOL_POLYLINE_PANEL_DESC_FLD = `${TWO_D_TOOL}PolylinePanelDescFld`;
export const TWO_D_TOOL_POLYLINE_PANEL_ELEVATION_GRAPH_TGL = `${TWO_D_TOOL}PolylinePanelElevationGraphTgl`;
export const TWO_D_TOOL_POLYLINE_PANEL_VIEW_CBOX = `${TWO_D_TOOL}PolylinePanelViewCbox`;
export const TWO_D_TOOL_POLYLINE_VIEW_ALL_CBOX = `${TWO_D_TOOL}PolylineViewAllCbox`;
export const TWO_D_TOOL_POLYLINE_VIEW_CBOX = `${TWO_D_TOOL}PolylineViewCbox`;
export const TWO_D_TOOL_POLYLINE_FOLDER_ADD_BTN = `${TWO_D_TOOL}PolylineFolderAddBtn`;
export const TWO_D_TOOL_POLYLINE_NAME_EDIT = `${TWO_D_TOOL}PolylineNameEdit`;
export const TWO_D_TOOL_POLYLINE_DRAG = `${TWO_D_TOOL}PolylineDrag`;
export const TWO_D_TOOL_POLYLINE_MOVE_BTN = `${TWO_D_TOOL}PolylineMoveBtn`;
export const TWO_D_TOOL_POLYLINE_SEL_BTN = `${TWO_D_TOOL}PolylineSelBtn`;

export const sendEvent2DTool = (action: string, label?: string) => {
  GA.event(TWO_D_TOOL, action, label);
};
export const sendTwoDToolPointLinkBtn = () => GA.event(TWO_D_TOOL, TWO_D_TOOL_POINT_LINK_BTN);
export const sendTwoDToolPolylineLinkBtn = () => GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYLINE_LINK_BTN);
export const sendTwoDToolPolygonLinkBtn = () => GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYGON_LINK_BTN);

export const sendTwoDToolPanelDelBtn = (type: AnnotationType) => {
  switch (type) {
    case 'points':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POINT_PANEL_DEL_BTN);
      break;
    case 'polylines':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYLINE_PANEL_DEL_BTN);
      break;
    case 'polygons':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYGON_PANEL_DEL_BTN);
      break;
    default:
      break;
  }
};
export const sendTwoDToolDelModalBtn = (type: AnnotationType, eventLabel: 'Unlink' | 'Del') => {
  switch (type) {
    case 'points':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POINT_DEL_MODAL_BTN, eventLabel);
      break;
    case 'polylines':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYLINE_DEL_MODAL_BTN, eventLabel);
      break;
    case 'polygons':
      GA.event(TWO_D_TOOL, TWO_D_TOOL_POLYGON_DEL_MODAL_BTN, eventLabel);
      break;
    default:
      break;
  }
};

export const sendEarthworkXingLineGrpDsmCalBtn = (recalculation = false) => {
  sendEvent2DTool(
    recalculation
      ? TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DSM_RECAL_BTN
      : TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DSM_CAL_BTN,
  );
};
export const sendEarthworkXingLineGrpDesignCalBtn = (recalculation = false) => {
  sendEvent2DTool(
    recalculation
      ? TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DESIGN_RECAL_BTN
      : TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DESIGN_CAL_BTN,
  );
};
export const sendTwoDToolEarthworkXingLineGrpDxfDownBtn = () => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_GRP_DXF_DOWN_BTN);
};
export const sendTwoDToolEarthworkXingLineItem = () => {
  /**원래 의도와 다르게 이벤트 적용 되어 있어 이름 변경함, 2DToolEarthworkXingLineGrp -> s2DToolEarthworkXingLineItem  */
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_ITEM);
};
export const sendTwoDToolEarthworkXingLineFile = () => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_FILE);
};
export const sendTwoDToolEarthworkXingLineViewByGrpCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_BY_GRP_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolEarthworkXingLineViewAllCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_ALL_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolEarthworkXingLineViewCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_VIEW_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolEarthworkXingLineCreateBtn = () => {
  sendEvent2DTool(TWO_D_TOOL_EARTHWORK_XING_LINE_CREATE_BTN);
};

export const sendTwoDToolPolygonItem = (resource?: 'drawing' | '') => {
  resource === 'drawing'
    ? sendEvent2DTool(TWO_D_TOOL_DRAWING_POLYGON_ITEM)
    : sendEvent2DTool(TWO_D_TOOL_POLYGON_LIST);
};
export const sendTwoDToolPolygonDrawingFile = () => {
  sendEvent2DTool(TWO_D_TOOL_DRAWING_POLYGON_FILE);
};
export const sendTwoDToolPolygonDrawingPolygonAddBtn = () => {
  sendEvent2DTool(TWO_D_TOOL_DRAWING_POLYGON_ADD_BTN);
};
export const sendTwoDToolPolygonDrawingPolygonCreateTableBtn = () => {
  sendEvent2DTool(TWO_D_TOOL_DRAWING_POLYGON_CREATE_TABLE_BTN);
};

export const sendTwoDToolPolygonViewCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POLYGON_VIEW_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolPolygonViewAllCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POLYGON_VIEW_ALL_CBOX, getCheckboxLabel(checked));
};

export const sendTwoDToolPolylineViewCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POLYLINE_VIEW_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolPolylineViewAllCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POLYLINE_VIEW_ALL_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolPolylineList = () => {
  sendEvent2DTool(TWO_D_TOOL_POLYLINE_LIST);
};

export const sendTwoDToolPointViewAllCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POINT_VIEW_ALL_CBOX, getCheckboxLabel(checked));
};
export const sendTwodToolPointViewCbox = (checked: boolean) => {
  sendEvent2DTool(TWO_D_TOOL_POINT_VIEW_CBOX, getCheckboxLabel(checked));
};
export const sendTwoDToolPointList = () => {
  sendEvent2DTool(TWO_D_TOOL_POINT_LIST);
};
