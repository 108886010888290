import IconCompassActiveCustom from 'assets/images/iconCompassActiveCustom.svg';
import IconCompassCustom from 'assets/images/iconCompassCustom.svg';
import LanguageIcon from 'assets/images/icon_language.svg';
import {
  DEFAULT_2D_MAP_STYLES,
  DEFAULT_CENTER,
  DEFAULT_ZOOM_LEVEL,
  MAX_ZOOM,
} from 'common/policies/map';
import { leafletCreatingBulkPolygonsStyle } from 'components/snapshot/dtmEditorPage/utils';
import 'leaflet-rotate';
import React from 'react';
import { AttributionControl, MapContainer, MapContainerProps } from 'react-leaflet';
import styled from 'styled-components';
import { others } from 'components/styles/colors/others';
import { COLOR_CHIP } from 'components/styles/colors/symbols';
import theme from 'components/styles/mui/theme';
import typography from 'components/styles/mui/typography';
import { tooltipTextStyle } from 'components/styles/text/tooltip';
import RotationInputControl from '../twodmap/RotationInputControl';
import useMapRotate from './useMapRotate';

/** @todo: map rotate 관련 타이핑 오류 수정 필요 */
interface Props extends MapContainerProps {
  rotate?: boolean;
  showRotationControl?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export default function Map({
  rotate = false,
  showRotationControl = true,
  children,
  ...props
}: Props) {
  const { ready, mapOptions } = useMapRotate(rotate, showRotationControl);

  if (rotate && !ready) return null;
  return (
    <Wrapper id="2d-map-container-wrapper" $rotate={rotate}>
      <MapContainer
        center={DEFAULT_CENTER}
        zoom={DEFAULT_ZOOM_LEVEL}
        style={DEFAULT_2D_MAP_STYLES}
        attributionControl={false}
        doubleClickZoom={false}
        maxZoom={props.maxZoom || MAX_ZOOM}
        {...(mapOptions as any)}
        {...props}
      >
        <AttributionControl position="bottomleft" />
        {children}
        {rotate && <RotationInputControl hidden={!showRotationControl} />}
      </MapContainer>
    </Wrapper>
  );
}

/** @todo: 한 도메인에서만 사용되는 스타일 정의는 분리해서 관리 할 수 있도록 수정 필요 */
const Wrapper = styled.div<{ $rotate?: boolean }>`
  img {
    image-rendering: pixelated;
  }
  position: relative;
  width: 100%;
  height: 100%;

  .leaflet-control-rotate {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .leaflet-bottom.leaflet-right {
    display: flex;
    margin-bottom: 0 !important;
    z-index: ${theme.zIndex.snackbar + 1};
  }

  .leaflet-control-attribution {
    background-color: transparent;
  }

  .leaflet-control-scale {
    background: ${others.BACKDROP};
    margin-bottom: 0;
    margin-right: 0;
    padding-left: 2rem;
    padding: 0.1875rem 0 0.1875rem 2rem;
    &.text {
      padding-left: 0.5rem;
      padding-right: 1rem;
    }
  }

  .leaflet-bottom.leaflet-right > *:nth-last-child(2) > .leaflet-control-scale-line {
    color: transparent;
    background: transparent;
    border-color: ${theme.palette.background.default};
    height: 0.5rem;
    margin: 0.25rem 0;
    text-shadow: none;
  }
  .leaflet-bottom.leaflet-right > *:last-child > .leaflet-control-scale-line {
    border: none;
    background: transparent;
    color: ${theme.palette.background.default};
    margin-top: 0.1875rem;
    width: auto !important;
    text-shadow: none;
  }

  .leaflet-control-coordinate {
    padding: 0.1875rem 0 0.1875rem 1rem;
    background: ${others.BACKDROP};
    color: ${theme.palette.background.default};
    font-size: ${theme.typography.caption.fontSize};
    font-weight: ${theme.typography.caption.fontWeight};
    margin-right: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    white-space: pre;

    &::before {
      content: '';
      background-image: url(${LanguageIcon});
      background-size: 1rem 1rem;
      width: 1rem;
      height: 1rem;
      display: flex;
      margin-right: 0.5rem;
    }
  }
  .leaflet-text-icon {
    display: none;
    font-size: 0.8125rem;
    font-weight: 700;
    white-space: nowrap;
  }
  .show-text-icon {
    display: block;
  }
  .leaflet-touch .leaflet-bar {
    border: none;
    a {
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
      border: none;
    }
  }

  .gmnoprint {
    &.gm-style-cc > div:nth-child(1) > div:nth-child(2),
    .gm-style-cc > div:nth-child(1) > div:nth-child(2) {
      background-color: transparent !important;
    }
  }
  .leaflet-control-zoom {
    display: none;
  }
  .leaflet-control-rotate {
    display: ${({ $rotate }) => ($rotate ? 'block' : 'none')};
    position: relative;

    .leaflet-control-rotate-toggle-tooltip {
      position: absolute;
      left: 3.75rem;
      top: 0.25rem;
      z-index: 1000;
      background-color: ${theme.palette.grey[900]};
      color: ${theme.palette.primary.contrastText};
      display: none;
      min-width: 18rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      white-space: pre-line;
      ${tooltipTextStyle};

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-width: 0.375rem;
        border-style: solid;
        border-color: transparent ${theme.palette.grey[900]} transparent transparent;
      }
    }
    :hover .leaflet-control-rotate-toggle-tooltip,
    :active .leaflet-control-rotate-toggle-tooltip,
    :focus .leaflet-control-rotate-toggle-tooltip {
      display: flex;
    }

    .leaflet-control-rotate-arrow {
      background-size: 2rem;
      background-image: url(${IconCompassCustom}) !important;
    }

    .leaflet-control-rotate-toggle {
      background-color: ${theme.palette.background.paper} !important;

      &:hover {
        background-color: #d2d5d7 !important;
      }
      &:active,
      &:focus {
        background-color: ${theme.palette.primary.main} !important;

        .leaflet-control-rotate-arrow {
          background-image: url(${IconCompassActiveCustom}) !important;
        }
      }
    }
  }
  .issue-tooltip {
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: ${typography.subtitle2.fontSize};
    font-weight: ${typography.subtitle2.fontWeight};
    line-height: ${typography.subtitle2.lineHeight};
    color: ${theme.palette.text.primary};
    ::before {
      display: none;
    }
  }
  .preview-popup {
    /* CCTV 플레이어가 window 사이즈에 유연하게 대응이 되지 않아 px로 고정 */

    .leaflet-popup-content-wrapper {
      border-radius: 4px;
      background-color: ${theme.palette.text.primary};
      .leaflet-popup-content {
        margin: 16px;
      }
    }
    .leaflet-popup-tip-container {
      display: none;
    }

    &.cctv .leaflet-popup-content-wrapper {
      border: 2px solid ${theme.palette.primary.main};
    }
    &.panorama .leaflet-popup-content-wrapper {
      border: 2px solid ${COLOR_CHIP.BLUE};
    }
  }
  .leaflet-popup-hazard-area-polygon {
    &.leaflet-popup {
      -moz-transition: opacity 0.1s linear;
      transition: opacity 0.1s linear;
    }
    .leaflet-popup-content-wrapper {
      border-radius: 0.5rem;
    }
    .leaflet-popup-content {
      min-width: 11.875rem;
      margin: 1rem;
      margin-right: 2rem;
    }
    a.leaflet-popup-close-button {
      padding: 1rem 1.875rem 0 0;
    }
  }

  .leaflet-editable-polygon-popup {
    z-index: 650;
    margin: 0;
    cursor: pointer;
    .leaflet-popup-content-wrapper {
      display: inline-flex;
      padding: 0.25rem 0.5rem;
      align-items: center;
      text-align: center;
      border-radius: 0rem;
      border-radius: 0.25rem;
      background: ${theme.palette.text.primary};
      .leaflet-popup-content {
        margin: 0rem;
      }
    }
    .leaflet-popup-tip-container {
      display: none;
    }
  }
  ${leafletCreatingBulkPolygonsStyle}
`;
