import { ANNOTATION_COLOR_CHIP, COLOR_CHIP } from 'components/styles/colors/symbols';
import theme from 'components/styles/mui/theme';

export const DEFAULT_ANNOTATION_COLOR = ANNOTATION_COLOR_CHIP.PURPLE;
export const DEFAULT_DEM_OPACITY = 0.8;
export const DEFAULT_DSM_OPACITY = 0.8;
export const DTM_EDITOR_DSM_OPACITY = 0.6;
export const DEFAULT_PLAN_LEVEL_OPACITY = 0.8;
export const MANUAL_DRAWING_OPACITY = 0.6;
export const VECTOR_DRAWING_OPACITY = 0.85;
export const RASTER_DRAWING_OPACITY = 1.0;
export const VECTOR_DRAWING_WEIGHT = 2;

export const ANNOTATION_POLYGON_MAX_VERTEX = 300;

/**
 * 2D 지도상에 쌓이는 레이어의 zIndex 정의
 * TwoDMap.tsx의 pane css와도 관련
 * @link - https://www.notion.so/3f92a11d9275412a806c6ecca73cc01c
 */
export const Z_INDEX = {
  SATELLITE: 0,
  ORTHOPHOTO: 10,
  CHANGE_DETECTION_OVERLAY: 15,
  DSM: 20,
  NDVI: 25,
  VARI: 25,
  PLAN_LEVEL: 30,
  NDVI_SEGMENTATION: 30,
  NDMI_SEGMENTATION: 30,
  THERMAL: 30,
  CADASTRAL: 40,
  DRAWING: 50,
  CADASTRAL_PARCEL: 60,
};

// Vector Layer Style - 지도도구, 위험구역 등

export const HAZARD_AREA_COLOR = theme.palette.error.dark;
export const HAZARD_AREA_END_STROKE_COLOR = theme.palette.grey[700];
export const HAZARD_AREA_END_FILL_COLOR = theme.palette.grey[600];
export const HAZARD_AREA_WEIGHT = 4;
export const HAZARD_AREA_DASH_ARRAY = [20, 10];

export const DEFAULT_PATH_WEIGHT = 3;
export const DEFAULT_PATH_DASH_ARRAY = [];
export const DEFAULT_PATH_DASH_OFFSET = '0';
export const DEFAULT_EDITING_PATH_DASH_ARRAY = [4, 4];
export const DEFAULT_EDITING_PATH_DASH_OFFSET = '16';
export const DEFAULT_NEW_POSITION_ID = -1;
export const DEFAULT_PATH_COLOR = theme.palette.error.main;

export const DEFAULT_CIRCLE_MARKER_RADIUS = 10;
export const DEFAULT_CIRCLE_CENTER = { lat: 0, lng: 0 };

export const RUNNING_ISSUE_ICON_COLOR = COLOR_CHIP.ORANGE;
export const DONE_ISSUE_ICON_COLOR = COLOR_CHIP.GREY;

export const NDVI_HISTOGRAM_FRACTION_DIGITS = 3;
export const NDMI_HISTOGRAM_FRACTION_DIGITS = 3;
export const THERMAL_HISTOGRAM_FRACTION_DIGITS = 3;
export const DSM_HISTOGRAM_FRACTION_DIGITS = 0;

export const NDVI_OPACITY = 1;
export const NDMI_OPACITY = 1;
export const THERMAL_OPACITY = 1;

export const ISSUE_POLYGON = {
  COLOR: { RUNNING: COLOR_CHIP.ORANGE, DONE: COLOR_CHIP.GREY },
  DIMMED_BACKGROUND_COLOR: theme.palette.text.primary,
  DIMMED_OPACITY: 0.65,
};
