/* eslint-disable no-template-curly-in-string */
export default {
  add: '追加',
  cancel: 'キャンセル',
  close: '閉じる',
  delete: '削除',
  modified: '編集',
  done: '完了',
  confirm: '確認',
  home: 'ホーム',
  project: 'プロジェクト',
  myInfo: 'プロフィール',
  manual: 'マニュアル',
  notice: 'お知らせ',
  logout: 'ログアウト',
  save: '保存',
  downloadButton: 'ダウンロード',
  logoutTitle: 'ログアウト確認',
  logoutMessage: 'ログアウトしますか？',
  edited: '編集済み',
  refresh: '再読み込み',
  addNew: '新規追加',
  reset: '初期化',
  warningBrowser:
    '推奨環境以外で利用された場合、機能が正常に動作しない可能性があります。推奨ブラウザをご利用ください。',
  dontShowAgain: '次回表示しない',
  total: '全体',
  select: '選択',
  twoDMapButton: '2D',
  threeDMapButton: '3D',
  back: '戻る',
  copy: 'コピー',
  change: '変更',
  stopButton: '中止',
  continuedButton: '続ける',
  nextButton: '次へ',
  prevButton: '戻る',
  submitButton: '送信',
  skip: 'スキップ',
  retry: '再試行',
  next: '次へ',
  noPermissionMsgContent: 'この機能を利用する権限がありません。',
  titleExitWithoutSaving: '変更を確認する',
  exitWithoutSaving: '変更を保存せずにこのページから移動しますか？',
  textFeedbackErrorMessage: 'エラーが発生しました。もう一度やり直してください。',
  textFeedbackSuccessMessage: '変更内容が保存されました。',
  textFeedbackLoadingMessage: 'しばらくお待ちください。',
  textFeedbackWarningMessage: '警告',
  noticeTitle: 'お知らせ',
  noticeNewTitle: '新機能',
  noticeErrorTitle: '警告',
  noticeDontShowAgainButton: '次回表示しない',
  noticeDontShowAgainMessage:
    'このメッセージをもう一度確認したい場合は、左側のお知らせアイコンをクリックしてください！',
  noticeDisableMessage: '一日表示しない',
  moreInformation: 'もっと見る',
  downloadChromeBrowser: 'Chromeをダウンロードする',
  BIM: 'BIM',
  currentDsm: '地盤面の高さ',
  loginTitle: 'ログイン',
  password: 'パスワード',
  inputEmail: 'メールアドレスを入力してください',
  inputPw: 'パスワードを入力してください',
  checkPwLength: '英文、数字を混ぜて最低8文字以上入力してください。',
  findPassword: 'パスワードをお忘れですか？',
  email: 'Eメール',
  oneStopService: 'DPISドローン情報基盤システム2.0',
  goBack: '戻る',
  generateTempPwButton: '仮パスワード発行',
  generateTempPwDescription:
    'ユーザー登録時のメールアドレスを入力してください。登録の有無を確認後に仮パスワードを発行いたします。',
  sentEmail: '仮パスワードを送信しました。',
  myPageTitle: 'マイプロフィール',
  myPageEditTitle: 'プロフィール編集',
  phPassword: 'パスワードを入力してください',
  name: '名前',
  emailAddress: 'Eメール',
  currentPassword: '現在のパスワード',
  newPassword: '新しいパスワード',
  confirmPassword: 'パスワード確認',
  confirmNewPassword: '新しいパスワードを確認',
  company: '会社',
  jobTitle: '役職',
  phName: '名前を入力してください',
  phCompany: '会社名を入力してください',
  phJobTitle: '役職を入力してください',
  phCurrentPassword: '現在のパスワードを入力してください',
  phNewPassword: '新しいパスワードを入力してください',
  phConfirmPassword: 'パスワードを再度入力してください',
  phConfirmNewPassword: '新しいパスワードを再度入力してください',
  requiredName: '名前を入力してください',
  requiredCompany: '会社名を入力してください',
  requiredPassword: 'パスワードを入力してください',
  requiredConfirmPassword: 'パスワードを再度入力してください',
  requiredConfirmNewPassword: '新しいパスワードを再度入力してください',
  notEqualPassword: 'パスワードが一致しません',
  notEqualNewPassword: '新しいパスワードが一致しません',
  minLength: '${n}文字以上で入力してください',
  completedMyInfoEditing: '変更内容を保存しました',
  confirmSecurityTitle: 'セキュリティーを確認',
  confirmSecurityMessage:
    '変更内容を保存します。\nセキュリティ確認のために現在のパスワードを入力してください。',
  latlng: '経緯度(EPSG 4326)',
  eastDatum: 'GRS80 東部(EPSG 5187)',
  eastSeaDatum: 'GRS80 東海(EPSG 5188)',
  centralDatum: 'GRS80 中部(EPSG 5186)',
  westDatum: 'GRS80 西部(EPSG 5185)',
  eastDatumBessel: 'Bessel 東部(EPSG 5176)',
  eastSeaDatumBessel: 'Bessel 東海(EPSG 5177)',
  centralDatumBessel: 'Bessel 中部(EPSG 5174)',
  westDatumBessel: 'Bessel 西部(EPSG 5173)',
  epsg6669: '1系 EPSG:6669(JGD2011)',
  epsg6670: '2系 EPSG:6670(JGD2011)',
  epsg6671: '3系 EPSG:6671(JGD2011)',
  epsg6672: '4系 EPSG:6672(JGD2011)',
  epsg6673: '5系 EPSG:6673(JGD2011)',
  epsg6674: '6系 EPSG:6674(JGD2011)',
  epsg6675: '7系 EPSG:6675(JGD2011)',
  epsg6676: '8系 EPSG:6676(JGD2011)',
  epsg6677: '9系 EPSG:6677(JGD2011)',
  epsg6678: '10系 EPSG:6678(JGD2011)',
  epsg6679: '11系 EPSG:6679(JGD2011)',
  epsg6680: '12系 EPSG:6680(JGD2011)',
  epsg6681: '13系 EPSG:6681(JGD2011)',
  epsg6682: '14系 EPSG:6682(JGD2011)',
  epsg6683: '15系 EPSG:6683(JGD2011)',
  epsg6684: '16系 EPSG:6684(JGD2011)',
  epsg6685: '17系 EPSG:6685(JGD2011)',
  epsg6686: '18系 EPSG:6686(JGD2011)',
  epsg6687: '19系 EPSG:6687(JGD2011)',
  selectEtc: '直接入力',
  phToSelectCoordSystem: '座標系を選択してください。',
  phToCoordSystem: '座標系を入力してください。',
  phToValidCoordinate: '正しくない座標系です。',
  grade: 'グレード',
  status: 'ステータス',
  ownerTitle: '最高管理者',
  ownerDesc: '管理者指定および全機能の使用',
  managerTitle: '管理者',
  managerDesc: '一般ユーザーの指定および現場状況関連機能の使用',
  userTitle: '一般ユーザー',
  userDesc: 'ゲストの指定および測量ツールの使用',
  guestTitle: 'ゲスト',
  guestDesc: '参加者の確認および現場状況を表示',
  pauseTitle: 'ユーザーを停止',
  pauseDesc: '全ての機能を停止',
  joinStatus: '参加中',
  deleteUserTitle: '参加者を削除',
  cancelDeleteMode: '退出する',
  deleteUser: 'このプロジェクトから${n}名の参加者を削除しますか？',
  failedToDeleteUser: 'エラーが発生し、削除できませんでした。\nもう一度お試しください。',
  changeGradeTitle: 'グレードの変更を確認',
  changeGrade: 'このアカウントのグレードを\n ${n}に変更しますか？',
  failedToChangeGrade: 'グレードの変更に失敗しました。\nもう一度お試しください。',
  invalidEmailFormatAlert: 'メールアドレスが正しくありません。\nもう一度入力してください。',
  inviteParticipant: '参加者を招待',
  invite: '招待',
  invited: '参加待機中',
  invitationTimestamp: '${n}に招待する',
  joinTimestamp: '${n}に参加する',
  participantGradeTooltipHeader: '参加者グレード別の権限',
  noMemberSelected: '選択したユーザーがいません。',
  noInviteesMessage: '「招待」ボタンを押して、参加者を招待してください。',
  sendInvitation: '招待メールを送信',
  sendInvitationLoadingMessage: 'しばらくお待ちください。',
  sendInvitationSuccessMessage: '招待メールを送信しました。',
  sendInvitationErrorMessage: '招待メールの送信に失敗しました。',
  resendInvitation: '招待メールを再送',
  resendInvitationSuccess: '招待メールを再送しました。',
  resendInvitationErrorTitle: '招待メールの再送エラー',
  resendInvitationErrorMessage: '招待メール再送中にエラーが発生しました。\n再試行しますか？',
  resendInvitationBlocked: '1分以内の再送信はできません。\nしばらくしてから再試行してください。',
  emailEmptyError: 'メールアドレスを入力してください。',
  emailFormatError: '間違ったメールアドレスです。再度入力してください。',
  emailAlreadyInvited: '既に招待したユーザーです。',
  emailAlreadyUser: '既に参加しているユーザーです。',
  gradeEmptyError: 'グレードを選択してください。',
  projectCardSnapshotNumber: '現場数:',
  projectCardLatestSnapshot: '最近の撮影日',
  mapSideMenuAccordionGroupTitle: '地図',
  measurementSideMenuAccordionGroupTitle: '測量',
  layerSideMenuAccordionGroupTitle: 'レイヤー',
  mediaSideMenuAccordionGroupTitle: 'メディア',
  issueSideMenuAccordionGroupTitle: '作業管理',
  safetySideMenuAccordionGroupTitle: '安全管理',
  gcpAccordionGoToEditorButtonTitle: 'GCPマークを確認',
  measurementSideMenuAccordionGroupTooltipMessage:
    '地図にツールを追加して周辺のコンテンツを確認し、\n距離や高度をもとに計算できます。',
  layerSideMenuAccordionGroupTooltipMessage:
    '地図に被せて確認できるデータであり、\n透明度を調整したり、土地補償状態を変更できます。',
  fileArchiveEmptyMessage: 'ファイルがありません。\n現場状況詳細にファイルを追加してください。',
  fileArchiveSearchEmptyMessage: '検索結果が見つかりませんでした。\n他のワードを検索してください。',
  fileArchiveSearchPlaceholder: 'ファイル名検索',
  fileArchiveTableHeaderZone: '区域',
  fileArchiveTableHeaderFileName: 'ファイル名',
  fileArchiveTableHeaderType: 'タイプ',
  fileArchiveTableHeaderDate: '作成日',
  fileArchiveTableHeaderDownload: 'ダウンロード',
  fileArchiveTableHeaderDelete: '削除',
  fileArchiveTableHeaderSelect: '選択する',
  fileArchiveTypeChain: '土工横断線',
  fileArchiveTypePoint: '地点ツール',
  fileArchiveTypePolyline: '線分ツール',
  fileArchiveTypePolygon: '面積ツール',
  fileArchiveTypePlanLevel: '計画高',
  fileArchiveTypeBim: 'BIM',
  fileArchiveTypeStratum: '地盤',
  fileArchiveTypeDrawingRaster: '自動ベクター図面',
  fileArchiveTypeDrawingVector: '自動ラスター図面',
  fileArchiveTypeDrawingPositioning: '手動配置図面',
  fileArchiveTypePolygonVector: '図面面積',
  fileArchiveDrawingDownloadTitle: '図面ダウンロード',
  fileArchiveDrawingDownloadDesc: 'ファイルを圧縮してください。',
  fileArchiveDrawingDownloadDescLoading: 'ファイルを圧縮しています。しばらくお待ちください。',
  fileArchiveDrawingDownloadDescSuccess: 'ダウンロードの準備ができました。',
  fileArchiveDrawingDownloadButton: 'ファイルを圧縮する',
  fileArchiveDrawingDownloadButtonSuccess: 'ダウンロード',
  fileArchiveDrawingDownloadCancelTitle: 'ファイルの圧縮キャンセル',
  fileArchiveDrawingDownloadCancelDesc:
    'ファイルの圧縮が完了していません。\nこのまま終了しますか？',
  fileArchiveDrawingDownloadCancelButton: '続ける',
  fileArchiveDrawingDownloadCancelConfirm: '退出する',
  exportTitle: 'ダウンロード',
  idleTitle: '待機',
  runningTitle: '分析中',
  errorTitle: 'エラー',
  doneTitle: '完了',
  drawingConfigTitle: '図面設定',
  drawingFirstStep:
    '図面をズームイン/アウトして、位置区分が可能な場所にマーカーを移動させてください。(1/2)',
  drawingSecondStep: 'マーカーをドラッグし、図面に沿って設定してください。(2/2)',
  drawingConfigTitleWithoutSaving: '図面設定をキャンセル',
  drawingConfigMessageWithoutSaving:
    'このまま終了すると設定した図面位置が保存されません\n作業を中断しますか？',
  drawingConfigNextButton: '次へ',
  drawingConfigPrevButton: '戻る',
  drawingConfigClear: 'リセット',
  drawingConfigClose: '閉じる',
  drawingConfigSubmit: '完了',
  areaExitTooltip: '右クリックまたは\nESCキーを押すと完了します。',
  areaExitTooltipTouchDevice: '「完了」ボタンを押すと作成されます。',
  sizeOriginal: '1倍率',
  sizeDouble: '2倍率',
  sizeTriple: '3倍率',
  sizeFourTimes: '4倍率',
  sizeFiveTimes: '5倍率',
  meterValue: '${n}m',
  squareMeterValue: '${n}m²',
  noInformationAnnotationCreator: 'ユーザー情報なし',
  deletedAnnotationCreator: '削除されたユーザー',
  emptyAnnotationCreator: '2023年4月20日以前のデータには\nユーザー情報がありません。',
  resourceToAnnotationProgressRunningChip: '分析中',
  resourceToAnnotationDeleteMessage:
    '${n}ファイルを削除する場合、全ての現場状況から完全に削除されます。本当に削除しますか？',
  resourceToAnnotationErrorMessage: 'エラーが発生しました。もう一度お試しください。',
  polygonVectorEmptyMessage: 'ファイルにポリゴンオブジェクトがないので、面積が生成されません。',
  polygonVectorUploadTitle: '図面で面積を追加',
  polygonVectorUploadDescription: 'ポリゴンオブジェクトのみ生成できます。',
  addPolygonVectorButton: '図面で追加',
  runningResourceToAnnotationTooltipMessage: '分析中のファイルには接続できません。',
  axisDataTitle: '水平距離(${n})',
  axisElevationTitle: '高度(${n})',
  cutVolumeSubTitle: '切土',
  fillVolumeSubTitle: '盛土',
  volumeSoil: '土量',
  referenceDateType: '基準日',
  compareDateType: '比較日',
  planElevationType: '計画高',
  lengthTooltip: '長さ',
  elevationTooltip: '高度比較',
  generateElevationDownload: 'DXFファイルを作成中です。\nしばらくお待ちください。',
  failedCreateElevationDownload: 'DXFファイルの作成に失敗しました。\nもう一度お試しください。',
  elevationDivEmpty: '数値表層モデルが重ならなかったため、グラフの生成に失敗しました。',
  elevationDivWarning: '数値表層モデルがない部分は、近くのデータに基づいて生成されます。',
  elevationDivError: '一時的なエラーが発生し、情報の読み取りに失敗しました。',
  cancelCreateElevationDownloadTitle: '作業をキャンセル',
  cancelCreateElevationDownload: 'DXFファイルを作成中です。\n作業を中断しますか？',
  stratumElevationNth: '地盤${n}',
  showElevationGraph: 'グラフ表示',
  elevationChartDownloadTooltip: '断面図グラフをダウンロード',
  elevationChartCloseTooltip: '閉じる',
  graphDownloadTitle: 'グラフをダウンロード',
  phToEmptyElevation: '${n} なし',
  graphSelectionDesc: 'ダウンロードするグラフの種類を選択してください。',
  createGraphButton: 'ダウンロード',
  zPositionWarningMsg: '数値表層モデルがないエリアは、Z値の読み取りができません。',
  zPositionErrorMsg: 'エラーが発生し、情報の読み取りに失敗しました。',
  etcTitle: 'その他のファイル',
  planLevelTitle: '計画高',
  snapshotHeaderCompare: '現場比較',
  snapshotHeaderEditButton: '現場状況を修正',
  basicShare: '共有',
  snapshotHeaderPrintButton: '印刷',
  snapshotHeaderDownloadButton: 'ダウンロード',
  snapshotHeaderMoreButton: 'さらに表示',
  downloadPopupTitle: '現場状況をダウンロード',
  downloadTabImageTitle: 'ドローン撮影画像',
  downloadTabOrthophotoTitle: 'オルソ画像',
  downloadTabDsmTitle: '数値表層モデル',
  downloadTabPointCloudTitle: 'ポイントクラウド',
  downloadTabMeshTitle: 'メッシュ',
  downloadTabGcpTitle: 'GCP',
  downloadTabReportTitle: '報告書',
  downloadImageName: 'Images.zip',
  downloadOrthophotoName: 'tif原本',
  downloadOrthophotoPngName: 'png高解像度',
  downloadOrthophotoSampledName: 'png低解像度',
  downloadDsmName: 'tif原本',
  downloadDsmDesc: '実際の高さ(z)を含むデータ',
  downloadDsmPngName: 'png高解像度',
  downloadDsmPngDesc: '標高をRGBカラーで視覚化',
  downloadPointCloudName: 'las原本',
  downloadPointCloudPlyName: 'ply原本',
  downloadPointCloudSampledName: 'ply低解像度',
  downloadPointCloudSampledDesc: 'ポイント数を減らしたデータ',
  downloadMeshFbxName: 'fbx原本',
  downloadMeshObjName: 'obj原本',
  downloadMeshObjDesc: 'obj、mtl、およびjpgファイルを含む',
  downloadGcpName: 'GCP',
  downloadPopupItemButton: 'ダウンロード',
  downloadArchivePopupItemButtton: '圧縮してダウンロード',
  downloadArchivePopupItemLoading: '圧縮中',
  downloadArchivePopupItemSuccess: '圧縮が完了しました。ダウンロードボタンを押してください。',
  downloadArchivePopupItemError: 'エラーが発生しました。もう一度やり直してください。',
  downloadPopupImageView: '個々の画像 (${n})',
  beta: 'Beta',
  downloadReportButton: '報告書をダウンロード',
  downloadPix4DReportLabel: 'Pix4D分析報告書',
  createReportButton: '報告書を作成',
  creatingReportButton: '報告書作成中',
  reportAnnotationInclusionModeLabel: '表示する測量ツールを選択',
  reportAnnotationExtensionLabel: '報告書の形式',
  reportCreationLoadingMessage: 'ファイルの作成中です。しばらくお待ちください。',
  reportCreationCompletedMessage: '報告書の作成が完了しました。',
  downloadEmptyViewMessage: 'ファイルがありません。',
  downloadEmptyViewAddButton: 'ファイル追加',
  downloadCreateZipFileButton: 'zipファイル作成',
  downloadLoadingZipFileButton: 'zipファイル作成中',
  downloadSuccessZipFile: 'zipファイルの作成が完了しました。',
  downloadCompensationReportComment: 'オルソ画像と用地補償状況が含まれています。',
  downloadAnnotationReportLabel: '測量ツール報告書',
  downloadCompensationReportLabel: '用地補償状況報告書',
  downloadSelectedAnnotationReportLabel: '選択された測量ツールのみを含む',
  downloadAllAnnotationReportLabel: '全ての測量ツールを含む',
  downloadSelectedAnnotationReportComment:
    'オルソ画像と現在の地図に表示された\n位置・線分・面積ツールが含まれます。',
  downloadAllAnnotationReportComment:
    'オルソ画像とアーカイブから取り込んだ全ての位置・線分・面積ツールが含まれます。',
  sharePopoverInfo:
    'リンクを使用するとログインせずにデータを確認できます。リンクを共有したい人へURLを共有してください。',
  sharePopoverCopyButton: 'コピー',
  sharePopoverCopyCompleteButton: 'コピー完了',
  deleteHistoryContent:
    '現場状況を削除すると、この現場に含まれる全ての現場状況データも削除されます。本当に削除しますか？',
  planVolumeOpacity: '不透明度',
  deleteMsg: '削除されました。',
  BimDataLoading: 'データを準備中です。しばらくお待ちください。',
  BimDataError:
    '予期せぬエラーによりファイルを読み込むことができません。削除してから再度アップロードしてください。',
  bimOnPointCloudNoLocation: 'このファイルには座標情報がありません。任意で中央に配置されます。',
  bimOnPointCloudOutOfPointCloudBound:
    'このファイルは現場状況の座標範囲を超えました。任意で中央に配置されます。',
  alertGcpWarning: '現場範囲外のGCPポイントです。',
  deletingSnapshot: '現場状況を削除',
  aerialImage: '画像',
  uploadButton: 'アップロード',
  uploadingButton: 'アップロード中',
  stratumAliasIsEmpty: '名前を入力してください。',
  stratumFileIsEmpty: 'ファイルを選択してください。',
  gcpEditorTitle: 'GCPマーク',
  gcpEditorDesc: '分析には、GCPポイントが3つ以上マークされた画像が3枚以上必要です。',
  resetGcpImageLocations: '全てリセット',
  gcpFileSubTitle: 'GCPリスト',
  gcpName: '名前',
  positionX: 'X(Easting)',
  positionY: 'y (Northing)',
  positionZ: 'Z (Altitude)',
  horizontalAccuracy: '水平精度 (${n})',
  verticalAccuracy: '垂直精度 (${n})',
  imageCount: 'マークされた画像',
  gcpImageCountDesc:
    '3Dモデルの精度のために、GCPごとに少なくとも4つ以上の画像をマークしてください。',
  settingGcpTitle: 'GCPマーカー設定',
  descriptionGcpSetting: '右クリックでマーカーを追加します。',
  gcpImageListTitle: '${n}画像リスト',
  gcpImageListDesc: '選択したGCPに近い順に整理された画像です。',
  resetGcpImagesAlertTitle: 'GCPマーカーをリセット',
  resetGcpImagesAlert:
    '${n}に設定された全てのマーカーをリセットしますか？\n自動推薦されたマーカーもリセットされます。',
  gcpMarkingDoneButton: 'マーキング完了',
  addGcpImageButton: '参考画像を追加',
  gcpImageManagePopupTitle: 'GCPの参考画像を管理',
  gcpRecommendationButton: 'GCP自動推薦',
  gcpImageTableCellTitle: '参考画像',
  gcpRecommendationInProgressMessage:
    '表示したマーカーを基に画像からGCPを検索しています。\n最大2分程度かかる場合があります。しばらくお待ちください。',
  gcpRecommendationErrorTitle: 'GCP自動推薦',
  gcpRecommendationInfoMessage:
    '推薦されたマーカー(GCP)は正確ではない場合があります。\n直接ポイントを再調整したマーカーのみ分析に使用します。',
  resetGcpMarkerLocationTooltip: '推薦された/直接マーキングした全てのマーカーが削除されます。',
  resetGcpMarkerButtonText: 'マーカーをリセット',
  gcpMarkerColorInPerson: 'マーキングが完了したGCP',
  gcpMarkerColorRecommended: 'マーキングが必要な(自動推薦)GCP',
  gcpRecommendationStopConfirmTitle: 'GCPの自動推薦をキャンセル',
  gcpRecommendationStopConfirmMessage: 'GCPを推薦中です。\n自動推薦を中断しますか？',
  gcpRecommendationStopConfirmOk: '中断する',
  gcpRecommendationStopConfirmCancel: '続ける',
  gcpRecommendationTooltipHow:
    'GCPごとに1つ以上の画像をマーキングしてください。[自動推薦]ボタンをクリック後、いくつかのGCPが一度に推薦されます。',
  gcpRecommendationTooltipWhat:
    '一度のマーキングで、残りの画像ではGCPのポイントを自動で推薦してくれます。',
  gcpRecommendationTooltipLink:
    'https://meissasaas.zendesk.com/hc/ja/articles/8113482136207-1-3-3-GCP%E4%BD%9C%E6%A5%AD%E6%96%B9%E6%B3%95',
  joinCheckBoxLabelText1: '受け入れます',
  joinCheckBoxPrivacyPolicy: 'プライバシーポリシー',
  joinCheckBoxLabelText2: 'および',
  joinCheckBoxTermsOfUse: '利用規約',
  joinCheckBoxLabelText3: 'に同意します。',
  joinCheckBoxErrorMessage: '利用規約に同意してください。',
  joinTitle: 'ユーザー登録',
  joinButton: '登録',
  joinSuccessAlertMsg:
    'ユーザー登録が完了しました。\nログインに進み、Meissa Platformをご利用ください。',
  termsOfUseUrl: 'https://meissa.notion.site/terms-and-conditions-42aeadfc19334ad38ca18d52a7aff588',
  privacyPolicyUrl: 'https://meissa.notion.site/privacy-policy-ff072616551a459b9ad76474757de20',
  projectFieldName: 'タイトル',
  projectFieldDescription: '説明',
  projectFieldCoord: '座標参照系(CRS)',
  projectFieldPeriod: '期間',
  projectFieldStatus: 'スターテス',
  projectStatusInProgress: '進行中',
  projectStatusCompleted: '完了',
  projectMapProvider: '地図',
  projectMapProviderVworld: 'Vworldマップ',
  projectMapProviderBing: 'Bingマップ',
  projectMapProviderGoogle: 'Googleマップ',
  projectFormHeadingCreating: '新しいプロジェクトを追加',
  projectFormHeadingEditing: 'プロジェクトを編集',
  projectFormSubheadingInfo: '基本情報',
  projectFormSubheadingInfoDetail: '現場詳細情報',
  projectFormSubheadingLaborerLogoutDistance: '作業者の位置によるチェックアウト',
  projectFormTitleHint: 'プロジェクト名を入力してください。',
  projectFormTitleEmptyError: 'タイトルを入力してください。',
  projectFormDescriptionHint: 'プロジェクトの説明を入力してください。',
  projectFormCoordSelectHint: '座標参照系を選択してください。',
  projectFormCoordTextHint: '数字のみ入力可能です。',
  projectFormCoordTextNoMatches: '対応していない座標参照系です。',
  projectFormCoordinateSystemTextEmptyError: '座標参照系を入力してください。',
  projectFormCoordinateSystemOptionEmptyError: '座標参照系を選択してください。',
  projectFormUnit: '単位',
  projectFormUnitAdd: '単位を追加',
  projectFormUnitHint: '追加する単位を入力してください。',
  projectFormUnitValue: '係数を入力',
  projectFormSuccessConfirmTitle: 'プロジェクトの追加完了',
  projectFormSuccessConfirmMessage: '${n} プロジェクトの追加が完了しました。',
  projectFormSuccessConfirmDefaultButton: '現場状況を追加',
  projectFormSuccessConfirmShowProjectListButton: 'プロジェクト一覧を確認',
  projectFormDistance: '距離',
  projectSettingButton: '設定',
  projectInfo: 'プロジェクト管理',
  participantsInfo: '参加者情報',
  fileArchive: 'ファイルアーカイブ',
  laborerHistory: '作業者の記録',
  projectFormUpdateSuccess: '情報が修正されました。',
  projectFormUpdateError: 'エラーが発生しました。もう一度お試しください。',
  projectListSearch: '検索',
  projectListInProgressTitle: '進行中のプロジェクト',
  projectListCompletedTitle: '完了したプロジェクト',
  projectListExpiredTitle: '満了したプロジェクト',
  projectListCreateButton: '新しいプロジェクトを追加',
  projectListEmptyText: 'プロジェクトが存在しません。',
  projectListFetchError: 'エラーが発生しました。もう一度お試しください。',
  sortOrderNameAsc: 'タイトル/昇順',
  sortOrderNameDesc: 'タイトル/降順',
  sortOrderConstructionDateAsc: '開始日/古い順',
  sortOrderConstructionDateDesc: '開始日/新しい順',
  projectInfoHeading: 'プロジェクト管理',
  projectInfoSnapshotList: 'プロジェクト現場状況一覧',
  projectDeleteConfirmTitle: 'プロジェクトを削除',
  projectDeleteConfirmMessage:
    'プロジェクトを削除すると、このプロジェクトに含まれる全ての現場状況データが一緒に削除されます。本当に削除しますか？',
  projectQRCodeDownload: 'QRコードをダウンロード',
  projectQRCodeDownloadTooltipMessage:
    '作業者がMeissa GuardアプリでチェックインできるQRコードの画像をダウンロードします。',
  projectFieldBoundaryDialogButton: 'フィールド境界の確認',
  projectQRCodeImageDownload: 'jpgファイルをダウンロード',
  projectQRCodeForMeiday: 'Meissa Guardチェックイン用QRコード',
  projectQRCodeDescription:
    '現場作業者がMeissa GuardアプリでこのQRコードをスキャンすると、該当プロジェクトにチェックインできます。\nチェックインした作業者の位置は、現場状況の「作業者の位置」から確認できます。',
  projectFieldBoundaryInstructionMessage:
    '現場の境界基準指定した距離を超えると、労働者は自動的にログアウトします。',
  zoneProjectList: 'プロジェクト一覧',
  zoneList: 'エリア一覧',
  zoneEditName: 'エリア名を編集',
  zoneSaveName: '保存',
  emptyZoneNameError: 'エリア名を入力してください。',
  zoneModeToMap: '地図で確認',
  zoneModeToCard: 'リストで確認',
  zoneEmptyData: '現場状況なし',
  zoneSnapshotList: '現場状況一覧',
  zoneImageTab: '画像',
  zoneVideoTab: '動画',
  zonePanoramaTab: '360°パノラマビュー',
  zoneBirdsEyeViewTab: '定点写真',
  buildingListEmpty: '「Meissa Scan」アプリケーションを使用し、屋内データを追加してください。',
  indoorDataTab: '屋内',
  zoneSortOrderDateDesc: '新しい順',
  zoneSortOrderDateAsc: '古い順',
  buildingSortOrderNameAsc: '名前/昇順',
  buildingSortOrderNameDesc: '名前/降順',
  zoneSnapshotEmpty: '現場状況が存在しません。',
  zoneSnapshotError: 'エラーが発生しました。もう一度お試しください。',
  zoneSnapshotRetryButton: '再試行',
  zoneSnapshotAddButton: '現場状況を追加',
  zoneSnapshotEdit: '編集',
  zoneSnapshotDelete: '削除',
  zoneBirdsEyeViewAddButton: '定点写真を追加',
  createZone: '新しいエリアを追加',
  createZoneDefaultName: '新しいエリア',
  zoneNameEditHelperText: '編集したい名前を入力してください。',
  zoneDeleteMessage:
    "${n}'を削除すると、このエリアに含まれる全ての現場状況データが一緒に削除されます。本当に削除しますか？",
  zoneName: 'エリア名',
  editName: '名前を編集',
  zoneDelete: 'エリアを削除',
  zoneNameRestriction: 'エリア名は最大${n}文字までです。',
  videoSnapshotCreatingTitle: '現場状況を動画で追加',
  videoSnapshotEditingTitle: '現場状況の動画を編集',
  videoSnapshotFileLabel: '動画ファイル',
  videoSnapshotFilePlaceholder: '動画ファイルを選択してください。',
  videoSnapshotFileSizeError: 'アップロードできる動画サイズの上限を超過しています。(1.5GB)',
  videoSnapshotFileFormatError:
    'サポートされていないファイル形式です。mp4、movファイルをアップロードしてください。',
  videoSnapshotFileInfo: '最大サイズ：1.5GB、ファイル形式：mp4、mov',
  panoramaSnapshotCreatingTitle: 'パノラマ画像を追加',
  panoramaSnapshotEditingTitle: 'パノラマ画像の修正',
  panoramaSnapshotFilePlaceholder: '360°パノラマビューファイルを選択してください。',
  panoramaSnapshotFileSizeError:
    'アップロードできる360°パノラマビューサイズの上限を超過しています。(60MB)',
  panoramaSnapshotFileFormatError:
    'サポートされていないファイル形式です。 jpg、jpegまたはpngファイルをアップロードしてください。',
  panoramaSnapshotFileInfo: '最大サイズ：60MB、ファイル形式： jpg、jpeg、png',
  panoramaConverting: '画像を準備中です。\nアップロード後、最大1分程度かかる場合があります。',
  panoramaDeleteTitle: 'ファイル削除',
  panoramaDeleteMessage: 'このファイルを削除しますか？',
  birdsEyeViewCreatingTitle: '定点写真を追加',
  birdsEyeViewEditingTitle: '定点写真を編集',
  birdsEyeViewFileLabel: '定点写真',
  birdsEyeViewFilePlaceholder: '画像ファイルを選択してください。',
  birdsEyeViewFileInfo: '最大サイズ：50MB、ファイル形式：jpg, png',
  birdsEyeViewFileSizeError: 'アップロードできる定点写真のサイズの上限を超過しています。(50MB)',
  birdsEyeViewFileFormatError:
    'サポートされていないファイル形式です。jpgまたはpngファイルをアップロードしてください。',
  snapshotZone: 'エリア',
  snapshotTakeDateLabel: '撮影日',
  snapshotDescriptionLabel: '説明',
  snapshotDescriptionPlaceholder: '説明を入力してください。',
  fileSnapshotCreatingButton: '追加',
  fileSnapshotEditingButton: '保存',
  fileSnapshotCreatingSuccess: '${n} 現場状況の追加が完了しました。',
  fileSnapshotEditingSuccess: '${n} 現場状況の修正が完了しました。',
  locationMarkerTooltip: '[${n1}] ${n2}(${n3}) - ${n4}',
  cadastralMarkerPopup: '住所：${n1} <br>公示地価：${n2}KRW<br>基準年月：${n3}-${n4}<br>${n5}',
  cadastralMarkerDetails: '詳細を確認',
  cadastralEmptyPrice: '-',
  customizingTitleSngis: 'Chung Cheong Inc.',
  customizingTitleKolon: 'Kolon Global',
  customizingTitleKcc: 'KCC E&Cプラットフォーム',
  customizingTitleHdc: 'HDC',
  customizingTitleLotte: 'ロッテE&C',
  customizingTitleNami: 'NAMI Global',
  customizingTitleDongbu: 'Dongbu Corporation',
  compensationStatusLabel: '補償状況',
  compensationRequired: '補償予定',
  compensationPending: '補償進行中',
  compensationDone: '補償完了',
  compensationUndefined: '未定',
  count: '${n}個',
  laborerMarkerPopupName: '名前：${n}',
  laborerMarkerPopupPhone: '電話番号：${n}',
  laborerMarkerPopupLocation: '最後の位置：${n}',
  laborerMarkerPopupLocationHistory: '以前の位置での時間 : ${n}',
  monthsAgo: '${n}ヶ月前',
  weeksAgo: '${n}週間前',
  hoursAgo: '${n}時間前',
  minutesAgo: '${n}分前',
  secondsAgo: '${n}秒前',
  compareSnapshotSliderTitle: '比較スライダー',
  compareSnapshotCompareTwoTitle: '2分割比較',
  compareSnapshotCompareFourTitle: '4分割比較',
  compareSnapshotMemoViewTitle: '現場メモ',
  compareSnapshotStandardDate: '基準日',
  compareSnapshotCompareDate: '比較日',
  snapshotDetailAddFileButton: 'ファイル追加',
  snapshotDetailAddingFileButton: 'ファイル追加中',
  snapshotDetailAddButton: '${n} 追加',
  snapshotDetailAnnotationEmpty: '${n} ツールがありません。',
  snapshotDetailHazardAreaEmpty: '現在登録されている危険区域はありません。',
  snapshotDetailFileEmpty: 'ファイルがありません。',
  snapshotDetailFile: 'ファイル',
  snapshotDetailAddFileMessage: 'ファイルがありません。新しく追加してください。',
  snapshotDetailViewButton: '${n} 詳細',
  layerViewAll: '全て表示',
  layerOpacity: '不透明度',
  layerOrthophotoTitle: 'オルソ画像',
  layerDrawingTitle: '図面',
  layerAnnotationTitle: '測量ツール',
  layerDSMTitle: '数値表層モデル',
  layerPointCloudTitle: '3Dポイント(Point Cloud)',
  layerMeshTitle: '3D面(Mesh)',
  layerMarkerTitle: '地点',
  layerLineTitle: '線分',
  layerAreaTitle: '面積',
  layerHeightTitle: '高さ',
  layerChainTitle: '土工横断線',
  layerPlanLevelTitle: '計画高',
  layerBimTitle: 'BIM',
  layerStratumTitle: '地盤',
  layerEtcTitle: 'その他のファイル',
  layerAerialImageTitle: 'ドローン撮影画像',
  layerIssueTitle: 'コメント',
  layerPolygonVector: '図面面積',
  editingPolygonVectorMessage: '図面面積を編集すると、既に作成済みの土工数量表は削除されます。',
  accordionGroupAnnotationTitle: '測量ツール',
  accordionGroupComparedDataTitle: '比較データ',
  comparedDataTooltipMessage: '現在の日付と比較できます。',
  layerCadastralTitle: '地籍図',
  layerGCPTitle: 'GCP',
  snapshotDetailGcpEditorButton: '確認',
  comparedCurrentDsm: '地盤高と比較',
  comparedCurrentDsmTooltipMessage: '現在の日付と高さを比較し、切盛土量を確認できます。',
  accordionGroupCurrentDataTitle: '現況データ',
  currentDataTooltipMessage:
    '現在の日付をより詳しく知ることができるデータや\nリアルタイムデータを見ることができます。',
  layerHazardAreaTitle: '危険区域',
  layerLaborerTitle: '作業者の位置',
  layerCctvTitle: 'カメラ',
  layerPanoramaTitle: '360°パノラマビュー',
  layerNdviTitle: '正規化植生指数(NDVI)',
  layerNdmiTitle: 'NDMI',
  layerThermalTitle: '熱画像',
  layerGreenTitle: 'グリーン',
  openSecondaryMenu: '詳細を見る',
  tooltipTitleWhat: 'どんな機能ですか？',
  tooltipTitleHow: 'どうやって使用しますか？',
  tooltipMoreButton: '続きを読む',
  layerChainTooltipWhat:
    'たくさんの線分ツールを一度に追加し、土工の進捗状況を簡単に確認し、CADファイル(.dxf)としてダウンロードできます。',
  layerChainTooltipHow:
    'CSVファイルを書式に合わせてにアップロードします。生成された線分の断面図グラフを確認できます。',
  layerPlanLevelTooltipWhat:
    '計画された地盤のtifファイルをアップロードすると、数値表層モデルと同様に計画高の高さを確認できます。',
  layerPlanLevelTooltipHow:
    '[地盤高と比較]トグルスイッチで該当日付の地盤高と計画高を比較し、計画高に合わせて必要な切/盛土量を計算できます。',
  layerStratumTooltipWhat: '埋め立てられた地層別の高さを確認できます。',
  layerStratumTooltipHow:
    '地盤をアップロードして線分ツールを生成すると、地層別の高さの値を個別で確認または重ね合わせて確認できます。',
  layerCadastralTooltipWhat: '国土交通省で提供する地籍情報を閲覧できます。',
  layerCadastralTooltipHow:
    '地図上の区切られたスペースをクリックすると、公示地価と建設中の土地補償の進捗状況を同時に確認できます。',
  layerHazardAreaTooltipWhat: '危険作業の内容の予想、予想時間、予想地点を指定できる機能です。',
  layerHazardAreaTooltipHow:
    'Meissa Guardアプリを利用すると、作業者の進入出内訳を確認したり、携帯電話で直接危険通知を送信できます。',
  layerLaborerTooltipWhat:
    '一か月間、Meissa Guardアプリでチェックインした作業者の位置を記録・確認できます。',
  layerLaborerTooltipHow:
    '作業者の携帯電話にMeissa Guardアプリをインストール後、チェックインするためには[プロジェクト情報]メニューでQRコードを提供してください。',
  layerIssueTooltipWhat:
    '工事中に発見された問題を地図上に表示することで問題が解決するまでの間、疎通し記録するための機能です。',
  layerIssueTooltipHow:
    '名前と説明を入力し、コメントを作成してください。コメント内の返信でチームメンバーと疎通できます。',
  drawingTooltip:
    '自動図面を設定する場合は、データ内の座標情報に基づいて地図に表示します。手動図面を設定する場合は、図面の位置を地図上で直接指定します。',
  snapshotDetailDownload: 'ダウンロード',
  snapshotDetailDelete: '削除',
  accordionGroupEtcFilesTitle: 'その他',
  etcFilesTooltipMessage:
    'このプロジェクトに属する他のファイルをアップロードおよびダウンロードできます。',
  resourceAddButton: '追加',
  resourceAddFolder: '新しいフォルダ',
  resourceAddFile: 'ファイルをアップロード',
  resourceListLoadingMessage: 'リストを読み込み中です。',
  snapshotItemUndefinedPosition: '位置が見つかりません。',
  snapshotCctvFullScreen: '全画面表示',
  snapshotCctvIsEmpty: '登録されたカメラがありません。',
  snapshotCctvAngleLabel: '角度',
  snapshotCctvPreviewButton: 'プレビュー',
  snapshotCctvPreviewTitle: 'カメラプレビュー',
  folderNameEnter: 'フォルダ名を入力してください。',
  folderNameDuplicate: '既に使用中のフォルダ名です。',
  folderCreateButton: '作成',
  folderEmptyMessage:
    '空のフォルダです。新しいファイルを追加するか、既存のファイルを連動させてください。',
  folderAddFile: 'このフォルダにファイルを追加',
  folderAddAnnotation: 'このフォルダに${n}を追加',
  folderMove: 'フォルダへ移動',
  folderMoveToAnother: '別のフォルダーに移動',
  folderMoveTitle: '移動する位置を選択',
  folderMoveButton: 'このフォルダに移動',
  folderMoveButtonError: '移動するフォルダがありません。\nフォルダを追加してから移動してください。',
  folderMoveErrorMessage: '既にこのフォルダ内にあります。',
  folderCurrentLocation: '現在位置',
  rename: '名前を変更',
  cctvCreationPopupTitle: 'カメラを追加',
  cctvCreationPopupLabelName: 'カメラ名',
  cctvCreationPopupLabelRtspUrl: 'RTSP URL',
  cctvCreationPopupLabelRtspUrlExample: '例) rtsp://0.0.0.0:554/live/cctv001.stream',
  cctvCreationPopupLabelRtspId: 'RTSP ID',
  cctvCreationPopupLabelRtspPw: 'RTSPパスワード',
  cctvCreationPopupCopyFieldPlaceholder: '入力した情報に自動的に結合されます。',
  cctvCreationPopupGuideButtonTitle: 'カメラの追加マニュアル',
  cctvDetailSideAreaLabelLocation: '位置',
  cctvDetailSideAreaButtonAddLocation: '位置を指定',
  cctvDetailSideAreaLabelRtspUrl: 'RTSP URL',
  cctvDetailSideAreaLabelRtspId: 'RTSP ID',
  cctvDetailSideAreaLabelRtspPw: 'RTSPパスワード',
  cctvDeleteConfirmTitle: 'カメラを削除',
  cctvDeleteConfirmMessage: 'このカメラは永久に削除されます。本当に削除しますか？',
  cctvPlayerSelectPlaceholder: 'カメラを選択',
  cctvPlayerButtonSplit4: '4分割比較',
  cctvPlayerButtonSplit9: '9分割比較',
  resetPositionLabel: '位置を初期化する',
  resetPositionAlertTitle: '位置を初期化する',
  resetPositionAlertMessage: 'この${n}の位置が削除されます。\n本当に位置を初期化しますか？',
  resetPositionAlertButton: '位置を初期化する',
  drawingManual: '手動',
  drawingAutomatic: '自動',
  drawingPositioning: '位置を指定',
  drawingPositioningEmptyMessage: '位置が指定されていないため、表示できません。',
  drawingConvertingErrorMessage: '変換できないファイルです。',
  drawingConvertingCancelMessage: '図面の変換がキャンセルされました。再試行してください。',
  QCAD1000: '変換できません。再度ファイルを確認してください。',
  markerAnnotationImageSubtitle: 'ドローン撮影画像',
  annotationNameHint: '${n} 名前',
  positionAreaCoordinateSystem: '座標',
  positionAreaTotal: '合計',
  measureVolume: '体積計算',
  measureVolumeTooltip: '選択した面積を基準に体積を計算し、\n切盛土量を確認できます。',
  totalVolume: '総面積',
  measureTypeTriangular: '三角分割',
  measureTypeTriangularTooltip: '指定した面積の点別のz値を基に\n三角形を作成して計算します。',
  measureTypeTriangularTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_triangulation_en.png',
  measureTypeTriangularTooltipLink:
    'https://www.notion.so/meissa/1-d4edd76173dd41da80c5b8c3a55dcc37?pvs=4#c24fa7900f39402cbae3e3c8d8b8398b',
  measureTypeDate: '比較日',
  measureTypeDateTooltip:
    '選択した二つの日付間の体積の変化量を計算します。B日対比A日の切盛土量を確認できます。',
  measureTypeDateTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_comparison-date_en.png',
  measureTypeDateTooltipLink:
    'https://www.notion.so/meissa/1-d4edd76173dd41da80c5b8c3a55dcc37?pvs=4#c24fa7900f39402cbae3e3c8d8b8398b',
  measureTypeHeight: '高さを入力',
  measureTypeHeightTooltip: '直接入力した高さを基準に切盛土量を計算します。',
  measureTypeHeightTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_height_en.png',
  measureTypeHeightTooltipLink:
    'https://www.notion.so/meissa/1-d4edd76173dd41da80c5b8c3a55dcc37?pvs=4#c24fa7900f39402cbae3e3c8d8b8398b',
  measureTypePlan: '計画高',
  measureTypePlanTooltip:
    '計画高と地盤高を比較すると切盛土量を確認できます。計画高を基準に上側が切土量、下側が盛土量です。',
  measureTypePlanTooltipImage:
    'https://meissa-frontend-public.s3.ap-northeast-2.amazonaws.com/image/info-image_design-level_en.png',
  measureTypePlanTooltipLink:
    'https://www.notion.so/meissa/1-d4edd76173dd41da80c5b8c3a55dcc37?pvs=4#c24fa7900f39402cbae3e3c8d8b8398b',
  measure: '計算',
  measuring: '計算中',
  insertReferenceHeight: '基準高さの入力',
  insertReferenceHeightEmpty: '高さを入力してください。',
  uploadDsm: '数値表層モデルをアップロードすると計算できます。',
  volumeErrorMessage: 'エラーが発生しました。',
  volumeCutSumLabel: '総切土',
  volumeCutSumDescription: '(切土－盛土)',
  volumeFillSumLabel: '総盛土',
  volumeFillSumDescription: '(盛土－切土)',
  calculatingVolumetricComparedToDesignLevel: '数量を変換中です。20分以上かかる場合があります。',
  volumeComparedToSnapshotInfoMessage: '${n1}対比${n2}の体積を計算した切盛土量です。',
  annotationDescriptionEmpty: '説明を入力してください。',
  hazardAreaDescriptionEmpty: '${n}字以内で入力してください',
  markerImageEmpty: '画像がありません。',
  markerImageCanAdd: '画像がありません。新しく追加してください。',
  hazardAreaDateSubtitle: '日時設定',
  hazardAreaStartDate: '開始日時',
  hazardAreaEndDate: '終了日時',
  hazardAreaEndDateRequired: '終了日時を選択してください。',
  hazardAreaEndDateBeforeStartDate: '開始日時以降の時間を選択してください。',
  hazardAreaTooltip:
    '満了した危険区域は自動的に削除されます。\n作業者の位置から満了した危険区域を確認してください。',
  laborerLocationTooltip:
    'Meissa Guardアプリでチェックインした作業者のリアルタイムの位置を確認します。',
  memberLocationTooltip:
    'Meissa Platform Mobileアプリでチェックインした管理者のリアルタイムの位置を確認します。',
  hazardAreaTooltipStartDate: '開始日時：${n}',
  hazardAreaTooltipEndDate: '終了日時：${n}',
  hazardAreaTooltipDescription: '説明：${n}',
  hazardEventHistoryButton: '危険区域履歴',
  hazardEventHistoryTypeAllHistory: '全体',
  hazardEventHistoryTypeHazardIn: '危険',
  hazardEventHistoryTypeAlarmOff: '安全',
  hazardEventHistoryTypeStart: '作成',
  hazardEventHistoryTypeEnd: '満了',
  hazardEventHistoryAreaListOptionAll: '危険区域全体',
  hazardEventHistoryTitleAlarmOff: '作業者の安全',
  hazardEventHistoryTitleHazardIn: '作業者が危険',
  hazardEventHistoryTitleStart: '危険区域の登録',
  hazardEventHistoryTitleEnd: '危険区域の満了',
  hazardEventHistoryDescriptionHazardIn: '${n1}様が${n2}に立ち入りました。',
  hazardEventHistoryDescriptionAlarmOff: '${n1}様が${n2}から安全な区域に移動しました。',
  hazardEventHistoryDescriptionStart: '${n1}様が${n2}を登録しました。',
  hazardEventHistoryDescriptionEnd: '${n}が満了しました。',
  hazardEventHistoryListEmptyMessage: '記録されている危険区域の履歴がありません。',
  calculateElevationOfChainListWithDsm: '地盤高を計算',
  calculateElevationOfChainListWithPlanLevel: '計画高を計算',
  calculationStatusForChainList: '待機中：${n1} 進行中：${n2} エラー：${n3} 完了：${n4}',
  calculationIsProgressingMessage:
    '計算中です。数分程度かかる場合があります。\n(ページを離れても計算は続行されます。)',
  calculationIsErrorMessage: '計算に失敗しました。再試行してください。',
  calculatingButton: '計算中',
  retryCalculationButton: '計算を再試行',
  calculationButton: '計算する',
  downloadChainListFileButton: 'グラフファイルをダウンロード',
  downloadChainListFileDescription: '計算された土工横断線のみDXFでダウンロードします。',
  selectPlanLevelPlaceholder: '計画高を選択してください。',
  selectEmptyPlanLevelPlaceholder: 'ファイルがありません。',
  selectNonePlanLevel: '選択しない',
  downloadStatusForChainList: 'エラー：${n1} 完了：${n2}',
  downloadChainListAlertMessage:
    '計算が完了した ${n}本の土工横断線がダウンロードされます。\nキャンセルを押すと、今まで変換された内容が削除されます。\nしばらくお待ちください。',
  downloadChainListAlertTitle: 'グラフファイルを生成し、ダウンロード中です。',
  downloadChainListCancelModalTitle: 'グラフファイルのダウンロードをキャンセル',
  downloadChainListCancelMessage: '土工横断線のDXFファイルを生成中です。\n作業を中断しますか？',
  downloadChainListKeepGoingButton: '続ける',
  downloadChainListStopButton: '中断する',
  downloadChainListWithStratum: 'ダウンロード時、連動された地盤グラフが含まれます。',
  polygonVectorLayerListTitle: '図面面積レイヤー',
  earthworkTableEntryButton: '土工事管理表',
  earthworkTableNoPlanLevelOption: '計画高なし',
  earthworkTableCalculationButton: '搬出量を計算',
  earthworkTableExportButton: 'エクセルファイルでエクスポート',
  earthworkTableExportButtonCaption: '全ての数量単位は${n}です。',
  earthworkTableExportingMessage: 'エクセルファイルを作成中です。しばらくお待ちください。',
  earthworkTableExportingErrorMessage: 'ファイルが作成されませんでした。再試行してください。',
  earthworkTableExportingSuccessMessage: 'ファイルが完成しました。',
  earthworkTableLabelCut: '切土',
  earthworkTableLabelFill: '盛土',
  earthworkTableLabelCutSum: '切土の小計',
  earthworkTableLabelFillSum: '盛土の小計',
  earthworkTableLabelSoil: '土壌',
  earthworkTableLabelTotal: '合計',
  earthworkTableHeadAnnotation: '面積',
  earthworkTableHeadIsCutOrFill: '区分',
  earthworkTableHeadStratumType: '地層区分',
  earthworkTableHeadTotalAmount: '全数量',
  earthworkTableHeadAmountComparedWithPastDate: '以前の日付対比の搬出量 (m³)',
  earthworkTableHeadPlanedAmount: '計画数量',
  earthworkTableHeadAmountLeft: '残土量',
  earthworkTableHeadSumAmountDayByDay: '日付別の合計',
  earthworkTableEmptyValueWarning: '入力されていない計画数量は0で計算されます。',
  earthworkTableNotTmsError:
    '2023年6月1日以前に分析した数値表層モデル、計画高、地盤では搬出量を計算できません。計算を希望する場合は、カスタマーセンターまたはhelp@meissa.aiまでお問い合わせください。',
  earthworkTableNoPlanLevelError: '計画高がないため、搬出量を計算できません。',
  earthworkTableVolumeListError: '表を読み込めないため、搬出量を計算できません。',
  earthworkTableExcludeSnapshotHasNoDsmWarning:
    '数値表層モデルのない現場状況は、搬出量が計算できないため除外されます。',
  earthworkTableCalculating: 'ページを離れても計算は続行されます。',
  earthworkTableCalculatingError: 'エラーが発生しました。関連データをもう一度確認してください。',
  earthworkTableCalculatingSuccess: '全ての計算が完了しました。',
  earthworkTableLoadingView: '表を作成中',
  earthworkTablePlanedVolumeErrorView:
    '計画数量の読み込み中にエラーが発生しました。\nページを更新してください。',
  earthworkTableCreatingErrorView:
    '表の読み込み中にエラーが発生しました。\nページを更新してください。',
  earthworkTableEmptyView: '計画高をアップロードしてください。',
  earthworkTableResourceConversion: '計画高や地盤を変換中のため、搬出量を計算できません。',
  earthworkTableFileLinkingAlert:
    '搬出量計算に必要なファイルが連動していない現場状況があります。計算のために該当ファイルを自動的に連動させます。',
  earthworkTableCellPlaceholder: '入力',
  earthworkTableCellCalculating: '計算中...',
  earthworkTableCellCalculatingError: '計算エラー',
  earthworkTableCellCalculatingRequired: '計算が必要',
  earthworkTableDateFormat: 'yy.MM.dd',
  adjustingBounds: '印刷範囲を設定中です',
  resetBounds: '範囲をリセット',
  print: '印刷',
  generatingPrintFile: 'ファイルを作成中です。しばらくお待ちください。',
  fileName: 'ファイル名',
  printFormat: 'ファイル形式',
  printResolution: '解像度',
  printHighResolution: '高解像度',
  printLowResolution: '低解像度',
  downloadPrintFile: 'ファイルダウンロード',
  generatePrintFile: '印刷ファイルを作成',
  printFileError: 'ファイルが作成されませんでした。再度お試しください。',
  printFileSuccess: 'ファイルが完成しました。',
  printHighResolutionInfo: '高解像度ファイルは、現場の大きさによって5分以上かかる場合があります。',
  controlPointSize: '点のサイズを調整',
  togglePointToMesh: 'メッシュで表示',
  helperControlPointSize:
    '点が大きいほど四角のタイル形態になり、状態\n屈曲面が確認しやすくなります。',
  helperTogglePointToMesh: '3Dモデルをメッシュデータで表示します。',
  tiffFileConverting:
    'ファイルを変換中です。ファイル容量と現場のサイズによっては20分以上かかる場合があります。',
  tiffFileConversionError: '変換できないファイルです。ファイルを削除後、再試行してください。',
  compareBim: 'BIMと比較',
  compareBimTooltipMessage:
    '3DポイントとBIMを同時に確認します。BIMは手動での位置調整が\n可能で、右上の「BIM位置操作ガイド」をご参照ください。',
  emptyBimFile: 'BIMファイルがありません。',
  bimOnPointCloudAlert: '座標情報のないBIMファイルは、自動で中央に配置されます。',
  bimControlAlert: '手動操作されたBIMの位置は自動的に保存されます。',
  bimControlGuide: 'BIM位置操作ガイド',
  bimTranslateUnit: '移動 (${n}m)',
  bimUpDownUnit: '上下移動 (${n}m)',
  bimRotateUnit: '左右回転 (${n}°)',
  bimDetailTranslateUnit: '詳細移動 (${n}cm)',
  bimDetailUpDownUnit: '詳細上下移動 (${n}cm)',
  bimDetailRotateUnit: '詳細左右回転 (${n}°)',
  bimControlInfo: 'BIMオブジェクトを選択後、キーボードで移動させられます。',
  bimManualControl: '手動',
  meshOnPointcloud: '3Dメッシュと同時に確認',
  meshOnPointcloudTooltip: '3Dポイントとメッシュを同時に確認します。',
  threeDTileLoadErrorMessage:
    'ファイルの読み込み中にエラーが発生しました。ページを更新後に再試行してください。',
  elevationSubTitle: '断面図グラフ',
  helperElevationGraph: '日付別の高度、計画高、地盤をグラフで比較します。',
  selectFile: 'ファイル選択',
  fileLinking: '連動 ${n}',
  stratumMaxLinkingCount: '(最大${n})つ)',
  fileStorage: 'ファイルアーカイブ',
  fileLinkingManagement: '連動管理',
  fileLinkingPointManagement: '地点連動管理',
  fileLinkingPolylineManagement: '線分連動管理',
  fileLinkingPolygonManagement: '面積連動管理',
  fileLinkingDrawingManagement: '図面連動管理',
  hazardAreaHistory: '危険区域履歴',
  fileStorageEmptyDescription:
    '連動できるファイルがありません。\nツールやファイルを新たに追加してください。',
  unlinkRelationButton: '連動を解除',
  fileLinkingStratumMaxNumberErrorMessage: '地盤は最大5つまで連動できます。',
  laborer: '現在地',
  laborerLocation: '作業者情報',
  laborerLocationListEmpty:
    '勤務中の作業者がいません。\nMeissa Guardアプリでチェックインした作業者の位置を確認できます。',
  laborerLocationHistoryListEmpty: '作業者の位置履歴がありません。\n別の日付を確認してください。',
  laborerLocationHistoryListTimeEmpty:
    '作業者の位置履歴がありません。\n別の時間を確認してください。',
  laborerLocationListEmptyForPrimary: '勤務中の作業者がいません。',
  laborerLocationHistoryListEmptyForPrimary: '作業者の以位置履歴がありません。',
  laborerVulnerable: '虚弱者',
  laborerPaused: '位置情報共有中止',
  laborerTabLocationList: '現在時刻',
  laborerTabLocationHistoryList: '位置履歴',
  laborerViewHazardArea: '危険区域履歴を表示',
  laborerViewHazardAreaTooltip: '以前の危険区域と共に確認する。',
  laborerSliderAlert: '再生可能な区間ではありません。',
  laborerDatePickerFormat: 'PPP',
  laborerGpsErrorSnackbarMessage:
    '現場環境により、地図上の位置と実際の作業者の位置が異なる場合があります。',
  getFileFromStorage: 'アーカイブから復元',
  showFileListInStorage: 'アーカイブで確認',
  addUploadFileRow: 'ファイル行を追加',
  fileStorageDeleteResourceTitle: 'ファイル削除',
  fileStorageDeleteResourceMessage:
    '${n}個のファイルを削除すると、現場全体の状況からファイルが完全に削除されます。削除しますか？',
  fileStorageDeleteFileAlertMessage:
    'ファイルを削除すると、現場全体の状況からファイルが完全に削除されます。削除しますか？',
  fileStorageDeleteResourceConfirmButton: '削除',
  fileStorageDeleteResourceCancelButton: 'キャンセル',
  fileStorageDeleteAnnotationTitle: '測量ツールを削除',
  fileStorageDeleteAnnotationMessage:
    '${n}測量ツールを削除すると、現場全体の状況からファイルが完全に削除されます。削除しますか？',
  fileStorageDeleteAnnotationConfirmButton: '削除',
  fileStorageDeleteAnnotationCancelButton: 'キャンセル',
  hazardAreaDeleteTitle: '危険区域を削除',
  hazardAreaDeleteMessage: '${n}ファイルを削除すると、位置履歴からも削除されます。\n削除しますか？',
  uploadPopupStratumFiles: '地盤ファイルを追加',
  uploadPopupEtcFiles: 'その他のファイルを追加',
  uploadPopupBimFiles: 'BIMファイルを追加',
  uploadPopupPlanLevelFiles: '計画高ファイルを追加',
  uploadPopupChainFiles: '土工横断線ファイルを追加',
  uploadPopupNdviSegmentationFiles: '正規化植生指数(区域)ファイルを追加',
  uploadPopupBimDescription:
    '座標情報が存在しない、またはマップ領域を超えたファイルは任意で中央に配置されます。',
  uploadPopupSuperResolutionFiles: '超解像度(SR)ファイルを追加',
  fileUploadExtensionPolicy: 'ファイル形式：${n}',
  fileUploadSizePolicy: '最大サイズ：${n}',
  fileUploadAllFileExtension: '全てのファイル形式',
  fileUploadSizeErrorMessage: '${n}以下のファイルのみアップロードできます。',
  fileUploadCountErrorMessage: 'ファイルは一度に${n}つまでアップロードできます。',
  fileUploadExtensionErrorMessage: '拡張子が${n}のファイルのみアップロードできます。',
  fileSizeNoLimit: '無制限',
  fileUploadFailMessage: 'アップロードに失敗しました。再試行してください。',
  uploadPopupStratumAliasPlaceholder: '地盤名',
  uploadPopupStratumFilePlaceholder: '地盤ファイルを選択してください。',
  drawingUploadPopupTitle: '図面ファイルを追加',
  drawingTabTypeManual: '自動配置',
  drawingTabTypeAutomatic: '手動配置',
  drawingSelectFileTitle: '[図面]選択',
  drawingAutoTypePolicy: 'ファイル形式：dxf、最大サイズ：${n} MB',
  drawingManualTypePolicy: 'ファイル形式：png、jpg、pdf、dxf、最大サイズ: ${n} MB',
  drawingManualInfo:
    '座標データのない画像をユーザーが直接配置します。\n手動配置で誤差が生じる可能性がありますが、初期変換と活用が軽くて速いです。',
  drawingAutoRasterInfo:
    '図面を画像化して表示します。[ベクター]に比べて初期変換が遅いですが、活用する際に軽くて速いです。',
  drawingAutoVectorInfo:
    'レイヤーを描くように表示します。[ラスター]に比べて初期変換が速いですが、性能が良くない機器では多少遅くなる場合があります。Meissa Flightアプリでは表示されません。',
  drawingSelectCRSTitle: '座標参照系設定',
  drawingSelectDisplayTitle: '表示方法',
  drawingRasterRadioLabel: '自動ラスター',
  drawingVectorRadioLabel: '自動ベクトル',
  drawingFileUploadButton: 'アップロード',
  autoRasterDrawingConverting:
    '図面を変換中です。数分程度かかる場合があります。\n(ページを離脱しても変換は続行されます。)',
  autoRasterDrawingEnhancing: '高解像度に変換中です。 (ページを離脱しても変換は続行されます。)',
  autoVectorDrawingRendering: '図面を地図に表示中です。${n1}/${n2}',
  drawingConvertButton: '変換',
  drawingAutoVectorChip: '自動ベクトル',
  drawingAutoRasterChip: '自動ラスター',
  drawingManualChip: '手動配置',
  drawingsUploadSuccess: 'アップロードが完了しました。',
  drawingsUploadError: 'エラーが発生しました。 もう一度お試しください。',
  drawingsUploadLoading: 'アップロード中です。少々お待ちください。',
  projectCRS: 'プロジェクト座標参照系',
  drawingFileMaxSizeAlert: '${n}MB以下のファイルのみアップロード可能です。 n',
  manualDrawingFileFormateAlert:
    '手動配置は、D、png、jpg、およびpdfファイルのみをアップロード可能です。',
  autoDrawingFileFormateAlert: '自動配置は、dxfファイルのみアップロード可能です。',
  snapshotPanoramaEmptyMessage: 'ファイルがありません。\n新しいファイルを追加してください。',
  snapshotPanoramaEmptyMessageForPrimary: 'ファイルがありません。',
  snapshotPanoramaErrorMessage: 'エラーが発生しました。\nもう一度お試しください。',
  snapshotPanoramaViewerButton: '最大化する',
  imageSnapshotEditTitle: '画像の現場状況の修正',
  snapshotProcessingTypeLabel: '現場状況タイプ',
  processingTypeLabel: 'プラットフォームでの分析',
  preprocessedTypeLabel: '分析されたファイルをアップロード',
  snapshotEngineLabel: '分析エンジン',
  imageSnapshotEditChangeZoneConfirmTitle: '区域変更',
  imageSnapshotEditChangeZoneConfirmMessage:
    '区域を変更すると、既存の区域データ連動はすべて解除され、変更される区域データと連動されます。\n区域を変更しますか？',
  snapshotFormPageTitle: '現場状況を追加',
  snapshotFormPageEditTitle: '現場状況を修正',
  snapshotFormPrevButton: '戻る',
  snapshotFormNextButton: '次',
  snapshotFormExitButton: '出る',
  snapshotFormRequiredCaption: '(必須)',
  snapshotFormRecommendedCaption: '(おすすめ)',
  snapshotFormInfoTitle: '現場状況の情報',
  snapshotFormInfoCaption: '必要な情報を入力します。(必須)',
  snapshotFormFileUploadTitle: '分析されたファイルをアップロード',
  snapshotFormFileUploadCaption: 'エンジン分析されたファイルをアップロードします。(必須)',
  snapshotFormImageUploadTitle: '元の画像をアップロード',
  snapshotFormImageUploadCaption: 'ドローンで撮影した画像を選択してアップロードします。${n}',
  snapshotFormGcpUploadTitle: 'GCPマーク',
  snapshotFormGcpUploadCaption: '元の画像とマッチングして、3Dモデルをより正確にします。${n}',
  snapshotFormProcessTitle: '分析開始',
  snapshotFormProcessCaption: 'アップロードされたファイルから分析を開始します。',
  snapshotFormOrthophotoLabel: '正射写真',
  snapshotFormDsmLabel: '数値表層モデル',
  snapshotFormPointCloudLabel: '3Dポイント (Point Cloud)',
  snapshotFormMeshLabel: '3D面 (Mesh)',
  snapshotFormFileFormatText: 'ファイル形式: ${n}',
  snapshotFormMeshFileFormatText: 'ファイル形式: ${n1}、最大サイズ: ${n2} MB',
  snapshotFormMeshMaxFileSizeError: '${n}MB以下のファイルのみアップロード可能です。',
  snapshotFormLoadingViewText: '読み込み。お待ちください。',
  snapshotFormFileMinCountAlert: '最低アップロード数は${n}個です。',
  snapshotFormFileMaxCountAlert: '最大アップロード数は${n}個です。',
  snapshotFormImageUploadLabel: '元の画像をアップロード',
  snapshotFormImageMinCountText: 'アップロードは最低30枚以上から可能です。',
  snapshotFormImageWithoutGpsAlert: '位置情報のない画像はアップロードできません。',
  snapshotFormImageCompressError: '圧縮中にエラーが発生しました。 再度お試しください。',
  snapshotFormImageCompressSuccess:
    '画像の圧縮が完了しました。ダウンロードボタンを押してください。',
  snapshotFormImageCompressLoading: 'アップロードされた画像を圧縮中です。しばらくお待ちください。',
  snapshotFormImageUploadSuccess: 'アップロードが完了しました。',
  snapshotFormImageUploadError:
    'アップロードに失敗したファイルがあります。もう一度お試しください。',
  snapshotFormImageUploadMaxCountAlert:
    'アップロード上限は${n}個です。ファイル数を確認してください。',
  snapshotFormMapTitle: 'プレビュー',
  snapshotFormMapCaptionForImage: '選択された画像が正しいファイルかを確認してください。',
  snapshotFormImageArchiveLoadingButton: '圧縮中',
  snapshotFormImageArchiveDownloadButton: 'Zipファイルをダウンロード',
  snapshotFormGcpUploadLabel: 'GCPファイルのアップロード',
  snapshotFormGcpDownloadButton: 'ダウンロード',
  snapshotFormGcpFileGuideText: 'ファイル形式：csv txt\nGCPポイント: 少なくとも3個以上入力',
  snapshotFormFileFormatGuideButton: 'アップロードガイド',
  snapshotFormGcpCoordinateLabel: '座標参照系を選択します。',
  snapshotFormGcpDisabledCoordinate:
    '座標参照系を変更する場合は、GCPファイルを削除して再度アップロードしてください。',
  snapshotFormGcpLocationWarningAlert: '${n}個のGCPポイントが現場範囲外にあります。',
  snapshotFormGcpCorrectionLabel: 'GCPマーク',
  snapshotFormGcpCorrectionCaption:
    '3Dモデルをより正確にするためにGCPを元の画像とマッチングします。',
  snapshotFormGcpCorrectionButton: 'GCPマーク',
  snapshotFormProcessButton: '分析開始',
  snapshotFormProcessingButton: '分析中',
  snapshotFormProcessingText:
    '元の画像数と現場面積により、最大24時間かかります。\n分析が完了したらメールでお知らせします。',
  snapshotFormPauseButton: '分析キャンセル',
  snapshotFormPauseConfirmTitle: '分析キャンセル',
  snapshotFormPauseConfirmMessage: '今まで分析した内容が消えます。\n分析を中止しますか？',
  snapshotFormPauseConfirmPrimaryButton: '分析キャンセル',
  snapshotFormPauseConfirmSecondaryButton: '続く',
  snapshotFormPauseSuccessMessage: '分析をキャンセルしました。',
  snapshotFormProcessDoneMessage: '分析が完了した現場の状況です。',
  snapshotFormResultInfoMessage: '分析完了後、4つの分析結果データを活用することができます。',
  snapshotFormImagesNeedToBeMatchedToGcpTitle: 'GCPマーカー分析',
  snapshotFormImagesNeedToBeMatchedToGcp:
    'GCPマーカーは追加されていません。 nこのまま分析しますか？',
  snapshotFormImagesNeedToBeMatchedToGcpPrimaryButton: 'マーカーを追加',
  snapshotFormImagesNeedToBeMatchedToGcpSecondaryButton: '分析する',
  snapshotFormGoGcpMarkPage: 'マークGCP',
  snapshotFormMapToolLinkTitle: 'マッピングツールに連動選択',
  snapshotFormMapToolLinkContent:
    '最も最近分析された${n}現場状況に連動された位置、線分、面積ツールを同じように連動します。',
  snapshotFormMappedImageCountIsNotRecommendedModalTitle: '現場状況分析',
  snapshotFormMappedImageCountIsNotRecommendedModalContent:
    'マークした画像数が5枚以下の場合、分析品質が低下する可能性があります。このまま分析を開始しますか？',
  snapshotFormMappedImageCountIsNotRecommendedModalSecondaryButton: 'GCPマーキングに移動',
  snapshotFormMappedImageCountIsNotRecommendedModalPrimaryButton: '分析開始',
  mediaViewerDetailWriter: '作成者',
  mediaViewerDetailUploadDate: 'アップロード',
  mediaViewerDetailFileCreationTime: '撮影日付',
  issueMediaFileExpand: 'ズームイン',
  doneIssueListTitle: '完了した問題',
  issueEmptyMessage: '問題がありません。',
  issueAddButton: '問題',
  issueFileUploadNumberIssue: '一度に${n}個のファイルしかアップロードできません。',
  issueCommentPlaceholder: '内容を入力してください。',
  doneIssueCommentPlaceholder: 'コメントを入力するには、問題を進行中にしてください。',
  deleteMainImageConfirmTitle: 'メイン画像削除',
  deleteMainImageConfirmMessage: 'メイン画像が完全に削除されます。削除しますか？',
  issueRunning: '進行中',
  issueDone: '完了しました。',
  issueNameHint: '名前を入力してください。(必須)',
  issueDescriptionHint: '説明を入力してください。(必須)',
  deleteCommentConfirmTitle: 'コメント削除',
  deleteCommentConfirmMessage: 'コメントは完全に削除されます。削除しますか？',
  deleteIssueConfirmTitle: '問題を削除する',
  deleteIssueConfirmMessage:
    "${n}' 問題を削除すると、全体の現場状況から完全に削除されます。削除しますか？",
  issueMainImageUploadButton: 'メイン画像を選択する',
  issueUpdateCancelWarningTitle: '変更された内容確認',
  issueUpdateCancelWarningMessage: '変更内容を保存せずにページを終了しますか？',
  localeDateWithoutYearFormat: 'MMMM do',
  localeDateWithoutCommaFormat: 'PPP',
  issueCreateSystemMessage: '${n}様が問題を作成しました。',
  issueDoneSystemMessage: '${n}様が問題を完了しました。',
  issueReactivatedSystemMessage: '${n}様が問題を進行中に変更しました。',
  issuePolygonNdviAnalysis: '問題領域の正規化差植生指数分析',
  issuePolygonNdviAnalysisTooltip: 'When NDVI is present, it is calculated based on NDVI.',
  issuePolygonNdviCalculationBtn: '正規化差植生指数計算',
  issuePolygonNdviHealthyChip: '良い',
  issuePolygonNdviStressedChip: '注意',
  issuePolygonNdviCriticalChip: '危険',
  issuePolygonCalculationRequiredText: '計算が必要です。',
  issuePolygonSizeLabel: '面積',
  issuePolygonNdviCalculationWarningMessage: '正規化差植生指数のエリアのみ算されます。',
  issuePolygonNdviViewCheckboxLabel: '正規化差植生指数を表示する',
  issuePolygonComparedButton: '日付別の比較',
  issuePolygonComparedSelectPlaceholder: '日付の選択',
  issuePolygonComparedMessageTitle: '正規化差植生指数',
  issuePolygonComparedEmptyFile: 'ファイルがない',
  issuePolygonComparedConverting: '変換中',
  issuePolygonComparedCalculating: '計算中',
  issuePolygonComparedCalculationError: '計算エラー',
  issuePolygonComparedCalculationRequired: '計算が必要です。',
  viewPointButtonTitle: '穴移動',
  mapControlCompareButtonGroupTitle: '現場比較',
  mapControlToolButtonGroupTitle: 'ツールを追加する',
  mapRotationTooltipMessage:
    '連動したマッピングツールが多いと、地図の回転時に速度が遅くなることがあります。\n使用しないマッピングツールは[連動管理]で連動解除してください。',
  errorBoundaryMessage: 'エラーが発生しました。 \nページをリロードしてください。',
  errorBoundaryRefreshButton: 'リロード',
  greenTooltipWhat: 'マップにグリーンを追加し、緑のステータスを監視します',
  greenTooltipHow:
    '緑の動き」を使用して、登録グリーンにすばやくナビゲートします。グリーンをタイプと日付で比較できます。',
  greenAddButtonTitle: '緑を追加します',
  greenCompareButtonTitle: '緑の比較',
  greenCompareByGreenButtonTitle: 'グリーンによって',
  greenCompareByDateButtonTitle: '日付によって',
  greenDeleteConfirmTitle: 'グリーンを削除します',
  greenDeleteConfirmMessage: '「${n}」グリーンを永続的に削除します。削除したいですか？」',
  greenAnalysis: 'グリーン分析',
  greenAnalysisInvalidMessage:
    'グリーンがないか、場所が植生指数の範囲外にあり、分析できません。グリーンを追加するか、場所を調整してください。',
  greenAnalysisProgressMessage:
    'グリーンの分析中です。少々お待ちください。（ページを離れても分析は続行されます。）',
  greenAnalysisErrorMessage: 'エラーが発生しました。もう一度お試しください。',
  greenLabel: 'グリーン',

  toolsAIaccordionGroupTitle: 'AIツール',
  toolsAIDetectButtonTitle: '探知',
  toolsAIColorPaletteTitle: '色相',
  toolsAIObjectDetectionAccordionTitle: '客体探知',
  toolsAIObjectDetectionSubtitleDetected: '探知される',
  toolsAIObjectDetectionGvienClassPlane: '飛行機',
  toolsAIObjectDetectionGvienClassShip: '船舶',
  toolsAIObjectDetectionGvienClassSmallVehicle: '小さい車両',
  toolsAIObjectDetectionGvienClassLargeVehicle: '大きな車両',
  toolsAIObjectDetectionGvienClassUncategorized: '未分類',
  toolsAIObjectDetectionUnProgressedMessage: '探知された客体がありません。',
  toolsAIObjectDetectionHideObjectTooltip: '地図から隠すには押してください',
  toolsAIObjectDetectionUnhideObjectTooltip: '地図にもう一度表示するには押します',
  toolsAIAnalyzeError: 'エラーが発生しました。 もう一度お試しください。',
  toolsAISegmentationAccordionTitle: 'オブジェクトの分割',
  toolsAISegmentationBefore: '分割前',
  toolsAISegmentationDo: '分割',
  toolsAISegmentationDone: 'セグメント化された',
  toolsAISegmentationBuilding: '建物',
  toolsAISegmentationRoad: '道路',
  toolsAISegmentationWater: '水',
  toolsAISegmentationVegetation: '植生',
  toolsAISegmentationBarren: '裸地',
  toolsAISegmentationOpacity: '不透明度',
  toolsAISegmentationErrorMessage: 'エラーが発生しました。 もう一度お試しください。',
  toolsAIChangeDetectionAccordionTitle: '変化探知',
  toolsAIChangeDetectionProgressingInformation:
    '変化探知には10分から最大30分ほどかかることがあります。 別の画面に移動しても検出が続きます。',
  toolsAIChangeDetectionSameSnapshotInvalidWarning: '同じ現場状況は変化探知ができません。',
  toolsAINdmiAccordionTitle: 'NDMI',
  toolsAINdmiAccordionUploadFileButtonTtile: 'ファイル追加',
  toolsAINdmiAccordionNoContents: 'ファイルがありません。',
  toolsAINdmiFileUploadPopupTitle: 'NDMIファイルを追加',
  toolsAINdmiFileUploadPopupDescription: '形式: tif,tiff、サイズ: 制限なし',
  toolsAINdmiFileUploadErrorMessage:
    '変換できないファイルです。ファイルを削除してから再試行してください。',
  landDisplacement: '地盤変位',
  landDisplacementAddPoint: '位置追加',
  landDisplacementPointTitle: '位置 ${n}',
  landDisplacementPointListEmpty: '位置を追加して地盤の変位をグラフで確認してみてください。',
  landDisplacementControlPixelSize: 'ピクセルサイズ (m)',
  landDisplacementGraphPositionInvalid: '選択した場所はグラフを表示できません。',
  landDisplacementVelSliderTitle: '変位速度 (mm/y)',
  landDisplacementGraphTitle: '位置 ${n} 地盤変位',
  landDisplacementGraphXTick: 'yyyy年',
  landDisplacementGraphYTitle: '変位 (mm)',
  landDisplacementTooltipCompareToDate: 'PPP対比',
  superResolutionAccordionTitle: '超解像度(SR)',
  superResolutionCompareButtonTitle: '原本と比較',
  superResolutionComparedOrthoPhotoInfoHeader: '正射写真',
  superResolutionCompareErrorDetail: 'この日付に超解像度(SR)がありません。',
  buildingCreateButton: '建物を追加',
  buildingFormModalCreateTitle: '建物を追加',
  buildingFormModalTitle: '建物の修正',
  buildingFormModalEditConfirmTitle: '変更された内容確認',
  buildingFormModalEditConfirmMessage: '変更内容を保存せずにページを終了しますか？',
  buildingFormModalNameLabel: '建物名',
  buildingFormModalDescriptionLabel: '説明',
  buildingFormModalEditTitlePlaceholder: '建物名を入力してください。',
  buildingFormModalEditDescriptionPlaceholder: '建物の説明を入力してください。',
  indoorDataAccordionGroupSubtitle: '屋内データ',
  indoorDataVideoAccordionTitle: '動画',
  indoorDataVideoManagementButton: '動画管理',
  indoorDataItemStatusLoading: '動画を分析中です。しばらくお待ちください。',
  indoorDataItemStatusError: '動画分析中にエラーが発生しました。 再試行してください。',
  indoorDataItemRetryButton: '再分析',
  indoorVideoFolderMoveTitle: '動画',
  indoorVideoFileUploadButton: '360動画ファイル',
  indoorVideoFileUploadTitle: '動画ファイルを選択',
  indoorVideoFileUploadSelectFolder: 'フォルダーを選択',
  indoorVideoFileUploadPolicyMessage: '最大サイズ: 制限なし、ファイル形式: mp4',
  indoorVideoFileUploadDisableTooltip:
    'フォルダがありません。フォルダを追加したらアップロードしてください。',
  indoorVideoFileUploadDescription:
    'equirectangular & mp4形式で 形式でのビデオのアップロードをサポートします。\n他の形式のビデオについては、[ビデオ変換ガイド]を参照してください。',
  indoorVideoFileUploadGuideButton: 'ビデオ変換ガイド',
  indoorDataVideoSecondaryEditButton: '編集',
  indoorDataVideoSecondaryMenuTitle: '動画管理',
  indoorDataVideoFileDeleteConfirmTitle: 'ファイルを削除する',
  indoorDataVideoFileDeleteConfirmMessage: '${n}個のファイルが完全に削除されます。削除しますか？',
  indoorDataVideoFileDeleteConfirmCancel: 'キャンセル',
  indoorDataVideoFileDeleteConfirmDelete: '削除',
  indoorDataVideoPlayButton: '元の動画を見る',
  panoramaVideoHelpText: 'マウスでドラッグすると\n映像を360度見渡すことができます。',
  indoorVideoViewTypeToggleWalkthrough: 'ウォークスルー',
  indoorVideoViewTypeToggleVideo: '360 ビデオ',
  indoorVideoEmptyMessage:
    '360 映像がありません。\n新しいフォルダを追加した後にアップロードするか、\nMeissa Scanアプリを使用してアップロードできます。',
  walkthroughComparePageTitle: '現場比較',
  walkthroughCompareButton: '現場比較',
  walkthroughCompareButtonTooltip: 'ミニマップを作成すれば現場比較が可能です。',
  walkthroughCompareSelectLabelBase: '基準画像',
  walkthroughCompareSelectLabelCompared: '比較画像',
  walkthroughCompareGuidePopupTitle: '屋内現場比較ガイド',
  walkthroughCompareArrowButtonSnackbarMessage:
    '画像を個別に操作できます。共通動作はミニマップを活用してください。',
  walkthroughCompareMiniMapMarkerSnackbarMessage:
    '映像を一緒に操作できます。個々の動作は矢印を活用してください。',
  floorPlanAccordionTitle: '平面図 (ミニマップ)',
  floorPlanFileUploadButton: 'アップロード',
  floorPlanFileEmptyMessage: 'ファイルがありません',
  floorPlanFileUploadTitle: '平面図ファイルを追加',
  floorPlanFileUploadPolicyMessage: 'ファイル形式: png, jpg, pdf, dxf, 最大ファイル容量: 50MB',
  floorPlanFileUploadDescription:
    '間取り図とウォークスルーを直接配置してミニマップを作成することができます.\nファイルは1つだけアップロード可能で、間取り図全体のアップロードを推奨します',
  floorPlanFileDeleteTitle: 'ファイルの削除',
  floorPlanFileDeleteMessage:
    'フロア プランを削除すると、すべてのビデオ ミニマップが完全に削除されます。 消去してもよろしいですか？',
  floorPlanFileConverting: 'ファイルを変換中です。しばらくお待ちください',
  miniMapTitle: 'ミニマップ',
  createMiniMapButton: 'ミニマップの作成',
  miniMapIsNotConnectedText: 'ミニマップが作成されない。',
  miniMapSettingTitle: 'ミニマップの作成',
  miniMapSettingConfirmWithoutSavingTitle: 'ミニマップの作成を中止します',
  miniMapSettingConfirmWithoutSavingMessage:
    '今すぐ終了すると、ミニマップが保存されません。\n作業を中断しますか',
  miniMapSettingStepOneHelpMessage: '1.配置するのに適した場所にマーカーを移動してください',
  miniMapSettingStepTwoHelpMessage: '2.マーカーをドラッグして平面図に合わせて配置してください',
  miniMapResetConfirmTitle: 'ミニマップをリセット',
  miniMapResetConfirmMessage:
    'ウォークスルーとフロアプランのレイアウトが削除されます。ミニマップをリセットしたいですか？',
  miniMapResetConfirmCancel: 'キャンセル',
  miniMapResetConfirmRefresh: 'リセット',
  miniMapResetButtonTooltip: 'ミニマップをリセット',
  miniMapShowSwitchLabel: 'ミニマップを見る',
  laborerHistoryTableCellDate: '日付',
  laborerHistoryTableCellTime: '時間',
  laborerHistoryTableCellName: '名前',
  laborerHistoryTableCellWeak: '脆弱',
  laborerHistoryTableCellStatus: '状態',
  laborerHistorySearchEmptyMessage: 'その日付のデータはありません。\n別の日付を選択してください。',
  laborerHistoryEmptyMessage: '検索の結果は見つかりませんでした',
  laborerHistoryFilterToggleButtonDate: '日',
  laborerHistoryFilterToggleButtonWeek: '週',
  laborerHistoryFilterToggleButtonMonth: '月',
  laborerHistoryFilterToggleButtonDuration: '期間',
  laborerHistoryFilterAll: '全体',
  laborerHistoryFilterWeakOrNot: '脆弱',
  laborerHistoryFilterWeak: '脆弱',
  laborerHistoryFilterNotWeak: '脆弱ではない',
  laborerHistoryFilterStatus: '労働者の状態',
  laborerHistoryFilterStatusCheckIn: 'チェックイン',
  laborerHistoryFilterStatusActive: '場所の共有を開始します',
  laborerHistoryFilterStatusPause: '場所の共有を停止します',
  laborerHistoryFilterStatusLogout: 'チェックアウト',
  laborerHistoryFilterStatusTimeLogout: '時間ロチェックアウト',
  laborerHistoryFilterStatusDistanceLogout: '距離ロチェックアウト',
  laborerHistoryFilterTextSearchPlaceHolder: '名前検索',
  CLIENT_ERROR: 'リクエストを処理できません。',
  SERVER_ERROR: 'リクエストされた操作を処理できません。',
  TIMEOUT_ERROR: 'サーバーが応答できません。',
  CONNECTION_ERROR: 'サーバーに接続できません。',
  NETWORK_ERROR: 'ネットワーク接続を確認してください。',
  ER1000: '現在の権限では\nこちらの機能はご利用できません。',
  ER1001: 'セキュリティ保護のため、ログアウトしました。\nもう一度ログインしてください。',
  ER1002: '間違ったリクエストです。',
  ER1003: '入力したメールアドレスまたはパスワードを再確認してください。',
  ER1004: '招待の有効期限が過ぎました。\nプロジェクト担当者に再度招待をリクエストしてください。',
  ER1005: 'ページが見つかりませんでした。\nログイン画面に移動します。',
  ER1006: '既に登録済みのユーザーです。\nログイン画面に移動します。',
  ER1007: 'このメールアドレスで登録されたアカウントが存在しません。\n再度確認してください。',
  ER1008: 'パスワードが一致しません。',
  ER1010:
    'パスワードの入力エラーが5回を超過しました。\n「パスワードをお忘れですか」をタップしてください。',
  ER2000: 'エラーが発生しました。\nもう一度お試しください。',
  ER3000: '削除されたか、存在しません。',
  ER3001: 'リクエストを処理できません。',
  ER3002: '既に招待されたアカウントです。',
  ER3003: '分析は中止できません。\n現場状況が分析中かどうか確認してください。',
  ER3004: 'Pix4Dエンジンで分析中の現場状況は\n分析を中止できません。',
  ER3005: '既に分析中です。',
  ER3006: '分析が完了した現場状況は\n再度分析できません。',
  ER3007: '全てのGCPポイントが現場エリアからずれています。',
  ER3008: '無効なデータです。確認後、再試行してください。',
  ER3009: '分析する画像がありません。\n画像をアップロードして再度分析を依頼してください。',
  ER3010: '現場状況の分析が完了していません。\n分析完了後、再試行してください。',
  ER3012: '圧縮に必要な画像がありません。 再度ご確認ください。',
  ER3013:
    'アップロードしたファイル形式が正しくありません。\n確認後、再度アップロードしてください。',
  ER3014: '1分以内の再送信はできません。\nしばらくしてから再試行してください。',
  ER3015: '画像を30枚以上アップロードしてください。',
  ER3016:
    '地盤データファイルの最大アップロード数を超過しました。ページを再読み込み後に確認してください。',
  ER3017: '図面の変換状態が変更されました。\nページを再読み込み後に再確認してください。',
  ER3018: '図面の変換状態が変更されました。\nページを再読み込み後に再確認してください。',
  ER3019: '無効なデータです。フォーマットを確認し、再試行してください。',
  ER3020: 'GCPポイントが少なくとも3つ以上入力されたファイルをアップロードしてください。',
  ER3021: '現場エリア内のGCPが4つ未満です。',
  ER3022: '変換できません。\n再度ファイルを確認してください。',
  ER3023: 'オルソ画像をアップロードしてください。',
  ER3024: 'マッチングされた画像数が3枚以上のGCPが3つ以上必要です。',
  ER3025: '一度にアップロードできるファイル数は10個までです。',
  ER3026:
    'ファイルが大きすぎるためアップロードできません。\nアップロード可能なファイルサイズは1GBまでです。',
  ER3027:
    '高画質サポートが可能な最大面積を超過しました。一部の区間で画質が低くなる場合があります。',
  ER3028: '完了したイシューには、コメントの入力や編集はできません。',
  ER3029: '添付ファイルが容量を超過しました。',
  ER3030: '土工横断線の連動情報が既に存在しています。',
  ER3031: '数値表層モデル(DSM)がないため、計算できません。',
  ER3032: 'ファイルのヘッダー行が空欄です。',
  ER3033:
    "ヘッダー行の作成規則を守ってください。 '| GCP名 | x | y | x | y |' または '| x | y | x | y |'",
  ER3034: '座標値に小数点が入力されていない、もしくは空白の値があります。',
  ER3035: '計算するためには、分析済みの正規化植生指標(NDVI)ファイルが必要です。',
  ER3036: '全体の正規化植生指標の変換後に計算できます。',
  ER3037: '重複した名前は使用できません。',
  ER3038: '該当の日付に数値表層モデル(DSM)がありません。',
  ER3041: 'マークした画像が1枚以上ないと自動推薦機能を利用できません。',
  ER3042: '既に連動している項目です。',
  ER3043: '頂点が300個を超える面積は編集ができません。',
  already_referenced_error: 'その値は使用中なので削除できません。',
  not_found_error: 'エラーが発生しました。もう一度お試しください。',
  invalid_input_parameter_error: '入力した情報が確認されていません。もう一度お試しください。',
  value_already_exist_error: '동일한 이름의 앨범이 있습니다',
  annotationColorPickerTitle: 'カラー選択',
  dtmAddButtonTitle: 'DTMの追加',
  dtmAddPopupTitle: 'DTMの追加',
  dtmCreateButtonTitle: '直接作成',
  dtmFileUploadButtonDescriptionMessage:
    '他のプラットフォームで作成したDTM、tifファイルを複数アップロードできます。',
  dtmCreateButtonDescriptionMessage: 'メイサのDSMに基づいて目的の領域を削除してDTMを作成できます。',
  demAnalyzedAccordionSubtitle: '分析ファイル',
  demUploadAccordionSubtitle: 'アップロードファイル',
  demAccordionTitle: 'DEM(数値標高モデル)',
  dsmAccordionItemTitle: 'DSM(数値表層モデル)',
  dtmAccordionItemTitle: 'DTM(数値地形モデル)',
  basicWorking: '作業中',
  dtmCreateTopBarButtonTitle: 'DTMの生成',
  dtmCreateConfirmTitle: 'DTMの生成',
  dtmCreateConfirmMessage:
    'DTMファイルが生成されるまで最大3時間かかることがあります。このまま生成しますか？',
  dtmPolygonCreateTopBarMessage:
    '「領域指定」ボタンをクリックして、消去したい領域を描いてみてください。',
  dtmPolygonCreateButtonTitle: '領域指定',
  dtmPolygonDraftButtonTitle: '一時保存',
  dtmEditorCloseConfirmTitle: 'DTM保存確認,',
  dtmEditorCloseConfirmMessage:
    'このまま進むと、作業内容は保存されません。本当にエディタを終了しますか？',
  basicExit: '出る',
  dtmDraftSuccessSnackbarMessage: '一時保存されました。',
  dtmEditorCloseWithoutCreateConfirmTitle: 'DTM 生成のキャンセル',
  dtmEditorCloseWithoutCreateConfirmMessage:
    'まだDTMファイルが生成されていません。本当にエディタを終了しますか？',
  basicMoreInfo: '詳細情報',
  basicDeleteFile: 'ファイル削除',
  dtmDeleteConfirmMessage:
    '${n} ファイル削除時、全現場状況でファイルが永久に削除されます。本当に削除しますか？',
  demCompareRightSideMenuToggleTitle: '一度に比較',
  demComplareRightSideMenuToggleTooltip: 'DSM/DTMのヒストグラムを一度に比較できます。',
  dtmPolygonSnackbarMessage:
    '消したい領域より大きく指定してください。\n指定した領域の頂点に基づいて平坦化します。',
  dtmPolygonListTitle: '作成した領域',
  dtmPolygonRefreshButtonTitle: '初期化',
  dtmPolygonRefreshConfirmTitle: '領域の初期化',
  dtmPolygonRefreshConfirmMessage: '指定したすべての領域を初期化しますか？',
  dtmEditButtonTitle: 'DTMの修正',
  earthworkBaseSurfaceSubtitle: '基準面',
  earthworkBaseSurfaceToggleLabel: 'DTM',
  earthworkBaseSurfaceTooltipMessage: 'DTMファイルがある日付の容量は、DTMに基づいて計算されます。',
  earthworkPlanLevelSubtitle: '計画高',
  dtmBulkPolygonName: '領域',
  dtmCreateFailErrorMessage: '変換できないファイルです。ファイルを削除してもう一度お試しください。',
  dtmUploadSuccessSnackbarMessage: 'アップロード完了！分析が完了するにはもう少し時間がかかります。',
  dtmGuideCaptionBadSnackbar: 'オブジェクトの頂点',
  dtmGuideCaptionGoodSnackbar: 'オブジェクトより大きく指定',
  dtmCopyObjectDetectionResultButtonTitle: '客体探知複写',
  dtmCopyObjectDetectionResultSnackbarMessage:
    '客体探知結果を複写して消去したい領域をより\n楽に指定してください。',
  dtmNotSpecifiedAreaMessage:
    'まだ指定された領域がありません\n領域を新たに指定したり、客体を探知してインポートすることができます',
  dtmCopyObjectDetectionResultNotFoundMessage: '客体探知の結果がありません。 新たに探知しますか？',
  dtmCopyObjectDetectionResultDuplicateWarningMessage:
    'すでに複写済みの領域がある場合は、領域が重複して生成される可能性があります。',
  dtmCopyObjectDetectionResultConfirmButton: '複写',
  dtmObjectDetectionInProgressMessage: '客体探知中です。 画面を離れても探知が続きます。',
  dtmCopyObjectDetectionResultInProgressMessage: '客体探知の結果を複写しています。',
  dtmCopyObjectDetectionResultModalTitle: '客体探知複写',
  dtmObjectDetectionRunConfirmButton: '探知',
  dtmObjectDetectionModalTitle: '客体探知',
  snapshotMapLoadingMessage: '地図を作成しています。\nしばらくお待ちください。',
  zoneDrawingCompletionDateSelectButtonTitle: '完了日選択',
  zoneDrawingCompletionDateTooltipMessage:
    '面積別に完了日を選択すると、その日付以降の土工物量表から計算が除外されます。',
  basicReset: '初期化',
  basicApply: '適用',
  basicCompleted: '完了',
  zoneDrawingCompletionDateConfirmTitle: '完了日確認',
  zoneDrawingCompletionDateConfirmMessage: '${n}以降、土工集計表から計算が除外されます。',
  zoneDrawingCompletionDateResetConfirmTitle: '完了日の初期化',
  zoneDrawingCompletionDateResetConfirmMessage: '適用した完了日を初期化しますか？',
  issueShareWithKakao: 'カカオトークの共有',
  issueShareWithClipboard: 'リンクのコピー',
  issueShareDone: 'リンクがコピーされました。',
  vehicleLocation: '建設車両の位置',
  layerVehicleTitle: '建設車両の位置',
  vehicleTabLocationList: 'リアルタイム',
  vehicleTabLocationHistoryList: '以前の位置記録',
  vehicleLocationListEmpty:
    '稼働中の車両ログはありません。\nMeissa Guardにチェックインした車両の位置を確認できます。',
  vehicleSliderAlert: '再生可能な区間ではありません。',
  vehicleDatePickerFormat: 'PPP',
  vehicleGpsErrorSnackbarMessage:
    '作業現場環境により、地図上の位置と実際の車両の位置が異なる場合があります。',
  vehicleLocationHistoryListEmpty:
    '車両ログの以前の位置記録はありません。\n別の日付を確認してください。',
  vehicleLocationHistoryListTimeEmpty:
    '車両ログの以前の位置記録はありません。\n他の時間を確認してください。',
  vehicleHistory: '車両の運行記録',
  vehicleHistoryFilterStatus: '車両の種類',
  vehicleHistoryFilterAll: '全体',
  vehicleHistoryFilterStatusDumpTruck: 'ダンプトラック',
  vehicleHistoryFilterStatusExcavator: '掘削機',
  vehicleHistoryFilterStatusCrane: 'クレーン',
  vehicleHistoryFilterStatusDozer: 'ブルドーザー',
  vehicleHistoryFilterStatusLoader: 'ローダー',
  vehicleHistoryFilterStatusGrader: 'グレーダー',
  vehicleHistoryFilterStatusScraper: 'スクレーパー',
  vehicleHistoryFilterStatusRoller: 'ローラー',
  vehicleHistoryFilterStatusOther: 'その他',
  vehicleHistoryFilterTextSearchPlaceHolder: '労働者の名前と車両登録番号で検索します',
  vehicleHistoryTableCellStartDate: '労働開始時刻',
  vehicleHistoryTableCellEndDate: '労働終了時刻',
  vehicleHistoryTableCellTotalDriveTime: '総走行時間',
  vehicleHistoryTableCellVehicleType: '車両の種類',
  vehicleHistoryTableCellVehicleNumber: '車両登録番号',
  vehicleHistoryTableCellName: '名前',
  vehicleHistoryTableCellRoute: '経路',
  vehicleHistorySearchEmptyMessage: 'その日付のデータはありません。\n別の日付を選択してください。',
  vehicleHistoryEmptyMessage: '検索の結果は見つかりませんでした',
  layerVehicleTooltipHow:
    '車両管理者の携帯電話にMeissa Guardアプリをインストールした後、チェックインのための[プロジェクト情報]メニューからQRコードまたはURLを共有してください。',
  layerVehicleTooltipWhat:
    'Meissa Guardアプリでチェックインした車両の位置情報を1か月間記録し、閲覧することができます。',
  vehicleHistoryFilterToggleButtonDate: '日',
  vehicleHistoryFilterToggleButtonWeek: '週',
  vehicleHistoryFilterToggleButtonMonth: '月',
  vehicleHistoryFilterToggleButtonDuration: '期間',
  vehicleHistoryFilterDateRangeAlertMessage: '開始日以降の日付を選択してください。',
  vehicleMarkerPopupName: '名前 : ${n}',
  vehicleMarkerPopupPhone: '電話番号 : ${n}',
  vehicleMarkerPopupLocation: '最後の場所 : ${n}',
  vehicleMarkerPopupLocationHistory: '前の位置時間 : ${n}',
  vehicleHistoryTableCellViewRouteButton: '経路を表示',
  vehicleDrivingPopupInformationStartDate: '労働開始日',
  vehicleDrivingPopupTitle: '車両位置経路',
  vehicleDrivingPopupDescription:
    '経路をクリックするか、マウスを乗せると時間が表示され、もう一度クリックすると解除されます。キャプチャ時には経路をクリックしてからキャプチャしてください。',
  vehicleDrivingPopupMapCaptureButton: '画像キャプチャ',
  vehicleDrivingPopupMapCaptureSnackbarComment: '画像がキャプチャされました',
  deeplinkGuardServiceMessage: 'リアルタイム安全管理でより安全！',
  deeplinkMobileServiceMessage: 'モバイルで始める現場コミュニケーション',
  deeplinkRedirectButtonMessage: 'アプリで開く',
  laborerHistorySearchEndDateSelect: '終了日を選択',
  annotationEditSnackbarMessage: '完了ボタンをクリックすると、修正事項が反映されます。',
  dtmOffsetModificationBatchOffset: '一括適用',
  dtmOffsetModificationTriangulated: '自動平坦化',
  dtmOffsetModificationFixedOffset: 'ユーザーの高さの指定',
  dtmOffsetModificationValuePlaceholder: '調整する高さを入力します',
  dtmCreationGuideButtonTitle: '生成ガイド',
  dtmCreationModalTitle: 'DTM生成ガイド',
  dtmBulkPolygonHeaderTitle: '高さ (Z)',
  dtmOffsetModificationDescriptionMessage: '高さを入力しない場合、自動平坦化されます。',
  polygonMaxVertexErrorMessage: '頂点は最大300個まで作成できます。',
  dailySchedule: '仕事日誌',
  dailyScheduleArchiveTabName: 'アーカイブ',
  dailyScheduleSearchTabName: '検索',
  dailyScheduleMaintenanceEmptyScheduleMessage: 'ジョブジャーナルはありません。',
  dailyScheduleMaintenanceCreateScheduleMessage: '新しいジャーナルを作成してみてください。',
  dailyScheduleMaintenanceCreateScheduleButton: '新しいジャーナルの作成',
  dailyScheduleMaintenanceCopyScheduleButton: '以前のジョブジャーナルのコピー',
  dailyScheduleMaintenanceCreateScheduleLoadMessage:
    '新しい日記を作成しています。しばらくお待ちください。',
  dailyScheduleMaintenanceCreateScheduleRetryButton:
    'ジャーナルは生成されませんでした。もう一度お試しください。',
  dailyScheduleMaintenanceRetryButton: 'リトライ',
  dailyScheduleMaintenancePositionFullCourseButton: 'フルコース',
  dailyScheduleMaintenancePositionFullHallButton: 'フルホール',
  dailyScheduleMaintenanceCategorySelectMessage: '選択または作成',
  dailyScheduleMaintenanceCreateButton: '作成',
  dailyScheduleMaintenanceSaveButton: '保存',
  dailyScheduleMaintenanceEditButton: '編集',
  dailyScheduleMaintenanceDeleteButton: '削除',
  dailyScheduleMaintenanceConfirmButton: '確認',
  dailyScheduleMaintenanceCancelButton: 'キャンセル',
  dailyScheduleMaintenanceAddRowButton: '追加',
  dailyScheduleReagentFertilizerSelectButton: '試薬カテゴリの選択',
  dailySchedulePositionCategorySelectMessage: '場所の選択',
  dailySchedulePositionCategoryEtcMessage: 'その他',
  dailyScheduleMaintenanceCreateErrorMessage: 'エラーが発生しました。 もう一度お試しください。',
  dailyScheduleCategoryDuplicateNameErrorMessage: '同じ名前があります。',
  dailyScheduleCategoryDeleteConfirmMessage: 'その値は永久に削除されます。本当に削除しますか？',
  dailyScheduleCategoryDeleteErrorMessage: 'その値は使用中なので削除できません。',
  dailyScheduleMaintenanceEditBreakAwayButton: '出る',
  dailyScheduleMaintenanceEditBreakAwayMessage:
    'このまま進むと、作業内容は保存されません。本当にページを出ますか？',
  dailyScheduleMaintenanceDeleteConfirmMessage:
    'そのジョブジャーナルが永久に削除されます。本当に削除しますか？',
  dailyScheduleSearchDateColumnName: '作業日記の日付',
  dailyScheduleSearchKeywordPlaceholderName: '仕事, 試薬/施肥 検索',
  dailyScheduleSearchEmptyScheduleMessage: '検索の結果は見つかりませんでした',
  dailyScheduleMaintenanceLoadingMessage: '作業日を確認しています。しばらくお待ちください。',
  dailyScheduleMaintenanceCommentLabel: '備考',
  dailyScheduleMaintenanceWeatherLabel: '天気',
  dailyScheduleMaintenanceWeatherLowestTemperatureLabel: '最低温度',
  dailyScheduleMaintenanceWeatherHighestTemperatureLabel: '最高温度',
  dailyScheduleMaintenanceWeatherHumidityLabel: '湿度',
  dailyScheduleMaintenanceWeatherPrecipitationLabel: '降雨（雪）量',
  dailyScheduleMaintenanceMowingHeightLabel: '予告',
  dailyScheduleMaintenanceMowingHeightGreenLabel: 'グリーン',
  dailyScheduleMaintenanceMowingHeightGreenCollarLabel: 'グリーンカラー',
  dailyScheduleMaintenanceMowingHeightTeeLabel: 'ティー',
  dailyScheduleMaintenanceMowingHeightFairwayLabel: 'フェアウェイ',
  dailyScheduleMaintenanceMowingHeightRoughLabel: 'ラフ',
  dailyScheduleMaintenanceMowingHeightGreenSpeedLabel: 'グリーンスピード',
  dailyScheduleMaintenanceCategoryLabel: '区分',
  dailyScheduleMaintenanceReagentFertilizerLabel: '試薬/施肥',
  dailyScheduleMaintenanceReagentNameLabel: '試薬名',
  dailyScheduleMaintenanceFertilizerNameLabel: '詩名',
  dailyScheduleMaintenanceReagentFertilizingUsageLabel: '使用量',
  dailyScheduleMaintenanceLocationLabel: '場所',
  dailyScheduleMaintenanceTaskAssigneeLabel: 'ワーカー',
  dailyScheduleMaintenanceTaskLabel: '仕事',
  dailyScheduleMaintenanceTaskContentLabel: '作業内容',
  dailyScheduleMaintenanceTaskNameLabel: '作業名',
  dailyScheduleMaintenanceDescriptionLabel: '詳細',
  dailyScheduleMaintenanceAttendanceLabel: '勤怠',
  dailyScheduleMaintenanceAttendanceEmployeeLabel: '従業員',
  dailyScheduleMaintenanceAttendanceEmployeeCountLabel: '従業員数',
  dailyScheduleMaintenanceAttendanceMaleLabel: '日用（男）',
  dailyScheduleMaintenanceAttendanceMaleCountLabel: '日用（男）数',
  dailyScheduleMaintenanceAttendanceFemaleLabel: '日用（女）',
  dailyScheduleMaintenanceAttendanceFemaleCountLabel: '日用（女）数',
  dailyScheduleMaintenanceAttendanceOffDutyLabel: '休業',
  dailyScheduleMaintenanceAttendanceOffDutyCountLabel: '定員数',
  dailyScheduleMaintenanceAttendanceTotalPersonnelLabel: '総人数',
  dailyScheduleMaintenanceAttendanceTotalPersonnelCountLabel: '総人数',
  dailyScheduleMaintenanceReagentTypeGermicideLabel: '殺菌剤',
  dailyScheduleMaintenanceReagentTypeInsecticideLabel: '殺虫剤',
  dailyScheduleMaintenanceReagentTypeHerbicideSelectiveLabel: '除草剤（選択性）',
  dailyScheduleMaintenanceReagentTypeHerbicideNoneSelectiveLabel: '除草剤（非選択性）',
  dailyScheduleMaintenanceReagentTypeGrowthRegulatorLabel: '成長調整剤',
  dailyScheduleMaintenanceReagentTypeOtherLabel: 'その他',
  planLevelFileUploadDescriptionMessage:
    '適用日が重複している場合は、最後にアップロードしたファイルが適用されます。',
  planLevelFileUploadInputPlaceholderMessage: '計画高ファイルを選択してください。',
  planLevelSubgradeType: '計画高の区分',
  basicType: '区分',
  basicDateOfApplication: '適用日',
  planLevelDatePickerPlaceholderMessage: '適用する日付を選択してください。',
  planLevelSubgradeTypeProjectApproval: '事業承認',
  planLevelSubgradeTypeDetailDesign: '実施設計',
  basicUploadInformation: 'アップロード情報',
  stratumSubgradeType: '地層タイプ',
  stratumSubgradeTypeRockClassification: 'がん判定',
  stratumSubgradeTypeGeotechnicalInvestigation: '地盤調査',
  basicAddNewItem: '新規追加',
  basicDetailInfo: '詳細情報',
  drawingVectorEditorLeftSideTitle: '生成ゾーン',
  addPolygonVectorButtonTitle: '施工範囲を追加',
  basicDescription: '説明',
  drawingVectorEditorDescriptionPlaceholderMessage: 'どのように変更されたかを記録してください。',
  drawingVectorVersionDifferenceInformationLabel: '修正情報',
  drawingVectorVersionInformationEditButtonTitle: '履歴情報の修正',
  drawingVectorVersionDeleteButtonTitle: '履歴を削除',
  drawingVectorVersionDeleteConfirmContentMessage:
    '${n} 削除時、土工集計表計算からも除外されます。本当に削除しますか？',
  earthworkDetailTable: '土工集計表',
  earthworkDetailTableOptionDialogTitle: '土工集計表の日付指定',
  earthworkDetailTableOptionDialogDescriptionMessage:
    'DSMやGCPがない場合、計画高や施工範囲が設定されていない日付では、土工集計を行うことができません。',
  earthworkDetailTableOptionSelectDate: '日付指定',
  earthworkDetailTableCompleteSnackbarMessage: 'すべての計算が完了しました。',
  basicNoData: 'データなし',
  earthworkSummaryTableWarningMessage:
    '細別項目の数量を正確に入力してください。数量の合計と日付別の合計が一致する必要があります。',
  earthworkSummaryTableDescriptionMessage:
    '詳細集計表は、土工集計表から抽出したデータです。後で土工集計表が変更されても、 詳細集計表には反映されません。',
  earthworkSummaryTableSuccessToastMessage: '集計表の作成が完了しました。',
  earthworkSummaryTableSuccessMessage: '数量合計と日付別合計が一致します。',
  basicTitle: '題目',
  drawingVectorEditButtonTitle: '施工範囲修正',
  drawingVectorVersionRightSideMenuTitle: '変更履歴',
  drawingVectorVersionButtonTitle: '変更履歴',
  basicLatest: '最新',
  earthwork: '土工',
  earthworkArea: '施工管理',
  earthworkAreaPlan: '施工管理計画',
  earthworkAreaPlanEditorHeaderMessage: '施工管理範囲の修正中です',
  earthworkAreaPlanVesionSaveDialogTitle: '施工管理計画を保存',
  earthworkAreaPlanVersionSelectDatePlaceholder: '適用する日付を選択',
  earthworkTableAccordionTitle: '土工集計表',
  earthworkTableGuideDialogDescirption:
    '土工集計表は施工管理計画と計画高が揃っていないと生成されません。',
  earthworkTableGuideTitle: '土工集計表作成ガイド',
  basicHasData: 'あり',
  basicDate: '日付',
  earthworkSummaryTable: '詳細集計表',
  earthworkSummaryTableDeleteConfirmTitle: '集計表の削除',
  earthworkSummaryTableCellAddButtonTitle: 'アイテムを追加',
  earthworkSummaryTableHeaderStratum: '地質',
  earthworkSummaryTableHeaderMaterial: '細別',
  earthworkSummaryTableHeaderQuantity: '数量',
  earthworkDetailTableResourceStratumCell: '${n1}他${n2}個',
  earthworkSummaryTableCreationButtonTitle: '集計表で抽出',
  earthworkSummaryTableCreationConfirmTitle: '集計表で抽出',
  earthworkSummaryTableCreationConfirmPlaceholderMessage: '集計表の名前を入力してください',
  backToMapPageTooltipMessage: '地図に戻る',
  earthworkDetailTableInformationQuantityTitle: '計画数量',
  earthworkDetailTableInformationQuantityContent: '入力されていない計画数量はゼロで表示されます。',
  earthworkDetailTableInformationDataTitle: 'データなし',
  earthworkDetailTableInformationDataContent:
    '該当日に適用された「地層高、「計画高」、「施工範囲」がないか削除された場合、表に「なし」と表示されます。',
  creatingInformation: '生成情報',
  fileConversionErrorMessage:
    'ファイルの変換中に問題が発生しました。チャンネルトークでお問い合わせください。',
  dailyScheduleExcelExportTitle: 'エクセルでエクスポート',
  dailyScheduleExcelExportBeforeStartMonthErrorMessage: '開始日以降の日付を選択してください。',
  dailyScheduleExcelExportThirteenMonthDiffErrorMessage: '最大1年単位で選択可能です。',
  dailyScheduleExcelExportBreakAwayTitle: 'Excelファイルにエクスポートをキャンセル',
  dailyScheduleExcelExportCalloutMessage:
    'ジャーナルのない日付がある場合、Excelファイルにエクスポートしてもその日付は空の状態にエクスポートされます。',
  dailyScheduleExcelExportSelectDateLabel: '選択期間',
  dailyScheduleExcelExportMaxSelectDateMessage: '最大選択期間：1年',
  dailyScheduleExcelExportErrorMessage:
    'エラーが発生したため、入力した情報が確認されませんでした。もう一度お試しください。',
  stratumSubgradeTypeIsEmpty: '区分を選択してください。',
  stratumEffectiveDateIsEmpty: '適用日を選択してください。',
  quantityVolumeOptionEmptyDsm: 'DSM 없음',
  earthworkAreaDetailSubtitle: '土工エリア詳細',
  earthworkSummaryTableEditConfirmTitle: '集計表名の編集',
  basicDownloadOriginalFile: 'オリジナルファイルのダウンロード',
  copyPreviousScheduleSelectDateMessage: '日付の選択',
  copyPreviousSchedulePreviewTitle: 'プレビュー',
  copyPreviousScheduleButtonLabel: 'コピーして作成',
  copyPreviousScheduleEmptyScheduleMessage: '選択した日付のデータが存在しません。',
  copyPreviousScheduleSuccessMessage: 'ジャーナルが作成されました。',
  basicDuplicateNameErrorMessage: 'すでに使用中の名前です。',
  earthworkResourceLegacyInfoTooltipMessage:
    '2024-08-30より前のデータにはアップロード情報はありません。',
  earthworkAreaPlanFileUploadPopupTitle: '土工区域計ファイルの追加',
  basicCalculatingStatusMessage: '計算中です。しばらくお待ちください。',
  photoBox: 'フォトボックス',
  photoBoxPhoto: '写真',
  photoBoxVideo: '動画',
  photoBoxSnapshotMediaListMessage: '最近アップロードされた${n}件の写真/動画のみが表示されます。',
  photoBoxSnapshotMediaListEmptyContentsBoxMessage:
    '保存された写真/動画がありません。\nフォトボックス管理から写真/動画をアップロードしてください。',
  photoBoxManagement: 'フォトボックス管理',
  photoBoxAlbum: 'アルバム',
  photoBoxMediaEmptyContentsBoxMessage:
    '保存された写真/動画がありません。\n写真や動画をドラッグするか、ボタンを押してアップロードしてください。',
  photoBoxAlbumDetailEmptyContentsBoxMessage: 'アルバムに写真/動画がありません。',
  photoBoxMediaFileLabel: '写真/動画',
  photoBoxMediaFileUploadLabel: '写真/動画アップロード',
  photoBoxMemoLabel: 'メモ',
  photoBoxMemoPlaceholderMessage: 'メモを入力してください',
  photoBoxEditMediaSectionCalloutMessage: '写真/動画を複数選択すると、メモが一括で適用されます。',
  photoBoxMediaFileUploadStopMessage:
    'アップロードが完了していません。完了したファイルはキャンセルされません。本当にアップロードをキャンセルしますか？',
  photoBoxMediaFileUploadRetryMessage: 'アップロードに失敗した写真/動画があります。',
  photoBoxMediaFileUploadLimitCountMessage: '写真/動画のアップロードは最大${n}件まで可能です。',
  photoBoxAddToAlbumLabel: 'アルバムに追加',
  photoBoxUploadDateLabel: 'アップロード日時',
  photoBoxDateOfShootingLabel: '撮影日時',
  photoBoxDeleteMediaCallbackMessage:
    '写真/動画を削除しますか？\nアルバムに保存された写真/動画も一緒に削除されます。',
  photoBoxAddToNewAlbumLabel: '新しいアルバムを追加',
  photoBoxAddToAlbumTitle: 'このアルバムに追加',
  photoBoxNewAlbumLabel: '新しいアルバム ${n}',
  photoBoxAlbumEmptyContentBoxMessage: 'アルバムを追加して写真/動画を管理してください。',
  photoBoxCreateNewAlbumTitle: '新しいアルバムを作成',
  photoBoxAddNewAlbumMessage: 'アルバムを作成した後、写真/動画を追加してください。',
  photoBoxDeleteAlbumCallbackTitle: 'アルバムを削除',
  photoBoxDeleteAlbumCallbackContentMessage:
    '選択したアルバムを削除しますか？\nアルバム内の写真は削除されません。',
  photoBoxUpdateAlbumNameLabel: 'アルバム名を変更',
  photoBoxDeleteFromAlbumCallbackTitle: 'アルバムから削除',
  photoBoxDeleteFromAlbumCallbackContent: '${n}枚の写真/動画をこのアルバムから削除しますか？',
  photoBoxDeleteMediaCallbackContent:
    '${n}枚の写真/動画を削除しますか？\nアルバムに保存された写真/動画も一緒に削除されます。',
  photoBoxDropzoneGuideSnackbarContentMessage:
    'ファイルをここにドラッグ&ドロップするとアップロードが開始されます。',
  photoBoxAddMediaToAlbumDuplicatedMessage:
    '選択した写真/動画が重複しているため、インポートできません。',
  photoBoxAddMediaToAlbumSuccessMessage: '‘${n1}’アルバムに写真/動画が${n2}枚追加されました。',
  earthworkFileSettingTitle: '土工ファイル管理',
  pileFileSettingTitle: 'パイルファイルの管理',
  basicPile: 'パイル',
  pileAIdetectionAccordionTitle: 'AI杭検出',
  basicInDev: '開発中',
  basicNewFolder: 'フォルダ追加',
  basicFileUpload: 'ファイル追加',
  earthworkFileSettingEmptyMessage: 'がん判定や土工に関連する根拠資料をアップロードしてください。',
  pileFileSettingEmptyMessage: '杭に関連する基礎資料をアップロードしてください。',
  fileSettingEmptyMessage: 'ファイルがありません。',
  fileSettingUploadNumberLimitMessage: 'ファイルアップロードは一度に20個まで可能です。',
  fileSettingUploadSizeLimitMessage: '50MB以下のファイルのみアップロード可能です。',
  earthworkFileSettingUploadPopupTitle: '土工ファイルの追加',
  basicEditFileInformation: 'ファイル情報の変更',
  basicMove: '移動',
  basicDownload: 'ダウンロード',
  basicFileName: 'ファイル名',
  fileSettingFolderMoveNoFolderErrorMessage:
    '移動するフォルダがありません。フォルダを追加して移動します。',
  fileUploadToFolderButtonTitle: 'このフォルダにファイルを追加する',
  basicEditName: '名前を編集',
  fileSettingFolderNameDuplicateErrorMessage: 'すでに使用しているフォルダ名です。',
  fileSettingDeleteFolderMessage:
    '${n}を削除すると、フォルダ内のすべてのファイルが削除されます。本当に削除しますか？',
  fileArchiveTypeEarthwork: '土工ファイル',
  fileArchiveTypePile: 'パイルファイル',
  fileSettingFolderMoveDialogTitle: '移動する場所を選択',
  basicCurrentLocation: '現在位置',
  fileSettingFolderMoveConfirmButton: 'このフォルダに移動',
  fileSettingDeleteFileMessage:
    '${n}を削除すると、現場全体でファイルが永久に削除されます。本当に削除しますか？',
  basicMore: 'さらに表示',
  photoBoxMediaLoadMoreLabel: 'さらに読み込む',
  panoramaTypeSelectModalTitle: '360°パノラマ現場状況を追加',
  panoramaImageUploadTitle: 'パノラマ画像をアップロード',
  panoramaImageUploadDescription:
    'パノラマ画像をアップロードすると、360°ビューで見ることができます。',
  panoramaStitchingTitle: 'パノラマ画像にマージ',
  panoramaStitchingDescription:
    'ドローンのパノラマモードで撮影した画像をアップロードするとパノラマにマージしてくれます。',
  panoramaStitchingImageUploadPopupTitle: 'マージする画像を追加',
  panoramaStitchingImageUploadDescription:
    'ファイル形式：jpg、jpeg、png（フルファイル容量制限：${n}MB)',
  panoramaStitchingImageUploadMinDescription: 'アップロードは少なくとも${n}枚以上可能です。',
  panoramaStitchingImageDownloadFileName: '[マージされたパノラマ] ${n1}(${n2})。',
  panoramaStitchingSelectImageTitle: '画像選択 (${n1}/${n2})',
  panoramaStitchingUploadLimitWarningMessage: '최最大ファイルアップロード数を超えました。',
  panoramaStitchingUploadTotalFileSizeLimitWarningMessage:
    '最大ファイルアップロードサポート容量を超えました。(${n}MB)\n容量を減らしてもう一度アップロードしてください。',
  panoramaStitchingUploadSingleFileSizeLimitWarningMessage:
    '画像容量が${n}MBを超えるファイルはアップロードできません。',
  fileArchivePhotoBoxFileImageType: 'フォトボックス写真',
  fileArchivePhotoBoxFileVideoType: 'フォトボックス動画',
  photoBoxFileSelectLimitAlertMessage: '一度に${n}個まで選択できます。',
  photoBoxSnapshotPositionDeleteAlertMessage:
    'この写真/動画の位置が削除されます。\n本当に位置をリセットしますか？',

  panoramaImageDownloadButtonTitle: 'マージされたパノラマダウンロード',
  panoramaOriginalImageDownloadButtonTitle: 'オリジナル画像をダウンロード',

  basicSharingEmail: 'メールで共有',
  recipeintUserBoxPlaceholder: '選択したユーザーを表示',
  projectUserSearchPlaceholder: '名前検索（プロジェクト参加者のみ表示）',
  sharingSuccessSnackbarMessage: '共有が完了しました。',
  basicDeletedFileErrorMessage: '削除されたファイルです。もう一度確認してください。',
};
