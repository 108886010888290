import { atom } from 'jotai';

type FolderState = {
  id: number;
  expanded?: boolean;
};

type PolygonState = {
  id: number;
  folderId?: number;
  /* visible이 필요한 모든 곳에서 사용 */
  visible?: boolean;
  /* map에서만 보여줘야하는 경우 사용 */
  visibleOnMap?: boolean;
};

const polygonEnabledAtom = atom<boolean>(false);
const polygonAccordionExpandedAtom = atom<boolean>(false);
const polygonStatesAtom = atom<PolygonState[]>([]);
const polygonFolderStatesAtom = atom<FolderState[]>([]);

export const polygonAtom = {
  enabled: polygonEnabledAtom,
  expanded: polygonAccordionExpandedAtom,
  states: polygonStatesAtom,
  folderStates: polygonFolderStatesAtom,
};
