import axios from 'axios';
import { baseUrl } from 'common/urls';
import { StaticFileType } from './types';

export default {
  async readFile({ type }: { type: StaticFileType }) {
    const response = await axios.get(`${baseUrl}/static/${type}`);
    return response.data;
  },
  async listProj4Definition() {
    const response = await axios.get(`${baseUrl}/static/coordinates/used`);
    return response.data?.results || [];
  },
  async listCoordinates({ search, limit }: { search: string; limit: string }) {
    const response = await axios.get(
      `${baseUrl}/static/coordinates?search=${search}&limit=${limit}`,
    );
    return response.data?.results || [];
  },
};
