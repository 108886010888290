import { AxiosError } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { Cctv } from 'infrastructure/query/cctv/types';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export function useCctvListQuery(
  zoneId: number,
  options?: UseQueryOptions<Cctv[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.list(zoneId), () => api.list({ zoneId }), {
    enabled: Boolean(zoneId && enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    onError: () => {},
    ...rest,
  });
}

export function useCctvQuery(zoneId: number, cctvId: number) {
  return useQuery(keys.detail(zoneId, cctvId), () => api.read({ zoneId, cctvId }), {
    enabled: !!zoneId && !!cctvId,
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}

export function useCctvUrlQuery(data: Parameters<typeof api.url>[0]) {
  return useQuery(keys.url(data), () => api.url(data), {
    enabled: !!data?.subUrl,
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}

export function useCctvMutation() {
  const queryClient = useQueryClient();
  const createCctv = useMutation(
    ({ zoneId, data }: Parameters<typeof api.create>[0]) => api.create({ zoneId, data }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.list(v?.zoneId));
      },
    },
  );
  const updateCctv = useMutation(
    ({ zoneId, cctvId, data }: Parameters<typeof api.update>[0]) =>
      api.update({ zoneId, cctvId, data }),
    {
      onSuccess: (res, v) => {
        queryClient.invalidateQueries(keys.detail(v.zoneId, v.cctvId));
        queryClient.invalidateQueries(keys.list(v?.zoneId));
      },
    },
  );
  const deleteCctv = useMutation(
    ({ zoneId, cctvId }: Parameters<typeof api.delete>[0]) => api.delete({ zoneId, cctvId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.list(v?.zoneId));
      },
    },
  );
  return { createCctv, updateCctv, deleteCctv };
}

export function useFocusedCctvQuery(zoneId: number) {
  return useQuery<Cctv>(keys.focused(zoneId), {
    initialData: null as Cctv,
    enabled: !!zoneId,
    staleTime: Infinity,
  });
}
