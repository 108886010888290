const MAIN = '#4CAF50';
const DARK = '#388E3C';
const LIGHT = '#81C784';
const CONTRAST_TEXT = '#FFFFFF';

export const success = {
  MAIN,
  DARK,
  LIGHT,
  CONTRAST_TEXT,
};
