import { useMatchParams } from 'components/common/hooks';
import { useAtomValue } from 'jotai';
import 'leaflet-rotate';
import { useProjectQuery } from 'infrastructure/query';
import { bearingAtom } from 'stores/v3/2dMap/map';

export default function useMapRotate(enabled: boolean, showRotateControl = true) {
  const { projectId, zoneId } = useMatchParams();
  const { data: project } = useProjectQuery(projectId);
  const bearing = useAtomValue(bearingAtom);

  const ready = project?.isMapRotation !== undefined && project?.isMapRotation !== null;
  const rotate = enabled && Boolean(project?.isMapRotation);

  const mapOptions = enabled
    ? {
        rotate: true,
        touchRotate: rotate,
        bearing: zoneId === bearing.zoneId ? bearing.value : 0,
        rotateControl: showRotateControl
          ? {
              closeOnZeroBearing: false,
              position: 'topleft',
            }
          : false,
      }
    : {};

  return { ready, mapOptions };
}
