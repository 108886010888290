import IconMarkerAdd from 'assets/images/iconMarkerAdd.svg';
import { DEFAULT_CIRCLE_MARKER_RADIUS } from 'common/policies/2d';
import { divIcon, icon, latLngBounds } from 'leaflet';

export function getBoundsCenter(positions: { latitude: number; longitude: number }[]) {
  const firstPosition = { lat: positions[0]?.latitude, lng: positions[0]?.longitude };
  const bounds = latLngBounds(firstPosition, firstPosition);
  positions.forEach((position) => {
    bounds.extend({ lat: position.latitude, lng: position.longitude });
  });
  return bounds.getCenter();
}

export function getAnnotationNumberMarker(color: string, number: number) {
  const fontSize = (() => {
    const numberString = number.toString();
    if (numberString.length === 3) return '0.6875rem';
    if (numberString.length === 4) return '0.5rem';
    return '1rem';
  })();
  return divIcon({
    html: `
      <div 
        style="color: ${color};
          width: 1.5rem !important;
          height: 1.5rem !important;
          border-radius: 1.125rem;
          text-align: center;
          margin-left: -0.75rem !important;
          margin-top: -0.75rem !important;
          font-size: ${fontSize};
          line-height: 1.125rem;
          border: 0.125rem solid;
          background-color: white;
          padding-top: 1px;"
      >
          ${number.toString()}
      </div>
    `,
    iconAnchor: [0, 0],
    iconSize: [1, 1],
  });
}

export function getMarkerAddIcon() {
  return icon({
    iconUrl: IconMarkerAdd,
    iconSize: [DEFAULT_CIRCLE_MARKER_RADIUS * 2, DEFAULT_CIRCLE_MARKER_RADIUS * 2],
  });
}
