import axios, { AxiosError } from 'axios';
import {
  DEFAULT_POLLING_INTERVAL,
  DEFAULT_QUERY_STALE_TIME,
  READ_ELEVATION_GROUP_INTERVAL,
} from 'common/policies/request';
import { Error, INVALID_ADDR } from 'common/types';
import { samplingElevationData } from 'components/snapshot/detailsPage/sideMenu/annotation/elevation/utils';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import {
  checkNeedPolling,
  CreateElevationType,
  CreateStratumElevationsType,
  EarthworkCrossingLinesElevations,
  Elevation,
  ElevationQueryType,
  ElevationType,
} from './types';

export function useElevationQuery({
  snapshotId,
  annotationId,
  referResourceId,
  referSnapshotId,
  elevationType,
  permission,
}: ElevationQueryType) {
  const [interval, setInterval] = useState<number | false>(false);
  const [elevationId, setElevationId] = useState<number>(null);
  const [elevationRelatedSnapshotId, setElevationRelatedSnapshotId] = useState<number>(null);
  const { createElevation } = useElevationMutation();

  useEffect(() => {
    if (!permission || !snapshotId || !annotationId) return;
    mutate();
  }, [snapshotId, annotationId, referResourceId, referSnapshotId, permission]);

  function mutate() {
    if (!referResourceId && !referSnapshotId) return;

    const createData: CreateElevationType['data'] = referSnapshotId
      ? { type: 'STANDARD', referSnapshotId }
      : { type: 'STANDARD', referResourceIds: [referResourceId] };

    createElevation.mutate(
      {
        snapshotId,
        annotationId,
        data: createData,
      },
      {
        onSuccess: (res) => {
          setElevationId(res.id);
          setElevationRelatedSnapshotId(res.elevationRelatedSnapshotId);
        },
      },
    );
  }

  const isCompare = Boolean(referSnapshotId);

  const { data, status } = useQuery<Elevation>(
    keys.elevation.detail(elevationId),
    () =>
      isCompare
        ? api.elevation.readCompare({ snapshotId, annotationId, referSnapshotId })
        : api.elevation.read({ elevationRelatedSnapshotId, annotationId, elevationId }),
    {
      enabled: Boolean(permission && elevationId && (isCompare || elevationRelatedSnapshotId)),
      refetchInterval: interval,
      retry: false,
      onSuccess: (elev) => {
        if (checkNeedPolling(elev)) {
          !interval && setInterval(DEFAULT_POLLING_INTERVAL);
        } else {
          setInterval(false);
        }
      },
      onError: (err) => {},
    },
  );
  const graphData = useElevationGraphData(data, elevationType);
  return { data, mutate, status, graphData };
}

export function useElevationGraphData(elevation: Elevation, elevationType: ElevationType) {
  const { data } = useQuery(
    keys.elevation.graph(elevation?.jsonFileUrl),
    () => axios.get(elevation?.jsonFileUrl),
    {
      select: (res) => samplingElevationData(res?.data, elevationType, +elevation?.length),
      enabled:
        !!elevation?.jsonFileUrl &&
        !['', 'error', 'empty'].includes(elevation?.resultCode) &&
        elevation?.jsonFileUrl !== INVALID_ADDR,
    },
  );
  return data;
}

export function useStratumElevationQuery({
  snapshotId,
  annotationId,
  stratumResourceList,
  permission,
}: CreateStratumElevationsType) {
  return [
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[0]?.id,
      elevationType: 'REFER_STRATUM1',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[1]?.id,
      elevationType: 'REFER_STRATUM2',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[2]?.id,
      elevationType: 'REFER_STRATUM3',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[3]?.id,
      elevationType: 'REFER_STRATUM4',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[4]?.id,
      elevationType: 'REFER_STRATUM5',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[5]?.id,
      elevationType: 'REFER_STRATUM6',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[6]?.id,
      elevationType: 'REFER_STRATUM7',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[7]?.id,
      elevationType: 'REFER_STRATUM8',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[8]?.id,
      elevationType: 'REFER_STRATUM9',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[9]?.id,
      elevationType: 'REFER_STRATUM10',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[10]?.id,
      elevationType: 'REFER_STRATUM11',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[11]?.id,
      elevationType: 'REFER_STRATUM12',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[12]?.id,
      elevationType: 'REFER_STRATUM13',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[13]?.id,
      elevationType: 'REFER_STRATUM14',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[14]?.id,
      elevationType: 'REFER_STRATUM15',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[15]?.id,
      elevationType: 'REFER_STRATUM16',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[16]?.id,
      elevationType: 'REFER_STRATUM17',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[17]?.id,
      elevationType: 'REFER_STRATUM18',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[18]?.id,
      elevationType: 'REFER_STRATUM19',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[19]?.id,
      elevationType: 'REFER_STRATUM20',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[20]?.id,
      elevationType: 'REFER_STRATUM21',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[21]?.id,
      elevationType: 'REFER_STRATUM22',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[22]?.id,
      elevationType: 'REFER_STRATUM23',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[23]?.id,
      elevationType: 'REFER_STRATUM24',
      permission,
    }),
    useElevationQuery({
      snapshotId,
      annotationId,
      referResourceId: stratumResourceList?.[24]?.id,
      elevationType: 'REFER_STRATUM25',
      permission,
    }),
  ];
}

export function useElevationDxfQuery(snapshotId: number, dxfFileKey: string) {
  return useQuery(
    keys.elevation.dxf(snapshotId, dxfFileKey),
    () => api.elevation.readElevationDxf({ snapshotId, dxfFileKey }),
    {
      enabled: Boolean(snapshotId && dxfFileKey),
      refetchInterval: (d) =>
        d?.progressStatus === 'IN_PROGRESS' ? DEFAULT_POLLING_INTERVAL : false,
    },
  );
}

export function useElevationMutation() {
  const queryClient = useQueryClient();
  const createElevation = useMutation(
    (payload: Parameters<typeof api.elevation.create>[0]) => api.elevation.create(payload),
    { onError: (err) => {} },
  );

  const createDxf = useMutation(
    (payload: Parameters<typeof api.elevation.createDxf>[0]) => api.elevation.createDxf(payload),
    {
      onError: (err) => {
        // 에러 처리를 elevation dxf read 요청에서 해주고 있어서 할 필요 없음
      },
    },
  );

  const createDxfOfEarthworkCrossingLines = useMutation(
    (payload: Parameters<typeof api.elevation.createDxfOfEarthworkCrossingLines>[0]) =>
      api.elevation.createDxfOfEarthworkCrossingLines(payload),
  );

  const calculateEarthworkCrossingLines = useMutation(
    (payload: Parameters<typeof api.elevation.calculateEarthworkCrossingLines>[0]) =>
      api.elevation.calculateEarthworkCrossingLines(payload),
    {
      onSuccess: (res, v) => {
        queryClient.invalidateQueries(
          keys.elevation.earthworkCrossingLines(v.snapshotId, v.resourceId, v.planLevelId),
        );
      },
    },
  );
  return {
    createElevation,
    createDxf,
    createDxfOfEarthworkCrossingLines,
    calculateEarthworkCrossingLines,
  };
}

export function useEarthworkCrossingLinesElevationListQuery(
  {
    snapshotId,
    resourceId,
    planLevelId,
  }: Parameters<typeof api.elevation.readEarthworkCrossingLines>[0],
  options?: UseQueryOptions<
    EarthworkCrossingLinesElevations,
    AxiosError<Error>,
    EarthworkCrossingLinesElevations
  >,
) {
  const { enabled = true, ...rest } = options ?? {};
  return useQuery<EarthworkCrossingLinesElevations>(
    keys.elevation.earthworkCrossingLines(snapshotId, resourceId, planLevelId),
    () => api.elevation.readEarthworkCrossingLines({ snapshotId, resourceId, planLevelId }),
    {
      enabled: Boolean(snapshotId && resourceId && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      refetchInterval: (d) =>
        d?.totalStatus === 'IN_PROGRESS' ? READ_ELEVATION_GROUP_INTERVAL : false,
      ...rest,
    },
  );
}
