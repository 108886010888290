import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import { nls } from 'infrastructure/locale/language';
import { useSnackbar } from 'notistack';
import { NoticeResult } from 'infrastructure/query/notice/types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR_CHIP } from 'components/styles/colors/symbols';
import theme from 'components/styles/mui/theme';

const buttonClass = 'notice-snackbar-button';

const hasButtonClass = (className: string | object) => {
  return typeof className === 'string' && className.includes(buttonClass);
};

const disableNotice = (id: number) => {
  localStorage.setItem(`noticeDisable-${id}`, 'true');
};

interface Props {
  notice: NoticeResult;
  showAlert: () => void;
}

export default function NoticeSnackbar({ notice, showAlert }: Props) {
  const { closeSnackbar } = useSnackbar();
  const [mouseOver, setMouseOver] = useState(false);

  const noticeId = notice.id;
  const noticeType = notice.type;
  const noticeContent = notice.content;
  const noticeUrl = notice.url;

  const handleClick = () => {
    closeSnackbar(noticeId);
    disableNotice(noticeId);
    window.open(noticeUrl, '_blank');
  };

  const handleDontShowAgain = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    closeSnackbar(noticeId);
    disableNotice(noticeId);
    showAlert();
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    closeSnackbar(noticeId);
  };

  const handleMouseEnter = () => setMouseOver(true);
  const handleMouseLeave = () => setMouseOver(false);
  const handleMouseMove = (e: any) => setMouseOver(!hasButtonClass(e.target.className));

  const titles = {
    NOTICE: nls.noticeTitle(),
    NEW: nls.noticeNewTitle(),
    ERROR: nls.noticeErrorTitle(),
  };

  const icons = {
    NOTICE: <NotificationsIcon />,
    NEW: <InfoIcon />,
    ERROR: <WarningIcon />,
  };

  const colors = {
    NOTICE: theme.palette.primary.light,
    NEW: COLOR_CHIP.PINK,
    ERROR: theme.palette.warning.light,
  };

  return (
    <Content
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      $underline={mouseOver}
    >
      <ContentMain>
        <NoticeTitle $color={colors[noticeType]}>
          <NoticeIcon>{icons[noticeType]}</NoticeIcon>
          <Typography variant="caption">{titles[noticeType]}</Typography>
        </NoticeTitle>
        <NoticeContent>{noticeContent}</NoticeContent>
      </ContentMain>
      <ContentDivider />
      <ContentFooter>
        <ButtonWrapper className={buttonClass}>
          <Button
            className={buttonClass}
            $color={theme.palette.grey[500]}
            onClick={handleDontShowAgain}
          >
            {nls.noticeDontShowAgainButton()}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper className={buttonClass}>
          <Button className={buttonClass} $color={theme.palette.grey[400]} onClick={handleClose}>
            {nls.close()}
          </Button>
        </ButtonWrapper>
      </ContentFooter>
    </Content>
  );
}

const NoticeTitle = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  color: ${({ $color }) => $color};
`;

const NoticeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    font-size: 0.875rem;
  }
`;

const NoticeContent = styled(Typography).attrs({ variant: 'subtitle2' })`
  color: ${theme.palette.grey[50]};
`;

const ContentDivider = styled(Divider)`
  margin: 0 auto;
  width: calc(100% - 1.75rem);
  background-color: ${theme.palette.grey.A700};
`;

const Content = styled.div<{ $underline: boolean }>`
  cursor: pointer;
  background-color: ${theme.palette.grey[900]};
  transition: background-color 0.25s ease;

  :hover {
    background-color: ${theme.palette.grey[800]};
  }

  :hover ${NoticeContent} {
    text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
  }

  :hover ${ContentDivider} {
    background-color: ${theme.palette.divider};
  }
`;

const ContentMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  gap: 0.25rem;
`;

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  padding: 0.25rem;
`;

const Button = styled.div<{ $color: string }>`
  user-select: none;
  padding: 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  border-radius: 0.25rem;
  transition: all 0.25s ease;
  color: ${({ $color }) => $color};

  :hover {
    background-color: #4d5254;
    color: ${theme.palette.grey[50]};
  }
`;
