import axios, { AxiosError, CancelToken } from 'axios';
import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { requestUploadFile, s3DirectFileUpload } from 'infrastructure/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'infrastructure/query/fileUpload/types';
import { Permission } from 'infrastructure/query/permission/types';
import { RelationAnnotation, RelationChain } from 'infrastructure/query/relation/types';
import { TempFile } from 'infrastructure/query/resource/types';
import { useState } from 'react';
import { UseQueryOptions, useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import {
  Annotation,
  AnnotationType,
  ChainListItem,
  CreateChainType,
  PointAnnotation,
  PolygonAnnotation,
  PolylineAnnotation,
  UpdateAnnotationType,
  UpdatePolygonType,
  UpdatePositionType,
  getStandardElevation,
  getZoneAnnotationType,
} from './types';

interface Props {
  zoneId: number;
  snapshotId: number;
  annotationType: AnnotationType;
  annotationId: number;
  positionId: number;
  elevationId: number;
  resourceId: number;
  type: RelationAnnotation['type'];
  data: any;
  permission: Permission;
  resourceIds?: number[];
}

export function useAllAnnotationsQuery({
  snapshotId,
  permission,
}: Pick<Props, 'snapshotId' | 'permission'>) {
  const pointsQuery = useAnnotationsQuery(
    { snapshotId, annotationType: 'points' },
    { enabled: Boolean(permission?.annotationGet) },
  );
  const polylinesQuery = useAnnotationsQuery(
    { snapshotId, annotationType: 'polylines' },
    { enabled: Boolean(permission?.annotationGet) },
  );
  const polygonsQuery = useAnnotationsQuery(
    { snapshotId, annotationType: 'polygons' },
    { enabled: Boolean(permission?.annotationGet) },
  );
  const chainsQuery = useChainListQuery(
    { snapshotId },
    { enabled: Boolean(permission?.annotationGet) },
  );
  return {
    points: pointsQuery?.data as PointAnnotation[],
    polylines: polylinesQuery?.data as PolylineAnnotation[],
    polygons: polygonsQuery?.data as PolygonAnnotation[],
    chains: chainsQuery?.data?.map((x) => ({ ...x, id: x.resourceId })) as ChainListItem[],
  };
}

export function useAnnotationsQuery(
  { snapshotId, annotationType }: Pick<Props, 'snapshotId' | 'annotationType'>,
  options?: UseQueryOptions<Annotation[], AxiosError<Error>, Annotation[]>,
) {
  const { enabled = true, ...rest } = options ?? {};
  return useQuery<Annotation[]>(
    keys.listBySnapshotId(annotationType, snapshotId),
    () => api.list({ snapshotId, annotationType }),
    { enabled: enabled && Boolean(snapshotId), staleTime: DEFAULT_QUERY_STALE_TIME, ...rest },
  );
}

export function useAnnotationListByZoneIdQuery({
  zoneId,
  snapshotId,
  annotationType,
  permission,
}: Pick<Props, 'snapshotId' | 'zoneId' | 'annotationType' | 'permission'>) {
  return useQuery<RelationAnnotation[]>(
    keys.listByZoneId(annotationType, zoneId, snapshotId),
    () => api.listByZoneId({ zoneId, snapshotId, type: getZoneAnnotationType(annotationType) }),
    {
      enabled: Boolean(
        permission?.annotationGet && zoneId && getZoneAnnotationType(annotationType),
      ),
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
}

export function useAnnotationQuery(
  {
    snapshotId,
    annotationType,
    annotationId,
    permission,
  }: Pick<Props, 'snapshotId' | 'annotationType' | 'annotationId' | 'permission'>,
  options?: UseQueryOptions<Annotation, AxiosError<Error>, Annotation>,
) {
  const [interval, setInterval] = useState<number | false>(false);
  const { enabled = true, ...rest } = options ?? {};
  const { data, status } = useQuery(
    keys.detail(annotationId),
    () => api.read({ snapshotId, annotationType, annotationId }),
    {
      /** annotationId로 mode의 값이 들어와 number가 아닌 경우가 존재 */
      enabled: Boolean(enabled && permission?.annotationGet && !!+annotationId),
      refetchInterval: interval,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      onSuccess: (res) => {
        setInterval(checkNeedPolling(annotationType, res));
      },
      ...rest,
    },
  );
  return {
    annotation: data,
    status,
  };
}

export function checkNeedPolling(type: AnnotationType, data: Annotation) {
  let ret: boolean | number = false;
  switch (type) {
    case 'polylines':
    case 'chains':
      ret = !getStandardElevation(data as PolylineAnnotation)?.resultCode || isAltitudeEmpty(data);
      break;
    default:
      ret = isAltitudeEmpty(data);
      break;
  }
  return ret ? DEFAULT_POLLING_INTERVAL : false;
}

const isAltitudeEmpty = (data: Annotation) => data?.positions?.some((x) => !x?.altitude);

export function useChainListQuery(
  { snapshotId }: Pick<Props, 'snapshotId'>,
  options?: UseQueryOptions<ChainListItem[], AxiosError<Error>, ChainListItem[]>,
) {
  if (!snapshotId) return null;
  const { enabled = true, ...rest } = options ?? {};
  return useQuery(keys.chain.list(snapshotId), () => api.chain.list({ snapshotId }), {
    enabled,
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...rest,
  });
}
export function useChainQuery(
  { snapshotId, resourceId }: Pick<Props, 'snapshotId' | 'resourceId'>,
  options?: UseQueryOptions<PolylineAnnotation[], AxiosError<Error>, PolylineAnnotation[]>,
) {
  const { enabled = true, ...rest } = options ?? {};
  return useQuery(keys.chain.group(resourceId), () => api.chain.read({ snapshotId, resourceId }), {
    enabled,
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...rest,
  });
}

export function useChainQueries({
  snapshotId,
  resourceIds,
  isEnabled,
  onSuccess,
}: Pick<Props, 'snapshotId' | 'resourceIds'> & {
  isEnabled: (resourceId: number) => boolean;
  onSuccess?: (data: PolylineAnnotation[]) => void;
}) {
  const queries = useQueries(
    resourceIds.map((resourceId) => ({
      queryKey: keys.chain.group(resourceId),
      queryFn: () => api.chain.read({ snapshotId, resourceId }),
      enabled: isEnabled(resourceId),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      onSuccess: (data) => onSuccess(data),
    })),
  );
  return queries?.reduce((acc, chain) => [...acc, ...(chain.data ?? [])], [] as any[]);
}

export function useChainListByZoneQuery({
  zoneId,
  snapshotId,
}: Pick<Props, 'zoneId' | 'snapshotId'>) {
  return useQuery<RelationChain[]>(
    keys.chain.listByZone(zoneId, snapshotId),
    () => api.chain.listByZone({ zoneId, snapshotId }),
    { staleTime: DEFAULT_QUERY_STALE_TIME },
  );
}

export function useAnnotationMutation() {
  const queryClient = useQueryClient();
  const createAnnotation = useMutation(
    (payload: { snapshotId: number; annotationType: AnnotationType; data }) => api.create(payload),
    {
      onSuccess: (_, variables) => {
        const { snapshotId, annotationType } = variables;
        queryClient.invalidateQueries(keys.listBySnapshotId(annotationType, snapshotId));
      },
    },
  );
  const updateAnnotation = useMutation((payload: UpdateAnnotationType) => api.update(payload), {
    onSuccess: (_, variables) => {
      const { snapshotId, annotationType, annotationId } = variables;
      queryClient.invalidateQueries(keys.detail(annotationId));
      queryClient.invalidateQueries(keys.listBySnapshotId(annotationType, snapshotId));
    },
  });
  const deleteAnnotation = useMutation(
    (payload: {
      zoneId: number;
      snapshotId?: number;
      annotationType: AnnotationType;
      annotationId: number;
    }) => api.delete(payload),
    {
      onSuccess: (_, variables) => {
        const { zoneId, snapshotId, annotationType } = variables;
        queryClient.invalidateQueries(keys.listBySnapshotId(annotationType, snapshotId));
        queryClient.invalidateQueries(keys.listByZoneId(annotationType, zoneId, snapshotId));
      },
    },
  );
  return { createAnnotation, updateAnnotation, deleteAnnotation };
}

export function usePositionMutation() {
  const queryClient = useQueryClient();
  const updatePosition = useMutation(
    (payload: UpdatePositionType) => api.position.update(payload),
    {
      onSuccess: (_, variables) => {
        const { snapshotId, annotationType, annotationId } = variables;
        queryClient.invalidateQueries(keys.detail(annotationId));
        queryClient.invalidateQueries(keys.listBySnapshotId(annotationType, snapshotId));
        if (annotationType === 'polygons') {
          // volume.all을 무효화할 경우 개별 volume 도 초기화 됨. 사라진 volume id로 조회하게 되는 것을 방지하고자 list만 무효화
          queryClient.invalidateQueries(keys.volume.listByPolygonId(annotationId));
        }
      },
    },
  );
  return { updatePosition };
}

export function usePolygonMutation() {
  const queryClient = useQueryClient();
  const updatePolygon = useMutation((payload: UpdatePolygonType) => api.polygon.patch(payload), {
    onSuccess: (_, variables) => {
      const { zoneId, snapshotId, annotationId } = variables;
      queryClient.invalidateQueries(keys.detail(annotationId));
      queryClient.invalidateQueries(keys.listByZoneId('polygons', zoneId, annotationId));
      queryClient.invalidateQueries(keys.listBySnapshotId('polygons', snapshotId));
      // volume.all을 무효화할 경우 개별 volume 도 초기화 됨. 사라진 volume id로 조회하게 되는 것을 방지하고자 list만 무효화
      queryClient.invalidateQueries(keys.volume.listByPolygonId(annotationId));
    },
  });

  return { updatePolygon };
}

interface ChainCreationProps {
  snapshotId: number;
  data: CreateChainType;
  tempFile: TempFile;
  onSuccess?: (tempFile) => void;
  onError?: (tempFile: TempFile, errorCode?: string) => void;
  onCancel?: (tempFile) => void;
  cancelToken: CancelToken;
}

export function useChainMutation() {
  const queryClient = useQueryClient();
  const createChain = useMutation<
    ChainListItem,
    AxiosError<{ errorCode: string; message: string }>,
    ChainCreationProps
  >(
    async (payload: ChainCreationProps) => {
      const { snapshotId, data, tempFile, cancelToken } = payload;
      const { data: dataForUploads } = await requestUploadFile(tempFile);

      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, tempFile?.file, cancelToken);

      const response = await api.chain.create({
        snapshotId,
        data: { ...data, filename: fields?.key || fields?.Key },
      });
      return response;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.chain.listAll());
        variables?.onSuccess({ ...data, ...variables.tempFile });
      },
      onError: (error, variables) => {
        if (axios.isCancel(error)) {
          variables?.onCancel && variables.onCancel(variables.tempFile);
        } else {
          variables?.onError(variables.tempFile, error?.response?.data?.errorCode);
        }
      },
    },
  );
  const deleteChain = useMutation(
    ({ zoneId, resourceId }: { zoneId: number; resourceId: number }) =>
      api.chain.delete({ zoneId, resourceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.chain.listAll());
      },
    },
  );
  return { createChain, deleteChain };
}
