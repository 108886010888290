export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const LIGHT_RED = '#f85756';
export const RED = '#FF5252';
export const BLUE = '#2727ff';
export const TRANSPARENT = 'transparent';
export const DARK_GREEN = '#009587';
export const GREEN = '#4bae4f';
export const GREEN2 = '#63E063';
export const LIGHT_GREEN = '#ccdb38';
export const ORANGE = '#fe9700';
export const DEEP_PINK = '#f50057';
export const INDIGO = '#6639b6';
export const ORANGE_RED = '#f34235';
export const PURPLE = '#9b26af';
export const YELLOW = '#fec006';
export const LIGHT_GRAY = '#fcfcfc';
export const LIGHT_GRAY2 = '#e9ebed';
export const LIGHT_GRAY3 = '#c9cdd2';
export const LIGHT_GRAY4 = '#dbdbdb';
export const LIGHT_GRAY5 = '#aaaaaa';
export const LIGHT_GRAY6 = '#cfcfcf';
export const LIGHT_GRAY7 = '#e0e1e2';
export const LIGHT_GRAY8 = '#cdd4d9';
export const GRAY = '#9fa4aa';
export const DARK_GRAY = '#73787f';
export const DARK_GRAY2 = '#707070';
export const DARK_GRAY3 = '#464c53';
export const DARK_GRAY4 = '#808080';
export const DARK_GRAY5 = '#8e9298';
export const DARK_GRAY6 = '#797b7b';
export const DARK_GRAY7 = '#4c5050';
export const DARK_BLACK = '#25282b';
export const DARK_BLACK2 = '#1b1d1f';
export const DARK_BLACK3 = '#3b4044';
export const CYAN = '#3aa0b2';
export const CYAN2 = '#4dc4da';
export const CYAN3 = '#6fd3e5';
export const CYAN4 = '#d8edfc';
export const CYAN5 = '#26C6DA';
export const MAIN = CYAN;
export const DARK_BACKGROUND = 'rgba(37, 40, 43, 0.2)';
export const DARK_BACKGROUND1 = 'rgba(37, 40, 43, 0.5)';
export const DARK_BACKGROUND2 = 'rgba(37, 40, 43, 0.7)';
export const BLACK_BACKGROUND = 'rgba(0, 0, 0, 0.8)';
export const BLACK_BACKGROUND2 = 'rgba(0, 0, 0, 0.4)';
export const WHITE_BACKGROUND = 'rgba(255, 255, 255, 0.7)';
export const WHITE_BACKGROUND2 = 'rgba(255, 255, 255, 1)';
export const SHADOW = 'rgba(0, 0, 0, 0.11)';
export const SHADOW2 = 'rgba(0, 0, 0, 0.14)';
export const SHADOW3 = 'rgba(0, 0, 0, 0.09)';
export const SHADOW4 = 'rgba(0, 0, 0, 0.18)';
export const SHADOW5 = 'rgba(0, 0, 0, 0.2)';
export const SHADOW6 = 'rgba(27, 29, 31, 0.5)';
export const SHADOW7 = 'rgba(58, 160, 178, .16)';
export const SHADOW8 = 'rgba(0, 0, 0, 0.13)';
export const PRIMARY_HOVER = 'rgba(58, 160, 178, 0.1)';
export const PRIMARY_ACTIVE = 'rgba(58, 160, 178, 0.24)';
export const GRAY_HOVER = 'rgba(115, 120, 127, 0.1)';
export const GRAY_ACTIVE = 'rgba(115, 120, 127, 0.24)';

export const VEHICLE_COLORS = {
  DUMP_TRUCK_PRIMARY: '#2F80ED',
  EXCAVATOR_PRIMARY: '#FF9800',
  CRANE_PRIMARY: '#FA7A7A',
  DOZER_PRIMARY: '#5032C8',
  LOADER_PRIMARY: '#003860',
  GRADER_PRIMARY: '#6C0606',
  SCRAPER_PRIMARY: '#00ACC1',
  ROLLER_PRIMARY: '#007065',
  OTHER_PRIMARY: '#1D2125',
  DUMP_TRUCK_SECONDARY: '#EEF3FF',
  EXCAVATOR_SECONDARY: '#FFF6E8',
  CRANE_SECONDARY: '#FFF2F2',
  DOZER_SECONDARY: '#F2EFFF',
  LOADER_SECONDARY: '#EEF1F9',
  GRADER_SECONDARY: '#FFF3EB',
  SCRAPER_SECONDARY: '#EBF8FA',
  ROLLER_SECONDARY: '#F0FDF1',
  OTHER_SECONDARY: '#EAEAEA',
};

export const CONSTRUCTION_SITE_AREA_COLOR = '#FC67FF';
