import { useEffect } from 'react';

declare const ChannelIO: (action: 'hideChannelButton' | 'showChannelButton') => void;

export default function useHideChannelTalkButton(enabled = true) {
  useEffect(() => {
    if (typeof ChannelIO !== 'undefined' && enabled) {
      ChannelIO('hideChannelButton');
      return () => ChannelIO('showChannelButton');
    }
    return () => {};
  }, []);
}
