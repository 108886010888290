export const keys = {
  earthworkResource: {
    list: (zoneId: number) => ['subgrade-resource', 'list', zoneId],
  },
  quantityEstimation: {
    all: () => ['quantity-estimation'],
    list: (zoneId: number) => ['quantity-estimation', 'summaries', zoneId],
    read: (quantityEstimationId: number) => ['quantity-estimation', 'read', quantityEstimationId],
  },
  quantityVolume: {
    all: () => ['quantity-volume'],
    read: (volumeIdList: number[]) => ['quantity-volume', volumeIdList?.join(',')],
  },
};
