import { atom } from 'jotai';
import { LatLngLiteral } from 'leaflet';

type FolderState = {
  id: number;
  expanded?: boolean;
};

type PolylineState = {
  id: number;
  folderId?: number;
  /* visible이 필요한 모든 곳에서 사용 */
  visible?: boolean;
  /* map에서만 보여줘야하는 경우 사용 */
  visibleOnMap?: boolean;
};

type ElevationHoverState = {
  id: number;
  latLng: LatLngLiteral;
};

const polylineEnabledAtom = atom<boolean>(false);
const polylineAccordionExpandedAtom = atom<boolean>(false);
const polylineStatesAtom = atom<PolylineState[]>([]);
const polylineFolderStatesAtom = atom<FolderState[]>([]);
const polylineElevationHoverStateAtom = atom<ElevationHoverState | false>(false);

export const polylineAtom = {
  enabled: polylineEnabledAtom,
  expanded: polylineAccordionExpandedAtom,
  states: polylineStatesAtom,
  folderStates: polylineFolderStatesAtom,
  hoverState: polylineElevationHoverStateAtom,
};
