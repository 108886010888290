import GA from 'infrastructure/ga';
import { INDOOR } from 'infrastructure/ga/category';

export const INDOOR_CARD = `${INDOOR}Card`;
export const INDOOR_EDIT_MENU = `${INDOOR}EditMenu`;
export const INDOOR_DEL_MENU = `${INDOOR}DelMenu`;
export const INDOOR_FOLDER_ADD_BTN = `${INDOOR}FolderAddBtn`;
export const INDOOR_VIDEO_ADD_BTN = `${INDOOR}VideoAddBtn`;
export const INDOOR_FOLDER_NAME_EDIT_BTN = `${INDOOR}FolderNameEditBtn`;
export const INDOOR_VIDEO_MANAGE_BTN = `${INDOOR}VideoManageBtn`;
export const INDOOR_VIDEO_DOWN_MENU = `${INDOOR}VideoDownMenu`;
export const INDOOR_VIDEO_DEL_MENU = `${INDOOR}VideoDelMenu`;
export const INDOOR_EDIT_BTN = `${INDOOR}EditBtn`;
export const INDOOR_FLOOR_PLAN_ADD_BTN = `${INDOOR}FloorPlanAddBtn`;
export const INDOOR_FLOOR_PLAN_DOWN_MENU = `${INDOOR}FloorPlanDownMenu`;
export const INDOOR_FLOOR_PLAN_DEL_MENU = `${INDOOR}FloorPlanDelMenu`;
export const INDOOR_MINIMAP_GENR_BTN = `${INDOOR}MinimapGenrBtn`;
export const INDOOR_VIDEO_MOVE_BTN = `${INDOOR}VideoMoveBtn`;
export const INDOOR_MINIMAP_MIN_BTN = `${INDOOR}MinimapMinBtn`;
export const INDOOR_MINIMAP_MAX_BTN = `${INDOOR}MinimapMaxBtn`;
export const INDOOR_WALKTHROUGH_VIEW_TGL = `${INDOOR}WalkthroughViewTgl`;
export const INDOOR_VIDEO_VIEW_TGL = `${INDOOR}VideoViewTgl`;
export const INDOOR_BUILDING_ADD_BTN = `${INDOOR}BuildingAddBtn`;

const INDOOR_COMPARE_TWO_SCREEN_BTN = `${INDOOR}CompareTwoScreenBtn`;
const INDOOR_COMPARE_REF_VIDEO_LIST = `${INDOOR}CompareRefVideoList`;
const INDOOR_COMPARE_COMPARE_VIDEO_LIST = `${INDOOR}CompareCompareVideoList`;
const INDOOR_COMPARE_MINIMAP_WALKTHROUGH = `${INDOOR}CompareMinimapWalkthrough`;
const INDOOR_COMPARE_REF_VIDEO_ARROW = `${INDOOR}CompareRefVideoArrow`;
const INDOOR_COMPARE_COMPARE_VIDEO_ARROW = `${INDOOR}CompareCompareVideoArrow`;

export const sendIndoorEditBtn = () => GA.event(INDOOR, INDOOR_EDIT_BTN);

export const sendIndoorCompareTwoScreenBtn = () => GA.event(INDOOR, INDOOR_COMPARE_TWO_SCREEN_BTN);
export const sendIndoorCompareRefVideoList = () => GA.event(INDOOR, INDOOR_COMPARE_REF_VIDEO_LIST);
export const sendIndoorCompareCompareVideoList = () =>
  GA.event(INDOOR, INDOOR_COMPARE_COMPARE_VIDEO_LIST);
export const sendIndoorCompareMinimapWalkthrough = () =>
  GA.event(INDOOR, INDOOR_COMPARE_MINIMAP_WALKTHROUGH);
export const sendIndoorCompareRefVideoArrow = () =>
  GA.event(INDOOR, INDOOR_COMPARE_REF_VIDEO_ARROW);
export const sendIndoorCompareCompareVideoArrow = () =>
  GA.event(INDOOR, INDOOR_COMPARE_COMPARE_VIDEO_ARROW);
