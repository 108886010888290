export const keys = {
  read: (snapshotId: number, gcpId: number, locationId: number) => [
    'gcpLocation',
    'detail',
    snapshotId,
    gcpId,
    locationId,
  ],
  recommendation: (snapshotId: number) => ['gcpLocation', 'recommendation', snapshotId],
};
