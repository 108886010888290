export const errorTextLeftMargin = '0.875rem';

export const scrollbarWidth = '0.5rem';
export const navigationBarWidth = '4rem';

export const topBarHeight = '4rem';
export const snapshotFullPageTopBarHeight = '4rem';
export const snapshotFullPageTopAlertHeight = '3rem';

export const sideMenuWidth = '21.25rem';
export const secondarySideMenuWidth = '20rem';
export const secondarySideMenuTopAreaHeight = '4.5rem';
export const rightSideMenuWidth = '21.25rem';

export const mobileSideMenuWidth = '15rem'; // temporary.

export const QRCodeSize = 4000;
export const QRCodeCanvasSize = 276;

export const QRCodeLogoWidth = 1380;
export const QRCodeLogoHeight = 280;
