import { PLACEHOLDER_VALUE } from 'components/common/select/Select';
import { nls } from 'infrastructure/locale/language';

export function validateTitle(x: string): string {
  if (x.length === 0) {
    return nls.projectFormTitleEmptyError();
  }
  return '';
}

export function validateCoordSystemText(x: string): string {
  if (x.length === 0) {
    return nls.projectFormCoordinateSystemTextEmptyError();
  }
  return '';
}

export function validateCoordSystemOption(x: string): string {
  if (!x || x === PLACEHOLDER_VALUE) {
    return nls.projectFormCoordinateSystemOptionEmptyError();
  }
  return '';
}
