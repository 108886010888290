import { NOTICE_QUERY_STALE_TIME } from 'common/policies/request';
import { useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export function useNoticeQuery() {
  return useQuery(keys.read(), () => api.read(), {
    staleTime: NOTICE_QUERY_STALE_TIME,
    refetchOnWindowFocus: false,
  });
}
