import { HazardEventHistoryParams } from './types';

export const keys = {
  all: () => ['hazardArea'],
  list: (projectId: number) => ['hazardArea', 'list', projectId],
  detail: (projectId: number, hazardAreaId: number) => [
    'hazardArea',
    'detail',
    projectId,
    hazardAreaId,
  ],
  allHistoryList: () => ['hazardArea', 'historyList'],
  historyList: (projectId: number, data: HazardEventHistoryParams) => [
    'hazardArea',
    'historyList',
    projectId,
    { ...data },
  ],
};
