import StylesProvider from '@material-ui/styles/StylesProvider';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as Sentry from '@sentry/react';
import ErrorPage from 'ErrorPage';
import * as color from 'common/colorScheme';
import { isJapan } from 'common/customize';
import { useCustomTitle } from 'common/customize/design';
import { guard, mobile } from 'common/policies/redirect';
import { scrollbarWidth } from 'common/policies/sizes';
import { isLogin } from 'common/utils';
import GlobalDialog from 'components/common/GlobalDialog';
import LazyFallbackComponent from 'components/common/LazyFallbackComponent';
import { useRefreshToken } from 'components/common/hooks';
import useHideChannelTalkButton from 'components/common/hooks/useHideChannelTalkButton';
import { DEFAULT_FONT_FAMILY } from 'components/common/style/TextStyles';
import ProjectFormPageContainer from 'components/project/formPage/PageContainer';
import CctvPlayerPage from 'components/snapshot/cctvPlayerPage/CctvPlayerPage';
import DrawingVectorEditorPage from 'components/snapshot/drawingVectorEditorPage/DrawingVectorEditorPage';
import IssuePolygonComparedPage from 'components/snapshot/issuePolygonComparedPage/IssuePolygonComparedPage';
import { background } from 'components/styles/colors/background';
import { SCROLLBAR_THUMB_COLOR, SCROLLBAR_TRACK_COLOR } from 'components/styles/colors/scrollbar';
import theme from 'components/styles/mui/theme';
import { getLanguage } from 'infrastructure/locale/language';
import useReactQueryDefaultOptions from 'infrastructure/query/error/useReactQueryDefaultOptions';
import React, { Suspense, lazy } from 'react';
import { hot } from 'react-hot-loader/root';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import './fonts.css';
import routes from './routes';

const MobileRedirectPage = lazy(
  () =>
    import(/* webpackChunkName: "mobileRedirectPage" */ 'components/redirect/MobileRedirectPage'),
);
const LoginPage = lazy(
  () => import(/* webpackChunkName: "loginPage" */ 'components/loginPage/LoginPage'),
);
const ReadOnlyPageContainer = lazy(
  () => import(/* webpackChunkName: "readOnly" */ 'components/readOnlyPage/PageContainer'),
);
const JoinPage = lazy(
  () => import(/* webpackChunkName: "joinPage" */ 'components/user/joinPage/JoinPage'),
);
const ZoneListPage = lazy(
  () => import(/* webpackChunkName: "zoneList" */ 'components/zone/listPage/ZoneListPage'),
);
const ZoneMapPage = lazy(
  () => import(/* webpackChunkName: "zoneMap" */ 'components/zone/mapPage/ZoneMapPage'),
);

const UserInfoPageContainer = lazy(
  () => import(/* webpackChunkName: "userInfo" */ 'components/user/userInfoPage/UserInfo'),
);
const UserEditPageContainer = lazy(
  () => import(/* webpackChunkName: "userEdit" */ 'components/user/userEditPage/UserEdit'),
);
const FindPasswordPage = lazy(
  () =>
    import(/* webpackChunkName: "findPwPage" */ 'components/user/findPasswordPage/FindPassword'),
);

// ProjectPages
const ProjectFileArchivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectFileArchivePage" */ 'components/project/fileArchivePage/ProjectFileArchivePage'
    ),
);
const ProjectLaborerHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLaborerHistoryPage" */ 'components/project/laborerHistoryPage/ProjectLaborerHistoryPage'
    ),
);
const ProjectVehicleHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectVehicleHistoryPage" */ 'components/project/vehicleHistoryPage/ProjectVehicleHistoryPage'
    ),
);
const ProjectInfoPageContainer = lazy(
  () =>
    import(/* webpackChunkName: "projectInfoPage" */ 'components/project/infoPage/PageContainer'),
);
const ProjectListPage = lazy(
  () =>
    import(/* webpackChunkName: "projectListPage" */ 'components/project/listPage/ProjectListPage'),
);
const ProjectMemberPage = lazy(
  () =>
    import(
      /* webpackChunkName: "projectMemberPage" */ 'components/project/memberPage/ProjectMemberPage'
    ),
);

// SnapshotPages
const CompareSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareSnapshotsPage" */ 'components/snapshot/comparePage/CompareSnapshotsPage'
    ),
);
const CompareGreenByGreenPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareByGreenPage" */ 'components/snapshot/comparePage/green/CompareByGreenPage'
    ),
);
const CompareGreenByDatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareByDatePage" */ 'components/snapshot/comparePage/green/CompareByDatePage'
    ),
);
const CompareSuperResolutionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompareSuperResolutionPage" */ 'components/snapshot/comparePage/superResolution/SuperResolutionComparePage'
    ),
);
const CompareChangeDetectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareChangeDetectionPage" */ 'components/snapshot/comparePage/changeDetection/CompareChangeDetectionPage'
    ),
);
const SnapshotDetailsContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotDetailsContainer" */ 'components/snapshot/detailsPage/SnapshotDetailsContainer'
    ),
);
const SnapshotPrintPage = lazy(
  () =>
    import(
      /* webpackChunkName: "snapshotPrintPage" */ 'components/snapshot/printPage/SnapshotPrintPage'
    ),
);
const DrawingConfigPage = lazy(
  () =>
    import(
      /* webpackChunkName: "drawingConfigPage" */ 'components/snapshot/drawingConfigPage/DrawingConfigPage'
    ),
);
const GcpEditorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "gcpEditorPage" */ 'components/snapshot/gcpEditorPage/GcpEditorPage'
    ),
);
const DtmEditorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "dtmEditorPage" */ 'components/snapshot/dtmEditorPage/DtmEditorPage'
    ),
);
const SnapshotFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotFormPage" */ 'components/snapshot/formPage/SnapshotFormPage'
    ),
);

const BuildingDetailsPage = lazy(
  () =>
    import(/* webpackChunkName: "BuildingDetailsPage" */ 'components/building/BuildingDetailsPage'),
);

const MiniMapSettingPage = lazy(
  () =>
    import(/* webpackChunkName: "MiniMapSettingPage" */ 'components/building/MiniMapSettingPage'),
);

const WalkthroughComparePage = lazy(
  () =>
    import(
      /* webpackChunkName: "WalkthroughComparePage" */ 'components/building/WalkthroughComparePage'
    ),
);

const ProjectLogManipulatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLogManipulatePage" */
      'pages/green/maintenanceLogPage/ProjectLogManipulatePage'
    ),
);

const ProjectLogListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLogListPage" */
      'pages/green/maintenanceLogPage/ProjectLogListPage'
    ),
);
const PhotoBoxMediaPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PhotoBoxMediaPage" */
      'components/photoBox/photoBoxPage/mediaPage'
    ),
);
const PhotoBoxAlbumPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PhotoBoxAlbumPage" */
      'components/photoBox/photoBoxPage/albumPage'
    ),
);

const LandDisplacementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LandDisplacementPage " */ 'components/landDisplacement/LandDisplacementPage'
    ),
);

function loginCheckForComponent(props, Component: (props: any) => JSX.Element | null | undefined) {
  const { location } = props;

  return isLogin() ? (
    <Component {...props} />
  ) : (
    <Redirect to={{ pathname: routes.login.path, state: { from: location } }} />
  );
}

function PrivateRoute({
  component,
  ...rest
}: { component: (props: any) => JSX.Element | null | undefined } & RouteProps) {
  return <Route {...rest} render={(props) => loginCheckForComponent(props, component)} />;
}

const snapshotDetailPath = [
  routes.polygonAnnotation.show.path,
  routes.polygonAnnotation.edit.path,
  routes.polylineAnnotation.show.path,
  routes.pointAnnotation.show.path,
  routes.chainLine.show.path,
  routes.drawingVector.show.path,
  routes.snapshot.show.path,
  routes.hazardArea.new.path,
  routes.hazardArea.show.path,
  routes.issue.show.path,
  routes.issue.deprecatedShow.path,
  routes.chain.show.path,
  routes.earthwork.detailTable.path,
  routes.earthwork.summaryTable.path,
  routes.green.show.path,
  routes.cctv.show.path,
  routes.snapshot.fileSharing.path,
];

const lang = getLanguage();

document.body.setAttribute('class', lang);
document.body.setAttribute('lang', lang);

const App = () => {
  useRefreshToken();
  useCustomTitle();
  useReactQueryDefaultOptions();

  useHideChannelTalkButton(isJapan());

  const Loading = () => <LazyFallbackComponent />;

  return (
    <StylesProvider injectFirst>
      <Style />
      <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Redirect exact from={routes.root.path} to={routes.project.index.path} />
              <Route exact path={routes.login.path} component={LoginPage} />
              <Route path={routes.guard.path} component={() => <MobileRedirectPage {...guard} />} />
              <Route
                path={routes.mobile.path}
                component={() => <MobileRedirectPage {...mobile} />}
              />
              <PrivateRoute
                exact
                path={routes.project.member.info.path}
                component={ProjectMemberPage}
              />
              <PrivateRoute
                exact
                path={[routes.project.new.path, routes.project.edit.path]}
                component={ProjectFormPageContainer}
              />
              <PrivateRoute
                exact
                path={routes.project.info.path}
                component={ProjectInfoPageContainer}
              />
              <PrivateRoute exact path={routes.project.index.path} component={ProjectListPage} />
              <PrivateRoute
                exact
                path={routes.project.archive.path}
                component={ProjectFileArchivePage}
              />
              <PrivateRoute
                exact
                path={routes.project.laborerHistory.path}
                component={ProjectLaborerHistoryPage}
              />
              <PrivateRoute
                exact
                path={routes.project.vehicleHistory.path}
                component={ProjectVehicleHistoryPage}
              />
              <Redirect exact from={routes.project.show.path} to={routes.project.info.path} />
              <PrivateRoute path={routes.drawingConfig.show.path} component={DrawingConfigPage} />
              <PrivateRoute
                exact
                path={routes.snapshot.entryNew.path}
                component={SnapshotFormPage}
              />
              <PrivateRoute exact path={routes.snapshot.new.path} component={SnapshotFormPage} />
              <PrivateRoute
                exact
                path={routes.snapshot.edit.path}
                component={(props) => <SnapshotFormPage {...props} isEditPage />}
              />
              <PrivateRoute path={routes.snapshot.print.path} component={SnapshotPrintPage} />
              <PrivateRoute
                path={routes.snapshot.compareTwo.path}
                component={(props) => <CompareSnapshotsPage {...props} type="two" />}
              />
              <PrivateRoute
                path={routes.snapshot.compareFour.path}
                component={(props) => <CompareSnapshotsPage {...props} type="four" />}
              />
              <PrivateRoute
                path={routes.snapshot.slider.path}
                component={(props) => <CompareSnapshotsPage {...props} type="slider" />}
              />
              <PrivateRoute
                path={routes.snapshot.compareGreenByGreen.path}
                component={CompareGreenByGreenPage}
              />
              <PrivateRoute
                path={routes.snapshot.compareGreenByDate.path}
                component={CompareGreenByDatePage}
              />
              <PrivateRoute
                path={routes.snapshot.superResolutionCompareSlider.path}
                component={(props) => <CompareSuperResolutionPage {...props} type="slider" />}
              />
              <PrivateRoute
                path={routes.snapshot.superResolutionCompareTwo.path}
                component={(props) => <CompareSuperResolutionPage {...props} type="two" />}
              />
              <PrivateRoute
                path={routes.snapshot.compareChangeDetection.path}
                component={CompareChangeDetectionPage}
              />
              <PrivateRoute path={routes.snapshot.gcpEditor.path} component={GcpEditorPage} />
              <PrivateRoute path={routes.snapshot.dtmEditor.path} component={DtmEditorPage} />
              <PrivateRoute
                path={routes.drawingVector.edit.path}
                component={DrawingVectorEditorPage}
              />
              <Redirect
                exact
                from="/projects/:projectId/zones/:zoneId/snapshots/:snapshotId/annotations/line/:annotationId"
                to={routes.polylineAnnotation.show.path}
              />
              <Redirect
                exact
                from="/projects/:projectId/zones/:zoneId/snapshots/:snapshotId/annotations/area/:annotationId"
                to={routes.polygonAnnotation.show.path}
              />
              <PrivateRoute path={routes.snapshot.cctv.path} component={CctvPlayerPage} />
              <PrivateRoute
                path={routes.issue.polygon.list.path}
                component={IssuePolygonComparedPage}
              />
              <PrivateRoute exact path={snapshotDetailPath} component={SnapshotDetailsContainer} />
              <Redirect
                from={routes.snapshot.showBase.path}
                to={`${routes.snapshot.showBase.path}/2d`}
              />
              <PrivateRoute exact path={routes.zone.map.path} component={ZoneMapPage} />
              <PrivateRoute
                exact
                path={[routes.snapshot.list.path, '/projects/:projectId/zones']}
                component={ZoneListPage}
              />
              <Redirect
                exact
                from="/projects/:projectId/zones/:zoneId"
                to={routes.snapshot.list.path}
              />
              <PrivateRoute
                exact
                path={routes.building.show.path}
                component={BuildingDetailsPage}
              />
              <PrivateRoute exact path={routes.miniMap.show.path} component={MiniMapSettingPage} />
              <PrivateRoute
                exact
                path={routes.building.compare.path}
                component={WalkthroughComparePage}
              />
              <Route path={routes.user.findPw.path} component={FindPasswordPage} />
              <PrivateRoute
                path={routes.landDisplacement.show.path}
                component={LandDisplacementPage}
              />
              <Route path={routes.user.join.path} component={JoinPage} />
              <PrivateRoute path={routes.user.info.show.path} component={UserInfoPageContainer} />
              <PrivateRoute path={routes.user.info.edit.path} component={UserEditPageContainer} />
              <Route exact path={routes.readonly.path} component={ReadOnlyPageContainer} />
              <PrivateRoute
                exact
                path={routes.maintenance.manipulate.path}
                component={ProjectLogManipulatePage}
              />
              <PrivateRoute
                exact
                path={routes.maintenance.date.path}
                component={ProjectLogManipulatePage}
              />
              <PrivateRoute
                exact
                path={routes.maintenance.list.path}
                component={ProjectLogListPage}
              />
              <PrivateRoute exact path={routes.photoBox.media.path} component={PhotoBoxMediaPage} />
              <PrivateRoute path={routes.photoBox.photo.path} component={PhotoBoxMediaPage} />
              <PrivateRoute path={routes.photoBox.video.path} component={PhotoBoxMediaPage} />
              <PrivateRoute exact path={routes.photoBox.album.path} component={PhotoBoxAlbumPage} />
              <PrivateRoute
                exact
                path={routes.photoBox.albumDetail.path}
                component={PhotoBoxAlbumPage}
              />
              <Route path="/guard" component={null} />
              <Redirect to={routes.project.index.path} />
            </Switch>
          </Suspense>
        </Sentry.ErrorBoundary>
        <GlobalDialog />
      </ThemeProvider>
    </StylesProvider>
  );
};

const Style = createGlobalStyle`
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    background-color: ${background.DEFAULT};
    font-size: 16px;

    .clearfix {
      zoom:1;

      &::after{
        content:' ';
        clear:both;
        display: block;
        height: 0;
        visibility: hidden;
      }
    }
  }

  body {
    font-family: ${DEFAULT_FONT_FAMILY};
    overflow-y: auto;

    & .rc-slider-tooltip {
      z-index: 2;
    }
  }

  @media (max-width: 1910px) {
    html{
      font-size:14px;
    }
  }
  @media (max-width: 1580px) {
    html{
      font-size:13px;
    }
  }
  @media (max-width: 1450px) {
    html{
      font-size:12px;
    }
  }

  a {
    text-decoration: none;
    color: initial;
    :hover{
      text-decoration: none;
    }
  }

  img {
    color: ${color.TRANSPARENT};
    -ms-interpolation-mode: bicubic;
  }

  p, ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  ::-webkit-scrollbar {
    width: ${scrollbarWidth};
    height: ${scrollbarWidth};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: ${SCROLLBAR_THUMB_COLOR};
    border: 0.125rem solid transparent;
    background-clip: padding-box;
    transition: background-color 0.2s ;
  }
  ::-webkit-scrollbar-track {
    background-color: ${SCROLLBAR_TRACK_COLOR};
    border: none;
    transition: background-color 0.2s ;
  }
`;

export default hot(App);
