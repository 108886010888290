import { Issue } from './types';

export const keys = {
  all: () => ['issue'],
  list: (zoneId: number, type?: Issue['type'] | '', status?: Issue['status']) => {
    const options = { ...(type && { type }), ...(status && { status }) };
    return ['issue', 'list', zoneId, options];
  },
  read: (zoneId: number, issueId: number | 'new') => ['issue', 'read', zoneId, { issueId }],
  comment: {
    all: () => ['comment'],
    list: (zoneId: number, issueId: number) => ['comment', 'list', zoneId, { issueId }],
    read: (zoneId: number, issueId: number, commentId: number) => [
      'comment',
      'read',
      zoneId,
      { issueId, commentId },
    ],
  },
  vi: {
    read: (snapshotId: number, issueId: number, resourceId: number) => [
      'issue',
      'vi',
      snapshotId,
      { issueId, resourceId },
    ],
  },
};
