export const MANUAL_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko',
  en: 'https://meissasaas.zendesk.com/hc/en-us',
  ja: 'https://meissasaas.zendesk.com/hc/ja',
};

export const GREEN_MANUAL_URLS = {
  ko: 'https://meissagreen.zendesk.com/hc/en-us',
  en: 'https://meissagreen.zendesk.com/hc/en-us',
  ja: 'https://meissagreen.zendesk.com/hc/en-us',
};

export const EARTHWORK_CROSSING_LINE_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109682105359-3-2-4-%EB%8B%A8%EB%A9%B4%EB%8F%84-%EC%83%9D%EC%84%B1-%EB%B0%8F-%EA%B3%A0%EB%8F%84-%EC%B8%A1%EB%9F%89#:~:text=%EB%AA%85%EC%B9%AD%EC%9C%BC%EB%A1%9C%20%EB%8B%A8%EB%A9%B4%EC%A0%95%EB%B3%B4%EC%97%90%EC%84%9C%20%EB%B3%B4%EC%97%AC%EC%A7%91%EB%8B%88%EB%8B%A4.-,%ED%86%A0%EA%B3%B5%ED%9A%A1%EB%8B%A8%EC%84%A0%20%EC%B6%94%EA%B0%80,-%ED%86%A0%EA%B3%B5%20%ED%9A%A1%EB%8B%A8%EC%84%A0%20%EB%A9%94%EB%89%B4',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8109682105359-3-2-4-Cross-section-creation-and-elevation-measurement#:~:text=actual%20file%20name.-,Earthwork%20crossing%20line,-Earthwork%20crossing%20line',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8109682105359-3-2-4-%E7%AB%AF%E9%9D%A2%E5%9B%B3%E4%BD%9C%E6%88%90%E3%81%8A%E3%82%88%E3%81%B3%E9%AB%98%E5%BA%A6%E6%B8%AC%E9%87%8F#:~:text=%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99%E3%80%82-,%E5%9C%9F%E5%B7%A5%E6%A8%AA%E6%96%AD%E7%B7%9A,-%E5%9C%9F%E5%B7%A5%E6%A8%AA%E6%96%AD%E7%B7%9A',
};

export const STRATUM_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109682105359-3-2-4-%EB%8B%A8%EB%A9%B4%EB%8F%84-%EC%83%9D%EC%84%B1-%EB%B0%8F-%EA%B3%A0%EB%8F%84-%EC%B8%A1%EB%9F%89#:~:text=%EB%B3%BC%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,%EC%A7%80%EC%B8%B5%20%ED%8C%8C%EC%9D%BC%20%EC%97%85%EB%A1%9C%EB%93%9C,-%EC%A7%80%EC%B8%B5%20%ED%8C%8C%EC%9D%BC%20%EC%97%85%EB%A1%9C%EB%93%9C%EB%8A%94',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8109682105359-3-2-4-Cross-section-creation-and-elevation-measurement#:~:text=to%20each%20line.-,Stratum%20file%20upload,-Edit%20%2D%20Stratum%20file',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8109682105359-3-2-4-%E7%AB%AF%E9%9D%A2%E5%9B%B3%E4%BD%9C%E6%88%90%E3%81%8A%E3%82%88%E3%81%B3%E9%AB%98%E5%BA%A6%E6%B8%AC%E9%87%8F#:~:text=%E3%81%8C%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82-,%E5%9C%B0%E5%B1%A4%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89,-%E7%8F%BE%E5%A0%B4%E7%8A%B6%E6%B3%81%20%2D%20%E4%BF%AE%E6%AD%A3',
};

export const CADASTRAL_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8490669170319-3-2-6-%EA%B3%84%ED%9A%8D%EA%B3%A0-%EB%B0%8F-%EC%A7%80%EC%A0%81%EB%8F%84-%ED%99%9C%EC%9A%A9#:~:text=%EA%B3%84%EC%82%B0%ED%95%A0%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,%EC%A7%80%EC%A0%81%EB%8F%84,-%EC%A7%80%EC%A0%81%EB%8F%84%20%2D%20%ED%86%A0%EC%A7%80%EB%B3%B4%EC%83%81%20%EC%97%AC%EB%B6%80',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8490669170319-3-2-6-Design-Level-and-Cadastral-Map#:~:text=the%20planned%20height.-,Cadastral%20Map*,-*This%20function%20is',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8490669170319-3-2-6-%E8%A8%88%E7%94%BB%E9%AB%98%E3%81%8A%E3%82%88%E3%81%B3%E5%9C%B0%E7%B1%8D%E5%9B%B3%E3%81%AE%E6%B4%BB%E7%94%A8#:~:text=%E8%A8%88%E7%AE%97%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82-,%E5%9C%B0%E7%B1%8D%E5%9B%B3,-*%E7%8F%BE%E5%9C%A8%E3%81%93%E3%81%AE%E6%A9%9F%E8%83%BD',
};

export const HAZARD_AREA_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%EC%84%A0%ED%83%9D%ED%95%A0%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,3.%20%EC%9C%84%ED%97%98%EA%B5%AC%EC%97%AD,-3%2D1.%20%EC%9C%84%ED%97%98%EA%B5%AC%EC%97%AD',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=to%2024%20hours.-,3.%20Hazardous%20Areas,-3%2D1.%20Add',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%E9%81%B8%E6%8A%9E%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82-,3.%20%E5%8D%B1%E9%99%BA%E5%8C%BA%E5%9F%9F,-3%2D1.%20%E5%8D%B1%E9%99%BA',
};

export const WORKER_LOCATION_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%EB%B0%9B%EC%9D%84%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,2.%20%EB%85%B8%EB%AC%B4%EC%9E%90%20%EC%9C%84%EC%B9%98%20%EC%A0%95%EB%B3%B4%20%ED%99%95%EC%9D%B8%ED%95%98%EA%B8%B0,-2%2D1.%20%EC%8B%A4%EC%8B%9C%EA%B0%84',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=2.%20Real%2Dtime%20worker%20location%20confirmation',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82-,2.%20%E5%8A%B4%E5%83%8D%E8%80%85%E3%81%AE%E4%BD%8D%E7%BD%AE%E7%A2%BA%E8%AA%8D,-2%2D1.%20%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0',
};

export const ISSUE_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109731384847-3-3-1-%EC%9D%B4%EC%8A%88-%EA%B4%80%EB%A6%AC',
  en: 'https://meissasaas.zendesk.com/hc/en-us/articles/8109731384847-3-3-1-Issue-management',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8109731384847-3-3-1-%E3%82%A4%E3%82%B7%E3%83%A5%E3%83%BC%E7%AE%A1%E7%90%86',
};
