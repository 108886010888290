import { useMutation } from 'react-query';
import api from './api';

export function useReportMutation() {
  const createReport = useMutation(
    ({
      snapshotId,
      inputData,
      annotationIds,
    }: {
      snapshotId: number;
      inputData: string[];
      annotationIds: number[];
    }) => api.report.create({ snapshotId, inputData, annotationIds }),
    { onError: () => {} },
  );
  return { createReport };
}
