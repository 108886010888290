import { Button, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogMUI from '@mui/material/Dialog';
import DialogActionsMUI from '@mui/material/DialogActions';
import DialogContentMUI from '@mui/material/DialogContent';
import DialogContentTextMUI from '@mui/material/DialogContentText';
import DialogTitleMUI from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';
import palette from 'components/styles/mui/palette';

export const DialogWrapper = styled(DialogMUI)`
  .MuiDialog-paperScrollPaper {
    width: 27.5rem;
    padding-top: 1.5rem;
    border-radius: 0.5rem;
  }
  .MuiButton-textSecondary {
    color: ${palette.error.main};
  }
`;

export const DialogTitle = styled(DialogTitleMUI)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.5rem 1rem 1.5rem 2rem;
  }
`;

export const DialogTitleText = styled(Typography).attrs({ variant: 'h6', component: 'div' })`
  color: ${theme.palette.text.primary};
`;

export const DialogContent = styled(DialogContentMUI)`
  && {
    padding: 0;
  }
`;

export const DialogContentText = styled(DialogContentTextMUI).attrs({ component: 'div' })`
  white-space: pre-line;
  padding: 1rem 2rem;
  margin: 0;
`;

export const DialogExtraContentArea = styled.div`
  padding: 0.75rem 2rem;
`;

export const DialogProgressBar = styled(LinearProgress)`
  margin: 0.5rem 2rem;
`;
export const DialogActions = styled(DialogActionsMUI)`
  &.MuiDialogActions-root {
    padding: 1rem;
  }
`;

export const DialogButton = styled(Button).attrs({ variant: 'text' })`
  min-width: auto;
  border-radius: 0.25rem;
  box-shadow: none;
  padding: 0.375rem 1rem;
  word-break: keep-all;

  &:focus {
    outline: none;
  }
  .MuiButton-startIcon {
    display: inline-block;
    margin-left: -0.25rem;
    margin-right: 0.25rem;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1.25rem;
    }
  }

  .MuiButton-endIcon {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: -0.25rem;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1.25rem;
    }
  }

  &.MuiButton-sizeLarge {
    padding: 0.5rem 1.375rem;

    .MuiButton-iconSizeLarge > *:first-child {
      font-size: 1.375rem;
    }
  }

  &.MuiButton-sizeSmall {
    padding: 0.25rem 0.625rem;
    font-size: 0.8125rem;
    line-height: 1.3rem;

    .MuiButton-iconSizeSmall {
      &.MuiButton-startIcon {
        margin-left: -0.125rem;
      }
      &.MuiButton-endIcon {
        margin-right: -0.125rem;
      }
      > *:first-child {
        font-size: 1.125rem;
      }
    }
  }
`;

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 0;

  &:focus {
    outline: none;
  }
`;
