import React from 'react';
import Alert from './Alert';
import Confirm from './Confirm';
import ListPopup from './ListPopup';
import Modal from './Modal';
import { DialogProps } from './types';
/**
 * @figma-url
 * https://www.figma.com/design/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484-7008&m=dev
 *
 * Alert 컴포넌트는 하나의 버튼만을 가지며, 버튼의 색상은 primary로 설정되어 있습니다.
 *
 * Confirm과 Modal 컴포넌트는 두 개의 버튼을 가지며, 버튼의 색상은 primary와 secondary로 설정되어 있습니다.
 * 우측 버튼이 primary, 좌측 버튼이 secondary로 설정되어 있습니다.
 * secondary 버튼은 hidden 속성을 통해 숨길 수 있습니다.
 */

export default function Dialog({ type, ...rest }: DialogProps) {
  if (type === 'alert') {
    return <Alert {...rest} />;
  }
  if (type === 'confirm') {
    return <Confirm {...rest} />;
  }
  if (type === 'modal') {
    return <Modal {...rest} />;
  }
  if (type === 'list') {
    return <ListPopup {...rest} />;
  }
  return null;
}
