import { useEffect } from 'react';

interface EventConsumer {
  addEventListener: (event: string, handler: (e: Event) => any) => void;
  removeEventListener: (event: string, handler: (e: Event) => any) => void;
}

export function useDefaultEventBlocker(target: EventConsumer, event: string) {
  const onEvent = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    target.addEventListener(event, onEvent);
    return () => target.removeEventListener(event, onEvent);
  }, []);
}
