export const DEFAULT_UNIT = {
  VOLUME: {
    NAME: 'm³',
    ID: 3,
    COEFFICIENT: 1,
  },
};
export const DEFAULT_LOGOUT_BY_DISTANCE = 5000;
export const LOGOUT_BY_DISTANCE_MINIMUM_INPUT_VALUE = 0;
export const LOGOUT_BY_DISTANCE_MAXIMUM_INPUT_VALUE = 999999;
