export const isDev = (process.env.ENVIRONMENT || '').indexOf('test') > -1;

export const isDevWithStaging = () => {
  const { origin } = document.location;
  return isDev || origin.indexOf('staging') > -1;
};

export const isDevMode = () => {
  return isDevWithStaging() && (localStorage.getItem('devMode') ?? 'TEST') === 'TEST';
};
export const isGreenDevMode = () => {
  return isDevWithStaging() && localStorage.getItem('devMode') === 'GREEN';
};
