import { guard, mobile } from 'common/policies/redirect';
import { IssueUrlType } from 'infrastructure/query/issue/types';

const projectId = 'projectId';
const snapshotId = 'snapshotId';
const zoneId = 'zoneId';
const annotationId = 'annotationId';
const drawingId = 'drawingId';
const hazardAreaId = 'hazardAreaId';
const mapType = 'mapType';
const issueId = 'issueId';
const resourceId = 'resourceId';
const issueType = 'issueType';
const buildingId = 'buildingId';
const indoorVideoId = 'indoorVideoId';
const greenId = 'greenId';
const cctvId = 'cctvId';
const scheduleDate = 'scheduleDate';
const albumId = 'albumId';

type Param = string | number;
type ProjectIdParam = { [projectId]: Param };
type ZoneIdParam = { [zoneId]: Param };
type SnapshotIdParam = { [snapshotId]: Param };
type AnnotationIdParam = { [annotationId]: Param };
type ResourceIdParam = { [resourceId]: Param };
type DrawingIdParam = { [drawingId]: Param };
type HazardAreaIdParam = { [hazardAreaId]: Param };
type MapTypeParam = { [mapType]: string };
type IssueIdParam = { [issueId]: Param };
type BuildingIdParam = { [buildingId]: Param };
type IndoorVideoIdParam = { [indoorVideoId]: Param };
type GreenIdParam = { [greenId]: Param };
type CctvIdParam = { [cctvId]: Param };
type ScheduleParam = { [scheduleDate]: string };
type AlbumIdParam = { [albumId]: Param };

// eslint-disable-next-line prettier/prettier

type IssueTypeParams = {
  [issueType]: IssueUrlType;
};

const routes = {
  root: {
    path: '/',
  },
  guard: {
    path: guard.path,
  },
  mobile: {
    path: mobile.path,
  },
  project: {
    index: {
      path: '/projects',
    },
    new: {
      path: '/projects/new',
    },
    show: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}`,
    }),
    info: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/info`,
    }),
    edit: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/edit`,
    }),
    member: {
      info: withParams<ProjectIdParam>({
        path: `/projects/:${projectId}/member/info`,
      }),
    },
    archive: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/archive`,
    }),
    laborerHistory: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/laborerHistory`,
    }),
    vehicleHistory: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/vehicleHistory`,
    }),
  },
  zone: {
    map: withParams<ProjectIdParam>({
      path: `/projects/:${projectId}/zones/map`,
    }),
  },
  snapshot: {
    list: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots`,
    }),
    edit: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/edit`,
    }),
    entryNew: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/new`,
    }),
    new: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/new`,
    }),
    showBase: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}`,
    }),
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & MapTypeParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/:${mapType}`,
    }),
    compareTwo: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareTwo`,
    }),
    compareFour: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareFour`,
    }),
    compareGreenByGreen: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareGreenByGreen`,
    }),
    compareGreenByDate: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareGreenByDate`,
    }),
    superResolutionCompareSlider: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareSuperResolution/slider`,
    }),
    superResolutionCompareTwo: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareSuperResolution/compareTwo`,
    }),
    slider: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/slider`,
    }),
    compareChangeDetection: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/compareChangeDetection`,
    }),
    gcpEditor: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/gcpEditor`,
    }),
    print: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/print`,
    }),
    cctv: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/cctvs-view`,
    }),
    dtmEditor: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/dtm-editor`,
    }),
    fileSharing: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/2d/file-sharing`,
    }),
  },
  drawingVector: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/drawing-vector/:${resourceId}`,
    }),
    edit: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/drawing-vector/:${resourceId}/edit`,
    }),
  },
  polygonAnnotation: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & AnnotationIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/annotations/polygon/:${annotationId}`,
    }),
    edit: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & AnnotationIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/annotations/polygon/:${annotationId}/edit`,
    }),
  },
  polylineAnnotation: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & AnnotationIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/annotations/polyline/:${annotationId}`,
    }),
  },
  chain: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/chains/:${resourceId}`,
    }),
  },
  chainLine: {
    show: withParams<
      ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam & AnnotationIdParam
    >({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/chains/:${resourceId}/polyline/:${annotationId}`,
    }),
  },
  pointAnnotation: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & AnnotationIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/annotations/point/:${annotationId}`,
    }),
  },
  images: {
    index: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/images`,
    }),
  },
  drawingConfig: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & DrawingIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/drawingConfig/:${drawingId}`,
    }),
  },
  hazardArea: {
    new: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/hazardAreas/new`,
    }),
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & HazardAreaIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/hazardAreas/:${hazardAreaId}`,
    }),
  },
  issue: {
    // 결국 issueType, issueId는 동적으로 할당하는데, 그렇게 타입이 하드코딩되어 있는 것들을 관리하기 위해서 여기에 명시함.
    show: withParams<
      ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueTypeParams & IssueIdParam
    >({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/:${issueType}/:${issueId}`,
    }),
    new: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueTypeParams>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/:${issueType}/new`,
    }),
    deprecatedShow: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/:${issueId}`,
    }),
    polygon: {
      index: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueIdParam>({
        path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/polygon/:${issueId}`,
      }),
      list: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueIdParam>({
        path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/polygon/:${issueId}/snapshots`,
      }),
    },
    point: {
      index: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & IssueIdParam>({
        path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/issues/point/:${issueId}`,
      }),
    },
  },
  cctv: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & CctvIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/cctvs/:${cctvId}`,
    }),
    edit: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & CctvIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/cctvs/:${cctvId}/edit`,
    }),
  },
  green: {
    show: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & GreenIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/greens/:${greenId}`,
    }),
    new: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/greens/new`,
    }),
  },
  maintenance: {
    manipulate: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/maintenance/manipulate`,
    }),
    date: withParams<ProjectIdParam & ZoneIdParam & ScheduleParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/maintenance/manipulate/:${scheduleDate}`,
    }),
    list: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/maintenance/list`,
    }),
    detail: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/maintenance/detail`,
    }),
  },
  earthwork: {
    detailTable: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/earthwork/:${resourceId}/earthwork-detail-table`,
    }),
    summaryTable: withParams<ProjectIdParam & ZoneIdParam & SnapshotIdParam & ResourceIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/snapshots/:${snapshotId}/earthwork/summary-table/:${resourceId}`,
    }),
  },
  building: {
    show: withParams<ProjectIdParam & ZoneIdParam & BuildingIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/buildings/:${buildingId}`,
    }),
    compare: withParams<ProjectIdParam & ZoneIdParam & BuildingIdParam & IndoorVideoIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/buildings/:${buildingId}/compare/:${indoorVideoId}`,
    }),
  },
  miniMap: {
    show: withParams<ProjectIdParam & ZoneIdParam & BuildingIdParam & IndoorVideoIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/buildings/:${buildingId}/miniMap/:${indoorVideoId}`,
    }),
  },
  landDisplacement: {
    show: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/landDisplacement`,
    }),
  },
  login: {
    path: '/login',
  },
  user: {
    join: {
      path: '/user/join',
    },
    findPw: {
      path: '/user/findPw',
    },
    info: {
      show: {
        path: '/user/info',
      },
      edit: {
        path: '/user/edit',
      },
    },
  },
  readonly: {
    path: `/watch`,
  },
  photoBox: {
    media: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/photoBox/media`,
    }),
    photo: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/photoBox/media/photo`,
    }),
    video: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/photoBox/media/video`,
    }),
    album: withParams<ProjectIdParam & ZoneIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/photoBox/album`,
    }),
    albumDetail: withParams<ProjectIdParam & ZoneIdParam & AlbumIdParam>({
      path: `/projects/:${projectId}/zones/:${zoneId}/photoBox/albumDetail/:${albumId}`,
    }),
  },
};

export default routes;

function withParams<T>(x: { path: string }) {
  return {
    ...x,
    of(args: T): string {
      return format(this.path, args);
    },
  };
}

function format(template: string, args: {}): string {
  return Object.keys(args).reduce((acc, x) => acc.replace(`:${x}`, args[x]), template);
}
