export const CURRENT_LEVEL = '#FF8431';
export const PLAN_LEVEL = '#B650FF';
export const COMPARISON_DATE = '#63E063';
export const STRATUM1 = '#FFFF00'; // 기존 색상
export const STRATUM2 = '#FFC34E'; // 기존 색상
export const STRATUM3 = '#18FFFF'; // 기존 색상
export const STRATUM4 = '#FF4FFF'; // 기존 색상
export const STRATUM5 = '#9D95FF'; // 기존 색상
export const STRATUM6 = '#FF5733'; // 추가 색상
export const STRATUM7 = '#33FF57'; // 추가 색상
export const STRATUM8 = '#33A1FF'; // 추가 색상
export const STRATUM9 = '#FF33A1'; // 추가 색상
export const STRATUM10 = '#A1FF33'; // 추가 색상
export const STRATUM11 = '#FFD700'; // 추가 색상
export const STRATUM12 = '#7FFF00'; // 추가 색상
export const STRATUM13 = '#00FF7F'; // 추가 색상
export const STRATUM14 = '#00BFFF'; // 추가 색상
export const STRATUM15 = '#BF00FF'; // 추가 색상
export const STRATUM16 = '#FF6FFF'; // 추가 색상
export const STRATUM17 = '#FFAE42'; // 추가 색상
export const STRATUM18 = '#00CED1'; // 추가 색상
export const STRATUM19 = '#FF1493'; // 추가 색상
export const STRATUM20 = '#9932CC'; // 추가 색상
export const STRATUM21 = '#FF4500'; // 추가 색상
export const STRATUM22 = '#32CD32'; // 추가 색상
export const STRATUM23 = '#4682B4'; // 추가 색상
export const STRATUM24 = '#FFDAB9'; // 추가 색상
export const STRATUM25 = '#800080'; // 추가 색상

export const THREE_D_ANNTATION_COLOR = 0xff0000;

export const ANNOTATION_COLOR_CHIP = {
  ORANGE: '#ff9800',
  GREEN: '#4caf50',
  BLUE: '#2f80ed',
  PINK: '#fc67ff',
  PURPLE: '#ad55ff', // default color
  DARK_PURPLE: '#5032c8',
  GREY: '#97a2b1',
  BLACK: '#1d2125',
} as const;

export const COLOR_CHIP = {
  CYAN: '#26b5ce',
  GREEN: '#4caf50',
  BLUE: '#2f80ed',
  PINK: '#fc67ff',
  GREY: '#97a2b1',
  PURPLE: '#ad55ff',
  RED: '#f44336',
  ORANGE: '#ff9800',
  YELLOW: '#ffd600',
  BLACK: '#1d2125',
} as const;

export const DRAWING_COLORS = {
  CYAN: '#4dc4da',
  DARK_GREEN: '#009587',
  GREEN: '#4bae4f',
  LIGHT_GREEN: '#ccdb38',
  ORANGE: '#fe9700',
  DEEP_PINK: '#f50057',
  INDIGO: '#6639b6',
  ORANGE_RED: '#f34235',
  PURPLE: '#9b26af',
  YELLOW: '#fec006',
};

export const ISSUE_COLORS = {
  RUNNING: COLOR_CHIP.ORANGE,
  DONE: COLOR_CHIP.GREEN,
};

export const VI_COLOR = {
  HEALTHY: '#48BB78',
  STRESSED: '#F5A95E',
  CRITICAL: '#E56060',
};

export const OBJECTDETECTION_CLASS_COLOR = {
  GREEN: '#00FF00',
  RED: '#FF1818',
  YELLOW: '#FFFF00',
  CYAN: '#00FFFF',
  PURPLE: '#A027FF',
  GREY: '#F4F4F4',
  PINK: '#FF3EFF',
  BLUE: '#0075FF',
};
