import { convertDateFormat, dateFormat } from 'common/utils/date';
import Map from 'components/common/2D/Map';
import OrthophotoLayer from 'components/common/2D/layers/OrthophotoLayer';
import SatelliteLayer from 'components/common/2D/layers/satellite/SatelliteLayer';
import { useMatchParams } from 'components/common/hooks';
import Select, { StyledMenuItem } from 'components/common/select/Select';
import 'leaflet.sync';
import { nls } from 'infrastructure/locale/language';
import { useIssueViQuery, useResourceListQuery } from 'infrastructure/query';
import { Permission } from 'infrastructure/query/permission/types';
import { Orthophoto } from 'infrastructure/query/resource/types';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NDVI_VARI_ID, Snapshot } from 'stores/data/types';
import styled from 'styled-components';
import { hexToRgba } from 'components/styles/colors/utils';
import theme from 'components/styles/mui/theme';
import AlertMessage from './AlertMessage';
import SummaryBoard from './SummaryBoard';

const SelectMenuProps = {
  MenuListProps: { style: { backgroundColor: theme.palette.text.primary } },
};
interface Props {
  snapshotId: number;
  permission: Permission;
  children: React.ReactNode;
  snapshotList: Snapshot[];
}
export default function MapItem({
  snapshotId: initialId,
  permission,
  snapshotList,
  children,
}: Props) {
  const location = useLocation();
  const { issueId } = useMatchParams();
  const [selectedId, setSelectedId] = useState(initialId);
  const snapshotId = selectedId ?? initialId;
  const viOption = (location?.state as any)?.viOption ?? NDVI_VARI_ID;

  const orthophotos = useResourceListQuery<Orthophoto>({
    snapshotId,
    permission,
    type: 'ORTHOPHOTO',
  })?.resources;
  const ndviFile = useResourceListQuery({ snapshotId, type: 'NDVI', permission })?.resources;
  const variFile = orthophotos?.[0];

  const resourceId = viOption === NDVI_VARI_ID ? variFile?.id : ndviFile?.[0]?.id;

  const vi = useIssueViQuery({
    snapshotId,
    issueId: issueId as number,
    data: { resourceId },
  })?.data;

  const options = snapshotList?.map((x) => ({
    name: convertDateFormat(x?.takeDate, dateFormat.LOCALE),
    value: x?.id.toString(),
  }));

  return (
    <ItemContainer>
      <ComparedMap snapshotId={snapshotId}>{children}</ComparedMap>
      <Floating>
        <Select
          options={options}
          value={snapshotId}
          onChange={(e) => setSelectedId(+e?.target.value)}
          placeholder={nls.issuePolygonComparedSelectPlaceholder()}
          CustomMenuItem={CustomMenuItem}
          MenuProps={SelectMenuProps}
          className="dark-style"
        />
        {snapshotId && vi?.calculationStatus === 'DONE' && <SummaryBoard vi={vi} />}
        {vi && (vi?.calculationStatus !== 'DONE' || vi?.errorCode) && <AlertMessage vi={vi} />}
      </Floating>
    </ItemContainer>
  );
}

function ComparedMap({ snapshotId, children }: { snapshotId: number; children: React.ReactNode }) {
  if (!snapshotId) return null;
  return (
    <Container>
      <Map>
        <SatelliteLayer />
        <OrthophotoLayer snapshotId={snapshotId} moveCenter={false} />
        {children}
      </Map>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 33.3%;
  flex-basis: 33.3%;
  border: 0.125rem solid ${theme.palette.grey[900]};
  background-color: #000000;
  position: relative;
  min-width: 20rem;
`;

const Floating = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  min-width: 14.25rem;

  .MuiSelect-outlined,
  .MuiSelect-outlined svg {
    background-color: ${theme.palette.text.primary};
    color: ${theme.palette.primary.contrastText};
  }
`;

const CustomMenuItem = styled(StyledMenuItem)<{ $special: boolean }>`
  background-color: ${theme.palette.text.primary};
  color: ${theme.palette.primary.contrastText};

  :hover {
    background-color: ${hexToRgba(theme.palette.primary.contrastText, 0.05)};
  }
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: ${hexToRgba(theme.palette.primary.contrastText, 0.1)};
  }
`;
