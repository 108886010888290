import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import chromeIcon from 'assets/images/icon_chrome@2x.png';
import { MOBILE_MAX_WIDTH } from 'common/policies/screen';
import { nls } from 'infrastructure/locale/language';
import React, { useState } from 'react';
import { isChrome } from 'react-device-detect';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';
import { ContainedButton } from '../button/ContainedButton';
import { IconButton } from '../button/IconButton';

export default function WarningBrowserBar() {
  const [close, setClose] = useState(false);

  const available = isChrome;

  if (available || close) return null;

  return (
    <Wrapper>
      <div className="mainArea">
        <Text>{nls.warningBrowser()}</Text>
        <BrowserButton
          href="https://www.google.co.kr/chrome"
          startIcon={<img src={chromeIcon} alt="chrome-icon" />}
        >
          <ButtonText>{nls.downloadChromeBrowser()}</ButtonText>
        </BrowserButton>
      </div>
      <div className="buttonArea">
        <IconButton onClick={() => setClose(true)}>
          <StyledCloseIcon />
        </IconButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  z-index: ${theme.zIndex.modal};
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${theme.palette.error.dark};
  > .mainArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  > .buttonArea {
    margin-right: 1.5rem;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`;

const Text = styled(Typography).attrs({ variant: 'subtitle1' })`
  color: ${theme.palette.primary.contrastText};
  margin-right: 1.5rem;
`;

const BrowserButton = styled(ContainedButton).attrs({
  component: 'a',
  color: 'default',
  size: 'large',
  target: '_blank',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: ${theme.palette.background.paper};
  cursor: pointer;
  & + & {
    margin-left: 1.5rem;
  }
  .MuiButton-startIcon img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ButtonText = styled(Typography).attrs({ variant: 'button' })`
  color: ${theme.palette.text.primary};
  text-transform: none;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${theme.palette.background.paper};
`;
