import Chip, { ChipProps } from '@material-ui/core/Chip';
import { nls } from 'infrastructure/locale/language';
import styled, { css } from 'styled-components';
import { background } from 'components/styles/colors/background';
import { others } from 'components/styles/colors/others';
import { primary } from 'components/styles/colors/primary';
import { COLOR_CHIP } from 'components/styles/colors/symbols';
import { hexToRgba } from 'components/styles/colors/utils';
import palette from 'components/styles/mui/palette';
import theme from 'components/styles/mui/theme';

/**
 * 도면 타입, CCTV 위치 없음 안내 Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484%3A4089
 */
export type CustomChipProps = ChipProps & { $inactive?: boolean };
export const TypeInfoChip = styled(Chip).attrs((Props: CustomChipProps) => ({
  variant: 'outlined',
  size: Props.size ?? 'small',
  $inactive: Props.$inactive ?? false,
}))<CustomChipProps>`
  border-radius: 0.25rem;
  height: auto;
  background-color: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.grey[300]};

  .MuiChip-label {
    padding: 0.25rem 0.375rem;
    color: ${theme.palette.text.secondary};
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;
  }

  ${({ size }) =>
    size === 'medium' &&
    css`
      .MuiChip-label {
        padding: 0.375rem;
        color: ${theme.palette.text.primary};
        font-size: 0.75rem;
        font-weight: 400;
      }
    `}

  ${({ $inactive }) =>
    $inactive &&
    css`
      .MuiChip-label {
        color: ${theme.palette.grey[500]};
      }
    `}
`;

/**
 * 좌표계 Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484%3A4095
 */
export const CRSChip = styled(TypeInfoChip)`
  background-color: ${theme.palette.grey[200]};
`;

export const getChipStyle = () => css`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  &::after {
    text-decoration: none;
    text-transform: none;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.25rem 0.375rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
  }
`;

/**
 * Beta Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484%3A4087
 * @param className
 */
export const getBetaChip = (className = '.beta') => css`
  ${className} {
    ${getChipStyle()}
    &::after {
      content: 'Beta';
      background-color: ${background.PRIMARY};
      border: 1px solid ${background.PRIMARY};
      color: ${primary.MAIN};
    }
  }
`;

/**
 * Unlimited Beta Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484%3A4065&t=vEbPYXbbehwmkC7a-0
 * @param className
 */
export const getUnlimitedBetaChip = (className = '.beta-grey') => css`
  ${className} {
    ${getChipStyle()}
    &::after {
      content: 'Beta';
      background-color: ${background.PAPER};
      border: 1px solid ${others.BORDER};
      color: ${palette.grey[500]};
    }
  }
`;

/**
 * New Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=2870%3A23230
 * @param className
 */
export const getNewChip = (className = '.new') => css`
  ${className} {
    ${getChipStyle()}
    &::after {
      content: 'New';
      background-color: ${hexToRgba(COLOR_CHIP.PINK, 0.1)};
      border: 1px solid ${hexToRgba(COLOR_CHIP.PINK, 0.1)};
      color: ${COLOR_CHIP.PINK};
    }
  }
`;

/**
 * Base Chip
 * @link https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?node-id=1484%3A13393
 */
export const BaseChip = styled(Chip).attrs({ variant: 'default', size: 'medium' })`
  height: 1.875rem;
  color: ${theme.palette.text.secondary};
`;

export const StatusChip = styled(Chip).attrs({ size: 'small' })<ChipProps & { warning?: boolean }>`
  padding: 0.0625rem 0.625rem;
  border-radius: 0.25rem;
  ${({ warning }) =>
    warning &&
    css`
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.common.white};
    `}
  ${({ color }) =>
    color === 'default' &&
    css`
      background-color: ${theme.palette.grey[400]};
      color: ${theme.palette.text.secondary};
    `}
  > span {
    padding: 0;
    font-weight: 500;
    font-size: 0.8125rem;
  }
`;

/**
 * FileItemRow에 사용하는 Chip
 */
export const ProgressStatusRunningChip = styled(Chip).attrs({
  label: nls.resourceToAnnotationProgressRunningChip(),
  size: 'small',
  color: 'primary',
})`
  background-color: ${theme.palette.grey[300]};
  color: ${theme.palette.text.secondary};
  height: 1.375rem;
`;
