import { navigationBarWidth, sideMenuWidth, topBarHeight } from 'common/policies/sizes';
import TopBar from 'components/common/TopBar';
import { useMatchParams } from 'components/common/hooks';
import NavigationNoticeLayout from 'components/common/layout/NavigationNoticeLayout';
import { nls } from 'infrastructure/locale/language';
import React, { ReactNode } from 'react';
import { Project } from 'stores/data/types';
import styled from 'styled-components';
import { scrollStyle } from 'components/styles/colors/scrollbar';
import palette from 'components/styles/mui/palette';
import SideMenu, { TabType } from './SideMenu';

interface Props {
  project?: Project;
  selectedTab?: TabType;
  children: ReactNode | ReactNode[];
}

export default function ProjectPageLayout({
  project = null,
  selectedTab = 'PROJECT_USER_SETTINGS',
  children,
}: Props) {
  const { projectId } = useMatchParams();
  const showSideMenu = isFinite(projectId);

  return (
    <Layout>
      <NavigationNoticeLayout fixed />
      <TopBar title={project?.name || nls.projectFormHeadingCreating()} />
      {showSideMenu && <FixedSideMenu project={project} selectedTab={selectedTab} />}
      <ContentArea $hasSideMenu={showSideMenu}>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentArea>
    </Layout>
  );
}

const FixedSideMenu = styled(SideMenu)`
  position: fixed;
  margin-left: ${navigationBarWidth};
  z-index: 9;
`;

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: ${topBarHeight};
  background-color: ${palette.background.paper};
`;

const ContentArea = styled.div<{ $hasSideMenu: boolean }>`
  width: 100%;
  padding-left: calc(
    ${navigationBarWidth} + ${({ $hasSideMenu }) => ($hasSideMenu ? sideMenuWidth : '0px')}
  );
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding-top: 3rem;
  padding-left: 12.5rem;
  padding-right: 12.5rem;
  background-color: ${palette.background.paper};
  ${scrollStyle};
`;
