export const keys = {
  focused: (zoneId: number) => ['cctv', 'focused', zoneId],
  list: (zoneId: number) => ['cctv', zoneId],
  detail: (zoneId: number, cctvId: number) => ['cctv', zoneId, cctvId],
  url: ({ subUrl, authId, authPw }: { subUrl: string; authId: string; authPw: string }) => [
    'cctv',
    'url',
    subUrl,
    authId,
    authPw,
  ],
};
