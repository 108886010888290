import TextField from '@material-ui/core/TextField';
import { useMatchParams } from 'components/common/hooks';
import useProj4 from 'components/common/twodmap/useProj4';
import { useProjectQuery } from 'infrastructure/query';
import React from 'react';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';

interface Props {
  index: number;
  value: { x: number; y: number; z?: number };
  handleValue: (key, value) => void;
  decimalPlaces?: number;
  disabledProj4?: boolean;
}

const DEFAULT_COORD = '4326';

/** @todo z 값이 포함되는 경우 확장, proj4 로직 분리 */
export default function CoordinateInput({ index, value, handleValue, decimalPlaces }: Props) {
  const { proj4 } = useProj4();
  const { projectId } = useMatchParams();
  const project = useProjectQuery(projectId)?.data;
  const coordinate = project?.coordinate;
  const [convertedX, convertedY] = proj4?.(DEFAULT_COORD, coordinate, [+value.x, +value.y]) || [
    value.x,
    value.y,
  ];

  function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: 'x' | 'y') {
    /** @todo 임시 disable 처리. 배포 후 개선 작업으로 포함하여 빠르게 수정할 예정 */
    /** proj4 변환한 것 다시 변환해서 보내느 작업 필요 */
    // if (isNaN(+e.target.value)) return;
    // handleValue(key, +e.target.value);
  }

  const x = decimalPlaces ? convertedX.toFixed(decimalPlaces) : convertedX;
  const y = decimalPlaces ? convertedY.toFixed(decimalPlaces) : convertedY;
  return (
    <Container>
      <div id="coordinate-item-index">{index}</div>
      <Input>
        <span>X</span>
        <StyledTextField value={x} onChange={(e) => onChange(e, 'x')} />
      </Input>
      <Input>
        <span>Y</span>
        <StyledTextField value={y} onChange={(e) => onChange(e, 'y')} />
      </Input>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.grey[300]};

  > #coordinate-item-index {
    width: 0.875rem;
    text-align: center;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 0.625rem */
    margin-right: 0.25rem;
  }
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background-color: ${theme.palette.grey[800]};
  flex: 1;

  > span {
    text-align: center;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 0.625rem */
    vertical-align: middle;
    margin-top: 1px;
  }

  &:not(:last-child) {
    margin-right: 0.375rem;
  }
`;

const StyledTextField = styled(TextField)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .MuiInputBase-input {
    padding: 0;
    font-size: 0.65625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 0.65625rem */
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiInputBase-root {
    color: ${theme.palette.primary.contrastText};
    background-color: transparent;
  }
`;
