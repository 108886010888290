import React from 'react';
import styled from 'styled-components';
import LeftSideBar from './LeftSideBar';
import MapArea from './MapArea';
import RightSideBar from './RightSideBar';
import TopBar from './TopBar';

export default function EditorPageLayout({ children }: { children: React.ReactNode }) {
  return (
    <Container>
      {/* <EditorPageLayout.TopBar /> */}
      {/* <EditorPageLayout.LeftSideBar /> */}
      {/* <EditorPageLayout.RightSideBar /> */}
      {/* <EditorPageLayout.Map /> */}
      {children}
    </Container>
  );
}

EditorPageLayout.TopBar = TopBar;
EditorPageLayout.LeftSideBar = LeftSideBar;
EditorPageLayout.RightSideBar = RightSideBar;
EditorPageLayout.MapArea = MapArea;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
