import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { ReactComponent as View9Icon } from 'assets/images/iconView9Custom.svg';
import { ReactComponent as View4Icon } from 'assets/images/icon_view_4.svg';
import { snapshotFullPageTopBarHeight } from 'common/policies/sizes';
import TextButton from 'components/common/button/TextButton';
import VerticalCustomButton from 'components/common/button/VerticalCustomButton';
import { useMatchParams, useURLQueryParams } from 'components/common/hooks';
import { useCctvHooks } from 'components/common/twodmap/useCctvHooks';
import { nls } from 'infrastructure/locale/language';
import { usePermissionQuery } from 'infrastructure/query';
import React, { useEffect } from 'react';
import { Prompt, useHistory } from 'react-router';
import routes from 'routes';
import styled from 'styled-components';
import theme from 'components/styles/mui/theme';
import CctvPlayer from './CctvPlayerItem';

type partCountType = 1 | 4 | 9;
const windowItemSizeProps = { 1: '100%', 4: '50%', 9: '33.3%' };

export default function CctvPlayerPage() {
  const { projectId, zoneId, snapshotId } = useMatchParams();
  const queryParams = useURLQueryParams();
  const partCount = +queryParams.get('part') as partCountType;
  const { data: permission } = usePermissionQuery(projectId);
  const { setCctvFocused, focusedCctv: cctv, cctvs } = useCctvHooks(permission?.cctvGet);
  const history = useHistory();
  const windowItemSize = windowItemSizeProps[partCount];

  useEffect(() => {
    if (![1, 4, 9].includes(partCount)) {
      handlePartChange(1);
    }
  }, [partCount]);

  const focusoutWithoutPosition = (location, action) => {
    if (location.pathname.indexOf('cctvs') < 0 && !cctv?.latitude && !cctv?.longitude) {
      // 현장상황 상세 페이지로 돌아갔을 때, 지도상에서 추가모드가 되지 않도록 수정
      setCctvFocused(false);
    }
    return true;
  };
  const handleFocusedCctvChange = (cctvId: number) => {
    setCctvFocused(cctvs?.find((x) => x.id === cctvId));
  };
  const goSnapshotDetail = () => {
    history.push(routes.snapshot.show.of({ projectId, zoneId, snapshotId, mapType: '2d' }));
  };
  const handlePartChange = (part: number) => {
    history.replace(`${routes.snapshot.cctv.of({ projectId, zoneId, snapshotId })}?part=${part}`);
  };

  return (
    <Container>
      <div className="header-area">
        <StyledButton startIcon={<CloseIcon />} onClick={goSnapshotDetail}>
          {nls.close()}
        </StyledButton>
        <SplitViewButton
          icon={<FullscreenIcon />}
          onClick={() => handlePartChange(1)}
          selected={partCount === 1}
        >
          {nls.snapshotCctvFullScreen()}
        </SplitViewButton>
        <SplitViewButton
          icon={<SvgIcon component={View4Icon} />}
          onClick={() => handlePartChange(4)}
          selected={partCount === 4}
        >
          {nls.cctvPlayerButtonSplit4()}
        </SplitViewButton>
        <SplitViewButton
          icon={<SvgIcon component={View9Icon} />}
          onClick={() => handlePartChange(9)}
          selected={partCount === 9}
        >
          {nls.cctvPlayerButtonSplit9()}
        </SplitViewButton>
      </div>
      <Content>
        {Array(partCount)
          .fill(null)
          .map((_, index) => {
            const cctvId = partCount === 1 ? cctv?.id : cctvs?.[index]?.id;
            return (
              <ItemContainer
                key={`${partCount}${index}${cctvId}`}
                $windowItemSize={windowItemSize}
                $hasBorder={partCount !== 1}
              >
                <CctvPlayer
                  cctvId={cctvId}
                  cctvList={cctvs}
                  onFocusedCctvChange={handleFocusedCctvChange}
                />
              </ItemContainer>
            );
          })}
      </Content>
      <Prompt message={focusoutWithoutPosition} />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-area {
    height: ${snapshotFullPageTopBarHeight};
    display: flex;
    align-items: center;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    background-color: ${theme.palette.text.primary};
    position: relative;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: black;
`;

const ItemContainer = styled.div<{ $windowItemSize: string; $hasBorder: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: row;
  height: ${({ $windowItemSize }) => $windowItemSize};
  flex-basis: ${({ $windowItemSize }) => $windowItemSize};
  ${({ $hasBorder }) => $hasBorder && `border: 1px solid ${theme.palette.primary.contrastText}`}
`;

const StyledButton = styled(TextButton).attrs({ size: 'large' })`
  color: ${theme.palette.primary.contrastText};
  position: absolute;
  right: 0.75rem;
`;

const SplitViewButton = styled(VerticalCustomButton).attrs({ className: 'dark-theme' })``;
